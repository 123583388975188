<template>
  <div>
    
    <form class="mt-6" @submit.prevent="moveToNext">
      <EducationalAlerts 
      title="BLOOD DONOR EDUCATIONAL MATERIAL - Making Your Donation Safe" 
      message="We know that you would not donate unless you think your blood is safe. However, in order for us to assess all risks that may affect you or a patient receiving a transfusion, it is essential that you answer each question completely and accurately. If you don’t understand a question, ask the blood center staff. All information provided is confidential." 
      type="warning"
      :hasMargin="true"
    />
    <EducationalAlerts 
      title="To determine if you are eligible to donate we will:" 
      message="
            <li>Ask about your health and travel</li>
            <li> Ask about medicines you are or have taken</li>
            <li>Ask about your risk for infections that can be transmitted by blood-especially HIV/AIDS and viral hepatitis.</li>
            <li>Take your blood pressure, temperature and pulse</li>
            <li>Take a blood sample to be sure your blood count is acceptable</li>
           "
      type="warning"
    />
    <EducationalAlerts 
      title="If you are eligible to donate we will:"  
      message="
            <li> Clean your arm with an antiseptic. Please inform us if you have any skin allergies.</li>
            <li>Use a new, sterile and disposable needle to collect your blood.</li>
            
           "
      type="warning"
    />
    <EducationalAlerts 
      title="DONOR ELIGIBILITY – SPECIFIC INFORMATION" 
      message="Certain diseases such as HIV/AIDS and hepatitis, can spread through sexual contact and enter your blood stream. We will ask specific questions about sexual contact" 
      type="warning"
    />
      <div class="flex justify-between mt-4">
        <button type="button" class="px-4 py-2 border rounded text-orange-500 border-orange-500" @click="moveToPrevious" >Back</button>
        <CustomButton type="submit" label="next" class="px-4 py-2" bg-class="bg-custom-yellow"  />
       
      </div>
    </form>
  </div>
</template>

<script>
import EducationalAlerts from '@/components/EducationalAlerts.vue';
import CustomButton from '@/components/CustomButton.vue';



export default {
  name: "NewDonorEducationalPamphlet",
  components: {
    EducationalAlerts,
    CustomButton,
  },
  methods: {
    moveToNext() {
      this.$emit('move-to-next-step');
    },
    moveToPrevious() {
      this.$emit('move-to-previous-step');
    }
  },
};
</script>
