<template>
  <div>
    <AlertLayout title="Reminders!"
      message="-Kindly note that only blood units registered on Damu-Sasa will be found by the search. <br> -Non-registered units should 1st be registered."
      type="warning" />
    <div class="px-4">
      <!-- Main Content -->
      <div class="bg-white shadow rounded-lg">
        <!-- Patient and Sample Information -->
        <div class="grid md:grid-cols-2 gap-6 p-6">
          <div>
            <h2 class="text-lg font-semibold mb-4">Patient Information</h2>
            <div class="space-y-3">
              <div class="grid grid-cols-2 py-2">
                <span class="text-gray-600">Patient Name</span>
                <span class="font-medium">{{ requisition.patient?.first_name }}
                  {{ requisition.patient?.last_name }}</span>
              </div>
              <div class="grid grid-cols-2 py-2">
                <span class="text-gray-600">IP/OP No</span>
                <span class="font-medium">{{ requisition.patient?.ipop }}</span>
              </div>
              <div class="grid grid-cols-2 py-2">
                <span class="text-gray-600">Blood Group</span>
                <span class="font-medium">{{ requisition.patient?.blood_group?.description }}</span>
              </div>
              <div class="grid grid-cols-2 py-2">
                <span class="text-gray-600">Ward</span>
                <span class="font-medium">{{ requisition.department?.name }}</span>
              </div>
            </div>
          </div>

          <div>
            <h2 class="text-lg font-semibold mb-4">Sample Information</h2>
            <div class="space-y-3">
              <div class="grid grid-cols-2 py-2">
                <span class="text-gray-600">Sample No</span>
                <span class="font-medium">{{ requisition?.samples?.[0]?.sample_number }}</span>
              </div>
              <div class="grid grid-cols-2 py-2">
                <span class="text-gray-600">Sample Lab No</span>
                <span class="font-medium">{{ requisition?.samples?.[0]?.sample_lab_number }}</span>
              </div>
              <div class="grid grid-cols-2 py-2">
                <span class="text-gray-600">Validity</span>
                <span class="font-medium">{{ daysRemaining }} days remaining</span>
              </div>
              <div class="grid grid-cols-2 py-2">
                <span class="text-gray-600">Status</span>
                <span class="font-medium">{{ requisition?.samples?.[0]?.sample_status }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Sample Edit Log -->
        <div class="px-6 pb-6">
          <h2 class="text-lg font-semibold mb-4">Sample Edit Log</h2>
          <div class="grid md:grid-cols-2 gap-6 p-6">
            <div class="grid grid-cols-2 py-2">
              <span class="text-gray-600">Last edited</span>
              <span class="font-medium">{{ requisition?.samples?.[0]?.gxm_confirmed_by?.first_name }}
                {{ requisition?.samples?.[0]?.gxm_confirmed_by?.last_name }}</span>
            </div>
            <div class="grid grid-cols-2 py-2">
              <span class="text-gray-600">Date and Time</span>
              <span class="font-medium">{{ requisition?.samples?.[0]?.gxm_confirmed_on }}</span>
            </div>
          </div>
        </div>

        <!-- Collection Details Table -->
        <div class="px-6 pb-6">
          <table class="w-full text-left rtl:text-right text-gray-700">
            <thead class="bg-gray-100">
              <tr>
                <th class="px-6 py-3 font-medium text-gray-800 tracking-wider">Date
                  Collected</th>
                <th class="px-6 py-3 font-medium text-gray-800 tracking-wider">Collected
                  by
                </th>
                <th class="px-6 py-3 font-medium text-gray-800 tracking-wider">Date
                  Received
                  at Lab</th>
                <th class="px-6 py-3 font-medium text-gray-800 tracking-wider">Received by
                </th>
                <th class="px-6 py-3 font-medium text-gray-800 tracking-wider">Test to be
                  done</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr>
                <td class="px-6 py-4 whitespace-nowrap">{{ requisition?.sample_collection_date }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ requisition?.samples?.[0]?.sample_received_by }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ requisition?.samples?.[0]?.sample_received_on }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ requisition?.samples?.[0]?.sample_received_by }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ requisition?.samples?.[0]?.test_to_be_done }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Action Buttons -->
        <div class="px-6 pb-6 flex justify-end space-x-4">
          <button class="bg-gray-200 hover:bg-gray-300 text-gray-700 px-4 py-2 rounded" @click="goBackToInventory">
            Back
          </button>
          <button class="bg-custom-yellow hover:bg-yellow-600 text-white px-4 py-2 rounded"
            @click="handleEdit(requisition?.requisition_id)">
            Edit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
// import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';

export default {
  name: "InventoryManagementPage",
  components: {
    AlertLayout,
    // CustomButton
  },
  data() {
    return {
      requisition: [],
    }
  },
  created() {
    this.getRequisitions();
  },
  computed: {
    daysRemaining() {
      const collectionDate = new Date(this.requisition?.sample_collection_date);

      if (isNaN(collectionDate.getTime())) {
        console.error("Invalid date:", this.requisition?.sample_collection_date);
        return "Invalid date";
      }

      const sevenDaysLater = new Date(collectionDate);
      sevenDaysLater.setDate(collectionDate.getDate() + 7);

      const currentDate = new Date();

      const timeDifference = sevenDaysLater - currentDate;
      const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      return daysRemaining > 0 ? daysRemaining : 0;
    }
  },
  methods: {
    goBackToInventory() {
      this.$router.push({ name: "InventoryManagementPage" });
    },

    handleEdit(requisition_id) {
      this.$router.push({
        name: "AddSampleForm",
        params: { requisition_id: requisition_id },
      });
    },
 
    async getRequisitions() {
      try {
        const id = this.$route.params.requisition_id;
        console.log("Fetching details for requisition ID:", id);
        const response = await api.getRequisition(id);
        this.requisition = (response.data || response)[0];
        console.log("Requisition data:", this.requisition);

        this.getRequisitionProducts(this.requisition.requisition_products);
      } catch (error) {
        this.error = error.message || "Failed to fetch requisition details";
      }
    },
  }
};
</script>

<style lang="css" scoped></style>