<template>
  <div>
    <AlertLayout v-if="currentTab === 'donor-registry' || currentTab === 'donor-questionnaire'" title="Reminders!"
      message="Kindly remember to inform the blood donor of the RISKS and BENEFITS of blood donation" type="warning" />
    <AlertLayout v-if="currentTab === 'eligibility-list'" title="Reminders!"
      message="Kindly note that only eligible donors will appear below" type="warning" />
    <AlertLayout v-if="currentTab === 'donor-deferrals'" title="Reminders!" message="<ul>Kindly rember to inform the donor why they have been deferred</ul>
         <ul>Kindly remember to inform the donor that they are free to donate once deferral period is over</ul>
          <ul>Kindly thank the donor for turning up to donate</ul> " type="warning" />
    <AlertLayout v-if="currentTab === 'donation-bookings'" title="Reminders!" message="<ul>Kindly thank the donor for showing up to donate</ul>
         <ul>Kindly note that the number of donors expected below is based on donors who have booked and the capacity you can handle per hour.</ul>
          <ul>Kindly click on the view button for more details</ul> " type="warning" />
    <div class="flex flex-wrap items-center justify-between pb-4 py-1">
      <div class="flex items-center space-x-4">
        <div class="relative">
          <button id="dropdownRadioButton"
            class="inline-flex items-center text-gray-400 bg-white border border-gray-300 rounded-md px-6 py-2"
            type="button" @click="toggleDropdown">
            <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                d="M18.796 4H5.204a1 1 0 0 0-.753 1.659l5.302 6.058a1 1 0 0 1 .247.659v4.874a.5.5 0 0 0 .2.4l3 2.25a.5.5 0 0 0 .8-.4v-7.124a1 1 0 0 1 .247-.659l5.302-6.059c.566-.646.106-1.658-.753-1.658Z" />
            </svg>
            Filters
            <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 4 4 4-4" />
            </svg>
          </button>

          <!-- start dropdown filter -->
          <template v-if="currentTab === 'donor-registry' || currentTab === 'eligibility-list'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <div class="flex">
                <button type="button"
                  class="ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex text-gray-500 items-center justify-end h-8 w-8 hover:text-custom-yellow"
                  @click="cancelFilters" aria-label="Close">
                  <span class="sr-only">Dismiss</span>
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
              </div>
              <form @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="donationProcess" class="block mb-2 text-sm font-medium text-gray-900">Blood Group
                  </label>
                  <select v-model="filters.bloodGroup"
                    class="bg-gray-50 border border-gray-200 text-gray-900 rounded-md block w-full py-2 px-1 dark:placeholder-gray-400">
                    <option disabled selected>Select blood group</option>
                    <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.description">
                      {{ group.description }}
                    </option>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="dateRegistered" class="block mb-2 text-sm font-medium text-gray-900">Date
                    Registered</label>
                  <input type="text" id="dateRegistered" v-model="filters.dateRegistered"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="mb-4">
                  <label for="gender" class="block mb-2 text-sm font-medium text-gray-900">Gender</label>
                  <select id="gender" v-model="filters.gender"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="donationProcess" class="block mb-2 text-sm font-medium text-gray-900">Donation
                    Process</label>
                  <select id="donationProcess" v-model="filters.donationProcess"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select</option>
                    <option value="Normal">Normal</option>
                    <option value="Apheresis">Apheresis</option>
                  </select>
                </div>
                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </template>

          <template v-if="currentTab === 'donor-referrals'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <form @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="dateRegistered" class="block mb-2 text-sm font-medium text-gray-900">Date
                    Registered</label>
                  <input type="text" id="dateRegistered" v-model="filters.dateRegistered"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="mb-4">
                  <label for="gender" class="block mb-2 text-sm font-medium text-gray-900">Gender</label>
                  <select id="gender" v-model="filters.gender"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Female">Other</option>
                  </select>
                </div>
                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </template>

          <template v-if="currentTab === 'donor-deferrals'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <form @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="dateRegistered" class="block mb-2 text-sm font-medium text-gray-900">Date
                    Deferred</label>
                  <input type="text" id="dateRegistered" v-model="filters.dateRegistered"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="mb-4">
                  <label for="nextDonationDate" class="block mb-2 text-sm font-medium text-gray-900">Next
                    Donation Date</label>
                  <input type="text" id="nextDonationDate" v-model="filters.nextDonationDate"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </template>



          <template v-if="currentTab === 'donor-questionnaire'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <form @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="questionnaireStatus" class="block mb-2 text-sm font-medium text-gray-900">Questionnaire
                    Status</label>
                  <select id="questionnaireStatus" v-model="filters.questionnaireStatus"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="new" :selected="filters.questionnaireStatus === 'new'">New</option>
                    <option value="archived" :selected="filters.questionnaireStatus === 'archived'">Archived</option>
                  </select>
                </div>

                <div class="mb-4">
                  <label for="dateRegistered" class="block mb-2 text-sm font-medium text-gray-900">Date
                    Registered</label>
                  <input type="text" id="dateRegistered" v-model="filters.dateRegistered"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="mb-4">
                  <label for="gender" class="block mb-2 text-sm font-medium text-gray-900">Gender</label>
                  <select id="gender" v-model="filters.gender"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Female">Other</option>
                  </select>
                </div>

                <div class="mb-4">
                  <label for="bookingDate" class="block mb-2 text-sm font-medium text-gray-900">Booking Date</label>
                  <input type="date" id="bookingDate" v-model="filters.bookingDate"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
            <!-- end dropdown filter  -->
          </template>


          <!-- start dropdown filter  -->
          <template v-if="currentTab === 'recalled-donors'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <form @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="donationProcess" class="block mb-2 text-sm font-medium text-gray-900">Blood Group
                  </label>
                  <select v-model="filters.bloodGroup"
                    class="bg-gray-50 border border-gray-200 text-gray-900 rounded-md block w-full py-2 px-1 dark:placeholder-gray-400">
                    <option disabled selected>Select blood group</option>
                    <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.description">
                      {{ group.description }}
                    </option>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="dateRegistered" class="block mb-2 text-sm font-medium text-gray-900">Appeal Date
                  </label>
                  <input type="text" id="dateRegistered" v-model="filters.dateRegistered"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="mb-4">
                  <label for="gender" class="block mb-2 text-sm font-medium text-gray-900">Gender</label>
                  <select id="gender" v-model="filters.gender"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </template>

        </div>
        <div class="relative flex">
          <input type="text" v-model="searchQuery" id="table-search"
            class="px-4 py-2 text-gray-800 border border-gray-300 rounded-sm dark:border-gray-300 dark:placeholder-gray-400"
            placeholder="Search" />
          <button type="submit" class="bg-custom-yellow text-white rounded-r px-2 md:px-3 py-0 md:py-1">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
            </svg>
          </button>
        </div>
      </div>
      <div class="flex items-center space-x-4 mt-4 sm:mt-0">
        <template v-if="currentTab === 'donor-registry'">
          <CustomButton label="New Donor" :icon-path="'M5 12h14m-7 7V5'" bg-class="bg-custom-yellow"
            @click="handleNewDonorRegistration" />
        </template>
        <template v-else-if="currentTab === 'donor-questionnaire'">
          <CustomButton label="New Questionnaire" :icon-path="'M5 12h14m-7 7V5'" bg-class="bg-custom-yellow"
            @click="handleNewQuestionnaire" />
        </template>
        <template v-else-if="currentTab === 'eligibility-list'">
          <h2>Selected Donors: {{ selectedCount }}</h2>
          <CustomButton label="Recall Donor(s)" bg-class="bg-custom-yellow" @click="handleRecallDonors" />
        </template>
      </div>
    </div>
    <div class="tabs flex border-b mb-4 mt-6">
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'donor-registry' }" @click="currentTab = 'donor-registry'">
        Donor Registry
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'donor-questionnaire' }"
        @click="currentTab = 'donor-questionnaire'">
        Donor Questionnaire
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'eligibility-list' }" @click="currentTab = 'eligibility-list'">
        Eligibility List
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'donor-deferrals' }" @click="currentTab = 'donor-deferrals'">
        Donor Deferrals
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'donor-referrals' }" @click="currentTab = 'donor-referrals'">
        Donor Referrals
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'recalled-donors' }" @click="currentTab = 'recalled-donors'">
        Recalled Donors
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'donation-bookings' }"
        @click="currentTab = 'donation-bookings'">
        Donation Bookings
      </button>
    </div>
    <component :is="currentTabComponent" ref="currentTabComponent" :search-query="searchQuery"
      @selection-change="updateSelectedDonors">
    </component>
  </div>
</template>

<script>
import AlertLayout from '@/components/AlertLayout.vue';
import CustomButton from '@/components/CustomButton.vue';
import DonorRegistryTab from '../tabs/DonorRegistryTab.vue';
import DonorQuestionnaireTab from '../tabs/DonorQuestionnaireTab.vue';
import EligibilityListTab from '../tabs/EligibilityListTab.vue';
import DonorDeferralsTab from '../tabs/DonorDeferralsTab.vue';
import DonorReferralsTab from '../tabs/DonorReferralsTab.vue';
import DonationAppealsTab from '../tabs/DonationAppealsTab.vue';
import DonationBookingsTab from '../tabs/DonationBookingsTab.vue';
import api from '@/services/api';
import { SweetAlertService } from '@/services/SweetAlertService';

export default {
  name: 'DonorManagementPage',
  components: {
    AlertLayout,
    CustomButton,
    DonorRegistryTab,
    DonorQuestionnaireTab,
    EligibilityListTab,
    DonorDeferralsTab,
    DonorReferralsTab,
    DonationAppealsTab,
    DonationBookingsTab
  },
  data() {
    return {
      searchQuery: '',
      filters: {
        bloodGroup: '',
        dateRegistered: '',
        gender: '',
        donationProcess: '',
        bookingDate: '',
        questionnaireStatus: 'new'
      },
      selectedCount: 0,
      selectedDonorIds: [],
      isDropdownOpen: false,
      currentTab: 'donor-registry',
      bloodGroups: [],
    };
  },

  computed: {
    currentTabComponent() {
      switch (this.currentTab) {
        case 'donor-registry':
          return 'DonorRegistryTab';
        case 'donor-questionnaire':
          return 'DonorQuestionnaireTab';
        case 'eligibility-list':
          return 'EligibilityListTab';
        case 'donor-deferrals':
          return 'DonorDeferralsTab';
        case 'donor-referrals':
          return 'DonorReferralsTab';
        case 'recalled-donors':
          return 'DonationAppealsTab';
        case 'donation-bookings':
          return 'DonationBookingsTab';
        default:
          return 'DonorRegistryTab';
      }
    }
  },

  created() {
    this.fetchBloodGroups();
  },

  methods: {
    updateSelectedDonors({ count, donorIds }) {
      this.selectedCount = count;
      this.selectedDonorIds = donorIds;
    },

    async handleRecallDonors() {
      if (this.selectedDonorIds.length === 0) {
        // Show warning popup if no donors are selected
        await SweetAlertService.showWarning('No Donors Selected', 'Please select donors to recall.');
        return;
      }

      try {
        await api.createDonorRecall({
          donor_recall: {
            donor_ids: this.selectedDonorIds,
          },
        });

        // Notify the user of success
        await SweetAlertService.showSuccess('Recalls Made', 'The selected donors have been recalled successfully.');
        this.selectedDonorIds = [];
        this.selectedCount = 0;
        // this.$router.go(0);
        this.currentTab = 'recalled-donors';

      } catch (error) {
        // Handle any errors
        // SweetAlertService.showError('Error', 'There was an issue recalling the donors.');
        SweetAlertService.showError('Error', error.message || 'An unknown error occurred.');
      }
    },

    handleNewDonorRegistration() {
      this.$router.push({ name: 'NewDonorRegistrationPage' });
    },

    handleNewQuestionnaire() {
      this.$router.push({ name: 'NewDonorQuestionnairePage' });
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    applyFilters() {
      console.log('Applying filters:', this.filters);
      this.applyFiltersToCurrentTab();
      this.isDropdownOpen = false;
    },

    applyFiltersToCurrentTab() {
      if (this.$refs.currentTabComponent && typeof this.$refs.currentTabComponent.applyFilters === 'function') {
        this.$refs.currentTabComponent.applyFilters(this.filters);
      } else {
        console.error('applyFilters method not found on current tab component');
      }
    },

    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response;
      } catch (error) {
        // Notify the user of the error
        SweetAlertService.showError('Error', 'Failed to fetch blood groups.');
        console.error('Error fetching blood groups:', error);
      }
    },

    cancelFilters() {
      this.filters = {
        bloodGroup: '',
        dateRegistered: '',
        gender: '',
        donationProcess: '',
        bookingDate: '',
        questionnaireStatus: ''
      };
      this.applyFiltersToCurrentTab();
      this.isDropdownOpen = false;
    }
  }
}
</script>

<style lang="css" scoped>
/* Add any additional styles if needed */
</style>