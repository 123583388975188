<template>
  <div>
    <AlertLayout
      title="Reminders!"
      message="Incorrect information will lead to nurses being unable to request for blood except for theatre."
      type="warning"
    />
    <div class="mx-auto p-10 border border-t-0">
      <h2 class="text-lg font-bold mb-6">Enter role details</h2>
      <form class="grid grid-cols-1 md:grid-cols-1 gap-5">
        <div>
          <label class="block mb-1" for="theatreDay">Role name <span class="text-red-500">*</span></label>
          <input type="text"
            v-model="formData.name"
            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter role name" required>
        </div>
        <div>
          <label class="block mb-1" for="reason">Role description<span class="text-red-500">*</span></label>
          <input type="text"
            v-model="formData.description"
            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter role description" required>
        </div>
      </form>
      <div class="flex items-center justify-between mt-8">
        
          <button  @click="handleBackToUserManagement"
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Back</button>
        <CustomButton
          label="Save"
          bg-class="bg-custom-yellow px-8 py-3"
          @click="handleSubmitNewRole"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: "NewRoleForm",
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      user: '',
      formData: {
        name: '',
        description: '',
      },
      status: 'active',
      error: null,
    };
  },
  mounted() {
    this.user = this.$store.getters["auth/user"];
  },
  methods: {
    validateForm() {
      const emptyFields = [];
      for (const [key, value] of Object.entries(this.formData)) {
        if (value === '') {
          emptyFields.push(key.replace(/_/g, ' '));
        }
      }
      return emptyFields;
    },
    handleBackToUserManagement() {
      this.$router.push({ name: 'UserManagementPage' });
    },
    async handleSubmitNewRole() {
      const data = {
        facility_id: this.user.facility_id,
        name: this.formData.name,
        description: this.formData.description,
      };

      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        await api.createRole(data);
        await SweetAlertService.showSuccess('Success!', 'Role saved successfully!.');
        this.$router.push({ name: 'UserManagementPage' });
      } catch (error) {
        this.error = error.message || 'Failed to create new role';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', 'Error saving role.');
      }
    },
  },
};
</script>

<style lang="css" scoped>
/* Your existing styles */
</style>
