<template>
  <div class="relative border-l border-r">
    <div class="flex items-center justify-between">
      <div v-for="(step, index) in steps" :key="index" class="flex flex-col items-start relative w-full">
        <!-- Container for Circle and Title -->
        <div class="flex items-center">
          <!-- Step Circle for Current Step -->
          <div v-if="step.id === currentStep" class="relative z-10 flex items-center justify-center w-6 h-6 shrink-0">
            <img
            class="object-cover mb-7 h-9"
            src="../assets/icons/Icon.svg"
            alt="Your avatar"
          />
          </div>

          <!-- Checkmark for Previous Steps -->
          <div v-else-if="index < currentStepIndex" class="text-green-500">
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>

          <!-- Future Steps -->
          <div v-else >
            <img
            class="object-cover mb-4  h-6"
            src="../assets/icons/circle.svg"
            alt="Your avatar"
            viewBox="0 0 24 24"
          />
          </div>

          <!-- Step Title and Subtitle -->
          <div class="ml-2 m-4">
            <h3 class="font-semibold text-sm text-left">{{ step.title }}</h3>
            <p class="text-xs text-gray-500 text-left">{{ step.subtitle }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Progress Line Background -->
    <div class="absolute bottom-0 left-0 w-full h-1 bg-gray-200 mt-2"></div>
    <!-- Progress Line Filled -->
    <div class="absolute bottom-0 left-0 h-1 bg-custom-yellow" :style="{ width: `${progressWidth}%` }"></div>
  </div>
</template>

<script>
export default {
  name: 'StepperProgress',
  props: {
    steps: {
      type: Array,
      required: true
    },
    currentStep: {
      type: String,
      required: true
    }
  },
  computed: {
    currentStepIndex() {
      return this.steps.findIndex(step => step.id === this.currentStep);
    },
    progressWidth() {
      return ((this.currentStepIndex + 1) / this.steps.length) * 100;
    }
  }
}
</script>

<style scoped>
.bg-custom-yellow {
  background-color: #F8951E;
}
</style>