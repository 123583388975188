<template>
  <div class="bg-cover bg-center bg-no-repeat bg-custom-login-image">
    <div class="flex items-center justify-center h-screen px-6 bg-gray-900/80">
      <div class="max-w-sm p-6 bg-custom-blue shadow-md w-[400px] h-[520px] top-[156px] left-[520px] rounded-[16px]">
        <div class="flex items-center justify-center">
          <img class="object-cover w-[200px] h-[64px] top-[23px]" src="@/assets/images/damu-sasa-logo.svg"
            alt="Your avatar" />
        </div>
        <div class="flex justify-center font-thin text-white text-xs">
          <div>Welcome to damu sasa,</div>
        </div>
        <div class="flex justify-center font-thin text-white text-xs">
          <div>
            Advancing <span class="text-custom-yellow">Healthcare</span> Tech!
          </div>
        </div>

        <!-- Error Alert -->
        <transition name="fade">
          <div v-if="errorMessage" class="p-4 mb-1 mt-3 text-sm text-red-800 rounded-lg bg-orange-50 dark:text-red-400"
            role="alert">
            <span class="font-medium">{{ errorMessage }}</span>
          </div>
        </transition>

        <form class="mt-8 px-3" @submit.prevent="login">
          <label class="block">
            <span class="text-sm text-white">Email or Phone</span>
            <input type="text" class="block w-full mt-1 px-3 py-2 dark:placeholder-gray-400 rounded-md" v-model="email"
              placeholder="johndoe@mail.com or 0712345678" />
          </label>

          <label class="block mt-4 relative">
            <span class="text-sm text-white">Password</span>
            <input ref="passwordField" :type="passwordVisible ? 'text' : 'password'"
              class="block w-full mt-1 px-3 py-2 dark:placeholder-gray-400 rounded-md" v-model="password"
              placeholder="your password" />
            <div class="absolute inset-y-4 py-8 right-0 pr-3 flex items-center text-sm leading-5">
              <button type="button" id="togglePassword"
                class="text-gray-500 focus:outline-none focus:text-gray-600 hover:text-gray-600"
                @click="togglePasswordVisibility">
                <svg v-if="passwordVisible" class="w-6 h-6 text-gray-800 dark:text-gray-500" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="m4 15.6 3.055-3.056A4.913 4.913 0 0 1 7 12.012a5.006 5.006 0 0 1 5-5c.178.009.356.027.532.054l1.744-1.744A8.973 8.973 0 0 0 12 5.012c-5.388 0-10 5.336-10 7A6.49 6.49 0 0 0 4 15.6Z" />
                  <path
                    d="m14.7 10.726 4.995-5.007A.998.998 0 0 0 18.99 4a1 1 0 0 0-.71.305l-4.995 5.007a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.402.211.59l-4.995 4.983a1 1 0 1 0 1.414 1.414l4.995-4.983c.189.091.386.162.59.211.011 0 .021.007.033.01a2.982 2.982 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z" />
                  <path
                    d="m19.821 8.605-2.857 2.857a4.952 4.952 0 0 1-5.514 5.514l-1.785 1.785c.767.166 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z" />
                </svg>
                <svg v-else class="w-6 h-6 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                  width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-width="2"
                    d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                  <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
              </button>
            </div>
          </label>

          <div class="mt-6">
            <button type="submit"
              class="w-full font-semibold leading-relaxed px-4 py-2 text-md text-center text-white bg-custom-yellow rounded-md focus:outline-none hover:text-custom-blue">
              Log in
            </button>
          </div>
          <div class="flex justify-center mt-4 text-white">
            <div>
              <a class="block text-sm hover:underline" href="#">Forgot your password?</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";

export default {
  name: "LoginPage",
  data() {
    return {
      email: "",
      password: "",
      passwordVisible: false,
      errorMessage: "", // Property for error message
    };
  },
  methods: {
    validateFields() {
      const emptyFields = [];
      if (!this.email) emptyFields.push('Email/Phone Number');
      if (!this.password) emptyFields.push('Password');

      return emptyFields;
    },

    async login() {
      // Clear any previous error messages
      this.errorMessage = "";

      const emptyFields = this.validateFields();
      if (emptyFields.length > 0) {
        this.errorMessage = `Please fill the following field(s): ${emptyFields.join(", ")}`;
        this.hideErrorMessage();
        return;
      }

      try {
        const response = await api.login({
          email: this.email,
          password: this.password,
        });

        const { data, token, roles } = response;
        // Dispatch to Vuex store
        this.$store.dispatch("auth/login", { data, token, roles });

        // Redirect after successful login
        this.$router.push("/donor-management");
      } catch (error) {
        // Set error message for wrong credentials
        this.errorMessage = 'Wrong email or password';
        this.hideErrorMessage();
      }
    },

    hideErrorMessage() {
      setTimeout(() => {
        this.errorMessage = "";
      }, 3500);
    },

    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
      const passwordField = this.$refs.passwordField;
      passwordField.type = this.passwordVisible ? "text" : "password";
    },
  },
};
</script>

<style scoped>
/* CSS for the fade transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>