// main.js

import { createApp } from 'vue';
import './assets/main.css';
import App from './App.vue';
import router from './router';
import store from './store';

import DashboardLayout from './components/DashboardLayout.vue';

const app = createApp(App);

app.component('default-layout', DashboardLayout);

router.beforeEach((to, from, next) => {
  
  if (to.meta.requiresAuth && !store.getters['auth/token']) {
    
    next({ name: 'Login' });
  } else {
    
    next();
  }
});

app.use(router);
app.use(store);

// Mount the app
app.mount('#app');
