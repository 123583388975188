<template>
  <div>
    <AlertLayout title="Reminders!" message="<ul>
  <li>You will receive an sms / email notification once the requisition you sent is received and processed by the receiving entity.</li>
  <li>An 'Expired' status means a new blood sample has to be drawn from the patient and a new requisition form manually filled, should you choose the more blood option.</li>
  <li> Click on units amount to 'REQUEST FOR MORE BLOOD' for that particular patient.</li></ul>" type="warning" />
    <div class="flex justify-end mt-5 space-x-2">
      <button v-if="requisition && (!requisition.samples || requisition.samples.length > 0)" type="submit"
        class="px-4 py-2 ml-3 bg-custom-yellow text-white rounded" @click="toggleProductsDropdown">
        View Products
      </button>
      <button
  v-if="requisition && (!requisition.samples || requisition.samples.length === 0 || requisition.samples.some(sample => sample.sample_status === 'rejected')) && flag == 'inventory_management'"
  type="submit" class="px-4 py-2 ml-3 bg-custom-yellow text-white rounded"
  @click="redirectToAddSample(requisition.requisition_id)">
  Add Sample
</button>
    </div>
    <!-- Dropdown table -->
    <div class="bg-white p-8 mt-2 rounded-lg shadow-inner overflow-hidden" v-if="showProductsDropdown">
      <table class="min-w-full divide-y divide-gray-200 border border-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-4 py-2 text-left text-base tracking-wider">
              Product
            </th>
            <th class="px-4 py-2 text-left text-base tracking-wider">
              Unit(s) Requested/Assigned
            </th>
            <th class="px-4 py-2 text-left text-base tracking-wider">
              Volume Requested
            </th>
            <th class="px-4 py-2 text-left text-base tracking-wider">
              Issued
            </th>
            <th class="px-4 py-2 text-left text-base tracking-wider"></th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(product, index) in requisition.requisition_products" :key="index">
            <td class="px-4 py-1 whitespace-nowrap text-base">
              {{ product.name || "N/A" }}
            </td>
            <td class="px-4 py-1 whitespace-nowrap text-base">
              {{ parseFloat(product.quantity) % 1 === 0 ? parseInt(product.quantity) : product.quantity }}/{{
                product.assigned_units.length }}
            </td>
            <td class="px-4 py-1 whitespace-nowrap text-base">
              {{ product.volume || "N/A" }}
            </td>
            <td class="px-4 py-1 whitespace-nowrap text-base">
              {{ product.assigned_units.filter(unit => unit.status === 'Issued').length }}
            </td>
            <td class="px-4 py-1 whitespace-nowrap text-base">
              <div class="flex justify-end space-x-2">
                <button type="button" class="px-4 py-1 bg-custom-yellow text-white rounded"
                  @click="redirectToAssignUnit((product.requisition_product_id))"
                  v-if="requisition.samples[0].sample_status != 'rejected' &&
                    product.assigned_units.length < product.quantity && requisition.status != 'Cancelled' && flag == 'inventory_management'">
                  Assign Unit
                </button>

                <button type="button" class="px-4 py-1 bg-custom-yellow text-white rounded"
                  @click="handlePrintSticker(product)" v-if="requisition.samples[0].sample_status != 'rejected' &&
                    product.assigned_units.length > 0 && requisition.status != 'Cancelled'">
                  Print Sticker
                </button>

                <button type="button" class="px-4 py-1 bg-custom-yellow text-white rounded"
                  @click="viewIssueOutPopup(product)"
                  v-if="requisition.samples[0].sample_status !== 'rejected' &&
                    product.assigned_units.length > 0 &&
                    product.quantity > product.assigned_units.filter(issued => issued.status === 'Issued').length && requisition.status != 'Cancelled' && flag == 'inventory_management'">
                  Issue Out
                </button>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div v-if="showProductsDropdown"
      class="fixed inset-0 bg-gray-900 bg-opacity-60 overflow-y-hidden h-full w-full flex items-center justify-center text-base">
      <div class="bg-white p-8 rounded-lg shadow-xl w-6/12 overflow-hidden">

      </div>
    </div> -->

    <div class="px-4">
      <h3 class="flex justify-start text-lg mt-5 mb-3">Requisition status: <span
          class="font-semibold dark:text-custom-yellow">{{ requisition?.status ?? '' }}</span></h3>
      <h3 class="text-lg font-semibold mt-5 mb-3">Patient Details</h3>

      <div>
        <div v-if="requisition" class="grid grid-cols-2 gap-4">
          <!-- Left Column -->
          <div class="space-y-6">
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Name</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.patient.first_name }}
                {{ requisition.patient.last_name }}
              </p>
            </div>

            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Date of birth</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.patient.dob }}
              </p>
            </div>
          </div>

          <div class="space-y-6">
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Gender</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.patient.gender }}
              </p>
            </div>
          </div>
        </div>

        <div v-else>Loading requisition details...</div>

        <h3 class="text-lg font-semibold mt-9 mb-3">
          Patient Medical Information
        </h3>

        <div v-if="requisition" class="grid grid-cols-2 gap-4">
          <!-- Left Column -->
          <div class="space-y-6">
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Blood group</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.patient.blood_group.description }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Patient Type</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.patient.patient_type }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">IP/OP No</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.patient.ipop }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Ward</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.department.name }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Bed no</label>
              <p class="text-base font-semibold mt-3">{{ requisition.bed.name }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Patient Situation</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.patient_situation }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Patient Intent</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.patient_intent }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Reason for transfusion</label>
              <p class="text-base font-semibold mt-3">{{ requisition.transfusion_reason }} </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Patient HB</label>
              <p class="text-base font-semibold mt-3">{{ requisition.patient_hb }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Platelets level</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.platelets_level }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Patient INR</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.patient_inr }}
              </p>
            </div>
          </div>
          <!-- Right Column -->
          <div class="space-y-6">
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Consultant</label>
              <p class="text-base font-semibold mt-3">{{ requisition.consultant.first_name }} {{
                requisition.consultant.last_name }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Nurse in-charge</label>
              <p class="text-base font-semibold mt-3">{{ requisition.nurse_in_charge.first_name }} {{
                requisition.nurse_in_charge.last_name }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Previous Transfusion Date</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.previous_transfusion_date }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Reaction to Transfusion</label>
              <p class="text-base font-semibold mt-3">{{ requisition.reaction ? 'Yes' : 'No' }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Signed consent form</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.consent?.signed ? 'Yes' : 'No' }}
              </p>
            </div>

            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Date consent form signed</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.consent?.date }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Sample Collected</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.pre_transfusion_sample ? 'Yes' : 'No' }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Date sample collected</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.sample_collection_date }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Contact Nurse</label>
              <p class="text-base font-semibold mt-3">{{ requisition.contact_nurse.first_name }} {{
                requisition.contact_nurse.last_name }}</p>
            </div>

            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Product(s) for which consent form signed</label>
              <p class="text-base font-semibold mt-3"></p>
            </div>
          </div>
        </div>
        <div v-else>Loading requisition details...</div>

        <h3 class="text-lg font-semibold mt-9 mb-3">Sample Details</h3>

        <div v-if="requisition" class="grid grid-cols-2 gap-4">
          <!-- Left Column -->
          <div class="space-y-6">
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Sample No</label>
              <p class="text-base font-semibold mt-3">{{ requisition.samples.length > 0 ? requisition.samples[0].sample_number
                : '' }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Sample Status</label>
              <p class="text-base font-semibold mt-3">{{ requisition.samples.length > 0 ? requisition.samples[0].sample_status
                : '' }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Test to be Done</label>
              <p class="text-base font-semibold mt-3">{{ requisition.samples.length > 0 ?
                requisition.samples[0].test_to_be_done : '' }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Lab No</label>
              <p class="text-base font-semibold mt-3">{{ requisition.samples.length > 0
                ? requisition.samples[0].sample_lab_number : '' }}</p>
            </div>
          </div>

          <div class="space-y-6">
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Received by</label>
              <p class="text-base font-semibold mt-3">{{ requisition.samples.length > 0 ?
                requisition.samples[0].sample_received_by : '' }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Rejected Reason</label>
              <p class="text-base font-semibold mt-3">{{ requisition.samples.length > 0 ?
                requisition.samples[0].rejection_reason : '' }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Corrective Action</label>
              <p class="text-base font-semibold mt-3">{{ requisition.samples.length > 0 ?
                requisition.samples[0].corrective_action : '' }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Corrected by</label>
              <p class="text-base font-semibold mt-3">{{ requisition.samples.length > 0 ?
                requisition.samples[0].corrective_action_by : '' }}</p>
            </div>
          </div>
        </div>

        <div v-else>Loading requisition details...</div>

        <h3 class="text-lg font-semibold mt-9 mb-3">Requisition Details</h3>

        <div v-if="requisition" class="grid grid-cols-2 gap-4">
          <!-- Left Column -->
          <div class="space-y-6">

            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Blood Unit Assigned</label>
              <div class="text-base font-normal mt-3">
                <div v-for="(product, index) in requisition.requisition_products" :key="index">
                  <span v-if="product.assigned_units.length > 0">
                    {{ product.assigned_units.map(unit => unit.pint_serial).join(', ') }}
                  </span>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Blood Unit Issued</label>
              <div class="text-base font-normal mt-3">
                <div v-for="(product, index) in requisition.requisition_products" :key="index">
                  <span v-if="product.assigned_units.length > 0">
                    {{ product.assigned_units
                      .filter(unit => unit.status === 'Issued')
                      .map(unit => unit.pint_serial)
                      .join(', ') }}
                  </span>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Lab Tech</label>
              <p class="text-base font-semibold mt-3">
                {{ requisition.samples ? requisition.samples[0]?.gxm_confirmed_by?.first_name : '' }}
                {{ requisition.samples ? requisition.samples[0]?.gxm_confirmed_by?.last_name : '' }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Collector</label>
              <p class="text-base font-semibold mt-3"></p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Time Blood is ready for Collection</label>
              <p class="text-base font-semibold mt-3"></p>
            </div>
          </div>


          <div class="space-y-6">

            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Time Blood Collected</label>
              <p class="text-base font-semibold mt-3"></p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Time Transfusion Commenced</label>
              <p class="text-base font-semibold mt-3"></p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Time Transfusion Ended</label>
              <p class="text-base font-semibold mt-3"></p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-base font-normal mt-3">Turn Around Time</label>
              <p class="text-base font-semibold mt-3"></p>
            </div>
          </div>
        </div>

        <div v-else>Loading requisition details...</div>

        <div class="flex justify-end mt-5 space-x-2">
          <button v-if="flag == 'clinical_management'"
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" @click="cancelRequisition">
            Cancel Requisition
          </button>
          <button v-if="flag == 'clinical_management'" type="submit"
            class="px-4 py-2 ml-3 bg-custom-yellow text-white rounded"
            @click="redirectToEditRequisitionForm(requisition.requisition_id)">
            Edit
          </button>
          <button v-if="flag == 'clinical_management'" type="submit"
            class="px-4 py-2 ml-3 bg-custom-yellow text-white rounded" @click="viewDifferentWardPopup">
            Move to Different Ward
          </button>
        </div>
      </div>

      <div v-if="showIssueOutPopup"
        class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
        <div class="bg-white p-8 rounded-lg shadow-xl w-1/2 max-w-3xl">
          <div class="flex items-center">
            <h2 class="text-lg font-bold mb-4">Unit Issue Out Confirmation</h2>
            <button type="button"
              class="ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex text-gray-800 items-center justify-center h-8 w-8"
              @click="closeIssueOutPopup" aria-label="Close">
              <span class="sr-only">Dismiss</span>
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button>
          </div>
          <div>
            <div v-for="unit in selectedProduct" :key="unit.assigned_units_id" class="flex items-center mb-5 mt-5">
              <input :id="'checkbox-table-search-' + unit.assigned_units_id" :value="unit.assigned_units_id"
                type="checkbox"
                class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 mr-3 rounded focus:ring-custom-yellow"
                v-model="selectedUnitIds" />
              <label :for="'checkbox-unit-' + unit.assigned_units_id" class="sr-only">checkbox</label>
              <h4 class="text-md font-semibold">Issue out unit {{ unit.pint_serial }}.</h4>
            </div>
            <div>
              <label class="block mb-2 text-gray-700 font-medium" for="collector">Collectors Name </label>
              <div class="relative">
                <select id="collector"
                  class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                  v-model="selectedIssuedBy" required>
                  <option value="" disabled selected>Select Nurse</option>
                  <option v-for="collector in filteredConsultants" :key="collector.user_id" :value="collector.user_id">
                    {{ collector.first_name }} {{ collector.last_name }}
                  </option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-5 space-x-2">
              <button type="button" @click="closeIssueOutPopup"
                class="px-4 py-2 bg-gray-200 text-gray-800 rounded">Cancel</button>
              <button type="submit" class="px-4 py-2 ml-3 bg-custom-yellow text-white rounded"
                @click="issueOut">Submit</button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showDifferentWardPopup"
        class="fixed inset-0 bg-gray-800 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
        <div class="bg-white p-8 rounded-lg shadow-xl w-6/12">
          <div>
            <label class="block mb-4 text-gray-700 font-medium" for="blood_group">Move Patient to a Different Ward
              <span class="text-red-500">*</span></label>
            <div class="relative">
              <select id="blood_group_id" v-model="formData.department_id"
                class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                required>
                <option value="" disabled selected>Select Ward</option>
                <option v-for="ward in departments" :key="ward.department_id" :value="ward.department_id">
                  {{ ward.name }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            <div class="flex justify-end mt-6 space-x-2">
              <button type="button" @click="closeDifferentWardPopup"
                class="px-4 py-2 bg-gray-200 text-gray-800 rounded">
                Cancel
              </button>
              <CustomButton @click="moveDepartment" label="Submit" bg-class="bg-custom-yellow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import AlertLayout from "@/components/AlertLayout.vue";
import api from "@/services/api";
import SweetAlertService from "@/services/SweetAlertService";
import hospitalLogo from '@/assets/images/Jiji-Health-Blue-Logo.png';

export default {
  name: "FacilityRequisitionDetailsPage",
  components: {
    CustomButton,
    AlertLayout,
  },
  data() {
    return {
      showProductsDropdown: false,
      showDifferentWardPopup: false,
      showIssueOutPopup: false,
      requisition: null,
      flag: null,
      error: null,
      selectedUnitIds: [],
      assignedUnits: [],
      selectedIssuedBy: "",
      selectedProduct: null,
      formData: {
        department_id: "",
      }
    };
  },
  computed: {
    filteredConsultants() {
      return this.users.filter(user => {
        return user.user_roles.some(role => role.role.name === 'Consultant'
          || role.role.name === 'Nurse'
        );
      });
    },
  },

  props: {
    requisition_id: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.flag = this.$route.query.flag;
  },

  created() {
    this.getWards();
    this.getRequisitions();
    this.getAssignedUnits();
    this.getUsers()
  },

  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    viewIssueOutPopup(product) {
      this.selectedProduct = product.assigned_units.filter(unit => unit.status === "Assigned");
      this.selectedUnitIds = [];
      this.showIssueOutPopup = true;
    },

    closeIssueOutPopup() {
      this.showIssueOutPopup = false;
    },
    viewDifferentWardPopup() {
      this.showDifferentWardPopup = true;
    },

    closeDifferentWardPopup() {
      this.showDifferentWardPopup = false;
    },

    async getRequisitions() {
      try {
        const id = this.$route.params.requisition_id;
        console.log("Fetching details for requisition ID:", id);
        const response = await api.getRequisition(id);
        this.requisition = (response.data || response)[0];

        this.getRequisitionProducts(this.requisition.requisition_products);
      } catch (error) {
        this.error = error.message || "Failed to fetch requisition details";
      }
    },

    async getRequisitionProducts(requisitionProducts) {
      try {
        if (requisitionProducts && requisitionProducts.length > 0) {
          const requisitionProductId = requisitionProducts[0].requisition_product_id;

          const response = await api.fetchRequisitionProduct(requisitionProductId);
          this.requisitionProducts = response.data || [];
        } else {
          console.log("No requisition products found for this requisition.");
        }
      } catch (error) {
        this.error = error.message || "Failed to fetch requisition products";
      }
    },

    async cancelRequisition() {
      try {
        const id = this.$route.params.requisition_id;
        const payload = { status: "Cancelled" };

        await api.cancelRequisition(id, payload);

        console.log("API call successful, showing success message.");
        await SweetAlertService.showSuccess(
          "Success!",
          "Requisition cancelled successfully!"
        );

        this.$router.push({ name: "RequisitionManagementPage" });
      } catch (error) {
        console.error("Error cancelling requisition:", error);
        this.error = error.message || "Failed to update requisition status";
      }
    },

    async moveDepartment() {
      try {
        const id = this.$route.params.requisition_id;
        const payload = {
          department_id: this.formData.department_id
        };

        await api.movePatientDepartment(id, payload);
        await SweetAlertService.showSuccess(
          "Success!",
          "Successfully Moved Patient to another ward!"
        );

        this.$router.push({ name: "RequisitionManagementPage" });
      } catch (error) {
        console.error("Error cancelling requisition:", error);
        this.error = error.message || "Failed to update requisition status";
      }
    },

    redirectToAssignUnit(id) {
      this.$router.push({
        name: "AssignUnitPage",
        params: { requisition_product_id: id },
      });
    },

    redirectToEditRequisitionForm(id) {
      this.$router.push({
        name: "NewRequisitionForm",
        params: { requisition_id: id },
      });
    },



    async getAssignedUnits() {
      try {
        const id = this.$route.params.requisition_id;
        console.log("Fetching details for requisition ID:", id);
        const response = await api.getRequisition(id);
        this.requisition = (response.data || response)[0];

        // Extract assigned units from requisition_products
        this.assignedUnits = this.requisition.requisition_products
          .flatMap((product) => product.assigned_units) // Flatten the array of assigned units
          .filter((unit) => unit.status == "Assigned"); // Only keep units that are not assigned (if applicable)
      } catch (error) {
        this.error = error.message || "Failed to fetch requisition details";
      }
    },

    async issueOut() {
      try {
        if (!this.selectedUnitIds || this.selectedUnitIds.length === 0) {
          throw new Error("No units selected");
        }

        if (!this.selectedIssuedBy) {
          throw new Error("No user selected for issuing");
        }

        const payload = {
          assigned_units: this.selectedUnitIds.map((unitId) => ({
            assigned_units_id: unitId,
          })),
          assigned_by: this.user().user_id,
          issued_by: this.selectedIssuedBy,
          issued_to_name: "",
          issued_on: "",
          status: "Issued",
        };

        const response = await api.issueOut(payload);
        console.log("Unit issued out successfully:", response);
        await SweetAlertService.showSuccess(
          "Success!",
          "Unit issued out successfully!"
        );
        window.location.reload();
      } catch (error) {
        this.error = error.message || "Failed to issue out unit";
        console.error("Error issuing out unit:", error.message);
        SweetAlertService.showError("Error", "Error issuing out unit.");
      }
    },

    toggleProductsDropdown() {
      this.showProductsDropdown = !this.showProductsDropdown;
    },

    async getWards() {
      try {
        const response = await api.getDepartments();
        this.departments = (response.data || response).filter(
          (ward) => ward.department_type === "Clinical Area"
        );
      } catch (error) {
        this.error = error.message || "Failed to fetch departments";
      }
    },

    async getUsers() {
      try {
        const response = await api.getUsers();
        this.users = response.filter(user => Array.isArray(user.user_roles) && user.user_roles.length > 0);
      } catch (error) {
        this.error = error.message || "Failed to fetch users";
      }
    },

    redirectToAddSample(requisition) {
      console.log("Passing requisition:", requisition);
      this.$router.push({
        name: "AddSampleForm",
        params: { requisition_id: requisition }, // Pass the entire requisition object
      });
    },

    async handlePrintSticker(product) {
      const printWindow = document.createElement('iframe');
      printWindow.style.position = 'absolute';
      printWindow.style.width = '0';
      printWindow.style.height = '0';
      document.body.appendChild(printWindow);

      try {
        const patient = this.requisition.patient;
        const department = this.requisition.department.name;
        const consultant = this.requisition.consultant;
        const sample = this.requisition.samples[0];

        let allPrintContent = '';
        product.assigned_units.forEach(unit => {
          const productDetails = {
            name: unit.unit_product.name || "N/A",
            volume: unit.volume || "N/A",
            bloodGroup: unit.blood_group.description || "N/A",
            serialNumber: unit.pint_serial || "N/A",
            expiryDate: unit.expiry_date || "N/A"
          };

          const printContent = `
        <div class="page-container">
          <div class="sticker-container">
            <div class="logo-container">
              <img src="${hospitalLogo}" alt="Hospital Logo" class="logo">
            </div>
            <h1 class="compatibility-title">Compatibility Label</h1>
            <div class="compatibility-container">
              <div class="patient-info">
                <h3>Patient Details</h3>
                <p><span class="label">Patient Name:</span> <span class="value">${patient.first_name} ${patient.last_name}</span></p>
                <p><span class="label">IP/OP No:</span> <span class="value">${patient.ipop}</span></p>
                <p><span class="label">Ward:</span> <span class="value">${department}</span></p>
                <p><span class="label">Blood Group:</span> <span class="value">${patient.blood_group.description}</span></p>
                <p><span class="label">Dr. Name:</span> <span class="value">${consultant.first_name} ${consultant.last_name}</span></p>
              </div>
              <div class="product-info">
                <h3>Product Details</h3>
                <p><span class="label">Serial No:</span> <span class="value">${productDetails.serialNumber}</span></p>
                <p><span class="label">Product:</span> <span class="value">${productDetails.name}</span></p>
                <p><span class="label">Blood Group:</span> <span class="value">${productDetails.bloodGroup}</span></p>
                <p><span class="label">Sample Lab No:</span> <span class="value">${sample.sample_lab_number}</span></p>
                <p><span class="label">Expiry Date:</span> <span class="value">${new Date(productDetails.expiryDate).toLocaleDateString()}</span></p>
              </div>
            </div>
            <div class="crossmatch-info">
              <p><span class="label">X-Match done by:</span> <span class="value">${sample.gxm_confirmed_by.first_name || ''} ${sample.gxm_confirmed_by.last_name || ''}</span></p>
              <p><span class="label">On:</span> <span class="value">${new Date(sample.gxm_confirmed_on).toLocaleDateString()}</span></p>
            </div>
          </div>
        </div>
      `;

          allPrintContent += printContent;
        });

        const printDocument = `
      <html>
      <head>
        <title>Print Preview</title>
        <style>
          @page {
            size: A6;
            margin: 0;
          }
          * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
          }
          body {
            font-family: Manjari, sans-serif;
            margin: 0;
            padding: 0;
            background-color: white;
          }
          .page-container {
            width: 100vw;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .sticker-container {
            width: 500px;  /* Fixed width for the content */
            padding: 20px;
            background-color: white;
            border: 1px solid #ddd;  /* Optional: adds a light border */
            box-shadow: 0 0 10px rgba(0,0,0,0.1);  /* Optional: adds a subtle shadow */
          }
          .logo-container {
            text-align: center;
            margin-bottom: 10px;
          }
          .logo {
            height: 60px;
            margin-bottom: 5px;
          }
          .hospital-name {
            color: #cc0000;
            margin: 5px 0;
            font-size: 16px;
          }
          .compatibility-title {
            text-align: center;
            font-size: 20px;
            margin: 10px 0;
          }
          .compatibility-container {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            margin-top: 10px;
          }
          .patient-info, .product-info {
            flex: 1;
          }
          h3 {
            font-size: 18px;
            margin: 0 0 10px 0;
          }
          p {
            margin: 5px 0;
            font-size: 16px;
            line-height: 1.3;
          }
          .label {
            font-weight: normal;
          }
          .value {
            font-weight: bold;
          }
          .crossmatch-info {
            text-align: center;
            margin-top: 15px;
            font-weight: bold;
          }
          @media print {
            .page-container {
              height: 100%;
              page-break-after: always;
            }
            .sticker-container {
              border: none;
              box-shadow: none;
            }
          }
        </style>
      </head>
      <body>
        ${allPrintContent}
      </body>
      </html>
    `;

        printWindow.contentDocument.open();
        printWindow.contentDocument.write(printDocument);
        printWindow.contentDocument.close();

        printWindow.contentWindow.onload = function () {
          printWindow.contentWindow.print();
        };
      } catch (error) {
        this.error = error.message || 'Failed to fetch donation';
      }
    }
  },
};
</script>
