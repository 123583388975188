<template>
  <div>
    <AlertLayout v-if="currentTab === 'training-material'" title="Reminders!" message="
    <ul>
      <li>Kindly note that <span style='color: green;'>Active</span> status means materials are within the set deadline and are visible to staff.</li>
      <li>Kindly note that <span style='color: red;'>Inactive</span> status means materials deadline has passed but are visible to staff.</li>
      <li>Kindly note that <span style='color: orange;'>Archived</span> status means materials are not visible to staff.</li>
    </ul>" type="warning" />
    <AlertLayout v-if="currentTab === 'assessement'" title="Reminders!" message="
    <ul>
      <li>Kindly note the <span style='color: green;'>Ongoing</span> status means the assessment is in progress and within the set deadline.</li>
      <li>Kindly note the <span style='color: blue;'>Completed</span> status means the assessment deadline has elapsed.</li>
      <li>Kindly note the <span style='color: orange;'>Pending</span> status means the assessment/C.A.T hasn't been uploaded yet.</li>
      <li>Kindly note the <span style='color: gray;'>Archived</span> status means the assessment isn't visible to staff.</li>
    </ul>
  " type="warning" />
    <AlertLayout v-if="currentTab === 'results'" title="Reminders!"
      message="Filter for a specific assessment to display results for all candidate who did the assessment and their scores."
      type="warning" />
    <div class="flex flex-wrap items-center justify-between pb-4 py-1">
      <div class="flex items-center space-x-4">
        <div class="relative">
          <button id="dropdownRadioButton"
            class="inline-flex items-center text-gray-400 bg-white border border-gray-300 rounded-md px-6 py-2"
            type="button" @click="toggleDropdown">
            <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                d="M18.796 4H5.204a1 1 0 0 0-.753 1.659l5.302 6.058a1 1 0 0 1 .247.659v4.874a.5.5 0 0 0 .2.4l3 2.25a.5.5 0 0 0 .8-.4v-7.124a1 1 0 0 1 .247-.659l5.302-6.059c.566-.646.106-1.658-.753-1.658Z" />
            </svg>
            Filters
            <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 4 4 4-4" />
            </svg>
          </button>

          <!-- start dropdown filter -->
          <template v-if="currentTab === 'training-material'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <div class="flex">
                <button type="button"
                  class="ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex text-gray-500 items-center justify-end h-8 w-8 hover:text-custom-yellow"
                  @click="cancelFilters" aria-label="Close">
                  <span class="sr-only">Dismiss</span>
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
              </div>
              <form @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="documentType" class="block mb-2 text-sm font-medium text-gray-900">Document Type
                  </label>
                  <select id="documentType" v-model="filters.documentType"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select</option>
                    <option value="SOP">SOP</option>
                    <option value="Training material">Training material</option>
                    <option value="Manuals">Manuals</option>
                    <option value="Memo">Memo</option>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="department" class="block mb-2 text-sm font-medium text-gray-900">Department
                  </label>
                  <select v-model="filters.department_id"
                    class="bg-gray-50 border border-gray-200 text-gray-900 rounded-md block w-full py-2 px-1 dark:placeholder-gray-400">
                    <option disabled selected>Select department type</option>
                    <option v-for="department in departments" :key="department.department_id" :value="department.department_id">
                      {{ department.name }}
                    </option>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="deadlineDate" class="block mb-2 text-sm font-medium text-gray-900">Deadline
                  </label>
                  <input type="text" id="deadlineDate" v-model="filters.deadlineDate"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="mb-4">
                  <label for="dateUploaded" class="block mb-2 text-sm font-medium text-gray-900">Date
                    Uploaded</label>
                  <input type="text" id="dateUploaded" v-model="filters.dateUploaded"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="mb-4">
                  <label for="documentStatus" class="block mb-2 text-sm font-medium text-gray-900">Status
                  </label>
                  <select id="documentStatus" v-model="filters.documentStatus"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select</option>
                    <option value="Active">Active</option>
                    <option value="Archived">Archived</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </div>
                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </template>



          <!-- assessement filter -->
          <template v-if="currentTab === 'assessement'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <div class="flex">
                <button type="button"
                  class="ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex text-gray-500 items-center justify-end h-8 w-8 hover:text-custom-yellow"
                  @click="cancelFilters" aria-label="Close">
                  <span class="sr-only">Dismiss</span>
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
              </div>
              <form @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="department" class="block mb-2 text-sm font-medium text-gray-900">Department
                  </label>
                  <select v-model="filters.role_id"
                    class="bg-gray-50 border border-gray-200 text-gray-900 rounded-md block w-full py-2 px-1 dark:placeholder-gray-400">
                    <option disabled selected>Select department</option>
                    <option v-for="department in departments" :key="department.department_id" :value="department.department_id">
                      {{ department.name }}
                    </option>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="assessmentDeadlineDate" class="block mb-2 text-sm font-medium text-gray-900">Deadline
                  </label>
                  <input type="text" id="assessmentDeadlineDate" v-model="filters.assessmentDeadlineDate"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="mb-4">
                  <label for="assessmentStatus" class="block mb-2 text-sm font-medium text-gray-900">Status
                  </label>
                  <select id="assessmentStatus" v-model="filters.assessmentStatus"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select</option>
                    <option value="Ongoing">Ongoing</option>
                    <option value="Completed">Completed</option>
                    <option value="Pending">Pending</option>
                  </select>
                </div>
                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </template>

          <!-- Result filter -->
          <template v-if="currentTab === 'results'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <div class="flex">
                <button type="button"
                  class="ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex text-gray-500 items-center justify-end h-8 w-8 hover:text-custom-yellow"
                  @click="cancelFilters" aria-label="Close">
                  <span class="sr-only">Dismiss</span>
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
              </div>
              <form @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="userType" class="block mb-2 text-sm font-medium text-gray-900">Assessments
                  </label>
                  <select v-model="filters.assessment_id"
                    class="bg-gray-50 border border-gray-200 text-gray-900 rounded-md block w-full py-2 px-1 dark:placeholder-gray-400">
                    <option v-for="assessment in assessments" :key="assessment.assessment_id"
                      :value="assessment.assessment_id">
                      {{ assessment.assessment_name }}
                    </option>
                  </select>
                </div>

                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </template>

        </div>

        <!-- search Input -->
        <div class="relative flex">
          <input type="text" v-model="searchQuery" id="table-search"
            class="px-4 py-2 text-gray-800 border border-gray-300 rounded-sm dark:border-gray-300 dark:placeholder-gray-400"
            placeholder="Search" />
          <button type="submit" class="bg-custom-yellow text-white rounded-r px-2 md:px-3 py-0 md:py-1">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
            </svg>
          </button>
        </div>

      </div>

      <div class="flex items-center space-x-4 mt-4 sm:mt-0">
        <template v-if="currentTab === 'training-material'">
          <CustomButton label="Upload Material" :icon-path="'M5 12h14m-7 7V5'" bg-class="bg-custom-yellow"
            @click="handleConsultationModal" />
        </template>
        <template v-else-if="currentTab === 'assessement'">
          <CustomButton label="Add Assessement" :icon-path="'M5 12h14m-7 7V5'" bg-class="bg-custom-yellow"
            @click="handleAddAssessementModal" />
        </template>
      </div>
    </div>

    <div v-if="currentTab === 'results'" class="mt-4">
      <div class="flex gap-3">
        <!-- Total Candidates -->
        <div class="w-full px-6 border border-gray-200 rounded-md">
          <div class="flex items-center px-1 py-3 bg-white rounded-md shadow-sm">
            <div class="w-full">
              <div class="text-gray-500">Total Candidates</div>
              <h4 class="text-2xl font-semibold text-gray-700">{{ totalCandidates }}</h4>
            </div>
          </div>
        </div>
        <!-- Average Score -->
        <div class="w-full px-6 border border-gray-200 rounded-md">
          <div class="flex items-center px-1 py-3 bg-white rounded-md shadow-sm">
            <div class="w-full">
              <div class="text-gray-500">Average Score</div>
              <h4 class="text-2xl font-semibold text-gray-700">{{ averageScore }}</h4>
            </div>
          </div>
        </div>
        <!-- Average Percentage -->
        <div class="w-full px-6 border border-gray-200 rounded-md">
          <div class="flex items-center px-1 py-3 bg-white rounded-md shadow-sm">
            <div class="w-full">
              <div class="text-gray-500">Average Percentage</div>
              <h4 class="text-2xl font-semibold text-gray-700">{{ averagePercentage }}%</h4>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="tabs flex border-b mb-4 mt-6">
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'training-material' }"
        @click="currentTab = 'training-material'">
        Training Materials
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'assessement' }" @click="currentTab = 'assessement'">
        Assessments
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'results' }" @click="currentTab = 'results'">
        Results
      </button>
    </div>
    <NewMaterialUploadForm :show="showConsultationModal" @close-modal="closeConsultationModal" />
    <NewAssessementUploadForm :show="showAssessementonModal" @close-modal="closeAssessementModal" />
    <component :is="currentTabComponent" ref="currentTabComponent" :search-query="searchQuery" @card-data="fetchCardData">
    </component>
  </div>
</template>

<script>
import api from "@/services/api";
import SweetAlertService from '@/services/SweetAlertService';
import AlertLayout from '@/components/AlertLayout.vue';
import CustomButton from '@/components/CustomButton.vue';
import SupervisorAssessementTab from '../tabs/SupervisorAssessementTab.vue';
import SupervisorTrainingMaterialTab from '../tabs/SupervisorTrainingMaterialTab.vue';
import SupervisorResultsTab from '../tabs/SupervisorResultsTab.vue';
import NewMaterialUploadForm from '../forms/NewMaterialUploadForm.vue';
import NewAssessementUploadForm from '../forms/NewAssessementUploadForm.vue';

export default {
  name: 'SupervisorLearningManagementPage',
  components: {
    AlertLayout,
    CustomButton,
    SupervisorTrainingMaterialTab,
    SupervisorAssessementTab,
    SupervisorResultsTab,
    NewMaterialUploadForm,
    NewAssessementUploadForm

  },
  data() {
    return {
      totalCandidates: 0,
      averageScore: 0,
      averagePercentage: 0,
      showConsultationModal: false,
      showAssessementonModal: false,
      searchQuery: '',
      assessments: [],
      filters: {
      },
      isDropdownOpen: false,
      currentTab: 'training-material',
    };
  },

  computed: {
    currentTabComponent() {
      switch (this.currentTab) {
        case 'training-material':
          return 'SupervisorTrainingMaterialTab';
        case 'assessement':
          return 'SupervisorAssessementTab';
        case 'results':
          return 'SupervisorResultsTab';
        default:
          return 'SupervisorTrainingMaterialTab';
      }
    }
  },

  created() {
    this.getDepartments();
    this.fetchRoles();
    this.fetchAssessmentFilter();
  },

  async mounted() {
    await this.fetchAssessmentFilter();
  },

  methods: {

    async getDepartments() {
      try {
        const response = await api.getDepartments();
        this.departments = response.data || response;
        console.log('Fetched departments:', this.departments);
      } catch (error) {
        console.error('Error fetching departments:', error);
        this.error = error.message || "Failed to fetch departments";
        await SweetAlertService.showError("Error", "Failed to load departments");
      }
    },

    async fetchRoles() {
      try {
        const response = await api.getRoles();
        this.roles = response.data || response;
      } catch (error) {
        console.error('Error fetching roles:', error);
        this.error = error.message || "Failed to fetch roles";
        await SweetAlertService.showError("Error", "Failed to load user types");
      }
    },

    async fetchAssessmentFilter() {
      try {
        const response = await api.getAssessmentFilter();
        this.assessments = response.data || response;

        // Set the first item as the default selection if the assessments are loaded
        if (this.assessments.length > 0) {
          this.filters.assessment_id = this.assessments[0].assessment_id;

          // Trigger the filter application when assessments are first loaded
          this.$nextTick(() => {
            this.applyFiltersToCurrentTab();
          });
        }
      } catch (error) {
        this.error = error.message || "Failed to fetch assessments";
      }
    },

    fetchCardData(data) {
      this.totalCandidates = data.total_candidates,
      this.averageScore = data.average_score,
      this.averagePercentage = data.average_percentage
    },

    handleConsultationModal() {
      this.showConsultationModal = true;
    },
    closeConsultationModal() {
      this.showConsultationModal = false;
    },

    handleAddAssessementModal() {
      this.showAssessementonModal = true;
    },
    closeAssessementModal() {
      this.showAssessementonModal = false;
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    applyFilters() {
      console.log('Applying filters:', this.filters);
      this.applyFiltersToCurrentTab();
      this.isDropdownOpen = false;
    },

    applyFiltersToCurrentTab() {
      if (this.$refs.currentTabComponent && typeof this.$refs.currentTabComponent.applyFilters === 'function') {
        this.$refs.currentTabComponent.applyFilters(this.filters);
      } else {
        console.error('applyFilters method not found on current tab component');
      }
    },

    cancelFilters() {
      this.filters = {
        documentType: "",
        department: "",
        dateUploaded: "",
        documentStatus: "",
        deadlineDate: "",
        assessmentDeadlineDate: "",
        assessmentStatus: "",
        assessment_id: ""
      };
      this.applyFiltersToCurrentTab();
      this.isDropdownOpen = false;
    }
  }
}
</script>


<style lang="css" scoped>
/* Add any additional styles if needed */
</style>