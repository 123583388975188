<template>
    <div>
        <AlertLayout v-if="currentTab === 'user-management' || currentTab === 'role-management'" title="Reminders!"
            message="Only registered officers will be found below. You may register new officer here.<br>Click on Details button for more information on the officer of choice"
            type="warning" />
        <AlertLayout v-if="currentTab === 'facility-management'" title="Reminders!"
            message="Only registered facilities will be found below. You may register new facility here.<br>Click on Details button for more information on the facility of choice"
            type="warning" />

        <div class="flex flex-wrap items-center justify-between pb-4 py-1">
            <div class="flex items-center space-x-4">
                <div class="relative">
                    <button id="dropdownRadioButton"
                        class="inline-flex items-center text-gray-400 bg-white border border-gray-300 rounded-md px-6 py-2"
                        type="button" @click="toggleDropdown">
                        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                d="M18.796 4H5.204a1 1 0 0 0-.753 1.659l5.302 6.058a1 1 0 0 1 .247.659v4.874a.5.5 0 0 0 .2.4l3 2.25a.5.5 0 0 0 .8-.4v-7.124a1 1 0 0 1 .247-.659l5.302-6.059c.566-.646.106-1.658-.753-1.658Z" />
                        </svg>
                        Filters
                        <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m1 1 4 4 4-4" />
                        </svg>
                    </button>

                    <!-- Filter dropdown -->
                    <div v-show="isDropdownOpen" id="filterDropdown"
                        class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
                        <form @submit.prevent="applyFilters">
                            <div class="mb-4">
                                <label for="contactMode" class="block mb-2 text-sm font-medium text-gray-900">Contact
                                    Mode</label>
                                <select id="contactMode" v-model="filters.contactMode"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                                    <option value="">All</option>
                                    <option value="Email">Email</option>
                                    <option value="Phone">Phone</option>
                                    <option value="Both">Both</option>
                                </select>
                            </div>
                            <div class="mb-4">
                                <label for="facility"
                                    class="block mb-2 text-sm font-medium text-gray-900">Facility</label>
                                <input type="text" id="facility" v-model="filters.facility"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                            </div>
                            <div class="mb-4">
                                <label for="department"
                                    class="block mb-2 text-sm font-medium text-gray-900">Department</label>
                                <input type="text" id="department" v-model="filters.department"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                            </div>
                            <div class="mb-4">
                                <label for="registrationDate"
                                    class="block mb-2 text-sm font-medium text-gray-900">Registration Date</label>
                                <input type="date" id="registrationDate" v-model="filters.registrationDate"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                            </div>
                            <div class="mb-4">
                                <label for="status" class="block mb-2 text-sm font-medium text-gray-900">Status</label>
                                <select id="status" v-model="filters.status"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                                    <option value="">All</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                            <div class="flex justify-end space-x-2">
                                <button type="button" @click="cancelFilters"
                                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                                    Clear
                                </button>
                                <button type="submit"
                                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                                    Apply Filters
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- Search input -->
                <div class="relative flex">
                    <input type="text" v-model="searchQuery" id="table-search"
                        class="px-4 py-2 text-gray-800 border border-gray-300 rounded-sm dark:border-gray-300 dark:placeholder-gray-400"
                        placeholder="Search" />
                    <button type="submit" class="bg-custom-yellow text-white rounded-r px-2 md:px-3 py-0 md:py-1">
                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="flex items-center space-x-4 mt-4 sm:mt-0">
                <template v-if="currentTab === 'user-management'">
                    <CustomButton label="New Officer" :icon-path="'M5 12h14m-7 7V5'" bg-class="bg-custom-yellow"
                        @click="handleNewUser" />
                </template>
                <template v-if="currentTab === 'facility-management'">
                    <CustomButton label="New Facility" :icon-path="'M5 12h14m-7 7V5'" bg-class="bg-custom-yellow"
                        @click="handleNewFacility" />
                </template>
                <template v-if="currentTab === 'role-management'">
                    <CustomButton label="New Role" :icon-path="'M5 12h14m-7 7V5'" bg-class="bg-custom-yellow"
                        @click="handleNewRole" />
                </template>
            </div>
        </div>
        <div class="tabs flex border-b mb-4">
            <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
                :class="{ 'border-custom-yellow': currentTab === 'user-management' }"
                @click="currentTab = 'user-management'">
                Users
            </button>
            <button v-if="hasManageClaim('Manage Client')"
                class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
                :class="{ 'border-custom-yellow': currentTab === 'facility-management' }"
                @click="currentTab = 'facility-management'">
                Facility
            </button>
            <button v-if="hasManageClaim('Manage Users')"
                class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
                :class="{ 'border-custom-yellow': currentTab === 'role-management' }"
                @click="currentTab = 'role-management'">
                Role Management
            </button>
        </div>
        <component :is="currentTabComponent" ref="currentTabComponent" :search-query="searchQuery"></component>
    </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import UserManagementTab from "../tabs/UserManagementTab.vue";
import FacilityManagementTab from "../tabs/FacilityManagementTab.vue";
import RoleManagementTab from "../tabs/RoleManagementTab.vue";

export default {
    name: "UserManagementPage",
    components: {
        AlertLayout,
        CustomButton,
        UserManagementTab,
        FacilityManagementTab,
        RoleManagementTab
    },
    created() { },
    data() {
        return {
            currentTab: "user-management",
            searchQuery: "",
            isDropdownOpen: false,
            user: [],
            filters: {
                contactMode: '',
                facility: '',
                department: '',
                registrationDate: '',
                status: ''
            }
        };
    },
    computed: {
        currentTabComponent() {
            switch (this.currentTab) {
                case "user-management":
                    return "UserManagementTab";
                case "facility-management":
                    return "FacilityManagementTab";
                case "role-management": 
                    return "RoleManagementTab";
                default:
                    return "UserManagementTab";
            }
        },
    },
    methods: {
        handleNewUser() {
            this.$router.push({ name: 'NewUserForm' });
        },
        handleNewFacility() {
            this.$router.push({ name: 'NewFacilityForm' });
        },
        handleNewRole() {
            this.$router.push({ name: 'NewRoleForm' });
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },

        applyFilters() {
            console.log('Applying filters:', this.filters);
            this.applyFiltersToCurrentTab();
            this.isDropdownOpen = false;
        },

        applyFiltersToCurrentTab() {
            if (this.$refs.currentTabComponent && typeof this.$refs.currentTabComponent.applyFilters === 'function') {
                this.$refs.currentTabComponent.applyFilters(this.filters);
            } else {
                console.error('applyFilters method not found on current tab component');
            }
        },

        cancelFilters() {
            this.filters = {
                contactMode: '',
                facility: '',
                department: '',
                registrationDate: '',
                status: ''
            };
            this.applyFilters();
        },

        hasManageClaim(value) {
            const claims = this.user?.user_roles?.[0]?.role?.claims || [];
            return claims.some((claim) => claim.name === value);
        },
    },
    mounted() {
        this.user = this.$store.getters["auth/user"];        
    },
};

</script>

<style lang="css" scoped></style>