<template>
  <div>
    <StepperLayoutMobile
      :steps="steps"
      :current-step="currentTab"
    />
  </div>
  <component
    :is="currentTabComponent"
    @move-to-previous-step="goToPreviousStep"
    @move-to-next-step="goToNextStep"
    :formData="formData" 
      :donorId="donorId" 
       @final-submit="handleFinalSubmit"
      @donor-created="handleDonorCreated"
  >
  </component>
</template>

<script>
import EducationalAlerts from "@/components/EducationalAlerts.vue";
import NewDonorEducationalPamphletMobile from "../forms/NewDonorEducationalPamphletMobile.vue";
import NewDonorInformationFormMobile from "../deleted_files/NewDonorInformationFormMobile.vue";
import NewDonorQuetionnaireForm from "../forms/NewDonorQuestionnaireForm.vue";
import NewDonorDeclarationFormMobile from "../forms/NewDonorDeclarationFormMobile.vue";
import StepperLayoutMobile from "@/components/StepperLayoutMobile.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

 
export default {
  name: "NewDonorQuestionnaireForm",
  components: {
    EducationalAlerts,
    StepperLayoutMobile,
    NewDonorEducationalPamphletMobile,
    NewDonorInformationFormMobile,
    NewDonorQuetionnaireForm,
    NewDonorDeclarationFormMobile,
  },
  data() {
    return {
      currentTab: 'NewDonorEducationalPamphletMobile',
      formData: {
        educationalPamphlet: {},
        donorInformation: {},
        questionnaire: {},
        declaration: {}
      },
      facility_id: this.user().facility_id,
      steps: [
        { id: 'NewDonorEducationalPamphletMobile', title: '1. Educational Pamphlet', subtitle: 'Read educational pamphlet', active: true },
        { id: 'NewDonorInformationFormMobile', title: '2. Donor Information', subtitle: 'Provide donor information', active: false },
        { id: 'NewDonorQuetionnaireForm', title: '3. Donor Questionnaire', subtitle: 'Answer the donor questionnaire', active: false },
        { id: 'NewDonorDeclarationFormMobile', title: '4. Declaration', subtitle: 'Fill in declaration & consent form', active: false },
      ]
    }
  },
  computed: {
    currentTabComponent() {
      switch (this.currentTab) {
        case "NewDonorEducationalPamphletMobile":
          return NewDonorEducationalPamphletMobile;
        case "NewDonorInformationFormMobile":
          return NewDonorInformationFormMobile;
        case "NewDonorQuetionnaireForm":
          return NewDonorQuetionnaireForm;
        case "NewDonorDeclarationFormMobile":
          return NewDonorDeclarationFormMobile;
        default:
          return NewDonorEducationalPamphletMobile;
      }
    },
  },
  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },
    
    handleDonorCreated(newDonorId) {
      this.donorId = newDonorId;
    },
    setActiveStep(stepId) {
      this.steps.forEach(step => {
        step.active = step.id === stepId;
      });
      this.currentTab = stepId;
    },
    goToPreviousStep() {
      const currentIndex = this.steps.findIndex(step => step.id === this.currentTab);
      if (currentIndex > 0) {
        this.setActiveStep(this.steps[currentIndex - 1].id);
      }
    },
    goToNextStep() {
      const currentIndex = this.steps.findIndex(step => step.id === this.currentTab);
      if (currentIndex < this.steps.length - 1) {
        this.setActiveStep(this.steps[currentIndex + 1].id);
      }
    },
    handleFinalSubmit() {
     
    const booking = {
      preferred_date:  this.formData.donorInformation.preferred_date, 
      preferred_time:  this.formData.donorInformation.preferred_time
    }
    const donorQuestionnaire = {
      feeling_healthy : this.formData.questionnaire?.feeling_healthy === "yes",
  take_flight: this.formData.questionnaire?.take_flight === "yes",
  minimum_weight: this.formData.questionnaire?.minimum_weight === "yes",
  active_allergy: this.formData.questionnaire?.active_allergy === "yes",
  severe_disease: this.formData.questionnaire?.severe_disease === "yes",
  had_alcohol: this.formData.questionnaire?.had_alcohol === "yes",
  had_drugs: this.formData.questionnaire?.had_drugs === "yes",
  taking_medication: this.formData.questionnaire?.taking_medication === "yes",
  dental_work: this.formData.questionnaire?.dental_work === "yes",
  unexplained_sickness: this.formData.questionnaire?.unexplained_sickness === "yes",
  relative_has_hepatitis: this.formData.questionnaire?.relative_has_hepatitis === "yes",
  had_body_piercing: this.formData.questionnaire?.had_body_piercing === "yes",
  immunization_vaccinations: this.formData.questionnaire?.immunization_vaccinations === "yes",
  pos_for_Hepatitis_C: this.formData.questionnaire?.pos_for_Hepatitis_C === "yes",
  had_surgery: this.formData.questionnaire?.had_surgery === "yes",
  infection_exposure: this.formData.questionnaire?.infection_exposure  === "yes",
  medical_condition: this.formData.questionnaire?.medical_condition  === "yes",
  positive_for_HIV: this.formData.questionnaire?.positive_for_HIV === "yes",
  bleeding_condition: this.formData.questionnaire?.bleeding_condition === "yes",
    rejected_as_donor: this.formData.questionnaire?.rejected_as_donor === "yes",
  };

  const declaration = {
    read_educational_material:  this.formData.declaration.read_educational_material,
    consent_checkbox: this.formData.declaration.consent_checkbox,
    blood_testing_consent: this.formData.declaration.blood_testing_consent,
    terms_agreed: this.formData.declaration.terms_agreed,
    consent_form: this.formData.declaration.consent_form,
  };

      const payload = {
        ...this.formData.donorInformation,
        donor_questionnaire: donorQuestionnaire,
        declaration: declaration,
        booking: booking
      };

      payload.facility_id = this.user().facility_id


      // Call your API with the payload
      this.submitToApi(payload);
    },
    async submitToApi(payload) {
      try {
        const response = await api.createDonor(payload);
        console.log('Submission successful:', response);
        await SweetAlertService.showSuccess('Success!', 'Donor information saved successfully!.');
      } catch (error) {
        console.error('Error submitting the form:', error);
        await SweetAlertService.showError('Submission Error', 'An error occurred while saving the donor information.');
      }
    }
    
  },
};
</script>
