<template>
  <div>
    <AlertLayout
      title="Reminders!"
      message="Incorrect information will lead to nurses being unable to request for blood except for theatre."
      type="warning"
    />
    <div class="mx-auto p-10 border border-t-0">
      <h2 class="text-lg font-bold mb-6">{{ bed_type_id ? 'Update bed type' : 'Add bed type' }}</h2>
      <form class="grid grid-cols-1 md:grid-cols-1 gap-5">
        <div>
          <label class="block mb-2 text-gray-700 font-medium">Select Department<span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="blood_group" v-model="formData.department"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            required>
            <option value="" disabled>Select department</option>
            <option v-for="department in departments" :key="department.department_id" :value="department.facility_departments[0].facility_department_id">
              {{ department.name }}
            </option>
          </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div>
          <label class="block mb-1" for="reason">Bed type Description <span class="text-red-500">*</span></label>
          <input type="text"
            v-model="formData.description"
            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Reason for theatre only here" required>
        </div>
      </form>
      <div class="flex items-center justify-between mt-8">
      
        <button @click="handleBedTypes" 
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Back</button>
        <CustomButton
          v-if="!bed_type_id"
          label="Save"
          bg-class="bg-custom-yellow px-8 py-3"
          @click="handleSubmitNewBedType"
        />
        <CustomButton
          v-if="bed_type_id"
          label="Update"
          bg-class="bg-custom-yellow px-8 py-3"
          @click="handleUpdateBedType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: "NewBedTypeForm",
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      user: '',
      bed_type_id: '',
      formData: {
        department: '',
        description: '',
      },
      status: 'active',
      error: null,
      departments: [],
    };
  },
  mounted() {
    this.user = this.$store.getters["auth/user"];
    this.bed_type_id = this.$route.params.bed_type_id;
  },
  created() {
    this.fetchDepartments();
    const bed_type_id = this.$route.params.bed_type_id;
    if (bed_type_id) {
      this.fetchBedTypeData(bed_type_id);
    }
  },
  methods: {
    validateForm() {
      const emptyFields = [];
      for (const [key, value] of Object.entries(this.formData)) {
        if (value === '') {
          emptyFields.push(key.replace(/_/g, ' '));
        }
      }
      return emptyFields;
    },
    handleBedTypes() {
      this.$router.push({ name: 'DepartmentsPage' });
    },
    async fetchDepartments() {
      try {
        const response = await api.getDepartments();
        this.departments = response;
      } catch (error) {
        console.error('Error departments:', error);
        SweetAlertService.showError('Error', 'Failed to fetch department data.');
      }
    },
    async fetchBedTypeData(bed_type_id) {
      try {
        const response = await api.getBedType(bed_type_id);
        const bedTypeData = response.data || response;
        this.populateFormData(bedTypeData);
      } catch (error) {
        console.error('Failed to fetch bed type data:', error);
        this.error = error.message || 'Failed to fetch bed type data';
        SweetAlertService.showError('Error', 'Failed to fetch Bed type.');
      }
    },
    populateFormData(bedTypeData) {
      this.formData.department = bedTypeData.facility_department_id || '';
      this.formData.description = bedTypeData.description || '';
    },
    async handleSubmitNewBedType() {
      const data = {
        facility_department_id: this.formData.department,
        description: this.formData.description,
      };

      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        // console.log('data:', data);
        await api.createBedType(data);
        await SweetAlertService.showSuccess('Success!', 'Bedtype saved successfully!.');
        this.$router.push({ name: 'DepartmentsPage' });
      } catch (error) {
        this.error = error.message || 'Failed to create bed types';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', 'Error saving bedtype.');
      }
    },
    async handleUpdateBedType() {
      const data = {
        facility_department_id: this.formData.department,
        description: this.formData.description,
      };

      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        await api.updateBedType(this.bed_type_id, data);
        await SweetAlertService.showSuccess('Success!', 'Bedtype updated successfully!.');
        this.$router.push({ name: 'DepartmentsPage' });
      } catch (error) {
        this.error = error.message || 'Failed to update bed types';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', 'Error updating bedtype.');
      }
    },
  },
};
</script>

<style lang="css" scoped>
/* Your existing styles */
</style>
