<template>
  <div>
    <AlertLayout v-if="currentTab === 'training-materilas'" title="Reminders!"
      message="The highlighted training materials have not been viewed yet.<br>Click on view details to access the document."
      type="warning" />

    <AlertLayout v-if="currentTab === 'assessments'" title="Reminders!"
      message="Kindly note that the highlighted assessment is yet to be viewed.<br>Kindly note that the <span style='color: red;'>Pending</span> status means the assessment is yet to be done.<br>Kindly note that the <span style='color: green;'>Completed</span> status means the assessment has been done and completed.<br>Kindly note that once you start a test, the timer starts running for the given duration of time indicated.<br>Kindly note that once the duration elapses, your assessment will be automatically submitted."
      type="warning" />

    <AlertLayout v-if="currentTab === 'results'" title="Reminders!" message="Kindly note this displays all your results for the tests/assessments taken with their scores.<br>
      Click on view result to access the questionnaires with their correct answers." type="warning" />

    <div class="flex flex-wrap items-center justify-between pb-4 py-1">
      <div class="flex items-center space-x-4">
        <div class="relative">
          <button id="dropdownRadioButton"
            class="inline-flex items-center text-gray-400 bg-white border border-gray-300 rounded-md px-6 py-2"
            type="button" @click="toggleDropdown">
            <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                d="M18.796 4H5.204a1 1 0 0 0-.753 1.659l5.302 6.058a1 1 0 0 1 .247.659v4.874a.5.5 0 0 0 .2.4l3 2.25a.5.5 0 0 0 .8-.4v-7.124a1 1 0 0 1 .247-.659l5.302-6.059c.566-.646.106-1.658-.753-1.658Z" />
            </svg>
            Filters
            <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 4 4 4-4" />
            </svg>
          </button>
          <!-- start dropdown filter -->
          <template v-if="currentTab === 'training-materilas'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <div class="flex">
                <button type="button"
                  class="ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex text-gray-500 items-center justify-end h-8 w-8 hover:text-custom-yellow"
                  @click="cancelFilters" aria-label="Close">
                  <span class="sr-only">Dismiss</span>
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
              </div>
              <form class="max-w-sm mx-auto" @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="statusFilter" class="block mb-2 text-sm font-medium text-gray-900">Document Type</label>
                  <select id="statusFilter" v-model="filters.documentTypes"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled>Select Document Type</option>
                    <option v-for="type in documentTypes" :key="type" :value="type">{{ type }}
                    </option>
                  </select>
                </div>
                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </template>
          <template v-if="currentTab === 'assessments'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <div class="flex">
                <button type="button"
                  class="ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex text-gray-500 items-center justify-end h-8 w-8 hover:text-custom-yellow"
                  @click="cancelFilters" aria-label="Close">
                  <span class="sr-only">Dismiss</span>
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
              </div>
              <form class="max-w-sm mx-auto" @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="statusFilter" class="block mb-2 text-sm font-medium text-gray-900">Assessment status</label>
                  <select id="statusFilter" v-model="filters.status"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled>Select status</option>
                    <option value="Pending">Pending</option>
                    <option value="Ongoing">Ongoing</option>
                    <option value="Complete">Complete</option>
                  </select>
                </div>
                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </template>
        </div>
        <div class="relative flex">
          <input type="text" v-model="searchQuery" id="table-search"
            class="px-4 py-2 text-gray-800 border border-gray-300 rounded-sm dark:border-gray-300 dark:placeholder-gray-400"
            placeholder="Search" />
          <button type="submit" class="bg-custom-yellow text-white rounded-r px-2 md:px-3 py-0 md:py-1">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="tabs flex border-b mb-4 mt-8">
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'training-materilas' }"
        @click="currentTab = 'training-materilas'">
        Training Materials
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'assessments' }" @click="currentTab = 'assessments'">
        Assessments
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'results' }" @click="currentTab = 'results'">
        Results
      </button>
    </div>
    <component :is="currentTabComponent" ref="currentTabComponent" :search-query="searchQuery"></component>
  </div>
</template>

<script>
import AlertLayout from '@/components/AlertLayout.vue';
import StaffTrainingMaterialTab from "../tabs/StaffTrainingMaterialTab.vue";
import StaffAssessmentTab from "../tabs/StaffAssessmentTab.vue";
import StaffResultsTab from "../tabs/StaffResultsTab.vue";

export default {
  name: "StaffLearningManagementPage",
  components: {
    AlertLayout,
    StaffTrainingMaterialTab,
    StaffAssessmentTab,
    StaffResultsTab
  },
  data() {
    return {
      currentTab: "training-materilas",
      isDropdownOpen: false,
      searchQuery: "",
      documentTypes: ['SOP', 'Training material', 'Manuals', 'Memo'],
      filters: {
        documentTypes: "",
        status: ""
      },
    }
  },
  computed: {
    currentTabComponent() {
        switch (this.currentTab) {
            case "training-materilas":
                return "StaffTrainingMaterialTab";
            case "assessments":
                return "StaffAssessmentTab";
            case "results":
                return "StaffResultsTab";
            default:
                return "StaffTrainingMaterialTab";
        }
    },
  },
  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    applyFilters() {
      this.applyFiltersToCurrentTab();
      this.isDropdownOpen = false;
    },

    applyFiltersToCurrentTab() {
      if (this.$refs.currentTabComponent && typeof this.$refs.currentTabComponent.applyFilters === 'function') {
        this.$refs.currentTabComponent.applyFilters(this.filters);
      } else {
        console.error('applyFilters method not found on current tab component');
      }
    },

    cancelFilters() {
      this.filters = {
        documentTypes: "",
        status:""
      };
      this.applyFiltersToCurrentTab();
      this.isDropdownOpen = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

  },
  mounted() {

  },
};
</script>

<style lang="css" scoped></style>
