<template>
    <div ref="dropdownRef" class="relative">
      <div @click="toggleDropdown" class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300 cursor-pointer flex items-center justify-between">
        <div class="flex flex-wrap gap-1">
          <span v-if="selectedOptions.length === 0" class="text-gray-400">{{ placeholder }}</span>
          <span v-for="option in selectedOptions" :key="option" class="bg-blue-100 text-blue-800 px-2 py-1 rounded text-sm flex items-center">
            {{ option }}
            <button @click.stop="removeOption(option)" class="ml-1 text-blue-600 hover:text-blue-800">
              &times;
            </button>
          </span>
        </div>
        <svg class="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </div>
      <div v-if="isOpen" class="absolute z-10 w-full mt-1 bg-white border rounded shadow-lg max-h-60 overflow-y-auto">
        <div v-for="option in availableOptions" :key="option" @click="toggleOption(option)" class="p-2 hover:bg-gray-100 cursor-pointer">
          <input type="checkbox" :checked="isSelected(option)" class="mr-2">
          {{ option }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed, watch } from 'vue';
  import { onClickOutside } from '@vueuse/core';
  
  export default {
    name: 'MultiSelectDropdown',
    props: {
      options: {
        type: Array,
        required: true
      },
      modelValue: {
        type: Array,
        default: () => []
      },
      placeholder: {
        type: String,
        default: 'Select options'
      }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const isOpen = ref(false);
      const selectedOptions = ref(props.modelValue);
      const dropdownRef = ref(null);
  
      const availableOptions = computed(() => {
        return props.options.filter(option => !selectedOptions.value.includes(option));
      });
  
      const toggleDropdown = () => {
        isOpen.value = !isOpen.value;
      };
  
      const closeDropdown = () => {
        isOpen.value = false;
      };
  
      const toggleOption = (option) => {
        const index = selectedOptions.value.indexOf(option);
        if (index === -1) {
          selectedOptions.value.push(option);
        } else {
          selectedOptions.value.splice(index, 1);
        }
        emit('update:modelValue', selectedOptions.value);
      };
  
      const removeOption = (option) => {
        const index = selectedOptions.value.indexOf(option);
        if (index !== -1) {
          selectedOptions.value.splice(index, 1);
          emit('update:modelValue', selectedOptions.value);
        }
      };
  
      const isSelected = (option) => {
        return selectedOptions.value.includes(option);
      };
  
      watch(() => props.modelValue, (newValue) => {
        selectedOptions.value = newValue;
      });
  
      onClickOutside(dropdownRef, closeDropdown);
  
      return {
        isOpen,
        selectedOptions,
        availableOptions,
        toggleDropdown,
        closeDropdown,
        toggleOption,
        removeOption,
        isSelected,
        dropdownRef
      };
    }
  };
  </script>
  
  <style scoped>
  /* Add your custom styles here */
  .max-h-60 {
    max-height: 15rem; /* Adjust as needed */
  }
  .overflow-y-auto {
    overflow-y: auto;
  }
  </style>
  