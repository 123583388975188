<template>
    <div v-if="show"
        class="fixed inset-0 bg-gray-900 bg-opacity-60 z-10 overflow-y-auto h-full w-full flex items-center justify-center text-base py-8">
        <div class="bg-white rounded-lg shadow-lg w-8/12 max-h-[90vh] flex flex-col">
            <!-- Header -->
            <div class="flex items-center justify-between p-4 border-b border-gray-200">
                <h2 class="text-2xl font-bold">Add Assessment</h2>
                <button @click="closeModal" class="text-gray-500 hover:text-gray-700" aria-label="Close">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                        </path>
                    </svg>
                </button>
            </div>

            <!-- Form content -->
            <div class="overflow-y-auto flex-1 px-8">
                <div class="py-6">
                    <form @submit.prevent="submitForm">
                        <div class="grid grid-cols-2 gap-6">
                            <!-- Left Column -->
                            <div class="space-y-4">
                                <div>
                                    <label for="assessmentName" class="block font-medium mb-2">Test Name<span
                                            class="text-red-500">*</span></label>
                                    <input id="assessmentName" v-model="formData.assessment_name" type="text"
                                        class="border border-gray-300 px-3 py-2 rounded-md w-full" required />
                                </div>

                                <!-- Updated Search Select for Basis -->
                                <div>
                                    <label for="basis" class="block font-medium mb-2">Basis<span
                                            class="text-red-500">*</span></label>
                                    <div class="relative">
                                        <!-- Search Input with Selected Items -->
                                        <div
                                            class="relative flex flex-wrap items-center gap-2 px-3 py-2 border rounded-md shadow-sm shadow-gray-400 bg-white">
                                            <!-- Selected Documents Display -->
                                            <span v-for="docId in formData.basis" :key="docId"
                                                class="bg-blue-100 text-blue-600 px-2 py-1 rounded flex items-center">
                                                <span class="text-xs text-gray-500 mr-1">[{{ getDocumentType(docId)
                                                    }}]</span>
                                                {{ getDocumentName(docId) }}
                                                <span class="cursor-pointer ml-1 text-red-500"
                                                    @click.stop="removeBasis(docId)">x</span>
                                            </span>
                                            <!-- Search Input -->
                                            <input type="text" v-model="basisSearch" @input="filterDocuments"
                                                @focus="handleSearchFocus" placeholder="Search document name..."
                                                class="flex-grow border-none focus:ring-0 outline-none placeholder-gray-400" />
                                        </div>

                                        <!-- Dropdown Container -->
                                        <div v-if="showBasisDropdown"
                                            class="absolute z-20 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                                            <!-- Type Tabs -->
                                            <div class="flex border-b border-gray-200">
                                                <button @click="selectedDocumentType = ''" :class="[
                                                    'px-4 py-2 text-sm font-medium',
                                                    !selectedDocumentType ?
                                                        'text-blue-600 border-b-2 border-blue-600' :
                                                        'text-gray-500 hover:text-gray-700'
                                                ]">
                                                    All
                                                </button>
                                                <button v-for="type in documentTypes" :key="type"
                                                    @click="selectedDocumentType = type" :class="[
                                                        'px-4 py-2 text-sm font-medium',
                                                        selectedDocumentType === type ?
                                                            'text-blue-600 border-b-2 border-blue-600' :
                                                            'text-gray-500 hover:text-gray-700'
                                                    ]">
                                                    {{ type }}
                                                </button>
                                            </div>

                                            <!-- Results Section -->
                                            <div class="max-h-60 overflow-y-auto">
                                                <div v-if="filteredDocuments.length > 0">
                                                    <div v-for="doc in filteredDocuments" :key="doc.document_id"
                                                        @click="selectBasis(doc)"
                                                        class="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center">
                                                        <span class="text-xs text-gray-500 mr-2">[{{ doc.document_type
                                                            }}]</span>
                                                        {{ doc.document_name }}
                                                    </div>
                                                </div>
                                                <div v-else class="p-4 text-center text-gray-500">
                                                    No documents found matching your criteria
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <!-- Department Multi-Select -->
                                <div v-if="showDepartmentField" class="mb-4">
                                    <label for="department" class="block font-medium mb-2">
                                        Department<span class="text-red-500">*</span>
                                    </label>
                                    <div class="relative">
                                        <!-- Selected Departments -->
                                        <div class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                                            @click="toggleDepartmentDropdown">
                                            <div class="flex flex-wrap gap-2">
                                                <span v-for="(department, index) in formData.department_type"
                                                    :key="department"
                                                    class="bg-blue-100 text-blue-600 px-2 py-1 rounded">
                                                    {{ getDepartmentName(department) }}
                                                    <span class="cursor-pointer ml-1 text-red-500"
                                                        @click="removeDepartment(index)">x</span>
                                                </span>
                                            </div>
                                            <div v-if="!formData.department_type.length" class="text-gray-400">
                                                Select department
                                            </div>
                                        </div>
                                        <div
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20">
                                                <path
                                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                            </svg>
                                        </div>

                                        <!-- Dropdown to show department options -->
                                        <div v-if="isDepartmentDropdownVisible"
                                            class="absolute z-10 bg-white shadow-lg mt-1 w-full border rounded">
                                            <ul class="max-h-60 overflow-y-auto">
                                                <li v-for="department in departments" :key="department.department_id"
                                                    class="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    @click="selectDepartment(department)">
                                                    {{ department.name }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label for="deadline" class="block font-medium mb-2">Deadline<span
                                            class="text-red-500">*</span></label>
                                    <input id="deadline" v-model="formData.deadline" type="datetime-local"
                                        class="border border-gray-300 px-3 py-2 rounded-md w-full" required />
                                </div>
                            </div>

                            <!-- Right Column -->
                            <div class="space-y-4">
                                <!-- User Type Multi-Select -->
                                <div v-if="showUserTypeField" class="mb-4">
                                    <label for="userType" class="block font-medium mb-2">
                                        User Type<span class="text-red-500">*</span>
                                    </label>
                                    <div class="relative">
                                        <!-- Selected Roles -->
                                        <div class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                                            @click="toggleUserTypeDropdown">
                                            <div class="flex flex-wrap gap-2">
                                                <span v-for="(role, index) in formData.user_type" :key="role"
                                                    class="bg-blue-100 text-blue-600 px-2 py-1 rounded">
                                                    {{ getRoleName(role) }}
                                                    <span class="cursor-pointer ml-1 text-red-500"
                                                        @click="removeRole(index)">x</span>
                                                </span>
                                            </div>
                                            <div v-if="!formData.user_type.length" class="text-gray-400">
                                                Select User Type
                                            </div>
                                        </div>
                                        <div
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20">
                                                <path
                                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                            </svg>
                                        </div>

                                        <!-- Dropdown to show role options -->
                                        <div v-if="isUserTypeDropdownVisible"
                                            class="absolute z-10 bg-white shadow-lg mt-1 w-full border rounded">
                                            <ul class="max-h-60 overflow-y-auto">
                                                <li v-for="role in roles" :key="role.role_id"
                                                    class="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    @click="selectRole(role)">
                                                    {{ role.name }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label for="numberOfQuestions" class="block font-medium mb-2">Number of
                                        Questions<span class="text-red-500">*</span></label>
                                    <input id="numberOfQuestions" v-model="formData.number_of_questions" type="number"
                                        class="border border-gray-300 px-3 py-2 rounded-md w-full" required />
                                </div>

                                <!-- Duration Selection Field -->
                                <div class="relative">
                                    <label for="timeSelection" class="block font-medium mb-2">Duration<span
                                            class="text-red-500">*</span></label>
                                    <div class="flex items-center space-x-2">
                                        <!-- Hours -->
                                        <div class="relative flex flex-col items-center">
                                            <span class="text-gray-400 text-sm">Hours</span>
                                            <select v-model="hours" @change="updateDuration"
                                                class="bg-white border border-gray-300 rounded-md px-4 py-2 appearance-none w-20 text-center focus:outline-none focus:ring-2 focus:ring-blue-500">
                                                <option v-for="n in 100" :key="n - 1" :value="n - 1">
                                                    {{ String(n - 1).padStart(2, '0') }}
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Colon separator -->
                                        <div class="text-xl font-medium">:</div>

                                        <!-- Minutes -->
                                        <div class="relative flex flex-col items-center">
                                            <span class="text-gray-400 text-sm">Minutes</span>
                                            <select v-model="minutes" @change="updateDuration"
                                                class="bg-white border border-gray-300 rounded-md px-4 py-2 appearance-none w-20 text-center focus:outline-none focus:ring-2 focus:ring-blue-500">
                                                <option v-for="n in 60" :key="n - 1" :value="n - 1">
                                                    {{ String(n - 1).padStart(2, '0') }}
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Colon separator -->
                                        <div class="text-xl font-medium">:</div>

                                        <!-- Seconds -->
                                        <div class="relative flex flex-col items-center">
                                            <span class="text-gray-400 text-sm">Seconds</span>
                                            <select v-model="seconds" @change="updateDuration"
                                                class="bg-white border border-gray-300 rounded-md px-2 py-2 appearance-none w-20 text-center focus:outline-none focus:ring-2 focus:ring-blue-500">
                                                <option v-for="n in 60" :key="n - 1" :value="n - 1">
                                                    {{ String(n - 1).padStart(2, '0') }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <!-- Description field -->
                        <div class="mt-6">
                            <label for="description" class="block font-medium mb-2">Description<span
                                    class="text-red-500">*</span></label>
                            <textarea id="description" v-model="formData.description"
                                class="border border-gray-300 px-3 py-2 rounded-md w-full" rows="3" required></textarea>
                        </div>
                    </form>
                </div>
            </div>

            <!-- Footer -->
            <div class="border-t border-gray-200 p-4 px-8">
                <div class="flex justify-end">
                    <button type="button" @click="clearForm"
                        class="bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-md mr-2">
                        Cancel
                    </button>
                    <button @click="submitForm" :disabled="isSubmitting"
                        class="bg-custom-yellow hover:bg-yellow-500 text-white font-medium py-2 px-4 rounded-md">
                        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/services/api";
import SweetAlertService from '@/services/SweetAlertService';

export default {
    name: 'AssessmentUploadForm',
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isSubmitting: false,
            errorMessage: '',
            formData: {
                assessment_name: '',
                basis: [],
                department_type: [],
                user_type: [],
                deadline: '',
                description: '',
                number_of_questions: '',
                duration: '',
            },
            documentTypes: ['SOP', 'Training material', 'Manuals', 'Memo'],
            departments: [],
            basisSearch: '',
            documents: [],
            roles: [],
            isDepartmentDropdownVisible: false,
            isUserTypeDropdownVisible: false,
            showDepartmentField: true,
            showUserTypeField: true,
            selectedDocumentType: '',
            showBasisDropdown: false,
            hours: 0,
            minutes: 0,
            seconds: 0,
            error: null
        };
    },
    created() {
        this.fetchRoles();
        this.getDepartments();
        this.fetchDocuments();
    },
    computed: {
        filteredDocuments() {
            let filtered = this.documents;

            // Filter by selected type
            if (this.selectedDocumentType) {
                filtered = filtered.filter(doc =>
                    doc.document_type === this.selectedDocumentType
                );
            }

            // Filter by search term
            if (this.basisSearch) {
                const searchTerm = this.basisSearch.toLowerCase();
                filtered = filtered.filter(doc =>
                    doc.document_name.toLowerCase().includes(searchTerm)
                );
            }

            // Filter out already selected documents
            return filtered.filter(doc => !this.formData.basis.includes(doc.document_id));
        }
    },

    methods: {
        toggleDepartmentDropdown() {
            this.isDepartmentDropdownVisible = !this.isDepartmentDropdownVisible;
        },

        toggleUserTypeDropdown() {
            this.isUserTypeDropdownVisible = !this.isUserTypeDropdownVisible;
        },

        selectDepartment(department) {
            if (!this.formData.department_type.includes(department.department_id)) {
                this.formData.department_type.push(department.department_id);
            }
            this.isDepartmentDropdownVisible = false; // Close dropdown after selection
        },

        selectRole(role) {
            if (!this.formData.user_type.includes(role.role_id)) {
                this.formData.user_type.push(role.role_id);
            }
            this.isUserTypeDropdownVisible = false; // Close dropdown after selection
        },

        removeDepartment(index) {
            this.formData.department_type.splice(index, 1);
        },

        removeRole(index) {
            this.formData.user_type.splice(index, 1);
        },

        getDepartmentName(departmentId) {
            const department = this.departments.find((dept) => dept.department_id === departmentId);
            return department ? department.name : '';
        },

        getRoleName(roleId) {
            const role = this.roles.find((r) => r.role_id === roleId);
            return role ? role.name : '';
        },

        updateDuration() {
            this.formData.duration = `${String(this.hours).padStart(2, '0')}:${String(this.minutes).padStart(2, '0')}:${String(this.seconds).padStart(2, '0')}`;
        },

        async getDepartments() {
            try {
                const response = await api.getDepartments();
                this.departments = response.data || response;
            } catch (error) {
                console.error('Error fetching departments:', error);
                this.error = error.message || "Failed to fetch departments";
                await SweetAlertService.showError("Error", "Failed to load departments");
            }
        },

        async fetchDocuments() {
            this.loading = true;
            try {
                const queryParams = { ...this.filters, search_query: this.searchQuery, page: this.currentPage, per_page: this.perPage };
                const response = await api.getDocuments(queryParams);
                this.documents = response.data || response;
                this.currentPage = response.current_page;
                this.totalPages = response.total_pages;
            } catch (error) {
                this.error = error.message || "Failed to fetch basis";
            } finally {
                this.loading = false;
            }
        },

        handleTypeChange() {
            this.basisSearch = ''; // Clear search when type changes
            this.showBasisDropdown = true;
        },

        selectBasis(document) {
            if (!this.formData.basis.includes(document.document_id)) {
                this.formData.basis.push(document.document_id);
            }
            this.basisSearch = '';
            this.showBasisDropdown = false;
        },

        removeBasis(documentId) {
            const index = this.formData.basis.indexOf(documentId);
            if (index > -1) {
                this.formData.basis.splice(index, 1);
            }
        },

        getDocumentName(documentId) {
            const document = this.documents.find(doc => doc.document_id === documentId);
            return document ? document.document_name : '';
        },

        getDocumentType(documentId) {
            const document = this.documents.find(doc => doc.document_id === documentId);
            return document ? document.document_type : '';
        },

        filterDocuments() {
            this.showBasisDropdown = true;
        },

        // Add click outside handler to close dropdown
        handleClickOutside(event) {
            if (!event.target.closest('.basis-select')) {
                this.showBasisDropdown = false;
            }
        },

        async fetchRoles() {
            try {
                const response = await api.getRoles();
                this.roles = response.data || response;
            } catch (error) {
                console.error('Error fetching roles:', error);
                this.error = error.message || "Failed to fetch roles";
                await SweetAlertService.showError("Error", "Failed to load user types");
            }
        },
        async submitForm() {
            if (this.formData.department_type.length === 0) {
                await SweetAlertService.showError("Error", "Please select at least one department");
                return;
            }
            if (this.formData.user_type.length === 0) {
                await SweetAlertService.showError("Error", "Please select at least one user type");
                return;
            }

            // Update duration before submitting
            this.updateDuration();
            this.isSubmitting = true;
            try {
                const response = await api.createAssessment(this.formData);
                this.$emit('assessment-created', response.data);
                await SweetAlertService.showSuccess("Success", "Assessment created successfully");
                this.clearForm();
                this.closeModal();
                this.$router.go(0);
            } catch (error) {
                console.error('Error creating assessment:', error);
                const errorMessage = error.response?.data?.message || 'Failed to create assessment. Please try again.';
                await SweetAlertService.showError("Error", errorMessage);
            } finally {
                this.isSubmitting = false;
            }
        },
        clearForm() {
            this.formData = {
                assessment_name: '',
                basis: '',
                department_type: [],
                user_type: [],
                deadline: '',
                description: '',
                number_of_questions: '',
                duration: '',
            };
            this.selectedDocumentType = '';
            this.basisSearch = '';
            this.showBasisDropdown = false;
            this.hours = 0;
            this.minutes = 0;
            this.seconds = 0;
        },

        closeModal() {
            this.clearForm();
            this.$emit('close-modal');
        }
    },

    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },

    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }
};
</script>