<template>
    <div class="max-w-7xl mx-auto p-4">
        <div v-if="error" class="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
            {{ error }}
        </div>

        <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
            <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>

        <template v-else>
            <div class="bg-white rounded-lg mb-4">
                <div class="flex justify-end">
                    <button @click="isEditing = true" :disabled="loading" class="text-custom-yellow">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>Edit
                    </button>
                </div>
                <div class="p-6">
                    <div v-if="isEditing">
                        <h2 class="text-lg font-semibold mb-4">Edit Assessment</h2>
                        <form @submit.prevent="updateAssessment">
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Test Name:</div>
                                    <input v-model="form.assessment_name"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" required />
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Number of Candidates:</div>
                                    <input v-model="form.audience_number" type="number"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" required />
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Basis:</div>
                                    <input v-model="form.documents"
                                        class="mt-2 w-full border border-gray-300 rounded p-2"
                                        placeholder="Comma-separated document names" required />
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Department:</div>
                                    <input v-model="form.departments"
                                        class="mt-2 w-full border border-gray-300 rounded p-2"
                                        placeholder="Comma-separated departments" required />
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Deadline:</div>
                                    <input v-model="form.deadline" type="date"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" required />
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">User Type:</div>
                                    <input v-model="form.roles" class="mt-2 w-full border border-gray-300 rounded p-2"
                                        placeholder="Comma-separated roles" required />
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Number of Questions:</div>
                                    <input v-model="form.number_of_questions" type="number"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" required />
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Duration:</div>
                                    <input v-model="form.duration" type="text"
                                        class="mt-2 w-full border border-gray-300 rounded p-2"
                                        placeholder="e.g., 2 hours" required />
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Status:</div>
                                    <input v-model="form.status" class="mt-2 w-full border border-gray-300 rounded p-2"
                                        required />
                                </div>

                                <div class="col-span-2 border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Description:</div>
                                    <textarea v-model="form.description"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" rows="4"></textarea>
                                </div>
                            </div>
                            <div class="flex justify-end mt-4">
                                <button type="submit"
                                    class="bg-custom-yellow hover:bg-orange-600 text-white px-4 py-2 rounded transition-colors">
                                    Save Changes
                                </button>
                                <button @click="isEditing = false"
                                    class="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded transition-colors ml-2">
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>

                    <div v-else>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <!-- Assessment Details -->
                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Test Name:</div>
                                <div class="mt-4">{{ assessment?.assessment_name }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Number of Candidates:</div>
                                <div class="mt-4">{{ assessment?.audience_number }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Basis:</div>
                                <div class="mt-4">
                                    <span v-for="(document, index) in assessment.documents" :key="document.document_id">
                                        {{ document.document_name }}
                                        <span v-if="index < assessment.documents.length - 1">, </span>
                                    </span>
                                </div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Date of Creation:</div>
                                <div class="mt-4">{{ formatDate(assessment?.created_at) }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Department:</div>
                                <div class="mt-4">
                                    <span v-for="(department, index) in assessment.departments"
                                        :key="department.assessment_id">
                                        {{ department.name }}
                                        <span v-if="index < assessment.departments.length - 1">, </span>
                                    </span>
                                </div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Deadline:</div>
                                <div class="mt-4">{{ formatDate(assessment?.deadline) }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">User Type:</div>
                                <div class="mt-4">
                                    <span v-for="(role, index) in assessment.roles" :key="role.assessment_id">
                                        {{ role.name }}
                                        <span v-if="index < assessment.roles.length - 1">, </span>
                                    </span>
                                </div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Number of Questions:</div>
                                <div class="mt-4">{{ assessment?.number_of_questions }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Duration:</div>
                                <div class="mt-4">{{ assessment?.duration }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Status:</div>
                                <div class="mt-4">{{ assessment?.status }}</div>
                            </div>

                            <div class="col-span-2 border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Description:</div>
                                <div class="mt-4">
                                    <p>{{ assessment?.description }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- Action Buttons -->
                        <div class="flex justify-end gap-4 mt-4">
                            <!-- <button @click="isEditing = true" :disabled="loading"
                                class="bg-custom-blue hover:bg-blue-600 disabled:bg-blue-300 text-white px-4 py-2 rounded transition-colors">
                                Edit
                            </button> -->
                            <button v-if="assessment.status !== 'Archived'" @click="archiveAssessment"
                                :disabled="loading"
                                class="bg-red-500 hover:bg-red-600 disabled:bg-red-300 text-white px-4 py-2 rounded transition-colors">
                                Archive
                            </button>

                            <button v-else @click="unarchiveAssessment" :disabled="loading"
                                class="bg-green-500 hover:bg-green-600 disabled:bg-green-300 text-white px-4 py-2 rounded transition-colors">
                                Unarchive
                            </button>
                            <button v-if="!questionsCreated" @click="handleAddCatQuetions(assessment.assessment_id)"
                                :disabled="loading"
                                class="bg-custom-yellow hover:bg-orange-600 disabled:bg-orange-300 text-white px-4 py-2 rounded transition-colors">
                                Add C.A.T
                            </button>
                            <button v-if="questionsCreated" @click="handleReviewQuestions(assessment.assessment_id)"
                                :disabled="loading"
                                class="bg-custom-yellow hover:bg-orange-600 disabled:bg-orange-300 text-white px-4 py-2 rounded transition-colors">
                                Review Questions
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import api from "@/services/api";
import SweetAlertService from '@/services/SweetAlertService';

export default {
    name: "SupervisorAssessmentDetailsPage",
    data() {
        return {
            loading: true,
            error: null,
            assessment: null,
            assessment_id: null,
            questionsCreated: null,
            isEditing: false,
            form: {
                assessment_name: '',
                audience_number: '',
                documents: '',
                departments: '',
                deadline: '',  
                roles: '',
                number_of_questions: '',
                duration: '',
                status: '',
                description: ''
            }
        };
    },
    created() {
        this.assessment_id = this.$route.params.assessment_id || this.assessmentIdFromParent;
        this.fetchAssessment();
        this.checkAssessment();
    },
    methods: {
        async fetchAssessment() {
            this.loading = true;
            this.error = null;
            try {
                const response = await api.getAssessment(this.assessment_id);
                this.assessment = response.data || response;

                // Populate the form with current assessment data for editing
                this.form = {
                    assessment_name: this.assessment.assessment_name,
                    audience_number: this.assessment.audience_number,
                    documents: this.assessment.documents.map(doc => doc.document_name).join(', '),
                    departments: this.assessment.departments.map(dep => dep.name).join(', '),
                    deadline: this.assessment.deadline.split('T')[0], // Ensure date is in YYYY-MM-DD format for input
                    roles: this.assessment.roles.map(role => role.name).join(', '),
                    number_of_questions: this.assessment.number_of_questions,
                    duration: this.assessment.duration,
                    status: this.assessment.status,
                    description: this.assessment.description
                };
            } catch (error) {
                this.error = error.message || "Failed to fetch assessment details.";
                console.error("Error fetching assessment:", error);
            } finally {
                this.loading = false;
            }
        },
        async checkAssessment() {
            try {
                const response = await api.checkIfAssessmentExist(this.assessment_id);
                const questionsData = response.data || response;

                this.questionsCreated = questionsData.exists;
            } catch (error) {
                this.error = error.message || "Failed to fetch questions";
                this.questionsCreated = false;
            }
        },
        async updateAssessment() {
            try {
                const updatedAssessment = {
                    assessment_name: this.form.assessment_name,
                    audience_number: this.form.audience_number,
                    documents: this.form.documents.split(',').map(doc => ({ document_name: doc.trim() })),
                    departments: this.form.departments.split(',').map(dep => ({ name: dep.trim() })),
                    deadline: this.form.deadline,
                    roles: this.form.roles.split(',').map(role => ({ name: role.trim() })),
                    number_of_questions: this.form.number_of_questions,
                    duration: this.form.duration,
                    status: this.form.status,
                    description: this.form.description
                };

                await api.updateAssessment(this.assessment_id, updatedAssessment);
                this.isEditing = false;
                this.fetchAssessment(); // Refresh the assessment details after update
            } catch (error) {
                this.error = error.message || "Failed to update assessment.";
            }
        },

        async archiveAssessment() {
            try {
                if (!this.assessment_id) throw new Error("Assessment ID is undefined");
                await api.toggleAssessmentStatus(this.assessment_id);
                await SweetAlertService.showSuccess("Success", "Assessment status updated to Archived");
                this.$router.go(0);
            } catch (error) {
                console.error(error);
                this.error = error.message || "Failed to archive document";
            } 
        },

        async unarchiveAssessment() {
            try {
                if (!this.assessment_id) throw new Error("Assessment ID is undefined");
                await api.toggleAssessmentStatus(this.assessment_id);
                await SweetAlertService.showSuccess("Success", "Assessment status updated to Ongoing");
                this.$router.go(0);
            } catch (error) {
                console.error(error);
                this.error = error.message || "Failed to unarchive document";
            }
        },


        formatDate(date) {
            return date ? new Date(date).toLocaleString() : '';
        },

        handleAddCatQuetions(assessmentId) {
            this.$router.push({ name: 'AddAssessementQuestionsPage', params: { assessment_id: assessmentId } });
        },
        handleReviewQuestions() {
            this.$router.push({ name: 'QuestionsReviewPage', params: { assessment_id: this.assessment_id } });
        },
    }
};
</script>

<style scoped>
/* Component-specific styles for improved alignment and responsiveness */
</style>