<template>
  <div class="container">
    <div class="mx-auto p-10 w-3/4">
      <form class="">
        <div class="grid grid-cols-1 gap-5">
          <h2 class="text-lg font-bold">My Information</h2>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <label class="block mb-1">First name <span class="text-red-500">*</span></label>
              <input type="text" v-model="formData.first_name"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                placeholder="Enter first name" required>
            </div>

            <div>
              <label class="block mb-1">Middle name</label>
              <input type="text" v-model="formData.middle_name"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                placeholder="Enter middle name" required>
            </div>

            <div>
              <label class="block mb-1">Last name <span class="text-red-500">*</span></label>
              <input type="text" v-model="formData.last_name"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                placeholder="Enter last name" required>
            </div>
            <div>
              <label class="block mb-2 text-gray-700 font-medium">Gender <span class="text-red-500">*</span></label>
              <div class="relative">
                <select id="gender" v-model="formData.gender"
                  class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                  required>
                  <option value="" disabled>Select gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div>
              <label class="block mb-1">Date of Birth <span class="text-red-500">*</span></label>
              <input type="date" v-model="formData.dob"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
            </div>

            <div>
              <label class="block mb-1">ID Number <span class="text-red-500">*</span></label>
              <input type="number" v-model="formData.id_number"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                placeholder="Enter ID number or passport" required>
            </div>

            <div>
              <label class="block mb-1">Phone number <span class="text-red-500">*</span></label>
              <input type="text" v-model="formData.phone_number"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                placeholder="Enter phone number" required>
            </div>

            <div>
              <label class="block mb-1">Email <span class="text-red-500">*</span></label>
              <input type="email" v-model="formData.email"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                placeholder="Enter email" required>
            </div>

            <div>
              <label class="block mb-2 text-gray-700 font-medium" for="blood_group">Select blood group <span
                  class="text-red-500">*</span></label>
              <div class="relative">
                <select id="blood_group_id" v-model="formData.blood_group_id"
                  class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                  required>
                  <option value="" disabled>Select blood group</option>
                  <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.blood_group_id">
                    {{ group.description }}
                  </option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div>
              <label class="block mb-2 text-gray-700 font-medium">Select contact mode<span
                  class="text-red-500">*</span></label>
              <div class="relative">
                <select id="contact_mode" v-model="formData.contact_mode"
                  class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                  required>
                  <option value="" disabled class="text-gray-400">Select contact mode</option>
                  <option value="both">Both</option>
                  <option value="phone">Phone</option>
                  <option value="email">Email</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <h2 class="text-lg font-semibold">Update password</h2>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <label class="block mb-1">New Password</label>
              <input type="password" v-model="formData.password"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                placeholder="Enter new password">
            </div>

            <div>
              <label class="block mb-1">Confirm Password</label>
              <input type="password" v-model="formData.password_confirmation"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                placeholder="Confirm new password">
            </div>
          </div>
        </div>
      </form>
      <div class="flex items-center justify-between mt-8">
        <div class="flex items-center ml-auto">
             <button @click="resetForm" 
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Cancel</button>
          <CustomButton label="Update" bg-class="bg-custom-yellow px-8 py-3 ml-3" @click="handleUpdateOfficer" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: 'MyProfileTab',
  components: {
    CustomButton,
  },
  data() {
    return {
      bloodGroups: [],
      userData: {},
      formData: {
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: '',
        dob: '',
        contact_mode: '',
        email: '',
        phone_number: '',
        id_number: '',
        blood_group_id: '',
        password: '',
        password_confirmation: '',
      },
      status: 'active',
      error: null,
    }
  },
  mounted() {
    this.userData = this.$store.getters["auth/user"];
    this.populateFormData();
    this.fetchBloodGroups();
  },
  methods: {
    validateFields() {
      const emptyFields = [];
      if (!this.formData.first_name) emptyFields.push('First name');
      if (!this.formData.last_name) emptyFields.push('Last name');
      if (!this.formData.gender) emptyFields.push('Gender');
      if (!this.formData.email) emptyFields.push('Email');
      if (!this.formData.phone_number) emptyFields.push('Phone number');
      if (!this.formData.contact_mode) emptyFields.push('Contact Mode');

      // Check password match
      if (this.formData.password && this.formData.password !== this.formData.password_confirmation) {
        emptyFields.push('Password confirmation does not match');
      }

      return emptyFields;
    },
    populateFormData() {
      this.formData.first_name = this.userData.first_name || '';
      this.formData.middle_name = this.userData.middle_name || '';
      this.formData.last_name = this.userData.last_name || '';
      this.formData.gender = this.userData.gender || '';
      this.formData.dob = this.userData.dob || '';
      this.formData.contact_mode = this.userData.contact_mode || '';
      this.formData.email = this.userData.email || '';
      this.formData.phone_number = this.userData.phone_number || '';
      this.formData.id_number = this.userData.id_number || '';
      this.formData.blood_group_id = this.userData.blood_group_id || '';
    },

    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response;
      } catch (error) {
        console.error('Error fetching blood groups:', error);
      }
    },

    async handleUpdateOfficer() {
      const data = {
        first_name: this.formData.first_name,
        middle_name: this.formData.middle_name,
        last_name: this.formData.last_name,
        gender: this.formData.gender,
        dob: this.formData.dob,
        contact_mode: this.formData.contact_mode,
        email: this.formData.email,
        phone_number: this.formData.phone_number,
        id_number: this.formData.id_number,
        blood_group_id: this.formData.blood_group_id,
        facility_id: this.userData.facility_id,
        facility_department_id: this.userData.user_roles[0].facility_department.facility_department_id,
        role_id: this.userData.user_roles[0].role_id,
      };

      if (this.formData.password) {
        data.password = this.formData.password;
        data.password_confirmation = this.formData.password_confirmation;
      }

      const emptyFields = this.validateFields();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        await api.updateUser(this.userData.user_id, data);
        if (this.formData.password) {
          await api.updatePassword(this.userData.user_id, {
            password: this.formData.password,
            password_confirmation: this.formData.password_confirmation
          });
        }
        await SweetAlertService.showSuccess('Success', 'Profile updated successfully!.');
        this.$store.dispatch("auth/logout");
        localStorage.removeItem('token');
        this.$router.go(0);
      } catch (error) {
        this.error = error.message || 'Failed to update Officer';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', 'Error updating profile.')
      }
    },
  },
}
</script>

<style lang="css" scoped></style>
