<template>
  <div>
    <AlertLayout title="Reminders!"
      message="Incorrect information will lead to nurses being unable to request for blood except for theatre."
      type="warning" />
    <div class="mx-auto px-10 border border-t-0">
      <h2 class="text-lg font-bold mb-6">Unit screening details</h2>
      <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div>
          <label class="block mb-1" for="serialNumber">Serial Number <span class="text-red-500">*</span></label>
          <input type="text" v-model="formData.serialNumber"
            class="w-full px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter Serial Number" required>
        </div>

        <div>
          <label class="block mb-1" for="donorName">Donor Name<span class="text-red-500">*</span></label>
          <input type="text" v-model="formData.donorName"
            class="w-full px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter Donor Number" required>
        </div>

        <div>
          <label class="block mb-1" for="bloodGroup">Blood Group <span class="text-red-500">*</span></label>
          <select v-model="formData.bloodGroup"
            class="w-full px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
            <option value="" disabled>Select Blood Group</option>
            <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.blood_group_id">
              {{ group.description }}
            </option>
          </select>
        </div>

        <div>
          <label class="block mb-1" for="bloodProduct">Blood Product <span class="text-red-500">*</span></label>
          <select v-model="formData.bloodProduct"
            class="w-full px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
            <option value="" disabled>Select Blood Product</option>
            <option v-for="product in bloodProducts" :key="product.unit_product_id" :value="product.unit_product_id">
              {{ product.description }}
            </option>
          </select>
        </div>

        <div>
          <label class="block mb-1" for="hepatitisBResult">Hepatitis B Result <span
              class="text-red-500">*</span></label>
          <div class="flex items-center w-full">
            <select v-model="formData.hepatitisBResult"
              :class="{ 'w-full': !tests.hepatitis_b, 'w-11/12': tests.hepatitis_b }"
              class="px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
              <option value="" disabled>Select Result</option>
              <option value="Negative">Negative</option>
              <option value="Positive">Positive</option>
              <option value="Inconclusive">Inconclusive</option>
            </select>
            <div v-if="tests.hepatitis_b" class="w-1/12 flex items-center justify-center">
              <input type="checkbox" v-model="testApproval.hepatitisBResult" :disabled="testApproval.hepatitisBResult"
                @change="confirmScreeningTest('hepatitisBResult')"
                class="w-6 h-6 border border-gray-300 rounded bg-gray-50 focus:ring-3 dark:bg-gray-700 dark:border-gray-600 dark:ring-offset-gray-800" />
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="hepatitisCResult">Hepatitis C Result <span
              class="text-red-500">*</span></label>
          <div class="flex items-center w-full">
            <select v-model="formData.hepatitisCResult"
              :class="{ 'w-full': !tests.hepatitis_c, 'w-11/12': tests.hepatitis_c }"
              class="px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
              <option value="" disabled>Select Result</option>
              <option value="Negative">Negative</option>
              <option value="Positive">Positive</option>
              <option value="Inconclusive">Inconclusive</option>
            </select>
            <div v-if="tests.hepatitis_c" class="w-1/12 flex items-center justify-center">
              <input type="checkbox" value="" v-model="testApproval.hepatitisCResult"
                :disabled="testApproval.hepatitisCResult" @change="confirmScreeningTest('hepatitisCResult')"
                class="w-6 h-6 border border-gray-300 rounded bg-gray-50 focus:ring-3 dark:bg-gray-700 dark:border-gray-600 dark:ring-offset-gray-800" />
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="hivResult">HIV Result <span class="text-red-500">*</span></label>
          <div class="flex items-center w-full">
            <select v-model="formData.hivResult" :class="{ 'w-full': !tests.hiv, 'w-11/12': tests.hiv }"
              class="px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
              <option value="" disabled>Select Result</option>
              <option value="Negative">Negative</option>
              <option value="Positive">Positive</option>
              <option value="Inconclusive">Inconclusive</option>
            </select>
            <div v-if="tests.hiv" class="w-1/12 flex items-center justify-center">
              <input type="checkbox" value="" v-model="testApproval.hivResult" :disabled="testApproval.hivResult"
                @change="confirmScreeningTest('hivResult')"
                class="w-6 h-6 border border-gray-300 rounded bg-gray-50 focus:ring-3 dark:bg-gray-700 dark:border-gray-600 dark:ring-offset-gray-800" />
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="vdrlResult">VDRL Result <span class="text-red-500">*</span></label>
          <div class="flex items-center w-full">
            <select v-model="formData.vdrlResult" :class="{ 'w-full': !tests.vdrl, 'w-11/12': tests.vdrl }"
              class="px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
              <option value="" disabled>Select Result</option>
              <option value="Non-Reactive">Non-Reactive</option>
              <option value="Reactive">Reactive</option>
              <option value="Inconclusive">Inconclusive</option>
            </select>
            <div v-if="tests.vdrl" class="w-1/12 flex items-center justify-center">
              <input type="checkbox" value="" v-model="testApproval.vdrlResult" :disabled="testApproval.vdrlResult"
                @change="confirmScreeningTest('vdrlResult')"
                class="w-6 h-6 border border-gray-300 rounded bg-gray-50 focus:ring-3 dark:bg-gray-700 dark:border-gray-600 dark:ring-offset-gray-800" />
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="malariaResult">Malaria Result <span class="text-red-500">*</span></label>
          <div class="flex items-center w-full">
            <select v-model="formData.malariaResult" :class="{ 'w-full': !tests.malaria, 'w-11/12': tests.malaria }"
              class="px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
              <option value="" disabled>Select Result</option>
              <option value="Negative">Negative</option>
              <option value="Positive">Positive</option>
              <option value="Inconclusive">Inconclusive</option>
            </select>
            <div v-if="tests.malaria" class="w-1/12 flex items-center justify-center">
              <input type="checkbox" value="" v-model="testApproval.malariaResult"
                :disabled="testApproval.malariaResult" @change="confirmScreeningTest('malariaResult')"
                class="w-6 h-6 border border-gray-300 rounded bg-gray-50 focus:ring-3 dark:bg-gray-700 dark:border-gray-600 dark:ring-offset-gray-800" />
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="convalescentPlasmaResult">Convalescent Plasma Result </label>
          <div class="flex items-center w-full">
            <select v-model="formData.convalescentPlasmaResult"
              :class="{ 'w-full': !tests.convalescent_plasma, 'w-11/12': tests.convalescent_plasma }"
              class="px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" >
              <option value="" disabled>Select Result</option>
              <option value="Negative">Negative</option>
              <option value="Positive">Positive</option>
              <option value="Inconclusive">Inconclusive</option>
            </select>
            <div v-if="tests.convalescent_plasma" class="w-1/12 flex items-center justify-center">
              <input type="checkbox" value="" v-model="testApproval.convalescentPlasmaResult"
                :disabled="testApproval.convalescentPlasmaResult"
                @change="confirmScreeningTest('convalescentPlasmaResult')"
                class="w-6 h-6 border border-gray-300 rounded bg-gray-50 focus:ring-3 dark:bg-gray-700 dark:border-gray-600 dark:ring-offset-gray-800" />
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="isClottedResult">Is the blood clotted <span
              class="text-red-500">*</span></label>
          <div class="flex items-center w-full">
            <select v-model="formData.isClottedResult"
              :class="{ 'w-full': !tests.blood_clot, 'w-11/12': tests.blood_clot }"
              class="px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
              <option value="" disabled>Select Answer</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
            <div v-if="tests.blood_clot" class="w-1/12 flex items-center justify-center">
              <input type="checkbox" value="" v-model="testApproval.isClottedResult"
                :disabled="testApproval.isClottedResult" @change="confirmScreeningTest('isClottedResult')"
                class="w-6 h-6 border border-gray-300 rounded bg-gray-50 focus:ring-3 dark:bg-gray-700 dark:border-gray-600 dark:ring-offset-gray-800" />
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="bloodOrigin">Blood Origin <span class="text-red-500">*</span></label>
          <select v-model="formData.bloodOrigin"
            class="w-full px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
            <option value="" disabled>Select Origin</option>
            <option value="Donation">Donation</option>
            <option value="Government">Government</option>
            <option value="Hospital Facility">Hospital Facility</option>
          </select>
        </div>

        <div>
          <label class="block mb-1" for="newBloodVolume">New Blood Volume <span class="text-red-500">*</span></label>
          <input type="text" v-model="formData.newBloodVolume"
            class="w-full px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter Blood Volume" required>
        </div>

        <div>
          <label class="block mb-1" for="bloodExpiryDate">Blood Expiry Date <span class="text-red-500">*</span></label>
          <input type="date" v-model="formData.bloodExpiryDate"
            class="w-full px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
        </div>
      </form>

      <div class="flex items-center justify-between mt-5 mb-4">
       
           <button @click="handleBackToBlooUnitsPage" 
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Back</button>
        <CustomButton label="Save" bg-class="bg-custom-yellow px-8 py-3" @click="handleSubmitBloodScreening" />
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: "NewTheatreDayForm",
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      user: '',
      donationId: '',
      donations: [],
      bloodProducts: [],
      bloodGroups: [],
      formData: {
        serialNumber: '',
        donorName: '',
        bloodGroup: '',
        bloodProduct: '',
        hepatitisBResult: '',
        hepatitisCResult: '',
        hivResult: '',
        vdrlResult: '',
        malariaResult: '',
        convalescentPlasmaResult: 'Negative',
        bloodOrigin: '',
        newBloodVolume: '',
        bloodExpiryDate: '',
        isClottedResult: '',
      },
      testApproval: {
        hepatitisBResult: false,
        hepatitisCResult: false,
        hivResult: false,
        vdrlResult: false,
        malariaResult: false,
        convalescentPlasmaResult: true,
        isClottedResult: false,
      },
      tests: {
        hepatitis_b: '',
        hepatitis_c: '',
        hiv: '',
        vdrl: '',
        malaria: '',
        convalescent_plasma: 'negative',
        blood_clot: '',
      },
      status: 'active',
      error: null,
    };
  },
  computed: {},
  mounted() {
    this.user = this.$store.getters["auth/user"];
    this.fetchBloodGroups();
    this.fetchBloodProducts();
    this.donationId = this.$route.params.blood_donation_id;
    if (this.donationId) {
      this.getBloodDonation(this.donationId);
    }
  },

  watch: {
  'formData.convalescentPlasmaResult'(newValue) {
    if (!newValue) {
      // Automatically set default to "Negative"
      this.formData.convalescentPlasmaResult = 'Negative';
      this.testApproval.convalescentPlasmaResult = true;
    }
  },
},

  methods: {
    handleBackToBlooUnitsPage() {
      this.$router.push({ name: 'InventoryManagementPage' });
    },
    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response || [];
      } catch (error) {
        console.error('Error fetching blood groups:', error);
      }
    },
    async fetchBloodProducts() {
      try {
        const response = await api.getUnitProducts();
        this.bloodProducts = response || [];
      } catch (error) {
        this.error = 'Failed to fetch blood products';
      }
    },
    async getBloodDonation(donationId) {
      try {
        const response = await api.getBloodDonation(donationId);
        const fetchedData = response.data || response;
        console.log('donation:', fetchedData);

        // Optionally, populate formfetchedData with the fetched donation details
        this.donations = fetchedData; // assuming the response returns an array
        console.log('donations:', this.donations);
        if (this.donations) {
          this.formData.serialNumber = this.donations.pint_serial || '';
          this.formData.donorName = this.donations.donor.user.first_name + ' ' + this.donations.donor.user.last_name || '';
          this.formData.bloodGroup = this.donations.blood_group_id || '';
          this.formData.bloodProduct = this.donations.unit_product_id || '';
          this.formData.hepatitisBResult = this.donations.screening_test.hepatitis_b || '';
          this.formData.hepatitisCResult = this.donations.screening_test.hepatitis_c || '';
          this.formData.hivResult = this.donations.screening_test.hiv || '';
          this.formData.vdrlResult = this.donations.screening_test.vdrl || '';
          this.formData.malariaResult = this.donations.screening_test.malaria || '';
          this.formData.convalescentPlasmaResult = this.donations.screening_test.convalescent_plasma || '';
          this.formData.isClottedResult = this.donations.screening_test.blood_clot || '';
          this.formData.bloodOrigin = this.donations.origin || '';
          this.formData.newBloodVolume = this.donations.quantity || '';
          this.formData.bloodExpiryDate = this.donations.expiry_date || '';

          this.tests.hepatitis_b = this.donations.screening_test.hepatitis_b || '';
          this.tests.hepatitis_c = this.donations.screening_test.hepatitis_c || '';
          this.tests.hiv = this.donations.screening_test.hiv || '';
          this.tests.vdrl = this.donations.screening_test.vdrl || '';
          this.tests.malaria = this.donations.screening_test.malaria || '';
          this.tests.convalescent_plasma = this.donations.screening_test.convalescent_plasma || '';
          this.tests.blood_clot = this.donations.screening_test.blood_clot || '';
        }

        const tests = [
          'hepatitis_b',
          'hepatitis_c',
          'hiv',
          'vdrl',
          'malaria',
          'convalescent_plasma',
          'blood_clot'
        ];

        tests.forEach(test => {
          const log = this.donations.screening_logs.find(
            entry => entry.property === test
          );
          this.testApproval[`${this.camelCase(test)}Result`] = log && log.confirmed_by?.user_id ? true : false;
        });

      } catch (error) {
        this.error = error.message || 'Failed to fetch donations';
        SweetAlertService.showError('Error', 'Error fetching blood donation.')
        this.$router.push({ name: 'InventoryManagementPage' });
      }
    },
    camelCase(str) {
      return str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
    },

    async handleSubmitBloodScreening() {

      if (!this.formData.convalescentPlasmaResult) {
    this.formData.convalescentPlasmaResult = "Negative";
    this.testApproval.convalescentPlasmaResult = true; // Auto-confirm
  }

      const data = {
        blood_donation: {
          donor_user_id: this.donations.donor.user.user_id,
          serial_number: this.formData.serialNumber,
          blood_group_id: this.formData.bloodGroup,
          unit_product_id: this.formData.bloodProduct,
          origin: this.formData.bloodOrigin,
          quantity: this.formData.newBloodVolume,
          expiry_date: this.formData.bloodExpiryDate,
        },
        screening_test: {
          hepatitis_b: this.formData.hepatitisBResult,
          hepatitis_c: this.formData.hepatitisCResult,
          hiv: this.formData.hivResult,
          vdrl: this.formData.vdrlResult,
          malaria: this.formData.malariaResult,
          convalescent_plasma: this.formData.convalescentPlasmaResult,
          blood_clot: this.formData.isClottedResult
        },
      };

      try {
        await api.updateScreeningTest(this.donationId, data);
        await SweetAlertService.showSuccess('Success!', 'Screening Test(s) updated successfully!.');
        this.$router.push({ name: 'InventoryManagementPage' });
      } catch (error) {
        this.error = error.message || 'Failed to save screening tests';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', 'Error updating screening tests.')
      }
    },

    async confirmScreeningTest(testProperty) {
      try {
        const backendPropertyMapping = {
          hepatitisBResult: 'hepatitis_b',
          hepatitisCResult: 'hepatitis_c',
          hivResult: 'hiv',
          vdrlResult: 'vdrl',
          malariaResult: 'malaria',
          convalescentPlasmaResult: 'convalescent_plasma',
          isClottedResult: 'blood_clot',
        };

         // Ensure convalescentPlasmaResult is "Negative" if empty
    if (testProperty === 'convalescentPlasmaResult' && !this.formData.convalescentPlasmaResult) {
      this.formData.convalescentPlasmaResult = "Negative";
      this.testApproval.convalescentPlasmaResult = true; // Auto-confirm
    }

        // Construct the payload
        const payload = {
          screening_test: {
            [backendPropertyMapping[testProperty]]: this.formData[testProperty]
          }
        };

        // Make the API call
        await api.confirmScreeningTest(this.donationId, payload);

      } catch (error) {
        console.error('Error confirming screening test:', error);
        this.error = 'Failed to confirm screening test.';
      }
    },
  },
};
</script>

<style lang="css" scoped></style>