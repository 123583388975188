<template>
    <button
      :type="type"
      :class="['flex items-center text-white rounded-md px-3 py-1 space-x-2', bgClass]"
    >
      <svg
        v-if="iconPath"
        :class="[iconClass, 'flex-shrink-0']"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path :d="iconPath" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </svg>
      <span>{{ label }}</span>
    </button>
  </template>
  
  <script>
  export default {
    props: {
      type: {
        type: String,
        default: 'button',
      },
      label: {
        type: String,
        required: true,
      },
      iconPath: {
        type: String,
        required: false,
      },
      iconClass: {
        type: String,
        default: 'w-6 h-6 text-gray-800 dark:text-white',
      },
      bgClass: {
        type: String,
        default: 'bg-custom-yellow',
      },
    },
  }
  </script>
  
  <style scoped>
  /* Add any additional styles if needed */
  </style>
  