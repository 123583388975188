<template>
  <div>
    <div class="mx-auto px-10 py-2 border border-t-0">
      <h2 class="text-lg font-bold mb-6">Blood Groups Targets</h2>
      <form>
        <div class="grid grid-cols-1 md:grid-cols-1 gap-5 py-2">
          <div>
            <label class="block mb-1">Select Product <span class="text-red-500">*</span></label>
            <select v-model="formData.unit_product_id" class="w-full p-2.5 border rounded bg-gray-100 shadow-sm">
              <option v-for="product in unitProducts" :key="product.unit_product_id" :value="product.unit_product_id">
                {{ product.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div>
            <label class="block mb-1">A+(Positive) <span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.a_positive"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter Target" required>
          </div>
          <div>
            <label class="block mb-1">A-(Negative) <span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.a_negative"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter Target" required>
          </div>
          <div>
            <label class="block mb-1">B+(Positive) <span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.b_positive"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter Target" required>
          </div>
          <div>
            <label class="block mb-1">B-(Negative) <span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.b_negative"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter Target" required>
          </div>
          <div>
            <label class="block mb-1">AB+(Positive) <span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.ab_positive"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter Target" required>
          </div>
          <div>
            <label class="block mb-1">AB-(Negative) <span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.ab_negative"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter Target" required>
          </div>
          <div>
            <label class="block mb-1">O+(Positive) <span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.o_positive"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter Target" required>
          </div>
          <div>
            <label class="block mb-1">O-(Negative) <span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.o_negative"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter Target" required>
          </div>
        </div>
      </form>
      <div class="flex items-center justify-between mt-8">
        <div class="flex items-center ml-auto">
          <CustomButton label="Save" bg-class="bg-custom-yellow px-8 py-3 ml-3"
            @click="handleSubmitNewUnitTargets" />
          <!-- <CustomButton v-if="targets.length > 0" label="Update Targets" bg-class="bg-custom-yellow px-8 py-3 ml-3"
            @click="handleUpdateUnitTargets" /> -->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';

export default {
  name: "UnitTargetsForm",
  components: {
    CustomButton,
  },
  data() {
    return {
      user: '',
      targets: [],
      unitProducts: [],
      formData: {
        a_positive: '',
        a_negative: '',
        b_positive: '',
        b_negative: '',
        ab_positive: '',
        ab_negative: '',
        o_positive: '',
        o_negative: '',
        facility_id: '',
      },
      status: 'active',
      error: null,
    };
  },
  watch: {
    'formData.unit_product_id': async function (newProductId) {
      if (newProductId) {
        this.fetchTargetData(this.user.facility_id);
      }
    }
  },
  mounted() {
    this.fetchBloodGroups();
    this.fetchBloodProducts();
    this.user = this.$store.getters["auth/user"];
    if (this.user) {
      this.fetchTargetData(this.user.facility_id);
    }
  },
  methods: {
    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups(); // Fetch blood groups from API
        this.bloodGroupMapping = response.reduce((acc, bloodGroup) => {
          acc[bloodGroup.name] = bloodGroup.blood_group_id;
          return acc;
        }, {});
      } catch (error) {
        console.error('Error fetching blood groups:', error);
      }
    },
    async fetchBloodProducts() {
      try {
        const response = await api.getUnitProducts();
        this.unitProducts = response || [];
      } catch (error) {
        console.error('Error fetching blood products:', error);
      }
    },
    async fetchTargetData(facility_id) {
      try {
        const response = await api.getUnitTargets(facility_id);
        this.targets = response.data || response;

        // Filter targets by the selected unit product
        const filteredTargets = this.targets.filter(target => target.unit_product_id === this.formData.unit_product_id);
        this.populateFormData(filteredTargets);
      } catch (error) {
        console.error('Failed to fetch target data:', error);
        this.error = error.message || 'Failed to fetch target data';
      }
    },

    populateFormData(targetData) {
      this.formData.a_positive = '';
      this.formData.a_negative = '';
      this.formData.b_positive = '';
      this.formData.b_negative = '';
      this.formData.ab_positive = '';
      this.formData.ab_negative = '';
      this.formData.o_positive = '';
      this.formData.o_negative = '';

      // Populate form data based on targetData
      targetData.forEach(data => {
        switch (data.blood_group_id) {
          case this.bloodGroupMapping['A+']:
            this.formData.a_positive = data.target_value || '';
            break;
          case this.bloodGroupMapping['A-']:
            this.formData.a_negative = data.target_value || '';
            break;
          case this.bloodGroupMapping['B+']:
            this.formData.b_positive = data.target_value || '';
            break;
          case this.bloodGroupMapping['B-']:
            this.formData.b_negative = data.target_value || '';
            break;
          case this.bloodGroupMapping['AB+']:
            this.formData.ab_positive = data.target_value || '';
            break;
          case this.bloodGroupMapping['AB-']:
            this.formData.ab_negative = data.target_value || '';
            break;
          case this.bloodGroupMapping['O+']:
            this.formData.o_positive = data.target_value || '';
            break;
          case this.bloodGroupMapping['O-']:
            this.formData.o_negative = data.target_value || '';
            break;
          default:
            break;
        }
      });
    },

    async handleSubmitNewUnitTargets() {
      const bloodGroups = [
        { blood_group_id: this.bloodGroupMapping['A+'], target_value: this.formData.a_positive },
        { blood_group_id: this.bloodGroupMapping['A-'], target_value: this.formData.a_negative },
        { blood_group_id: this.bloodGroupMapping['B+'], target_value: this.formData.b_positive },
        { blood_group_id: this.bloodGroupMapping['B-'], target_value: this.formData.b_negative },
        { blood_group_id: this.bloodGroupMapping['AB+'], target_value: this.formData.ab_positive },
        { blood_group_id: this.bloodGroupMapping['AB-'], target_value: this.formData.ab_negative },
        { blood_group_id: this.bloodGroupMapping['O+'], target_value: this.formData.o_positive },
        { blood_group_id: this.bloodGroupMapping['O-'], target_value: this.formData.o_negative },
      ].filter(group => group.target_value);

      const data = {
        unit_product_id: this.formData.unit_product_id,
        facility_id: this.user.facility_id,
        unit_targets: bloodGroups,
      };

      try {
        await api.createUnitTarget(data);
        this.$router.go(0);
      } catch (error) {
        this.error = error.message || 'Failed to save Unit Targets';
        console.error('Error:', this.error);
      }
    },
  },
};
</script>

<style lang="css" scoped>
/* Your existing styles */
</style>
