<template>
  <div>
    <AlertLayout  v-if="currentTab === 'blood-units'" title="Reminders!" message="Incorrect screening results could be fatal to recipients. Kindly ensure you enter the correct
    results and information below <br> Kindly note that you can only process one unit at at a time"
      type="warning" />
      <AlertLayout  v-if="currentTab === 'patient-samples'" title="Reminders!" message="Kindly note that only valid samples are listed below. Each sample has a 7-day validity period."
      type="warning" />
      <AlertLayout  v-if="currentTab === 'live-counter'" title="Reminders!" message="Kindly note that the default stats below are based on today's date. <br> 
        Kindly note that the stats below are ordered in the following manner SUPPLY/ DEMAND/ TARGET DEFICIT <br> 
        Kindly note that the stats below can be downloaded to an excel file format for further analysis" 
      type="warning" />
    <div class="flex flex-wrap items-center justify-between pb-4 py-1">
      <div class="flex items-center space-x-4">
        <div class="relative">
          <button id="dropdownRadioButton"
            class="inline-flex items-center text-gray-400 bg-white border border-gray-300 rounded-md px-6 py-2"
            type="button" @click="toggleDropdown">
            <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                d="M18.796 4H5.204a1 1 0 0 0-.753 1.659l5.302 6.058a1 1 0 0 1 .247.659v4.874a.5.5 0 0 0 .2.4l3 2.25a.5.5 0 0 0 .8-.4v-7.124a1 1 0 0 1 .247-.659l5.302-6.059c.566-.646.106-1.658-.753-1.658Z" />
            </svg>
            Filters
            <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 4 4 4-4" />
            </svg>
          </button>
          <!-- start dropdown filter -->
          <template v-if="currentTab === 'blood-units'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <div class="flex">
                <button type="button"
                  class="ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex text-gray-500 items-center justify-end h-8 w-8 hover:text-custom-yellow"
                  @click="cancelFilters" aria-label="Close">
                  <span class="sr-only">Dismiss</span>
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
              </div>
              <form class="max-w-sm mx-auto" @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="donationProcess" class="block mb-2 text-sm font-medium text-gray-900">Blood Group
                  </label>
                  <select v-model="filters.bloodGroup"
                    class="bg-gray-50 border border-gray-200 text-gray-900 rounded-md block w-full py-2 px-1 dark:placeholder-gray-400">
                    <option disabled selected>Select blood group</option>
                    <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.description">
                      {{ group.description }}
                    </option>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="dateRegistered" class="block mb-2 text-sm font-medium text-gray-900">Donation Date</label>
                  <input type="text" id="dateRegistered" v-model="filters.dateRegistered"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="mb-4">
                  <label for="donationProcess" class="block mb-2 text-sm font-medium text-gray-900">Donation
                    Process</label>
                  <select id="donationProcess" v-model="filters.donationProcess"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select</option>
                    <option value="Normal">Normal</option>
                    <option value="Apheresis">Apheresis</option>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="statusFilter" class="block mb-2 text-sm font-medium text-gray-900">Status</label>
                  <select id="statusFilter" v-model="filters.status"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select</option>
                    <option value="Unscreened">Unscreened</option>
                    <option value="Ongoing Screening">Ongoing Screening</option>
                    <option value="Pending Approval">Pending Approval</option>
                    <option value="Approved">Approved</option>
                    <option value="Disposed">Disposed</option>
                    <option value="Pending Disposal">Pending Disposal</option>
                  </select>
                </div>
                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </template>


          <!-- live counter filter -->

          <template v-if="currentTab === 'live-counter'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <div class="flex">
                <button type="button"
                  class="ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex text-gray-500 items-center justify-end h-8 w-8 hover:text-custom-yellow"
                  @click="cancelFilters" aria-label="Close">
                  <span class="sr-only">Dismiss</span>
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
              </div>
              <form class="max-w-sm mx-auto" @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="donationProcess" class="block mb-2 text-sm font-medium text-gray-900">Blood Group
                  </label>
                  <select v-model="filters.bloodGroup"
                    class="bg-gray-50 border border-gray-200 text-gray-900 rounded-md block w-full py-2 px-1 dark:placeholder-gray-400">
                    <option disabled selected>Select blood group</option>
                    <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.description">
                      {{ group.description }}
                    </option>
                  </select>
                </div>
                <!-- Unit Product Filter -->
                <div class="mb-4">
                  <label for="unitProduct" class="block mb-2 text-sm font-medium text-gray-900">Unit Product</label>
                  <select v-model="filters.unitProduct"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select unit product</option>
                    <option v-for="product in bloodProducts" :key="product.unit_product_id" :value="product.name">
                      {{ product.name }}
                    </option>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="dateRegistered" class="block mb-2 text-sm font-medium text-gray-900">Donation Date</label>
                  <input type="text" id="dateRegistered" v-model="filters.dateRegistered"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="mb-4">
                  <label for="statusFilter" class="block mb-2 text-sm font-medium text-gray-900">Status</label>
                  <select id="statusFilter" v-model="filters.status"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select</option>
                    <option value="Okay">Okay</option>
                    <option value="Assigned">Assigned</option>
                    <option value="Pending Approval">Issued</option>
                    <option value="Transfused">Transfused</option>
                    <option value="Expired">Expired</option>
                    <option value="Segregated">Segregated</option>
                  </select>
                </div>
                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </template>
        </div>

        <div class="relative flex">
          <input type="text" v-model="searchQuery" id="table-search"
            class="px-4 py-2 text-gray-800 border border-gray-300 rounded-sm dark:border-gray-300 dark:placeholder-gray-400"
            placeholder="Search" />
          <button type="submit" class="bg-custom-yellow text-white rounded-r px-2 md:px-3 py-0 md:py-1">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
            </svg>
          </button>
        </div>
      </div>
      <div class="flex items-center space-x-4 mt-4 sm:mt-0">
        <template v-if="currentTab === 'blood-units'">
          <CustomButton label="New Unit" :icon-path="'M5 12h14m-7 7V5'" bg-class="bg-custom-yellow"
            @click="handleNewUnitRegistration" />
        </template>
        <!-- <template v-else-if="currentTab === 'patient-samples'">
          <CustomButton label="New Sample" :icon-path="'M5 12h14m-7 7V5'" bg-class="bg-custom-yellow"
            @click="handleNewSample" />
        </template> -->
        <template v-else-if="currentTab === 'live-counter'">
          <CustomButton label="Live Counter Tally" :icon-path="'M5 12h14m-7 7V5'" bg-class="bg-custom-yellow"
            @click="handleLiveCounter" />
        </template>
      </div>
    </div>
    <div class="tabs flex border-b mb-4">
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'blood-units' }" @click="currentTab = 'blood-units'">
        Blood Units
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'patient-samples' }" @click="currentTab = 'patient-samples'">
        Patient Samples
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'live-counter' }" @click="currentTab = 'live-counter'">
        Live counter
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'unit-target' }" @click="currentTab = 'unit-target'">
        Unit target
      </button>
    </div>
    <component :is="currentTabComponent" ref="currentTabComponent" :search-query="searchQuery"></component>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import BloodUnitsTab from "../tabs/BloodUnitsTab.vue";
import PatientSamplesTab from "../tabs/PatientSamplesTab.vue";
import LiveCounterTab from "../tabs/LiveCounterTab.vue";
import UnitTargetsForm from "../forms/UnitTargetsForm.vue";
import api from '@/services/api';

export default {
  name: "InventoryManagementPage",
  components: {
    AlertLayout,
    CustomButton,
    BloodUnitsTab,
    PatientSamplesTab,
    LiveCounterTab,
    UnitTargetsForm,
  },
  data() {
    return {
      searchQuery: "",
      isDropdownOpen: false,
      currentTab: "blood-units",
      bloodGroups: [],
      bloodProducts: [],
      filters: {
        bloodGroup: "",
        dateRegistered: '',
        donationProcess: '',
        status: "",
        unitProduct: ""
      },
    };
  },
  computed: {
    currentTabComponent() {
      switch (this.currentTab) {
        case "blood-units":
          return "BloodUnitsTab";
        case "patient-samples":
          return "PatientSamplesTab";
        case "live-counter":
          return "LiveCounterTab";
        case "unit-target":
          return "UnitTargetsForm";
        default:
          return "BloodUnitsTab";
      }
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    token() {
      return this.$store.getters["auth/token"];
    },
  },
  created() {
    this.fetchBloodGroups();
    this.fetchBloodProducts();
  },
  methods: {

    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response;
      } catch (error) {
        console.error('Error fetching blood groups:', error);
      }
    },

    async fetchBloodProducts() {
      try {
        const response = await api.getUnitProducts();
        this.bloodProducts = response;
      } catch (error) {
        console.error('Error fetching blood products:', error);
      }
    },

    handleNewUnitRegistration() {
      this.$router.push({ name: 'NewExternalUnit' });
    },

    handleNewSample() {
      // Handle the action for the "New Questionnaire" button
      // e.g., navigating to a form or opening a modal
    },

    handleLiveCounter() {
      this.$router.push({ name: 'LiveCounterTallyPage' });
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    applyFilters() {
      console.log('Applying filters:', this.filters);
      this.applyFiltersToCurrentTab();
      this.isDropdownOpen = false;
    },

    applyFiltersToCurrentTab() {
      if (this.$refs.currentTabComponent && typeof this.$refs.currentTabComponent.applyFilters === 'function') {
        this.$refs.currentTabComponent.applyFilters(this.filters);
      } else {
        console.error('applyFilters method not found on current tab component');
      }
    },

    cancelFilters() {
      this.filters = {
        bloodGroup: "",
        dateRegistered: '',
        donationProcess: '',
        status: "",
        unitProduct: ""
      };
      this.applyFiltersToCurrentTab();
      this.isDropdownOpen = false;
    },
  },

  mounted() { },

};
</script>

<style lang="css" scoped></style>