<template>
  <div class="px-6 py-4">
    <AlertLayout title="Reminders!"
      message="Kindly transfuse blood units within 30min of collection to maintain cold chain. Selecting the correct serial/pilot number will assist you in accounting for collected blood units. Enter the correct patient IP/OP No. associated with the unit as well to ensure acccuracy of unit for the patient. In case of any transfusion reaction send the following sample to laboratory: <br>-10ml of blood into a plain tube <br>- 2ml of blood into an EDTA <br>-The first voided urine <br>-The blood that reacted together with the attached transfusion set <br>-All empty blood bags of already transfused blood."
      type="warning" />
    <div class="font-bold text-lg mb-4">Product Details</div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 border-b border-gray-300 py-3">
      <!-- Left Column: Product Information -->
      <div class="py-2 space-y-6">
        <div class="grid grid-cols-2 items-center border-t m-2">
          <label class="text-md font-normal mt-3">Serial No:</label>
          <p class="text-md mt-3 font-semibold">{{ assigned_unit.product?.pint_serial }}</p>
        </div>

        <div class="grid grid-cols-2 items-center border-t m-2">
          <label class="text-md font-normal mt-3">Product:</label>
          <p class="text-md mt-3 font-semibold">{{ assigned_unit.product?.unit_product?.description }}</p>
        </div>

        <div class="grid grid-cols-2 items-center border-t m-2">
          <label class="text-md font-normal mt-3">Blood Group:</label>
          <p class="text-md mt-3 font-semibold">{{ assigned_unit.product?.blood_group?.description }}</p>
        </div>

        <div class="grid grid-cols-2 items-center border-t m-2">
          <label class="text-md font-normal mt-3">Prescription:</label>
          <p class="text-md mt-3 font-semibold">{{ assigned_unit.requisition_product?.quantity }} unit(s) ({{
            assigned_unit.requisition_product?.volume }} {{ assigned_unit.requisition_product?.uom }})</p>
        </div>
      </div>

      <!-- Right Column: Transfusion Information -->
      <div class="py-2 space-y-6">
        <div class="grid grid-cols-2 items-center border-t m-2">
          <label class="text-md font-normal mt-3">Product Expiry Date:</label>
          <p class="text-md mt-3 font-semibold">
            {{ assigned_unit.product?.expiry_date ? new Date(assigned_unit.product.expiry_date).toISOString().split('T')[0] : '' }}
          </p>
        </div>

        <div class="grid grid-cols-2 items-center border-t m-2">
          <label class="text-md font-normal mt-3">Start Transfusion Date:</label>
          <p class="text-md mt-3 font-semibold">
            {{ transfusion.started_on ? new Date(transfusion.started_on).toLocaleString() : '' }}
          </p>
        </div>

        <div class="grid grid-cols-2 items-center border-t m-2">
          <label class="text-md font-normal mt-3">Started By:</label>
          <p class="text-md mt-3 font-semibold">{{ transfusion.started_by?.first_name }}
            {{ transfusion.started_by?.last_name }}
          </p>
        </div>

        <div class="grid grid-cols-2 items-center border-t m-2">
          <label class="text-md font-normal mt-3">Witness:</label>
          <p class="text-md mt-3 font-semibold">{{ transfusion.witness?.first_name }}
            {{ transfusion.witness?.last_name }}
          </p>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-3 py-3 border-b border-gray-300">
      <div class="flex items-center w-4/6 justify-between px-2">
        <label class="text-md">Verbal ID</label>
        <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
          v-model="vitalsData.verbal_id" />
      </div>
      <div class="flex items-center w-4/6 justify-between px-2">
        <label class="text-md">Rhesus D</label>
        <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4" 
        v-model="vitalsData.rhesus" />
      </div>
      <div class="flex items-center w-4/6 justify-between px-2">
        <label class="text-md">ID Band</label>
        <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
          v-model="vitalsData.id_band" />
      </div>
      <div class="flex items-center w-4/6 justify-between px-2">
        <label class="text-md">IV Filter System</label>
        <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
          v-model="vitalsData.iv_filter" />
      </div>
      <div class="flex items-center w-4/6 justify-between px-2">
        <label class="text-md">Consent</label>
        <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
          v-model="vitalsData.consent" />
      </div>
    </div>


    <div class="grid grid-cols-1 md:grid-cols-1 gap-6 mt-3 py-3">
      <!-- readings Monitoring Table -->
      <div class="w-full mt-6 md:mt-0">
        <table class="table-auto w-full text-md text-center">
          <thead class="bg-gray-100">
            <tr>
              <th class="px-2 py-2"></th>
              <th class="px-2 py-2">Pre Transfusion</th>
              <th class="px-2 py-2">15 mins</th>
              <th class="px-2 py-2">30 mins</th>
              <th class="px-2 py-2">45 mins</th>
              <th class="px-2 py-2">60 mins</th>
              <th class="px-2 py-2">90 mins</th>
              <th class="px-2 py-2">120 mins</th>
              <th class="px-2 py-2">180 mins</th>
              <th class="px-2 py-2">240 mins</th>
              <th class="px-2 py-2">300 mins</th>
              <th class="px-2 py-2">1 hr Post Transfusion</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="px-3 py-1">Real Time</td>
              <td>
                <input type="time" v-model="readings.real_time[0]" :disabled="isTransfusionComplete"
                  @change="saveReading('0', 'real_time')" :class="{ 'bg-gray-200': readings.real_time[0] }"
                  class="input-table" />
              </td>
              <td>
                <input type="time" v-model="readings.real_time[15]" :disabled="isTransfusionComplete"
                  @change="saveReading('15', 'real_time')" :class="{ 'bg-gray-200': readings.real_time[15] }"
                  class="input-table" />
              </td>
              <td>
                <input type="time" v-model="readings.real_time[30]" :disabled="isTransfusionComplete"
                  @change="saveReading('30', 'real_time')" :class="{ 'bg-gray-200': readings.real_time[30] }"
                  class="input-table" />
              </td>
              <td>
                <input type="time" v-model="readings.real_time[45]" :disabled="isTransfusionComplete"
                  @change="saveReading('45', 'real_time')" :class="{ 'bg-gray-200': readings.real_time[45] }"
                  class="input-table" />
              </td>
              <td>
                <input type="time" v-model="readings.real_time[60]" :disabled="isTransfusionComplete"
                  @change="saveReading('60', 'real_time')" :class="{ 'bg-gray-200': readings.real_time[60] }"
                  class="input-table" />
              </td>
              <td>
                <input type="time" v-model="readings.real_time[90]" :disabled="isTransfusionComplete"
                  @change="saveReading('90', 'real_time')" :class="{ 'bg-gray-200': readings.real_time[90] }"
                  class="input-table" />
              </td>
              <td>
                <input type="time" v-model="readings.real_time[120]" :disabled="isTransfusionComplete"
                  @change="saveReading('120', 'real_time')" :class="{ 'bg-gray-200': readings.real_time[120] }"
                  class="input-table" />
              </td>
              <td>
                <input type="time" v-model="readings.real_time[180]" :disabled="isTransfusionComplete"
                  @change="saveReading('180', 'real_time')" :class="{ 'bg-gray-200': readings.real_time[180] }"
                  class="input-table" />
              </td>
              <td>
                <input type="time" v-model="readings.real_time[240]" :disabled="isTransfusionComplete"
                  @change="saveReading('240', 'real_time')" :class="{ 'bg-gray-200': readings.real_time[240] }"
                  class="input-table" />
              </td>
              <td>
                <input type="time" v-model="readings.real_time[300]" :disabled="isTransfusionComplete"
                  @change="saveReading('300', 'real_time')" :class="{ 'bg-gray-200': readings.real_time[300] }"
                  class="input-table" />
              </td>
              <td>
                <input type="time" v-model="readings.real_time[1000]" :disabled="isTransfusionComplete"
                  @change="saveReading('1000', 'real_time')" :class="{ 'bg-gray-200': readings.real_time[1000] }"
                  class="input-table" />
              </td>
            </tr>

            <tr>
              <td class="px-3 py-1">Temp (°C)</td>
              <td>
                <input v-model="readings.temperature[0]" :disabled="isTransfusionComplete"
                  @change="saveReading('0', 'temperature')" type="text"
                  :class="{ 'bg-gray-200': readings.temperature[0] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.temperature[15]" :disabled="isTransfusionComplete"
                  @change="saveReading('15', 'temperature')" type="text"
                  :class="{ 'bg-gray-200': readings.temperature[15] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.temperature[30]" :disabled="isTransfusionComplete"
                  @change="saveReading('30', 'temperature')" type="text"
                  :class="{ 'bg-gray-200': readings.temperature[30] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.temperature[45]" :disabled="isTransfusionComplete"
                  @change="saveReading('45', 'temperature')" type="text"
                  :class="{ 'bg-gray-200': readings.temperature[45] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.temperature[60]" :disabled="isTransfusionComplete"
                  @change="saveReading('60', 'temperature')" type="text"
                  :class="{ 'bg-gray-200': readings.temperature[60] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.temperature[90]" :disabled="isTransfusionComplete"
                  @change="saveReading('90', 'temperature')" type="text"
                  :class="{ 'bg-gray-200': readings.temperature[90] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.temperature[120]" :disabled="isTransfusionComplete"
                  @change="saveReading('120', 'temperature')" type="text"
                  :class="{ 'bg-gray-200': readings.temperature[120] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.temperature[180]" :disabled="isTransfusionComplete"
                  @change="saveReading('180', 'temperature')" type="text"
                  :class="{ 'bg-gray-200': readings.temperature[180] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.temperature[240]" :disabled="isTransfusionComplete"
                  @change="saveReading('240', 'temperature')" type="text"
                  :class="{ 'bg-gray-200': readings.temperature[240] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.temperature[300]" :disabled="isTransfusionComplete"
                  @change="saveReading('300', 'temperature')" type="text"
                  :class="{ 'bg-gray-200': readings.temperature[300] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.temperature[1000]" :disabled="isTransfusionComplete"
                  @change="saveReading('1000', 'temperature')" type="text"
                  :class="{ 'bg-gray-200': readings.temperature[1000] }" class="input-table" />
              </td>
            </tr>

            <tr>
              <td class="px-3 py-1">Pulse</td>
              <td>
                <input v-model="readings.pulse[0]" :disabled="isTransfusionComplete" @change="saveReading('0', 'pulse')"
                  type="text" :class="{ 'bg-gray-200': readings.pulse[0] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.pulse[15]" :disabled="isTransfusionComplete"
                  @change="saveReading('15', 'pulse')" type="text" :class="{ 'bg-gray-200': readings.pulse[15] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.pulse[30]" :disabled="isTransfusionComplete"
                  @change="saveReading('30', 'pulse')" type="text" :class="{ 'bg-gray-200': readings.pulse[30] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.pulse[45]" :disabled="isTransfusionComplete"
                  @change="saveReading('45', 'pulse')" type="text" :class="{ 'bg-gray-200': readings.pulse[45] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.pulse[60]" :disabled="isTransfusionComplete"
                  @change="saveReading('60', 'pulse')" type="text" :class="{ 'bg-gray-200': readings.pulse[60] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.pulse[90]" :disabled="isTransfusionComplete"
                  @change="saveReading('90', 'pulse')" type="text" :class="{ 'bg-gray-200': readings.pulse[90] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.pulse[120]" :disabled="isTransfusionComplete"
                  @change="saveReading('120', 'pulse')" type="text" :class="{ 'bg-gray-200': readings.pulse[120] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.pulse[180]" :disabled="isTransfusionComplete"
                  @change="saveReading('180', 'pulse')" type="text" :class="{ 'bg-gray-200': readings.pulse[180] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.pulse[240]" :disabled="isTransfusionComplete"
                  @change="saveReading('240', 'pulse')" type="text" :class="{ 'bg-gray-200': readings.pulse[240] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.pulse[300]" :disabled="isTransfusionComplete"
                  @change="saveReading('300', 'pulse')" type="text" :class="{ 'bg-gray-200': readings.pulse[300] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.pulse[1000]" :disabled="isTransfusionComplete"
                  @change="saveReading('1000', 'pulse')" type="text" :class="{ 'bg-gray-200': readings.pulse[1000] }"
                  class="input-table" />
              </td>
            </tr>

            <tr>
              <td class="px-3 py-1">Resp/min</td>
              <td>
                <input v-model="readings.respiration_rate[0]" :disabled="isTransfusionComplete"
                  @change="saveReading('0', 'respiration_rate')" type="text"
                  :class="{ 'bg-gray-200': readings.respiration_rate[0] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.respiration_rate[15]" :disabled="isTransfusionComplete"
                  @change="saveReading('15', 'respiration_rate')" type="text"
                  :class="{ 'bg-gray-200': readings.respiration_rate[15] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.respiration_rate[30]" :disabled="isTransfusionComplete"
                  @change="saveReading('30', 'respiration_rate')" type="text"
                  :class="{ 'bg-gray-200': readings.respiration_rate[30] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.respiration_rate[45]" :disabled="isTransfusionComplete"
                  @change="saveReading('45', 'respiration_rate')" type="text"
                  :class="{ 'bg-gray-200': readings.respiration_rate[45] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.respiration_rate[60]" :disabled="isTransfusionComplete"
                  @change="saveReading('60', 'respiration_rate')" type="text"
                  :class="{ 'bg-gray-200': readings.respiration_rate[60] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.respiration_rate[90]" :disabled="isTransfusionComplete"
                  @change="saveReading('90', 'respiration_rate')" type="text"
                  :class="{ 'bg-gray-200': readings.respiration_rate[90] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.respiration_rate[120]" :disabled="isTransfusionComplete"
                  @change="saveReading('120', 'respiration_rate')" type="text"
                  :class="{ 'bg-gray-200': readings.respiration_rate[120] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.respiration_rate[180]" :disabled="isTransfusionComplete"
                  @change="saveReading('180', 'respiration_rate')" type="text"
                  :class="{ 'bg-gray-200': readings.respiration_rate[180] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.respiration_rate[240]" :disabled="isTransfusionComplete"
                  @change="saveReading('240', 'respiration_rate')" type="text"
                  :class="{ 'bg-gray-200': readings.respiration_rate[240] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.respiration_rate[300]" :disabled="isTransfusionComplete"
                  @change="saveReading('300', 'respiration_rate')" type="text"
                  :class="{ 'bg-gray-200': readings.respiration_rate[300] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.respiration_rate[1000]" :disabled="isTransfusionComplete"
                  @change="saveReading('1000', 'respiration_rate')" type="text"
                  :class="{ 'bg-gray-200': readings.respiration_rate[1000] }" class="input-table" />
              </td>
            </tr>

            <tr>
              <td class="px-3 py-1">Bp</td>
              <td>
                <input v-model="readings.blood_pressure[0]" :disabled="isTransfusionComplete"
                  @change="saveReading('0', 'blood_pressure')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_pressure[0] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_pressure[15]" :disabled="isTransfusionComplete"
                  @change="saveReading('15', 'blood_pressure')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_pressure[15] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_pressure[30]" :disabled="isTransfusionComplete"
                  @change="saveReading('30', 'blood_pressure')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_pressure[30] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_pressure[45]" :disabled="isTransfusionComplete"
                  @change="saveReading('45', 'blood_pressure')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_pressure[45] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_pressure[60]" :disabled="isTransfusionComplete"
                  @change="saveReading('60', 'blood_pressure')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_pressure[60] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_pressure[90]" :disabled="isTransfusionComplete"
                  @change="saveReading('90', 'blood_pressure')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_pressure[90] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_pressure[120]" :disabled="isTransfusionComplete"
                  @change="saveReading('120', 'blood_pressure')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_pressure[120] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_pressure[180]" :disabled="isTransfusionComplete"
                  @change="saveReading('180', 'blood_pressure')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_pressure[180] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_pressure[240]" :disabled="isTransfusionComplete"
                  @change="saveReading('240', 'blood_pressure')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_pressure[240] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_pressure[300]" :disabled="isTransfusionComplete"
                  @change="saveReading('300', 'blood_pressure')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_pressure[300] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_pressure[1000]" :disabled="isTransfusionComplete"
                  @change="saveReading('1000', 'blood_pressure')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_pressure[1000] }" class="input-table" />
              </td>
            </tr>

            <tr>
              <td class="px-3 py-1">SP02</td>
              <td>
                <input v-model="readings.sp02[0]" :disabled="isTransfusionComplete" @change="saveReading('0', 'sp02')"
                  type="text" :class="{ 'bg-gray-200': readings.sp02[0] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.sp02[15]" :disabled="isTransfusionComplete" @change="saveReading('15', 'sp02')"
                  type="text" :class="{ 'bg-gray-200': readings.sp02[15] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.sp02[30]" :disabled="isTransfusionComplete" @change="saveReading('30', 'sp02')"
                  type="text" :class="{ 'bg-gray-200': readings.sp02[30] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.sp02[45]" :disabled="isTransfusionComplete" @change="saveReading('45', 'sp02')"
                  type="text" :class="{ 'bg-gray-200': readings.sp02[45] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.sp02[60]" :disabled="isTransfusionComplete" @change="saveReading('60', 'sp02')"
                  type="text" :class="{ 'bg-gray-200': readings.sp02[60] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.sp02[90]" :disabled="isTransfusionComplete" @change="saveReading('90', 'sp02')"
                  type="text" :class="{ 'bg-gray-200': readings.sp02[90] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.sp02[120]" :disabled="isTransfusionComplete"
                  @change="saveReading('120', 'sp02')" type="text" :class="{ 'bg-gray-200': readings.sp02[120] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.sp02[180]" :disabled="isTransfusionComplete"
                  @change="saveReading('180', 'sp02')" type="text" :class="{ 'bg-gray-200': readings.sp02[180] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.sp02[240]" :disabled="isTransfusionComplete"
                  @change="saveReading('240', 'sp02')" type="text" :class="{ 'bg-gray-200': readings.sp02[240] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.sp02[300]" :disabled="isTransfusionComplete"
                  @change="saveReading('300', 'sp02')" type="text" :class="{ 'bg-gray-200': readings.sp02[300] }"
                  class="input-table" />
              </td>
              <td>
                <input v-model="readings.sp02[1000]" :disabled="isTransfusionComplete"
                  @change="saveReading('1000', 'sp02')" type="text" :class="{ 'bg-gray-200': readings.sp02[1000] }"
                  class="input-table" />
              </td>
            </tr>

            <tr>
              <td class="px-3 py-1">Blood in bag</td>
              <td>
                <input v-model="readings.blood_in_bag[0]" :disabled="isTransfusionComplete"
                  @change="saveReading('0', 'blood_in_bag')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_in_bag[0] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_in_bag[15]" :disabled="isTransfusionComplete"
                  @change="saveReading('15', 'blood_in_bag')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_in_bag[15] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_in_bag[30]" :disabled="isTransfusionComplete"
                  @change="saveReading('30', 'blood_in_bag')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_in_bag[30] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_in_bag[45]" :disabled="isTransfusionComplete"
                  @change="saveReading('45', 'blood_in_bag')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_in_bag[45] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_in_bag[60]" :disabled="isTransfusionComplete"
                  @change="saveReading('60', 'blood_in_bag')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_in_bag[60] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_in_bag[90]" :disabled="isTransfusionComplete"
                  @change="saveReading('90', 'blood_in_bag')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_in_bag[90] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_in_bag[120]" :disabled="isTransfusionComplete"
                  @change="saveReading('120', 'blood_in_bag')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_in_bag[120] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_in_bag[180]" :disabled="isTransfusionComplete"
                  @change="saveReading('180', 'blood_in_bag')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_in_bag[180] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_in_bag[240]" :disabled="isTransfusionComplete"
                  @change="saveReading('240', 'blood_in_bag')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_in_bag[240] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_in_bag[300]" :disabled="isTransfusionComplete"
                  @change="saveReading('300', 'blood_in_bag')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_in_bag[300] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_in_bag[1000]" :disabled="isTransfusionComplete"
                  @change="saveReading('1000', 'blood_in_bag')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_in_bag[1000] }" class="input-table" />
              </td>
            </tr>

            <tr>
              <td class="px-3 py-1">Blood infused</td>
              <td>
                <input v-model="readings.blood_infused[0]" :disabled="isTransfusionComplete"
                  @change="saveReading('0', 'blood_infused')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_infused[0] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_infused[15]" :disabled="isTransfusionComplete"
                  @change="saveReading('15', 'blood_infused')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_infused[15] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_infused[30]" :disabled="isTransfusionComplete"
                  @change="saveReading('30', 'blood_infused')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_infused[30] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_infused[45]" :disabled="isTransfusionComplete"
                  @change="saveReading('45', 'blood_infused')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_infused[45] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_infused[60]" :disabled="isTransfusionComplete"
                  @change="saveReading('60', 'blood_infused')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_infused[60] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_infused[90]" :disabled="isTransfusionComplete"
                  @change="saveReading('90', 'blood_infused')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_infused[90] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_infused[120]" :disabled="isTransfusionComplete"
                  @change="saveReading('120', 'blood_infused')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_infused[120] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_infused[180]" :disabled="isTransfusionComplete"
                  @change="saveReading('180', 'blood_infused')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_infused[180] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_infused[240]" :disabled="isTransfusionComplete"
                  @change="saveReading('240', 'blood_infused')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_infused[240] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_infused[300]" :disabled="isTransfusionComplete"
                  @change="saveReading('300', 'blood_infused')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_infused[300] }" class="input-table" />
              </td>
              <td>
                <input v-model="readings.blood_infused[1000]" :disabled="isTransfusionComplete"
                  @change="saveReading('1000', 'blood_infused')" type="text"
                  :class="{ 'bg-gray-200': readings.blood_infused[1000] }" class="input-table" />
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
    <div v-if="showReactionModal"
      class="fixed inset-0 bg-gray-800 bg-opacity-60 overflow-y-auto h-full w-full flex items-center justify-center">
      <div class="bg-white p-8 rounded-lg shadow-xl w-full sm:w-4/6 md:w-3/6 lg:w-3/6 max-w-full">
        <h2 class="text-2xl text-gray-800 font-bold mb-4">Reaction Flow Sheet</h2>
        <hr class="h-px my-4 bg-orange-200 border-0 dark:bg-orange-200">
        <div class="mb-6">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mt-3 py-3 border-b border-gray-300">
            <!-- Haemolytic (0-15 mins) -->
            <div>
              <h3 class="font-bold">HAEMOLYTIC (0-15 MINS)</h3>
              <div class="flex items-center justify-between px-2 py-2 mt-3">
                <label class="text-md">Increased BP</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.haemolytic.increased_bp" />
              </div>
              <div class="flex items-center justify-between px-2 py-2">
                <label class="text-md">Increased Pulse & Resps</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.haemolytic.increased_pulse" />
              </div>
              <div class="flex items-center justify-between px-2 py-2">
                <label class="text-md">Shivering</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.haemolytic.shivering" />
              </div>
              <div class="flex items-center justify-between px-2 py-2">
                <label class="text-md">Headache</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.haemolytic.headache" />
              </div>
              <div class="flex items-center justify-between px-2 py-2">
                <label class="text-md">Lower back pain</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.haemolytic.lower_back_pain" />
              </div>
            </div>

            <!-- Febrile (30 mins) -->
            <div>
              <h3 class="font-bold">FEBRILE (30 MINS)</h3>
              <div class="flex items-center justify-between px-2 py-2 mt-3">
                <label class="text-md">Increased Temperature</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.febrile.increased_temperature" />
              </div>
              
              <div class="flex items-center justify-between px-2 py-2">
                <label class="text-md">Back Pain</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.febrile.back_pain" />
              </div>
              <div class="flex items-center justify-between px-2 py-2">
                <label class="text-md">Shivering</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.febrile.shivering" />
              </div>
              <div class="flex items-center justify-between px-2 py-2">
                <label class="text-md">Headache</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.febrile.headache" />
              </div>
              <div class="flex items-center justify-between px-2 py-2">
                <label class="text-md">Hematemesis</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.febrile.hematemesis" />
              </div>
            </div>

            <!-- Allergic -->
            <div>
              <h3 class="font-bold">ALLERGIC</h3>
              <div class="flex items-center justify-between px-2 py-2 mt-3">
                <label class="text-md">Wheezing</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.allergic.wheezing" />
              </div>
              <div class="flex items-center justify-between px-2 py-2">
                <label class="text-md">Itching</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.allergic.itching" />
              </div>
              <div class="flex items-center justify-between px-2 py-2">
                <label class="text-md">Hives</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.allergic.hives" />
              </div>
              <div class="flex items-center justify-between px-2 py-2">
                <label class="text-md">Joint pain</label>
                <input type="checkbox" :disabled="isTransfusionComplete" class="ml-2 p-4 w-4 h-4"
                  v-model="formData.allergic.joint_pain" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end space-x-2">
          <button type="button" @click="closeReactionModal"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded">Close</button>
          <button v-if="transfusion.status !== 'Complete' && transfusion.status !== 'Complete/Reaction'"
            @click="handleSubmitReaction()" class="px-4 py-2 bg-custom-yellow text-white rounded">Submit</button>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between mt-8">
      <div class="flex items-center ml-auto">
        <CustomButton v-if="transfusion.status !== 'Complete' && transfusion.status !== 'Complete/Reaction'"
          label="Record Reaction" bg-class="bg-red-500 px-8 py-3 ml-3" @click="handleReactionModal" />
        <CustomButton v-if="transfusion.status !== 'Complete' && transfusion.status !== 'Complete/Reaction'"
          label="End Transfusion" bg-class="bg-custom-yellow px-8 py-3 ml-3" @click="handleSubmitEndTransfusion" />
        <CustomButton v-if="isTransfusionComplete" label="View Reaction" bg-class="bg-custom-yellow px-8 py-3 ml-3"
          @click="handleReactionModal" />
        <!-- <CustomButton v-if="isTransfusionComplete" label="Print Transfusion" bg-class="bg-custom-yellow px-8 py-3 ml-3"/> -->
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: 'ProductDetails',
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      showReactionModal: false,
      transfusionId: '',
      transfusion: [],
      assigned_unit: '',
      transfusion_readings: [],
      readings: {
        real_time: {},
        temperature: {},
        pulse: {},
        respiration_rate: {}, 
        blood_pressure:  {},
        sp02: {},
        blood_in_bag: {},
        blood_infused: {},
      },
      vitalsData: {
        verbal_id: false,
        rhesus: false,
        id_band: false,
        iv_filter: false,
        consent: false,
      },
      formData: {
        haemolytic: {
          increased_bp: false,
          increased_pulse: false,
          shivering: false,
          headache: false,
          lower_back_pain: false,
        },
        febrile: {
          increased_temperature: false,
          back_pain: false,
          shivering: false,
          headache: false,
          hematemesis: false,
        },
        allergic: {
          wheezing: false,
          itching: false,
          hives: false,
          joint_pain: false,
        },
      },
    };
  },
  created() {
    this.transfusionId = this.$route.params.transfusion_id;
  },
  computed: {
    isTransfusionComplete() {
      return this.transfusion.status === 'Complete' || this.transfusion.status === 'Complete/Reaction';
    }
  },
  mounted() {
    this.fetchTransfusion()
    this.fetchTransfusionReadings();
  },
  watch: {
    'readings.real_time': {
      handler() {
        this.saveReading('real_time', 'real_time');
      },
      deep: true
    },
    'readings.temperature': {
      handler() {
        this.saveReading('temperature', 'temperature');
      },
      deep: true
    },
    'readings.pulse': {
      handler() {
        this.saveReading('pulse', 'pulse');
      },
      deep: true
    },
    'readings.respiration_rate': {
      handler() {
        this.saveReading('respiration_rate', 'respiration_rate');
      },
      deep: true
    },
    'readings.blood_pressure': {
      handler() {
        this.saveReading('blood_pressure', 'blood_pressure');
      },
      deep: true
    },
    'readings.sp02': {
      handler() {
        this.saveReading('sp02', 'sp02');
      },
      deep: true
    },
    'readings.blood_in_bag': {
      handler() {
        this.saveReading('blood_in_bag', 'blood_in_bag');
      },
      deep: true
    },
    'readings.blood_infused': {
      handler() {
        this.saveReading('blood_infused', 'blood_infused');
      },
      deep: true
    },

    'vitalsData.verbal_id': 'saveVitals',
    'vitalsData.rhesus': 'saveVitals',
    'vitalsData.id_band': 'saveVitals',
    'vitalsData.iv_filter': 'saveVitals',
    'vitalsData.consent': 'saveVitals',
  },
  methods: {
    handleReactionModal() {
      this.showReactionModal = true;
    },

    closeReactionModal() {
      this.showReactionModal = false;
    },

    async handleSubmitReaction() {
      try {
        const reactionData = {
          haemolytic: {
            increased_bp: this.formData.haemolytic.increased_bp,
            increased_pulse: this.formData.haemolytic.increased_pulse,
            shivering: this.formData.haemolytic.shivering,
            headache: this.formData.haemolytic.headache,
            lower_back_pain: this.formData.haemolytic.lower_back_pain,
          },
          febrile: {
            increased_temperature: this.formData.febrile.increased_temperature,
            back_pain: this.formData.febrile.back_pain,
            shivering: this.formData.febrile.shivering,
            headache: this.formData.febrile.headache,
            hematemesis: this.formData.febrile.hematemesis,
          },
          allergic: {
            wheezing: this.formData.allergic.wheezing,
            itching: this.formData.allergic.itching,
            hives: this.formData.allergic.hives,
            joint_pain: this.formData.allergic.joint_pain,
          }
        };

        await api.createUpdateReaction({
          transfusion_id: this.transfusionId,
          ...reactionData
        });
        this.closeReactionModal();
        this.$router.go(0);
      } catch (error) {
        console.error('Error recording reaction:', error);
      }
    },

    async handleSubmitEndTransfusion() {
      let status = this.transfusion.transfusion_reactions.length > 0 ? 'Complete/Reaction' : 'Complete';
      console.log(status)

      try {
        const endData = { status };
        await api.endTransfusion(this.transfusionId, endData);
        await SweetAlertService.showSuccess('Success!', 'Transfusion ended successfully!.');
        this.$router.push({ name: 'ClinicalAreaPage' });
      } catch (error) {
        console.error('Error ending transfusion:', error.response?.data || error.message);
        SweetAlertService.showError('Error', 'Error ending transfusion.');
      }
    },

    async saveVitals() {
      try {
        const response = await api.createUpdateTransfusionVitals({
          transfusion_id: this.transfusionId,
          ...this.vitalsData
        });

        console.log('Success:', response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    },

    async saveReading(interval_min, key) {
      const reading = this.readings[key][interval_min];
      if (!reading) return;

      try {
        // Fetch the existing reading
        const response = await api.getTransfusionReading(this.transfusionId);
        const existingReading = response.data;

        if (existingReading) {
          // Update the existing reading
          await api.updateTransfusionReading(this.transfusionId, interval_min, {
            [key]: reading
          });
        } else {
          // Create a new reading
          await api.createTransfusionReading(this.transfusionId, interval_min, {
            [key]: reading
          });
        }
      } catch (error) {
        this.error = error.message || "Failed to save transfusion reading";
      }
    },

    async fetchTransfusion() {
      try {
        const response = await api.getTransfusion(this.transfusionId);
        this.transfusion = response.data || response;

        // Populate vitalsData with the fetched data
        if (this.transfusion.transfusion_vitals.length > 0) {
          const vital = this.transfusion.transfusion_vitals[0];
          this.vitalsData = {
            verbal_id: vital.verbal_id || false,
            rhesus: vital.rhesus || false,
            id_band: vital.id_band || false,
            iv_filter: vital.iv_filter || false,
            consent: vital.consent || false
          };
        }

        // Populate reaction data if available
        if (this.transfusion.transfusion_reactions.length > 0) {
          const reaction = this.transfusion.transfusion_reactions[0];
          this.formData = {
            haemolytic: reaction.haemolytic || {},
            febrile: reaction.febrile || {},
            allergic: reaction.allergic || {}
          };
        }

      } catch (error) {
        this.error = error.message || "Failed to fetch transfusion";
      }

      const assigned_units_id = this.transfusion.assigned_units_id;
      this.getAssignedUnits(assigned_units_id);
    },

    async fetchTransfusionReadings() {
      try {
        const response = await api.getTransfusionReading(this.transfusionId);
        this.transfusion_readings = response.data || response;
        this.populateReadings(this.transfusion_readings);
      } catch (error) {
        console.error("Error fetching transfusion readings", error);
      }
    },

    populateReadings(data) {
      data.forEach(reading => {
        const interval = reading.interval_min;

        if (reading.real_time) {
          const realTime = new Date(reading.real_time).toISOString().slice(11, 16);
          this.readings.real_time[interval] = realTime;
        } else {
          this.readings.real_time[interval] = '';
        }

        // Populate other fields with fallback to empty string if no value
        this.readings.temperature[interval] = reading.temperature || '';
        this.readings.pulse[interval] = reading.pulse || '';
        this.readings.respiration_rate[interval] = reading.respiration_rate || '';
        this.readings.blood_pressure[interval] = reading.blood_pressure || '';
        this.readings.sp02[interval] = reading.sp02 || '';
        this.readings.blood_in_bag[interval] = reading.blood_in_bag || '';
        this.readings.blood_infused[interval] = reading.blood_infused || '';
      });
    },

    async getAssignedUnits(assigned_units_id) {
      try {
        const response = await api.fetchAssignedUnit(assigned_units_id);
        this.assigned_unit = response.data || response;
      } catch (error) {
        this.error = error.message || "Failed to fetch assigned unit";
      }
    },
  }
}
</script>

<style scoped>
.input-field {
  @apply w-full p-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300;
}

.input-table {
  @apply w-full p-2 text-center border border-gray-300 rounded-sm focus:outline-none focus:ring focus:border-blue-300;
}
</style>
