<template>
    <div class="flex justify-center m-9   w-full max-w-4xl">
      <ol  class="flex justify-center  mx-auto w-full max-w-4xl">
        <li
          v-for="(step, index) in steps"
          :key="step.id"
          class="flex w-full items-center"
          :class="{
            'text-custom-yellow': index <= currentStepIndex,
            'after:border-custom-yellow': index <= currentStepIndex,
            'after:border-gray-100': index > currentStepIndex,
            'dark:after:border-custom-yellow': index <= currentStepIndex,
            'dark:after:border-gray-300': index > currentStepIndex
          }"
        >
          <span
            :class="{
              'bg-custom-yellow': index <= currentStepIndex,
              'bg-gray-100': index > currentStepIndex,
              'dark:bg-custom-yellow': index <= currentStepIndex,
              'dark:bg-gray-300': index > currentStepIndex
            }"
  class="flex items-center justify-center w-5 h-5 lg:h-12 lg:w-12 rounded-full shrink-0 text-black font-bold"
  >
          <span v-if="index <= currentStepIndex" class="text-lg lg:text-2xl">{{ index + 1 }}
        </span>
          </span>

         

          <span
          v-if="index < steps.length - 1"
          class="w-full h-1 border-b-4"
          :class="{
            'border-custom-yellow': index < currentStepIndex,
            'border-gray-100': index >= currentStepIndex,
            'dark:border-custom-yellow': index < currentStepIndex,
            'dark:border-gray-300': index >= currentStepIndex
          }"
        ></span>
        </li>
      </ol>
    </div>
  </template>
  
  <script>
  export default {
    name: 'StepperProgress',
    props: {
      steps: {
        type: Array,
        required: true
      },
      currentStep: {
        type: String,
        required: true
      }
    },
    computed: {
      currentStepIndex() {
        return this.steps.findIndex(step => step.id === this.currentStep);
      },
      progressWidth() {
        return ((this.currentStepIndex + 1) / this.steps.length) * 100;
      }
    }
  }
  </script>
  