<template>
  <div class="ml-12">
    <h2 class="text-lg font-semibold mb-4 mt-20">Donor Questionnaire</h2>
    <!-- Form Fields for Donor Vitals -->
    <form @submit.prevent="moveToNext">
      <table class="min-w-full bg-white border border-gray-200">
        <thead>
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b border-gray-200">Are you</th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              Are you in good health now?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="feeling_healthy" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.feeling_healthy" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="feeling_healthy" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.feeling_healthy" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Intending to take a flight in the next 12hrs?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="take_flight" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.take_flight" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="take_flight" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.take_flight" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Do you weigh more than 50kgs?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="minimum_weight" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.minimum_weight" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="minimum_weight" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.minimum_weight" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Do you now have a cold, sore throat, asthma, active allergy, skin
              rash or infection?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="active_allergy" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.active_allergy" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="active_allergy" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.active_allergy" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Have you had: Cancer, epilepsy, convulsions, fainting, heart
              disease, stroke, multiple sclerosis or babesiosis?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="severe_disease" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.severe_disease" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="severe_disease" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.severe_disease" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b border-gray-200">
              In the past 24 hours, have you
            </th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">Had alcohol?</td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="had_alcohol" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.had_alcohol" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="had_alcohol" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.had_alcohol" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">Had hard drugs?</td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="had_drugs" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.had_drugs" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="had_drugs" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.had_drugs" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <!-- Nairobi hospital -->
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b border-gray-200">
              In the past 1 week, have you
            </th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Received a covid-19 vaccination?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="covid_vaccine" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.covid_vaccine" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="covid_vaccine" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.covid_vaccine" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b border-gray-200">
              In the past 2 weeks, have you
            </th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Been taking any prescription medication for a fever or infection
              or any other medication?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="taking_medication" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.taking_medication" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="taking_medication" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.taking_medication" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Had any tooth extration or extension dental work?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="dental_work" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.dental_work" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="dental_work" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.dental_work" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <!-- Nairobi hospital -->
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Received a yellow fever vaccination?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="yellow_fever_vaccine" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.yellow_fever_vaccine" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="yellow_fever_vaccine" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.yellow_fever_vaccine" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b border-gray-200">
              In the past 3 weeks, have you
            </th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Been exposed to any infection?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="infection_exposure" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.infection_exposure" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="infection_exposure" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.infection_exposure" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b border-gray-200">
              In the past 6 weeks, have you
            </th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Ever had any surgery or a serious injury?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="had_surgery" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.had_surgery" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="had_surgery" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.had_surgery" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <!-- Nairobi hospital -->
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Received any immunisation vaccination?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="immunization_vaccinations" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.immunization_vaccinations" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="immunization_vaccinations" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.immunization_vaccinations" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b border-gray-200">
              In the past 12 weeks, have you
            </th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Donated any blood within the past 3 months?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="donated_blood_component" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.donated_blood_component" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="donated_blood_component" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.donated_blood_component" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b border-gray-200">
              In the past 12 months, have you
            </th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              In the past year have you had unexplained fever or weight loss,
              night sweats, enlarged lymph glands or lumps, discolored areas of
              skin, white spots or unusual blemishes in your mouth or throat,
              persistent cough or persistent diarrhea?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="unexplained_sickness" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.unexplained_sickness" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="unexplained_sickness" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.unexplained_sickness" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Had a relative or friend who suffered from hepatitis, jaundice
              whom you took part in looking after at home?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="relative_has_hepatitis" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.relative_has_hepatitis" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="relative_has_hepatitis" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.relative_has_hepatitis" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Have you received a blood transfusion, tattoo, acupucture, ear or
              nose piercing?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="had_body_piercing" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.had_body_piercing" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="had_body_piercing" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.had_body_piercing" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Received any Hepatitis B or Rabies vaccination?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="hepatitis_rabies_vaccine" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.hepatitis_rabies_vaccine" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="hepatitis_rabies_vaccine" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.hepatitis_rabies_vaccine" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b border-gray-200">Have you ever</th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
            <th class="px-4 py-2 border-b border-gray-200"></th>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Had hepatitis, jaundice or positive blood test for hepatitis?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="pos_for_Hepatitis_C" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.pos_for_Hepatitis_C" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="pos_for_Hepatitis_C" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.pos_for_Hepatitis_C" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Do you suffer form any medical condition like diabetes, ulcers or
              high blood pressure?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="medical_condition" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.medical_condition" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="medical_condition" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.medical_condition" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Tested POSITIVE for the HIV/AIDS virus?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="positive_for_HIV" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.positive_for_HIV" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="positive_for_HIV" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.positive_for_HIV" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Had a Lung or Kidney disease, blood disease, or bleeding
              tendencies?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="bleeding_condition" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.bleeding_condition" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="bleeding_condition" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.bleeding_condition" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              Been rejected as a blood donor or had problems donating?
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="yes" name="rejected_as_donor" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.rejected_as_donor" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">Yes</span>
                </label>
              </div>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 w-1/3">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input value="no" name="rejected_as_donor" type="radio" class="hidden"
                    v-model="localFormData.questionnaire.rejected_as_donor" />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow rounded-full transition duration-200 ease-in-out"></span>
                  <span class="ml-2 block text-sm font-medium leading-6 text-gray-900">No</span>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-8 flex justify-between">
        <button type="button" class="px-4 py-2 border rounded text-orange-500 border-orange-500"
          @click="moveToPrevious">
          Back
        </button>
        <CustomButton type="submit" label="next" class="px-4 py-2" bg-class="bg-custom-yellow" />
      </div>
    </form>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import SweetAlertService from '@/services/SweetAlertService';


export default {
  name: "NewDonorQuestionnaireForm",
  components: {
    CustomButton,
  },

  data() {
    return {
      localFormData: { ...this.formData },
    };
  },

  watch: {
    localFormData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true,
    },
  },

  props: {
    formData: Object
  },

  methods: {
    async moveToNext() {
      // Field names for checking answers
      const questions = {
        feeling_healthy: "Are you in good health now?",
        take_flight: "Intending to take a flight in the next 12hrs?",
        minimum_weight: "Do you weigh more than 50kgs?",
        active_allergy: "Do you now have a cold, sore throat, asthma, active allergy, skin rash or infection?",
        severe_disease: "Have you had: Cancer, epilepsy, convulsions, fainting, heart disease, stroke, multiple sclerosis or babesiosis?",
        had_alcohol: "Had alchohol?",
        had_drugs: "Had hard drugs?",
        taking_medication: " Been taking any prescription medication for a fever or infection or any other medication?",
        dental_work: "Had any tooth extration or extension dental work?",
        infection_exposure: "Been exposed to any infection?",
        had_surgery: "Ever had any surgery or a serious injury?",
        donated_blood_component: " Donated any blood within the past 3 months?",
        unexplained_sickness: "In the past year have you had unexplained sickness?",
        relative_has_hepatitis: " Had a relative or friend who suffered from hepatitis, jaundice",
        had_body_piercing: "Have you received a blood transfusion, tattoo, acupucture, ear or nose piercing?",
        immunization_vaccinations: "Received any immunisation vaccination?",
        pos_for_Hepatitis_C: "Had hepatitis, jaundice or positive blood test for hepatitis?",
        medical_condition: " Do you suffer form any medical condition like diabetes, ulcers or high blood pressure?",
        positive_for_HIV: "Tested POSITIVE for the HIV/AIDS virus?",
        bleeding_condition: "Had a Lung or Kidney disease, blood disease, or bleeding tendencies?",
        rejected_as_donor: "Been rejected as a blood donor or had problems donating?",
        yellow_fever_vaccine: "Received any yellow fever vaccination?",
        hepatitis_rabies_vaccine: "Received any hepatitis B or rabies vaccination?",
        covid_vaccine: "Received any covid-19 vaccination",
      };

      let emptyFields = [];
      let problematicFields = [];

      // Check for empty fields and problematic "yes" answers
      Object.keys(questions).forEach((key) => {
        const answer = this.localFormData.questionnaire[key];
        if (!answer) {
          emptyFields.push(questions[key]);
        } else if ((key === "feeling_healthy" || key === "minimum_weight") && answer === "no") {
          problematicFields.push(questions[key]);
        } else if (key !== "feeling_healthy" && key !== "minimum_weight" && answer === "yes") {
          problematicFields.push(questions[key]);
          // } else if (key === " " && answer === "no") {
          //   problematicFields.push(questions[key]);
        }
      });

      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return; // Stop form submission
      }

      if (problematicFields.length > 0) {
        const confirmed = await SweetAlertService.showConfirmation(
          "Confirm Your Answers",
          `To the question: ${problematicFields.join(", ")}. Are you sure you want to proceed?`
        );

        if (!confirmed.isConfirmed) {
          return; // Stop form submission if they don't confirm
        }
      }

      this.$emit("move-to-next-step");
    },


    moveToPrevious() {
      this.$emit("move-to-previous-step");
    },
  },
};
</script>

<style scoped>
input[type="radio"]:checked+span {
  background-color: #f8951e;
  /* custom yellow */
}
</style>
