<template>
  <div class="p-2 rounded-lg">
    <AlertLayout title="Reminders!"
      message="Kindly remember to inform the donor on the RISKS and BENEFITS of donation.<br>Kindly remember to as well seek the donor's consent for future recalls i the event of emergencies. <br> Kindly inform donor that this regestration guarantees them an online account to the Jiji Health Blood Donor's portal"
      type="warning" />
    <div class="bg-white border border-t-0 rounded-lg shadow-md p-8">
      <div class="mt-4">
        <h2 class="text-2xl font-bold">Edit Donor Information</h2>
      </div>

      <form class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="first_name">Enter First Name <span
              class="text-red-500">*</span></label>
          <input id="first_name" type="text" v-model="firstName"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter First Name" required>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="gender">Select gender <span
              class="text-red-500">*</span></label>
          <div class="relative">
            <select id="gender" v-model="gender"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled>Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="last_name">Enter Last Name/Other Name <span
              class="text-red-500">*</span></label>
          <input id="last_name" type="text" v-model="lastName"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter Last Name/Other Name" required>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="birth_year">Enter year of birth (Your age is:) <span
              class="text-red-500">*</span></label>
          <input id="birth_year" type="date" v-model="birthYear"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter year of birth (Your age is:)" required>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="id_number">Enter Donor Unique Identifier/ National ID
            Number <span class="text-red-500">*</span></label>
          <input id="id_number" type="text" v-model="idNumber"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter Donor Unique Identifier/ National ID Number" required>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="county">Select county <span
              class="text-red-500">*</span></label>
          <div class="relative">
            <select id="county" v-model="county"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option v-for="region in counties" :key="region.county_id" :value="region.name">
                {{ region.name }}
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="blood_group">Select blood group <span
              class="text-red-500">*</span></label>
          <div class="relative">
            <select id="bloodGroup" v-model="bloodGroup"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.name">
                {{ group.name }}
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="phone">Contact phone number (Needed for official
            communication) <span class="text-red-500">*</span></label>
          <input id="phone" type="tel" v-model="phone"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Contact phone number (Needed for official communication)" required>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="email">Enter email</label>
          <input id="email" type="email" v-model="email"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter email">
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="tcs">Select whether user has agreed with T&Cs <span
              class="text-red-500">*</span></label>
          <select id="tcs" v-model="tcs"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            required>
            <option value="" disabled>Select whether user has agreed with T&Cs</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="consent_form">Upload Consent Form (Not
            mandatory)</label>
          <input type="file" id="consent_form" @change="handleFileUpload"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400" />
        </div>
      </form>


      <div class="flex justify-between pt-8">
        <button class="px-4 py-2 border rounded text-orange-500 border-orange-500" @click="handleBack">Back</button>
        <button class="bg-custom-yellow hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
          @click="handleSaveEditedDonorInfo">
          Save
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import AlertLayout from '@/components/AlertLayout.vue';
import api from '@/services/api';

export default {
  name: 'EditDonorInformation',
  components: {
    AlertLayout,
  },
  data() {
    return {
      firstName: '', 
      lastName: '',
      gender: '',
      birthYear: '',
      idNumber: '',
      county: '',
      bloodGroup: '',
      phone: '',
      email: '',
      tcs: '',
      consentForm: '',
      bloodGroups: [], 
      counties: [], 
    };
  },
  async created() {
    try {
      await Promise.all([
        this.fetchBloodGroups(),
        this.fetchRegions()
      ]);
      await this.fetchDonorDetails();
    } catch (error) {
      console.error('Error initializing data:', error);
    }
  },

  methods: {
    async fetchDonorDetails() {
      try {
        const donorId = this.$route.params.donor_id;
        const response = await api.getDonor(donorId);
        const donorData = response.data || response;

        // Populate the form fields with existing donor data
        this.firstName = donorData.user.first_name;
        this.lastName = donorData.user.last_name;
        this.gender = donorData.user.gender.toLowerCase();
        this.birthYear = donorData.user.dob;
        this.idNumber = donorData.user.id_number;
        this.bloodGroup = donorData.user.blood_group_id.name;
        this.phone = donorData.user.phone_number;
        this.email = donorData.user.email;
        this.county = donorData.county_id.name;
        this.tcs = donorData.terms_agreed.toLowerCase();
        this.consentForm = donorData.consent_form || '';
      } catch (error) {
        console.error('Error fetching donor details:', error);
      }
    },

    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response.data || response;
      } catch (error) {
        console.error('Error fetching blood groups:', error);
      }
    },

    async fetchRegions() {
      try {
        const response = await api.getRegions();
        this.counties = response.counties || []; 
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    },

    async handleSaveEditedDonorInfo() {
      try {
        const donorId = this.$route.params.donor_id;

        // Helper function to find ID by name
        const findIdByName = (array, name, idField = 'id') => {
          const item = array.find(item => item.name === name);
          return item ? item[idField] : null;
        };
        // Collect form data
        const donorData = {
          user: {
          first_name: this.firstName,
          last_name: this.lastName,
          dob: this.birthYear,
          gender: this.gender.charAt(0).toUpperCase() + this.gender.slice(1),
          email: this.email,
          phone_number: this.phone,
          blood_group_id: findIdByName(this.bloodGroups, this.bloodGroup, 'blood_group_id'),
          id_number: this.idNumber,
          county_id: findIdByName(this.counties, this.county, 'county_id'),
          terms_agreed: this.tcs
          }
          
        };

        // For debugging, log the data before sending
        console.log('Donor ID:', donorId);
        console.log('Donor Data:', JSON.stringify(donorData, null, 2));

        // Send updated data to the API
        const response = await api.updateDonor(donorId, donorData);
        console.log('Donor information updated:', response);

        // Navigate back to the donor details page
        this.$router.push({ name: 'DonorDetailsPage', params: { donor_id: donorId } });
      } catch (error) {
        console.error('Error updating donor information:', error);
        // Handle the error, maybe show a notification to the user
      }
    },

    handleBack() {
      this.$router.push({ name: 'DonorDetailsPage', params: { donor_id: this.$route.params.donor_id } });
    }
  }
};
</script>
