<template>
  <div class="container">
    <div class="mx-auto p-10 w-3/4">
      <form class="">
        <div class="grid grid-cols-1 md:grid-cols-1 gap-5 mb-3">
          <h2 class="text-lg font-bold">Facility Logo</h2>
          <div class="grid grid-cols-12 gap-5">
            <div class="col-span-12 md:col-span-6 border-r-2 border-r-gray-300 pr-10">
              <div class="grid grid-cols-12 gap-5">
                <div class="col-span-12 md:col-span-6">
                  <img class="object-cover w-24 h-24 rounded-full m-2" src="@/assets/images/avatar.png"
                    alt="Your avatar" />
                </div>
                <div class="col-span-12 md:col-span-6">
                  <div class="flex flex-col items-center">
                   
                       <button @click="resetForm" px-8 py-2 my-2 
            class="px-4 py-2 border rounded dark:text-custom-blue border-2 border-custom-blue" >Change Photo</button>
                    <CustomButton label="Remove" bg-class="bg-custom-blue px-8 py-3 my-2 text-center"
                      @click="handleSubmitNewOfficer" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6 px-10">
              <h2 class="text-lg ">Image requirements</h2>
              <ol class="list-decimal px-5">
                <li>Min. 400 x 400px</li>
                <li>Max. 2MB</li>
                <li>Your personal image</li>
              </ol>
            </div>
          </div>
          <h2 class="text-lg font-bold">Facility Information</h2>
          <div>
            <label class="block mb-1">Hospital name <span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.name"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter Facility name" required>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-5 mb-3">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <label class="block mb-1">Contact Phone Number</label>
              <input type="text" v-model="formData.contact_person_phone"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                placeholder="Enter contact phone number" required>
            </div>

            <div>
              <label class="block mb-1">Contact Email <span class="text-red-500">*</span></label>
              <input type="email" v-model="formData.email"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                placeholder="Enter facility email" required>
            </div>
            <div>
              <label class="block mb-1">Facility Type</label>
              <select id="blood_group_id" v-model="formData.facility_type"
                class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                required>
                <option value="" disabled>Select facility type</option>
                <option value="private">Private</option>
                <option value="public">Public</option>
              </select>
            </div>

            <div>
              <label class="block mb-2 text-gray-700 font-medium">County <span class="text-red-500">*</span></label>
              <div class="relative">
                <select id="blood_group_id" v-model="formData.county_id"
                  class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                  required>
                  <option value="" disabled>Select blood group</option>
                  <option v-for="county in counties" :key="county.county_id" :value="county.county_id">
                    {{ county.name }}
                  </option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5 mb-3">
          <div>
            <label class="block mb-1">Longitude<span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.longitude"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter facility latitude" required>
          </div>
          <div>
            <label class="block mb-1">Latitude<span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.latitude"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter facility latitude" required>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div>
            <label class="block mb-1">DS Admin<span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.created_by"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter creator" required>
          </div>
          <div>
            <label class="block mb-1">Package<span class="text-red-500">*</span></label>
            <input type="text" v-model="formData.account_status"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Select package" required>
          </div>
        </div>
      </form>
      <div class="flex items-center justify-between mt-8">
        <div class="flex items-center ml-auto">
          <button @click="resetForm" 
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Cancel</button>
          <CustomButton label="Update" bg-class="bg-custom-yellow px-8 py-3 ml-3" @click="handleSubmitUpdateFacility" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: 'FacilityProfileTab',
  components: {
    CustomButton,
  },
  data() {
    return {
      counties: [],
      userData: {},
      formData: {
        name: '',
        email: '',
        facility_type: '',
        contact_person_name: '',
        contact_person_phone: '',
        country: '',
        county_id: '',
        second_level_devision: '',
        third_level_devision: '',
        longitude: '',
        latitude: '',
        account_status: '',
      },
      status: 'active',
      error: null,
      facility: [],
    }
  },
  created() {
    this.fetchRegions();
  },
  mounted() {
    this.userData = this.$store.getters["auth/user"];
    this.populateFormData();
  },
  methods: {
    validateFields() {
      const emptyFields = [];
      if (!this.formData.name) emptyFields.push('Facility name');
      if (!this.formData.email) emptyFields.push('First email');
      if (!this.formData.facility_type) emptyFields.push('Facility Type');
      if (!this.formData.contact_person_name) emptyFields.push('Contact person name');
      if (!this.formData.contact_person_phone) emptyFields.push('Contact person phone');
      if (!this.formData.country) emptyFields.push('Country');
      if (!this.formData.county_id) emptyFields.push('Sub-county');
      if (!this.formData.third_level_devision) emptyFields.push('Address');
      if (!this.formData.account_status) emptyFields.push('Account status');

      return emptyFields;
    },
    populateFormData() {
      if (Array.isArray(this.userData.user_roles) && this.userData.user_roles.length > 0) {
        this.facility = this.userData.user_roles[0].facility_department.facility;

        this.formData.name = this.facility.name || '';
        this.formData.email = this.facility.email || '';
        this.formData.facility_type = this.facility.facility_type || '';
        this.formData.contact_person_name = this.facility.contact_person_name || '';
        this.formData.contact_person_phone = this.facility.contact_person_phone || '';
        this.formData.country = this.facility.country || '';
        this.formData.county_id = this.facility.county_id || '';
        this.formData.second_level_devision = this.facility.second_level_devision || '';
        this.formData.third_level_devision = this.facility.third_level_devision || '';
        this.formData.longitude = this.facility.longitude || '';
        this.formData.latitude = this.facility.latitude || '';
        this.formData.account_status = this.facility.account_status || '';
      } else {
        console.warn('user_roles is undefined or empty');
      }
    },

    async fetchRegions() {
      try {
        const response = await api.getRegions();
        this.counties = response.counties || [];
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    },

    async handleSubmitUpdateFacility() {
      const data = {
        name: this.formData.name,
        email: this.formData.email,
        facility_type: this.formData.facility_type,
        contact_person_name: this.formData.contact_person_name,
        contact_person_phone: this.formData.contact_person_phone,
        country: this.formData.country,
        county_id: this.formData.county_id,
        second_level_devision: this.formData.second_level_devision,
        third_level_devision: this.formData.third_level_devision,
        longitude: this.formData.longitude,
        latitude: this.formData.latitude,
        account_status: this.formData.account_status,
      };

      try {
        await api.updateFacility(this.facility.facility_id, data);
        await SweetAlertService.showSuccess('Success!', 'Facility updated successfully!.');
        this.$router.go(0);
      } catch (error) {
        this.error = error.message || 'Failed to update facility';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', 'Error updating facility.')
      }
    },
  },
}
</script>

<style lang="css" scoped></style>
