<template>
  <div class="container mx-auto px-4 ">

    <!-- Dynamic AlertLayout -->
    <AlertLayout :title="alertTitle" :message="alertMessage" :type="alertType" v-if="alertTitle && alertMessage" />

    <!-- Stepper -->
    <StepperLayout :steps="steps" :current-step="currentTab" class="mb-8" />

    <!-- Listen for Events emitted -->
    <component :is="currentTabComponent" :donorId="donorId" @move-to-previous-step="goToPreviousStep"
      @move-to-next-step="goToNextStep" @donor-created="handleDonorCreated">
    </component>

  </div>
</template>

<script>
import AlertLayout from '@/components/AlertLayout.vue';
import CustomButton from '@/components/CustomButton.vue';
import StepperLayout from '@/components/StepperLayout.vue';
import NewDonorRegistrationForm from '@/views/forms/NewDonorRegistrationForm.vue';
import DonorVitalsForm from '@/views/forms/DonorVitalsForm.vue';
import RecordDonationForm from '@/views/forms/RecordDonationForm.vue';

export default {
  name: 'NewDonorRegistrationPage',
  components: {
    AlertLayout,
    CustomButton,
    StepperLayout,
    NewDonorRegistrationForm,
    DonorVitalsForm,
    RecordDonationForm
  },
  data() {
    return {
      currentTab: 'new-donor-registration',
      steps: [
        { id: 'new-donor-registration', title: '1. New Donor Registration', subtitle: 'Provide donor information', active: true, loading: false },
        { id: 'donor-vitals', title: '2. Donor Vitals', subtitle: 'Record pre-donation vitals', active: false, loading: false },
        { id: 'record-donation', title: '3. Record donation', subtitle: 'Create an admin', active: false, loading: false },
      ],
      alertTitle: '',
      alertMessage: '',
      alertType: 'info',
      donorId: ''
    };
  },
  computed: {
    currentTabComponent() {
      switch (this.currentTab) {
        case 'new-donor-registration':
          return NewDonorRegistrationForm;
        case 'donor-vitals':
          return DonorVitalsForm;
        case 'record-donation':
          return RecordDonationForm;
        default:
          return NewDonorRegistrationForm;
      }
    }
  },
  methods: {
    handleDonorCreated(newDonorId) {
      this.donorId = newDonorId;
    },
    setActiveStep(stepId) {
      this.steps.forEach(step => {
        step.active = step.id === stepId;
      });
      this.currentTab = stepId;
      this.updateAlertLayout();
    },
    goToPreviousStep() {
      const currentIndex = this.steps.findIndex(step => step.id === this.currentTab);
      if (currentIndex > 0) {
        this.setActiveStep(this.steps[currentIndex - 1].id);
      }
    },
    goToNextStep() {
      const currentIndex = this.steps.findIndex(step => step.id === this.currentTab);
      if (currentIndex < this.steps.length - 1) {
        this.setActiveStep(this.steps[currentIndex + 1].id);
      }
    },
    handleSubmit() {
      // Handle final submission logic
      console.log('Submission completed');
    },
    updateAlertLayout() {
      switch (this.currentTab) {
        case 'new-donor-registration':
          this.alertTitle = 'Reminders!';
          this.alertMessage = `
                          <ul>
                            <li>-Kindly remember to inform the donor on the RISKS and BENEFITS of donation.</li>
                            <li>-Kindly remember to as well seek the donor's consent for future recalls in the event of emergencies.</li>
                            <li>-Kindly inform donor that this registration guarantees them an online account to the Damu-Sasa Blood Donor's portal</li>
                            <li>-Donors Phone number will be used for communications.</li>
                          </ul>
                        `;
          this.alertType = 'warning';
          break;
        case 'donor-vitals':
          this.alertTitle = 'Reminders!';
          this.alertMessage =`
                             <ul>
                              <li>-Kindly record the correct details.</li>
                             </ul>
                          `;
          this.alertType = 'warning';
          break;
        case 'record-donation':
          this.alertTitle = 'Reminders!';
          this.alertMessage = `
                             <ul>
                              <li>-Finalize the donation record and submit.</li>
                             </ul>
                          `;
          this.alertType = 'warning';
          break;
        default:
          this.alertTitle = '';
          this.alertMessage = '';
          this.alertType = 'warning';
          break;
      }
    }
  },
  mounted() {
    this.updateAlertLayout(); // Initialize the alert layout based on the initial tab
  }
}
</script>
