<template>
  <div class="ml-12">
    <h2 class="text-lg font-semibold mb-4 mt-20">Record Donation</h2>
    <!-- Form Fields for Recording Donation -->
    <form @submit.prevent="handleFinalSubmit">
      <table class="min-w-full bg-white border border-gray-200">
        <thead>
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b font-semibold border-gray-200">
              PLEASE CONFIRM AND SIGN BEFORE DONATING
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    class="hidden"
                    id="readPamphlet"
                    v-model="declaration.read_educational_material"
                  />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow transition duration-200 ease-in-out flex items-center justify-center"
                  >
                    <!-- Icon for checked state -->
                    <svg
                      class="hidden w-3 h-3 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </span>
                  <span
                    class="ml-4 block text-sm font-semibold leading-6 text-gray-900"
                    >I have read and understood the pamphlet on "BLOOD
                    DONATION".</span
                  >
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    class="hidden"
                    id="correctAnswer"
                    v-model="declaration.consent_checkbox"
                  />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow transition duration-200 ease-in-out flex items-center justify-center"
                  >
                    <!-- Icon for checked state -->
                    <svg
                      class="hidden w-3 h-3 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </span>
                  <span
                    class="ml-4 block text-sm font-semibold leading-6 text-gray-900"
                    >I have accurately answered each of the above questions to
                    the best of my knowledge and volunteer to donate
                    blood.</span
                  >
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    class="hidden"
                    id="bloodConsent"
                    v-model="declaration.blood_testing_consent"
                  />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow transition duration-200 ease-in-out flex items-center justify-center"
                  >
                    <!-- Icon for checked state -->
                    <svg
                      class="hidden w-3 h-3 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </span>
                  <span
                    class="ml-4 block text-sm font-semibold leading-6 text-gray-900"
                    >I understand that my blood will be used for treatment of
                    patients and consent to my blood being tested for HIV,
                    Hepatitis B, Hepatitis C, VDRL(Syphilis) and Malaria.</span
                  >
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    class="hidden"
                    id="testResults"
                    v-model="declaration.terms_agreed"
                  />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow transition duration-200 ease-in-out flex items-center justify-center"
                  >
                    <!-- Icon for checked state -->
                    <svg
                      class="hidden w-3 h-3 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </span>
                  <span
                    class="ml-4 block text-sm font-semibold leading-6 text-gray-900"
                    >I understand that I will be informed of my test results if
                    I choose to.</span
                  >
                </label>
              </div>
            </td>
          </tr>
          <tr class="hover:bg-gray-50">
            <td class="px-4 py-2 border-b border-gray-200">
              <div class="flex items-center gap-x-3">
                <label class="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    class="hidden"
                    id="consent"
                    v-model="declaration.consent_form"
                  />
                  <span
                    class="h-4 w-4 inline-block border-2 border-custom-yellow transition duration-200 ease-in-out flex items-center justify-center"
                  >
                    <!-- Icon for checked state -->
                    <svg
                      class="hidden w-3 h-3 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </span>
                  <span
                    class="ml-4 block text-sm font-semibold leading-6 text-gray-900"
                    >I consent to further communication by Hospital Donor Center
                    regarding future donations.</span
                  >
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-8 flex justify-between">
        <button
          type="button"
          class="px-4 py-2 border rounded text-orange-500 border-orange-500"
          @click="moveToPrevious"
        >
          Back
        </button>
        <CustomButton
          type="submit"
          label="Submit"
          class="px-4 py-2"
          bg-class="bg-custom-yellow"
        />
      </div>
    </form>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from "@/services/api";

export default {
  name: "EditNewDonorDeclarationForm",
  components: {
    CustomButton,
  },
  data() {
    return {
      facility_id: this.user().facility_id,
      donor: [],
      declaration: {
        read_educational_material: "",
        blood_testing_consent: "",
        consent_checkbox: "",
        terms_agreed: "",
        consent_form: "",
      },
    };
  },

  watch: {
    localFormData: {
      handler(newValue) {
        this.$emit("update:formData", newValue);
      },
      deep: true,
    },
  },

  props: {
    formData: Object,
  },

  created() {
    this.getDonors();
  },

  mounted() {
    // this.user()
    console.log("user:" + JSON.stringify(this.user().facility_id));
  },

  methods: {
    async getDonors() {
      try {
        const donorId = this.$route.params.donor_id;
        console.log("Fetching details for donor ID:", donorId);
        const response = await api.getDonor(donorId);
        this.donor = response.data || response;
        this.populateDeclaration(this.donor);
        console.log("data:", this.donor);
      } catch (error) {
        this.error = error.message || "Failed to fetch donors";
      }
    },
    populateDeclaration(declarations) {
      (this.declaration.read_educational_material =
        declarations.declaration?.read_educational_material),
        (this.declaration.consent_checkbox =
          declarations.declaration?.consent_checkbox),
        (this.declaration.blood_testing_consent =
          declarations.declaration?.blood_testing_consent),
        (this.declaration.terms_agreed =
          declarations.declaration?.terms_agreed),
        (this.declaration.consent_form =
          declarations.declaration?.consent_form);
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    handleFinalSubmit() {
      // Gather data from local storage
      const donorFormData =
        JSON.parse(localStorage.getItem("donorFormData")) || {};
      const questionnaireFormData =
        JSON.parse(localStorage.getItem("questionnaireFormData")) || {};
      const declarationFormData = this.declaration

      // Combine data from both child components
      const combinedData = {
        donorFormData,
        questionnaireFormData,
        declarationFormData
      };
      this.$emit("final-submit", combinedData);
    },

    moveToPrevious() {
      this.$emit("move-to-previous-step");
    },
  },
};
</script>

<style scoped>
input[type="checkbox"]:checked + span {
  background-color: #f8951e; /* custom yellow */
}

input[type="checkbox"]:checked + span svg {
  display: block; /* Show the checkmark */
}
</style>
