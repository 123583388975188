<template>
  <div>
    <AlertLayout title="Reminders!"
      message="Incorrect information will lead to nurses being unable to request for blood except for theatre."
      type="warning" />
    <div class="container mx-auto p-8 border border-t-0">
      <!-- Form Fields for Recording Donation -->
      <form @submit.prevent="">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <h2 class="text-lg font-semibold mb-4 mt-5">Patient Details</h2>

          <h2 class="text-lg font-semibold mb-4 mt-5">Sample Details</h2>
          <div>
            <label class="block mb-1" for="patientName">Patient Name <span class="text-red-500">*</span></label>
            <input id="patientName" type="text"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter Patient Name" v-model="formData.patient_name" />
          </div>

          <div>
            <label class="block mb-1" for="sampleNumber">Sample No <span class="text-red-500">*</span></label>
            <input id="sampleNumber" type="text"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter Sample no" v-model="formData.sample_number" />
          </div>

          <div>
            <label class="block mb-1" for="birthDate">Date of Birth <span class="text-red-500">*</span></label>
            <input type="date" id="birthDate"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="select Date of Birth" v-model="formData.dob" required />
          </div>

          <div>
            <label class="block mb-1" for="test_to_be_done">
              Test to be Done <span class="text-red-500">*</span>
            </label>
            <div class="relative">
              <div
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                @click="toggleTestDropdown">
                <div class="flex flex-wrap gap-2">
                  <span v-for="(test, index) in (formData.test_to_be_done || [])" :key="test"
                    class="bg-blue-100 text-blue-600 px-2 py-1 rounded">
                    {{ test }}
                    <span class="cursor-pointer ml-1 text-red-500" @click.stop="removeTest(index)">x</span>
                  </span>
                </div>
                <div v-if="!(formData.test_to_be_done && formData.test_to_be_done.length)" class="text-gray-400">
                  Test to be Done
                </div>
              </div>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>

              <!-- Dropdown to show test options -->
              <div v-if="isTestDropdownVisible" class="absolute z-10 bg-white shadow-lg mt-1 w-full border rounded">
                <ul class="max-h-60 overflow-y-auto">
                  <li v-for="test in testOptions" :key="test" class="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    @click="selectTest(test)">
                    {{ test }}
                  </li>
                </ul>
              </div>
            </div>
          </div>


          <div>
            <label class="block mb-1" for="gender">Gender <span class="text-red-500">*</span></label>
            <div class="relative">
              <select id="gender"
                class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.gender">
                <option value="" disabled selected>Select gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="status">Sample Status <span class="text-red-500">*</span></label>
            <div class="relative">
              <select id="status"
                class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.sample_status">
                <option value="" disabled selected>Select Status</option>
                <option value="received">Received</option>
                <option value="rejected">Rejected</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="ipop">IPOP<span class="text-red-500">*</span></label>
            <input id="ipop" type="text"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter patient IPOP" v-model="formData.ipop" />
          </div>

          <div v-if="formData.sample_status === 'received'">
            <label class="block mb-1" for="receivedBy">Received By <span class="text-red-500">*</span></label>
            <input id="receivedBy" type="text"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter Name" v-model="formData.sample_received_by" />
          </div>

          <div>
            <label class="block mb-2 text-gray-700 font-medium" for="blood_group">Ward <span
                class="text-red-500">*</span></label>
            <div class="relative">
              <select id="blood_group_id"
                class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                required v-model="formData.department_id">
                <option value="" disabled selected>Select Ward</option>
                <option v-for="ward in departments" :key="ward.department" :value="ward.department_id">
                  {{ ward.name }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div >
            <label class="block mb-1" for="labNumber">Lab No <span class="text-red-500">*</span></label>
            <input id="labNumber" type="text"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter lab no" v-model="formData.sample_lab_number" />
          </div>

          <div>
            <label class="block mb-2 text-gray-700 font-medium" for="blood_group">Select blood group <span
                class="text-red-500">*</span></label>
            <div class="relative">
              <select id="blood_group_id" v-model="formData.blood_group_id"
                class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                required>
                <option value="" disabled>Select blood group</option>
                <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.blood_group_id">
                  {{ group.description }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div v-if="formData.sample_status === 'rejected'">
            <label class="block mb-1" for="rejection_reason">Rejection Reason<span class="text-red-500">*</span></label>
            <input id="rejection_reason" type="text"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter Rejection reason" v-model="formData.rejection_reason" />
          </div>

          <div v-if="formData.sample_status === 'rejected'">
            <label class="block mb-1" for="correctiveAction">Corrective Action<span
                class="text-red-500">*</span></label>
            <input id="correctiveActionBy" type="text"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter corrective action" v-model="formData.corrective_action" />
          </div>

          <div v-if="formData.sample_status === 'rejected'">
            <label class="block mb-1" for="correctiveActionBy">Corrective Action By<span
                class="text-red-500">*</span></label>
            <input id="correctiveActionBy" type="text"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter Name" v-model="formData.corrective_action_by" />
          </div>
        </div>
        <div class="flex justify-end mt-5 space-x-2">
          <button v-if="requisitionData && requisitionData.samples && requisitionData.samples.length > 0" type="submit"
            class="px-4 py-2 ml-3 bg-custom-yellow text-white rounded" @click="updatePatientSample">
            Update Sample
          </button>
          <button v-else type="submit" class="px-4 py-2 ml-3 bg-custom-yellow text-white rounded"
            @click="addPatientSample">
            Add Sample
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import api from "@/services/api";
import SweetAlertService from "@/services/SweetAlertService";

export default {
  name: "AddSampleForm",
  components: {
    AlertLayout,
  },
  data() {
    return {
      bloodGroups: [],
      departments: [],
      showProductPopup: false,
      isTestDropdownVisible: false,
      testOptions: [
        "Grouping",
        "GXM",
        "DIRECT COOMB'S TEST",
        "INDIRECT COOMB'S TEST",
        "Du TEST"
      ],
      requisitionId: null,
      requisitionData: [],
      formData: {
        first_name: "",
        patient_name: "",
        dob: "",
        gender: "",
        ipop: "",
        blood_group_id: "",
        department_id: "",
        blood_group: "",
        patient_requisition_id: "",
        sample_status: "",
        sample_received_on: "",
        sample_received_by: "",
        sample_lab_number: "",
        hiv_sample_collected: "",
        hiv_sample_datetime: "",
        rejection_reason: "",
        corrective_action_by: "",
        corrective_action: "",
        gxm_done_on: "",
        gxm_done_by: "",
        gxm_confirmed_by: "",
        gxm_confirmed_on: "",
        test_to_be_done: [],
        unit_product_ids: [],
        sample_number: "",
      },
    };
  },

  computed: {},

  created() {
    this.fetchBloodGroups();
    this.getWards();
    this.fetchRequisitionDetails();

    this.formData.sample_received_on = new Date().toISOString().split("T")[0];
  },

  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response;
      } catch (error) {
        console.error("Error fetching blood groups:", error);
      }
    },

    validateFields() {
      const emptyFields = [];

      if (!this.formData.patient_name) emptyFields.push('Patient Name');
      if (!this.formData.ipop) emptyFields.push('Patient IPOP');
      if (!this.formData.dob) emptyFields.push('Date of Birth');
      if (!this.formData.blood_group_id) emptyFields.push('Blood Group');
      if (!this.formData.gender) emptyFields.push('Gender');
      if (!this.formData.sample_status) emptyFields.push('Sample status');
      if (!this.formData.department_id) emptyFields.push('Ward');
      if (!this.formData.sample_number) emptyFields.push('Sample number');
      if (!this.formData.sample_lab_number) emptyFields.push('Sample lab number');
      if (!this.formData.test_to_be_done) emptyFields.push('Tests done');

      // Only check rejection fields if sample_status is 'rejected'
      if (this.formData.sample_status === 'rejected') {
        if (!this.formData.rejection_reason) emptyFields.push('Rejection reason');
        if (!this.formData.corrective_action) emptyFields.push('Corrective action');
        if (!this.formData.corrective_action_by) emptyFields.push('Corrective action by');
      }

      return emptyFields;
    },

    async addPatientSample() {
      const emptyFields = this.validateFields();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        const payload = {
          patient_requisition_id: this.formData.patient_requisition_id,
          sample_status: this.formData.sample_status,
          sample_received_on:
            this.formData.sample_received_on ||
            new Date().toISOString().split("T")[0],
          sample_lab_number: this.formData.sample_lab_number,
          blood_group_id: this.formData.blood_group_id,
          test_to_be_done: this.formData.test_to_be_done,
          sample_number: this.formData.sample_number,
          sample_received_by: this.formData.sample_received_by,
          rejection_reason: this.formData.rejection_reason,
          corrective_action_by: this.formData.corrective_action_by,
          corrective_action: this.formData.corrective_action,
        };
        const response = await api.createSample(payload);
        console.log("add sample:", payload);
        const requisitionID = this.$route.params.requisition_id;

        console.log("Sample saved successfully:", response);
        await SweetAlertService.showSuccess(
          "Success!",
          "Sample added successfully!"
        );
        this.$router.push({
          name: "FacilityRequisitionDetailsPage",
          params: { requisitionID },
        });


        // Once redirected, show a confirmation alert
        const confirmResponse = await SweetAlertService.showConfirmation(
          "Crossmatch Confirmation",
          "Have you reviewed the crossmatch before assigning units?"
        );

        if (confirmResponse.isConfirmed) {
          const currentUser = this.$store.getters["auth/user"];

          console.log("Current User:", currentUser);

          await api.updateSample(response.sample_id, {
            gxm_confirmed_by: currentUser.user_id,
            gxm_confirmed_on: new Date().toISOString(),
          });

          SweetAlertService.showSuccess(
            "Confirmation Recorded",
            `Crossmatch confirmed by ${currentUser.first_name} ${currentUser.last_name}`
          );
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
        SweetAlertService.showError("Error", "Error adding sample.");
      }
    },

    async updatePatientSample() {
      try {
        const sample_id = this.requisitionData?.samples?.[0]?.sample_id
        const payload = {
          patient_requisition_id: this.formData.patient_requisition_id,
          sample_status: this.formData.sample_status,
          sample_received_on:
            this.formData.sample_received_on ||
            new Date().toISOString().split("T")[0],
          sample_lab_number: this.formData.sample_lab_number,
          blood_group_id: this.formData.blood_group_id,
          test_to_be_done: this.formData.test_to_be_done,
          sample_number: this.formData.sample_number,
          sample_received_by: this.formData.sample_received_by,
          rejection_reason: this.formData.rejection_reason,
          corrective_action_by: this.formData.corrective_action_by,
          corrective_action: this.formData.corrective_action,
        };
        const response = await api.updateSample(sample_id, payload);
        const requisitionID = this.$route.params.requisition_id;

        console.log("Sample updated successfully:", response);
        await SweetAlertService.showSuccess(
          "Success!",
          "Sample added successfully!"
        );
        this.$router.push({
          name: "InventoryManagementPage",
          params: { requisitionID },
        });
      } catch (error) {
        console.error("Error updating sample:", error);
        SweetAlertService.showError("Error", "Error adding sample.");
      }
    },

    async fetchRequisitionDetails() {
      try {
        this.requisitionId = this.$route.params.requisition_id;
        const response = await api.getRequisition(this.requisitionId);
        this.requisitionData = response.data || response[0];
        console.log("Requisition data:", this.requisitionData);

        // Ensure formData is correctly populated with patient details
        this.formData = {
          patient_name: `${this.requisitionData.patient?.first_name || ""} ${this.requisitionData.patient?.last_name || ""
            }`.trim(),
          dob: this.requisitionData.patient?.dob || "",
          gender: this.requisitionData.patient?.gender || "",
          ipop: this.requisitionData.patient?.ipop || "",
          department_id: this.requisitionData.department.department_id || "",
          sample_number: this.requisitionData?.samples?.[0]?.sample_number || "",
          test_to_be_done: this.requisitionData?.samples?.[0]?.test_to_be_done
            ? JSON.parse(this.requisitionData.samples[0].test_to_be_done)
            : [],
          patient_requisition_id: this.requisitionData.patient_requisition_id || "",
          blood_group_id: this.requisitionData?.samples?.[0]?.blood_group_id || "",
          sample_status: this.requisitionData?.samples?.[0]?.sample_status || "",
          sample_received_by: this.requisitionData?.samples?.[0]?.sample_received_by || "",
          sample_lab_number: this.requisitionData?.samples?.[0]?.sample_lab_number || ""
        };
      } catch (error) {
        console.error("Error fetching requisition details:", error);
      }
    },

    async getWards() {
      try {
        const response = await api.getDepartments();
        this.departments = (response.data || response).filter(
          (ward) => ward.department_type === "Clinical Area"
        );
      } catch (error) {
        this.error = error.message || "Failed to fetch departments";
      }
    },

    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    toggleTestDropdown() {
      this.isTestDropdownVisible = !this.isTestDropdownVisible;
    },
    selectProduct(product) {
      if (!this.formData.unit_product_ids.includes(product.unit_product_id)) {
        this.formData.unit_product_ids.push(product.unit_product_id);
      }
      this.isDropdownVisible = false;
    },
    removeProduct(index) {
      this.formData.unit_product_ids.splice(index, 1);
    },
    selectTest(test) {
      if (!Array.isArray(this.formData.test_to_be_done)) {
        this.formData.test_to_be_done = [];
      }

      if (!this.formData.test_to_be_done.includes(test)) {
        this.formData.test_to_be_done.push(test);
      }
      this.isTestDropdownVisible = false;
    },
    removeTest(index) {
      this.formData.test_to_be_done.splice(index, 1);
    }
  },
};
</script>