<template>
  <div>
    <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-custom-yellow"></div>
    </div>
    <template v-else>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <table class="w-full text-left rtl:text-right text-gray-700">
          <thead>
            <tr class="bg-gray-100">
              <th scope="col" class="p-4">
                <div class="flex items-center">
                  <input id="checkbox-all-search" type="checkbox"
                    class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                  <label for="checkbox-all-search" class="sr-only">checkbox</label>
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">Candidate Name</div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">User Type</div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">Exam Date</div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">Score</div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">Percentage</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(result, index) in filteredresults" :key="index" class="border-b">
              <td class="w-4 p-4">
                <div class="flex items-center">
                  <input :id="'checkbox-table-search-' + index" type="checkbox" class="w-4 h-4" />
                  <label :for="'checkbox-table-search-' + index" class="sr-only">checkbox</label>
                </div>
              </td>
              <td class="px-6 py-1">{{ result.user?.first_name }} {{ result.user?.last_name }}</td>
              <td class="px-6 py-1">
                <span v-for="(role, index) in result.user_type" :key="role.assessment_id">
                  {{ role.name }}
                  <span v-if="index < result.user_type.length - 1">, </span>
                </span>
              </td>

              <td class="px-6 py-1">
                {{
                  new Date(result.examination_date).toLocaleString()
                }}
              </td>
              <td class="px-6 py-1">
                {{ result.score }}/{{ result.total_questions }}
              </td>
              <td class="px-6 py-2">
                {{ (result.score / result.total_questions) * 100 }}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Pagination -->
      <div class="flex justify-center gap-4 mt-4">
        <button @click="previousPage" :disabled="currentPage === 1"
          class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
          Previous
        </button>

        <div class="flex items-center gap-2">
          <button v-for="page in totalPages" :key="page" @click="goToPage(page)" :class="{
            'bg-custom-blue text-white': page === currentPage,
            'text-custom-blue': page !== currentPage
          }"
            class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20"
            type="button">
            {{ page }}
          </button>
        </div>

        <button @click="nextPage" :disabled="currentPage === totalPages"
          class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          Next
          <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  name: "SupervisorResultsTab",
  components: {
  },
  created() {
    if (this.filters.assessment_id) {
      this.fetchAssessmentResults();
    }
  },
  props: {
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      results: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 10,
      error: null,
      loading: false,
      filters: {
        assessment_id: "",
      },
    };
  },
  computed: {
    filteredresults() {
      return (this.results?.exam_results || []).filter((result) => {
        const search = this.searchQuery.toLowerCase();
        const safeToLower = (value) =>
          typeof value === "string" ? value.toLowerCase() : "";

        // Apply search filter
        const matchesSearch =
          safeToLower(result.user?.first_name).includes(search) ||
          safeToLower(result.user?.last_name).includes(search) ||
          safeToLower(result.user_type).includes(search) ||
          safeToLower(new Date(result.examination_date).toLocaleDateString("en-CA")).includes(search);

        // Filter by assessment
        const matchesAssessment =
          !this.filters.assessment_id ||
          result.assessment_id?.toString() === this.filters.assessment_id.toLowerCase();

        // Combine all filters
        return matchesSearch && matchesAssessment;
      });
    },
  },

  methods: {
    async fetchAssessmentResults() {
      this.loading = true;
      try {
        const response = await api.getAssessmentTestResults(this.filters.assessment_id, {
          page: this.currentPage,
          per_page: this.perPage,
          assessment: this.filters.assessment_id,
          search: this.searchQuery,
        });
        this.results = response;
        this.totalPages = response.total_pages || 1;
        this.$emit('card-data' , 
          {
            total_candidates: this.results?.total_candidates,
            average_score: this.results?.average_score,
            average_percentage: this.results?.average_percentage
          }
        )
      } catch (error) {
        this.error = error.message || "Failed to fetch results";
        this.results = {};
      } finally {
        this.loading = false;
      }
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchAssessmentResults();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
        this.fetchAssessmentResults();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.fetchAssessmentResults();
      }
    },

    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };
      this.currentPage = 1;
      this.fetchAssessmentResults();
    },

    resetFilters() {
      this.filters = {
        assessment_id: "",
      };
      this.currentPage = 1;
      this.fetchAssessmentResults();
    },

    handleReviewAssesment(assessment_id) {
      this.$router.push({ name: 'ExamTestReviewPage', params: { assessment_id: assessment_id } });
    },

  },
};
</script>

<style lang="css" scoped></style>
