<template>
  <div>
    <AlertLayout title="Reminders!" message="Incorrect screening results could be fatal to recipients. Kindly ensure you enter the correct results and information below.
    <br> Kindly note that you can only process one unit at a time" type="warning" />
    <div class="mx-auto px-10 py-2 border border-t-0">
      <h2 class="text-lg font-bold mb-6">Add New Blood Product</h2>
      <form class="grid grid-cols-2 md:grid-cols-2 gap-5">
        <div>
          <label class="block mb-1">Pint Serial<span class="text-red-500">*</span></label>
          <input type="text" v-model="formData.pint_serial"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter Pint Serial" required>
        </div>

        <div>
          <label class="block mb-1">Blood Origin</label>
          <input type="text" v-model="formData.source"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter blood origin" required>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="blood_group">Select blood group</label>
          <div class="relative">
            <select id="blood_group_id" v-model="formData.blood_group_id"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled>Select blood group</option>
              <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.blood_group_id">
                {{ group.description }}
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="bloodProduct">Blood Product <span class="text-red-500">*</span></label>
          <select v-model="formData.unit_product_id"
            class="w-full px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
            <option value="" disabled>Select Blood Product</option>
            <option v-for="product in bloodProducts" :key="product.unit_product_id" :value="product.unit_product_id">
              {{ product.description }}
            </option>
          </select>
        </div>

        <div>
          <label class="block mb-1">Enter Volume</label>
          <input type="text" v-model="formData.volume"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter volume" required>
        </div>

        <div>
          <label class="block mb-1" for="bloodExpiryDate">Blood Expiry Date <span class="text-red-500">*</span></label>
          <input type="date" v-model="formData.expiry_date"
            class="w-full px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
        </div>

      </form>
      <div class="flex items-center justify-between mt-8">
       
            <button @click="handleBackToInventory" 
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Back</button>

        <div class="flex items-center ml-auto">
              <button  @click="resetForm"  v-if="!officer_id"
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Cancel</button>
          <CustomButton v-if="!officer_id" label="Save" bg-class="bg-custom-yellow px-8 py-3 ml-3"
            @click="handleSubmitNewUnit" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: "NewUsersForm",
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      user: '',
      bloodGroups: [],
      bloodProducts: [],
      formData: {
        pint_serial: '',
        source: '',
        blood_group_id: '',
        unit_product_id: '',
        volume: '',
        expiry_date: '',
      },
      status: 'active',
      error: null,
    };
  },
  mounted() {
    this.user = this.$store.getters["auth/user"];
    this.fetchBloodGroups();
    this.fetchBloodProducts();
  },
  created() {
  },
  methods: {
    handleBackToInventory() {
      this.$router.push({ name: 'InventoryManagementPage' });
    },

    validateFields() {
      const emptyFields = [];
      if (!this.formData.pint_serial) emptyFields.push('Unit Serial');
      if (!this.formData.source) emptyFields.push('Unit Origin');
      if (!this.formData.blood_group_id) emptyFields.push('Blood Group');
      if (!this.formData.unit_product_id) emptyFields.push('Blood Product');
      if (!this.formData.volume) emptyFields.push('Volume');
      if (!this.formData.expiry_date) emptyFields.push('Expiry Date');

      return emptyFields;
    },

    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response;
      } catch (error) {
        console.error('Error fetching blood groups:', error);
      }
    },

    async fetchBloodProducts() {
      try {
        const response = await api.getUnitProducts();
        this.bloodProducts = response || [];
      } catch (error) {
        this.error = 'Failed to fetch blood products';
      }
    },

   resetForm() {
      this.formData = {
         pint_serial: '',
        source: '',
        blood_group_id: '',
        unit_product_id: '',
        volume: '',
        expiry_date: '',
      };
    },

    async handleSubmitNewUnit() {
      const data = {
        pint_serial: this.formData.pint_serial,
        source: this.formData.source,
        blood_group_id: this.formData.blood_group_id,
        unit_product_id: this.formData.unit_product_id,
        volume: this.formData.volume,
        expiry_date: this.formData.expiry_date
      };

      const emptyFields = this.validateFields();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        await api.createProduct(data);
        await SweetAlertService.showSuccess('Success!', 'Blood Product Added successfully!.');
        this.$router.push({ name: 'InventoryManagementPage' });
      } catch (error) {
        this.error = error.message || 'Failed to save Blood Product';
        console.error('Error:', this.error);
        SweetAlertService.showError('Error', 'Error updating Blood Product.')
      }
    },
  },
};
</script>

<style lang="css" scoped>
/* Your existing styles */
</style>
