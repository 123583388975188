<template>
  <div>
    <AlertLayout title="Reminders!"
      message="Kindly make sure you have stable internet and power before starting the test" type="warning" />

    <div class="flex flex-col items-center justify-center">
      <div class="rounded-lg w-full p-6">
        <div class="grid grid-cols-2 gap-4">
          <div class="border-b-2">
            <p class="font-bold py-1">Document Name:</p>
            <p class="py-2">{{ document.document_name }}</p>
          </div>
          <div class="border-b-2">
            <p class="font-bold py-1">Document Type:</p>
            <p class="py-2">{{ document.document_type }}</p>
          </div>
          <div class="border-b-2">
            <p class="font-bold py-1">Deadline:</p>
            <p class="py-2">{{ new Date(document.deadline).toLocaleString() }}</p>
          </div>
          <div class="border-b-2">
            <p class="font-bold py-1">Author:</p>
            <p class="py-2">{{ document.created_by?.first_name }} {{ document.created_by?.last_name }}</p>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-4">
          <div class="border-b-2">
            <p class="font-bold py-1">Description:</p>
            <p class="py-2">{{ document.description }}</p>
          </div>
        </div>
        <div class="mt-4">
          <p class="font-bold py-1">Uploaded Document:</p>
          <div class="py-2 px-2 bg-gray-100 rounded-md">
            <iframe :src="`/uploads/${document.file_url}`" width="100%" height="700px"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from '@/components/AlertLayout.vue';
import api from '@/services/api';

export default {
  name: "StaffAssessmentDetailsPage",
  components: {
    AlertLayout
  },
  data() {
    return {
      document: [],
      document_id: null,
    }
  },
  created() {
    this.document_id = this.$route.params.document_id;
    this.fetchDocument();
  },
  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    async fetchDocument() {
      try {
        const response = await api.getDocument(this.document_id);
        this.document = response.data || response;
      } catch (error) {
        this.error = error.message || "Failed to fetch documents";
      }
    },
  },
};
</script>

<style scoped></style>
