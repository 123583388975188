<template>
    <div>
        <AlertLayout title="Reminders!" message="Kindly record the correct details." type="warning" />
        <div class="mx-auto p-10 border border-t-0">
            <h2 class="text-lg font-bold mb-6">Add Referral Facility</h2>

            <!-- Add Referral Facility Form -->
            <form @submit.prevent="submitReferralForm">
                <div class="grid grid-cols-2 gap-6">
                    <div>
                        <label class="block mb-1" for="facilityName">Facility Name <span
                                class="text-red-500">*</span></label>
                        <input v-model="referralForm.facility_name" type="text" id="facilityName"
                            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                            required>
                    </div>
                    <div>
                        <label class="block mb-1" for="facilityCode">Facility Code <span
                                class="text-red-500">*</span></label>
                        <input v-model="referralForm.facility_code" type="text" id="facilityCode"
                            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                            required>
                    </div>
                    <div>
                        <label class="block mb-1" for="countyId">County <span class="text-red-500">*</span></label>
                        <select v-model="referralForm.county_id" id="countyId"
                            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                            required>
                            <option value="">Select a county</option>
                            <option v-for="county in counties" :key="county.id" :value="county.name">
                                {{ county.name }}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label class="block mb-1" for="facilityEmail">Facility Email <span
                                class="text-red-500">*</span></label>
                        <input v-model="referralForm.facility_email" type="email" id="facilityEmail"
                            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
                            required>
                    </div>

                </div>
                <div class="flex justify-end space-x-2 mt-6">
                    <button @click="clearReferralForm" 
                    class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Cancel</button>
                    <CustomButton label="Submit" bg-class="bg-custom-yellow px-8 py-3" type="submit" />
                </div>
            </form>
            <!-- End Add Referral Facility Form -->
        </div>
    </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from "@/services/SweetAlertService";

export default {
    name: "AddReferralFacilityForm",
    components: {
        AlertLayout,
        CustomButton,
    },
    data() {
        return {
            error: null,
            referralForm: {
                facility_name: '',
                facility_code: '',
                facility_email: '',
                county_id: '',
            },
            counties: [],
        };
    },
    mounted() {
        this.fetchRegions();
    },
    methods: {
        user() {
            return this.$store.getters["auth/user"];
        },

        async fetchRegions() {
            try {
                const response = await api.getRegions();
                const kenyaRegion = response.regions.find((region) => region.name === 'Kenya');
                this.counties = kenyaRegion ? kenyaRegion.counties : [];
            } catch (error) {
                console.error("Error fetching regions:", error);
            }
        },

        async submitReferralForm() {
            // Check for empty fields
            const emptyFields = [];
            if (!this.referralForm.facility_name) emptyFields.push('Facility Name');
            if (!this.referralForm.facility_code) emptyFields.push('Facility Code');
            if (!this.referralForm.facility_email) emptyFields.push('Facility Email');
            if (!this.referralForm.county_id) emptyFields.push('County');

            // If any required fields are empty, show an alert and stop the submission
            if (emptyFields.length > 0) {
                SweetAlertService.showEmptyFieldsAlert(emptyFields);
                return;
            }

            try {
                // Send the payload directly without nesting it under 'referral_facility'
                const payload = {
                    ...this.referralForm
                };

                const response = await api.createReferralFacility(payload);

                await SweetAlertService.showSuccess('Success', 'Referral facility added successfully!');

                this.clearReferralForm();
                this.$emit('facilityAdded', response.data);
                this.$router.push({ name: 'DonorDetailsPage' });
            } catch (error) {
                console.error('Error creating referral facility:', error);

                const errorMessage = error.message || 'Failed to add referral facility. Please try again.';
                SweetAlertService.showError('Error', errorMessage);
            }
        },

        clearReferralForm() {
            this.referralForm = {
                facility_name: '',
                facility_code: '',
                facility_email: '',
                county_id: '',
            };
        }
    },
};
</script>

<style lang="css" scoped>
/* Your styles here if needed */
</style>