<template>
  <div class="container mx-auto p-4">
    <AlertLayout title="Reminders!"
      message="Kindly note that only blood units registered on Damu-Sasa will be found by the search.<br>Non-registered units should 1st be registered."
      type="warning" class="mb-4" />
    <div class="bg-white border border-t-0 rounded-lg shadow-md p-5">
      <h2 class="text-xl mb-4">
        <span v-if="flag === 'Split'">Unit Splitting</span>
        <span v-else-if="flag === 'Requote'">Unit Requote</span>
        <span v-else-if="flag === 'Leuco-reduction'">Unit Leucoreduction</span>
        <span v-else>Component Preparation</span>
      </h2>
      <div class="mb-8">
        <div class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg shadow-md">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Serial Number</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Blood Group</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Blood Product</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Volume</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Expiry Date</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Process</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ source_unit.pint_serial }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ source_unit.blood_group?.description }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ source_unit.unit_product?.name }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ source_unit.volume }} ml</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ new Date(source_unit.expiry_date).toLocaleString() }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ flag }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="flex justify-end">
        <button v-if="flag !== 'requote'" class="p-1 border rounded text-white bg-custom-yellow" @click="addRow">
          <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
            viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M5 12h14m-7 7V5" />
          </svg>
        </button>
      </div>

      <div class="py-6">
        <form @submit.prevent="handleSubmit">
          <div v-for="(row, index) in formData.rows" :key="index" class="grid grid-cols-1 md:grid-cols-5 gap-5 mb-4">
            <div>
              <label class="block text-gray-700 mb-2" for="unit_product_id">Component type <span
                  class="text-red-500">*</span></label>
              <select v-model="row.unit_product_id" class="block w-full px-4 py-2 border rounded-md">
                <option value="" disabled>--Select component type--</option>
                <option v-for="product in filteredBloodProducts" :key="product.unit_product_id"
                  :value="product.unit_product_id">
                  {{ product.name }}
                </option>
              </select>

            </div>

            <div>
              <label class="block text-gray-700 mb-2" for="volume">Volume <span class="text-red-500">*</span></label>
              <input v-model.number="row.volume" type="text" placeholder="New volume"
                class="block w-full px-4 py-2 border rounded-md">
            </div>

            <div>
              <label class="block text-gray-700 mb-2" for="pint_serial">
                Serial/Bag number <span class="text-red-500">*</span>
              </label>
              <input v-model="row.pint_serial" type="text" placeholder="Scan or type pilot tube serial number"
                class="block w-full px-4 py-2 border rounded-md" />
            </div>

            <div>
              <label class="block text-gray-700 mb-2" for="expiryDays">Number of expiry days <span
                  class="text-red-500">*</span></label>
              <select v-model="row.expiryDays" class="block w-full px-4 py-2 border rounded-md">
                <option value="" disabled>Select expiry days</option>
                <option value="7">7 Days</option>
                <option value="24">24 Days</option>
                <option value="30">30 Days</option>
                <option value="35">35 Days</option>
                <option value="42">42 Days</option>
                <option value="45">45 Days</option>
                <option value="365">1 year</option>
              </select>
            </div>

            <div>
              <label class="block text-gray-700 mb-2" for="expiry_date">Unit expiry date <span
                  class="text-red-500">*</span></label>
              <div class="flex items-center space-x-2">
                <div class="flex-1 mr-2">
                  <input v-model="row.expiry_date" type="date" class="w-full px-4 py-2 border rounded-md">
                </div>
                <button class="p-1 border rounded text-custom-yellow border-custom-yellow" @click="removeRow(index)">
                  <svg class="w-7 h-7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="flex justify-between">
        <div>
          <button class="px-4 py-2 border rounded text-custom-yellow border-custom-yellow" @click="goBack">
            Back
          </button>
        </div>
        <div>
          <button class="px-4 py-2 border rounded text-custom-yellow border-custom-yellow mr-2" @click="goBack">
            Cancel
          </button>
          <button class="bg-custom-yellow text-white py-2 px-4 rounded" @click="handleSubmit">
            Submit
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import AlertLayout from '@/components/AlertLayout.vue';
// import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: 'ComponentPreperationPage',
  components: {
    AlertLayout,
    // CustomButton
  },
  data() {
    return {
      user: '',
      source_unit: [],
      source_product_id: '',
      flag: '',
      bloodProducts: [],
      error: null,
      showDeferralPopup: false,
      originalVolume: 0,
      formData: {
        rows: [
          {
            unit_product_id: '',
            volume: '',
            pint_serial: '',
            expiryDays: '',
            expiry_date: ''
          }
        ]
      },
    };
  },
  computed: {
    filteredBloodProducts() {
      // If flag is 'Requote', only show the unit product related to source_unit
      if (this.flag === 'Requote') {
        return this.bloodProducts.filter(
          product => product.unit_product_id === this.source_unit.unit_product_id
        );
      }
      // Otherwise, show all blood products
      return this.bloodProducts;
    }
  },
  created() {
    this.source_product_id = this.$route.params.product_id;
    this.flag = this.$route.params.flag;
  },

  mounted() {
    this.user = this.$store.getters["auth/user"];
    this.fetchBloodProducts();
    this.getBloodUnit();
  },

  watch: {
    'formData.rows': {
      handler(newRows) {
        newRows.forEach(row => {
          this.updateexpiry_date(row);
        });
      },
      deep: true
    }
  },

  methods: {
    async fetchBloodProducts() {
      try {
        const response = await api.getUnitProducts();
        this.bloodProducts = response || [];
      } catch (error) {
        this.error = 'Failed to fetch blood products';
      }
    },
    goBack() {
      this.$router.push({ name: 'ProductDetailsPage' });
    },
    async getBloodUnit() {
      try {
        const response = await api.fetchSingleProduct(this.source_product_id);
        this.source_unit = response.data || response;
        this.originalVolume = this.source_unit.volume;
        this.initializeRows();
      } catch (error) {
        this.error = error.message || 'Failed to fetch source unit';
      }
    },
    initializeRows() {
      this.formData.rows = [
        {
          source_id: this.source_unit.product_id,
          unit_product_id: '',
          volume: this.originalVolume,
          pint_serial: this.source_unit.pint_serial || '',
          expiryDays: '',
          expiry_date: ''
        }
      ];
    },
    async handleSubmit() {
      const payload = {
        source_id: this.source_unit.product_id,
        process_type: this.flag,
        rows: this.formData.rows
      };

      try {
        await api.componentPreparation(payload);
        await SweetAlertService.showSuccess('Success!', 'Component(s) prepared successfully!.');
        this.$router.push({ name: 'InventoryManagementPage' });
      } catch (error) {
        console.error('Error during component preparation:', error);
        SweetAlertService.showError('Error', 'Error preparing component(s).');
      }
    },
    handleCancel() {
      // Handle cancel action
      this.resetForm();
    },
    resetForm() {
      this.formData.rows = [
        {
          unit_product_id: '',
          volume: this.originalVolume, // Reset with original volume
          pint_serial: this.source_unit.pint_serial || '',
          expiryDays: '',
          expiry_date: ''
        }
      ];
    },
    addRow() {
      const remainingVolume = this.calculateRemainingVolume();
      if (remainingVolume <= 0) {
        alert('No more volume available to add.');
        return;
      }

      this.formData.rows.push({
        unit_product_id: '',
        volume: remainingVolume,
        pint_serial: this.source_unit.pint_serial || '',
        expiryDays: '',
        expiry_date: ''
      });
    },
    removeRow(index) {
      this.formData.rows.splice(index, 1);
    },
    calculateRemainingVolume() {
      const totalVolumeUsed = this.formData.rows.reduce((total, row) => total + (row.volume || 0), 0);
      return this.originalVolume - totalVolumeUsed;
    },
    calculateexpiry_date(days) {
      const today = new Date();
      today.setDate(today.getDate() + days);
      return today.toISOString().split('T')[0];
    },
    updateexpiry_date(row) {
      if (this.flag === 'Requote') {
        row.expiry_date = new Date(this.source_unit.expiry_date)
          .toISOString()
          .split('T')[0];
        row.unit_product_id = this.source_unit.unit_product_id;
      } else if (row.expiryDays) {
        row.expiry_date = this.calculateexpiry_date(parseInt(row.expiryDays, 10));
      }
    }
  },
};
</script>