<template>
  <div class="container mx-auto p-4">
    <AlertLayout title="Reminders!"
      message="Kindly note that only blood units registered on Damu-Sasa will be found by the search.<br>Non-registered units should 1st be registered."
      type="warning" class="mb-4" />
    <div class="bg-white border border-t-0 rounded-lg shadow-md p-5">
      <h2 class="text-xl font-bold mb-2">Donation Information</h2>

      <div class="bg-gray-10 p-6 rounded-lg mb-6">
        <div class="grid grid-cols-2 gap-4">
          <!-- Left Column -->
          <div class="space-y-6">
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Serial Number:</label>
              <p class="text-sm font-semibold mt-3">
                {{ donation.pint_serial }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Donor Name:</label>
              <p class="text-sm font-semibold mt-3">{{ donation.donor?.user?.first_name ?? '' }}
                {{ donation.donor?.user?.last_name ?? '' }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Blood Group:</label>
              <p class="text-sm font-semibold mt-3">{{ donation.blood_group?.description ?? 'Unknown' }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Blood Volume:</label>
              <p class="text-sm font-semibold mt-3">{{ donation.quantity }} ml</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Type of Donation:</label>
              <p class="text-sm font-semibold mt-3">{{ donation.donation_type }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Technology:</label>
              <p class="text-sm font-semibold mt-3">{{ donation.technology }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Donation Date:</label>
              <p class="text-sm font-semibold mt-3">{{ donation.date_added }}</p>
            </div>
          </div>
          <!-- Right Column -->
          <div class="space-y-6">
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Donated To:</label>
              <p class="text-sm font-semibold mt-3">{{ donation.donor?.patient ?? '' }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Blood Product:</label>
              <p class="text-sm font-semibold mt-3">{{ donation.unit_product?.description }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Blood Origin:</label>
              <p class="text-sm font-semibold mt-3">{{ donation.origin }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Expiry Date:</label>
              <p class="text-sm font-semibold mt-3">{{ donation.expiry_date }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Blood Status:</label>
              <p class="text-sm font-semibold mt-3">{{ donation.status }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-8">
        <h2 class="text-xl font-semibold mb-4">Screening Results</h2>
        <div class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg shadow-md">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-sm tracking-wider">Hepatitis B</th>
                <th class="px-6 py-3 text-left text-sm tracking-wider">Hepatitis C</th>
                <th class="px-6 py-3 text-left text-sm tracking-wider">HIV</th>
                <th class="px-6 py-3 text-left text-sm tracking-wider">Malaria</th>
                <th class="px-6 py-3 text-left text-sm tracking-wider">VDRL</th>
                <th class="px-6 py-3 text-left text-sm tracking-wider">Convalescent Plasma</th>
                <th class="px-6 py-3 text-left text-sm tracking-wider">Blood Clot</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation.screening_test?.hepatitis_b ?? '' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation.screening_test?.hepatitis_c ?? '' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation.screening_test?.hiv ?? '' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation.screening_test?.malaria ?? '' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation.screening_test?.vdrl ?? '' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation.screening_test?.convalescent_plasma ?? '' }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation.screening_test?.blood_clot ?? '' }}</td>
              </tr>
              <tr class="bg-gray-50">
                <td v-for="test in testTypes" :key="test" class="px-6 py-4 whitespace-nowrap text-sm">
                  <div class="text-xs">
                    Done by: {{ getPersonnelInfo(test, 'done_by') }}<br>
                    Confirmed by: {{ getPersonnelInfo(test, 'confirmed_by') }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="products.length > 0" class="mb-8">
        <h2 class="text-xl font-semibold mb-4">Blood Components</h2>
        <div class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg shadow-md">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Serial Number
                </th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Blood Group</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Blood Product</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Volume</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Expiry Date</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(product, index) in products" :key="index" class="border-b">
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ product.pint_serial ?? '' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ product.blood_group?.description ?? '' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ product.unit_product?.name ?? '' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ product.volume ?? '' }} ml</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  {{ (new Date(product.expiry_date).toLocaleDateString()) ?? '' }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ product.status ?? '' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="flex justify-start">
        <div>
          <button class="px-4 py-2 border rounded text-orange-500 border-orange-500 mr-2"
            @click="handleBackToUnitDetails">
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from '@/components/AlertLayout.vue';
import api from '@/services/api';

export default {
  name: 'UnitDetailsPage',
  components: {
    AlertLayout,
  },
  data() {
    return {
      donation: [],
      products: [],
      blood_donation_id: '',
      totalVolume: 0,
      error: null,
      showDeferralPopup: false,
      testTypes: [
        'hepatitis_b',
        'hepatitis_c',
        'hiv',
        'malaria',
        'vdrl',
        'convalescent_plasma',
        'blood_clot'
      ]
    };
  },
  created() {
    this.blood_donation_id = this.$route.params.blood_donation_id;
    this.getBlooUnitDonation();
    this.getBloodProducts();
  },

  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },
    handleBackToUnitDetails() {
      this.$router.push({ name: 'InventoryManagementPage' });
    },
    async getBloodProducts() {
      try {
        const response = await api.fetchProductOnDonation(this.blood_donation_id);
        this.products = response.data || response;
        this.totalVolume = this.products.reduce((sum, product) => {
          return sum + product.volume;
        }, 0);
      } catch (error) {
        this.error = error.message || 'Failed to fetch donation';
      }
    },
    async getBlooUnitDonation() {
      try {
        const response = await api.getBloodDonation(this.blood_donation_id);
        this.donation = response.data || response;
      } catch (error) {
        this.error = error.message || 'Failed to fetch donation';
      }
    },
    getPersonnelInfo(testType, role) {
      const log = this.donation.screening_logs?.find(log => log.property === testType)
      if (!log || !log[role]) return ''
      return `${log[role].first_name} ${log[role].last_name}`
    }
  },
};
</script>