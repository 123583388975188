<template>
  <div>
    <AlertLayout title="Reminders!"
      message="Kindly make sure you have stable internet and power before starting the test" type="warning" />

    <div class="flex flex-col items-center justify-center px-10">
      <div class="rounded-lg w-full py-6">
        <h1 class="text-2xl font-bold mb-4">{{ assessmentName }}</h1>
        <div class="grid grid-cols-2 gap-4">
          <div class="border-b-2">
            <p class="font-bold py-1">Test Name:</p>
            <p class="py-2">{{ assessment.assessment_name }}</p>
          </div>
          <div class="border-b-2">
            <p class="font-bold py-1">Status:</p>
            <p class="py-2">{{ assessment.status }}</p>
          </div>
          <div class="border-b-2">
            <p class="font-bold py-1">Deadline:</p>
            <p class="py-2">{{ new Date(assessment.deadline).toLocaleString() }}</p>
          </div>
          <div class="border-b-2">
            <p class="font-bold py-1">Date Created:</p>
            <p class="py-2">{{ new Date(assessment.created_at).toLocaleString() }}</p>
          </div>
          <div class="border-b-2">
            <p class="font-bold py-1">Duration:</p>
            <p class="py-2">{{ assessment.duration }}</p>
          </div>
          <div class="border-b-2">
            <p class="font-bold py-1">Number of questions:</p>
            <p class="py-2">{{ assessment.number_of_questions }}</p>
          </div>
          <div class="border-b-2">
            <p class="font-bold py-1">Examiner:</p>
            <p class="py-2">{{ assessment.examiner?.first_name }} {{ assessment.examiner?.last_name }}</p>
          </div>
          <div class="border-b-2">
            <p class="font-bold py-1">Basis:</p>
            <p class="py-2">
              <span v-for="(document, index) in assessment.documents" :key="document.document_id">
                <a href="javascript:void(0)" @click="redirectToDocument(document.document_id)"
                  class="text-blue-500 hover:underline">
                  {{ document.document_name }}
                </a>
                <span v-if="index < assessment.documents.length - 1">, </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end p-10">
      <button v-if="!assessmentDone" @click="handleTakeCatModal()"
        class="px-4 py-2 bg-custom-yellow text-white rounded">Take C.A.T</button>
      <button v-if="assessmentDone" @click="handleReviewAssesment()"
        class="px-4 py-2 bg-custom-yellow text-white rounded">Review Assessment</button>
    </div>

    <div v-if="showCatModal"
      class="fixed inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto h-full w-full flex items-center justify-center">
      <div class="bg-white p-8 rounded-lg shadow-xl w-4/10 mt-4 mb-4">
        <h2 class="text-2xl text-gray-700 font-bold mb-4">Start Test</h2>
        <hr class="h-px my-4 bg-orange-200 border-0 dark:bg-orange-200">
        <div class="mb-6">
          <div class="py-2 px-4 mb-4 border-l-4 text-custom-yellow border-custom-yellow dark:bg-orange-50 rounded-sm"
            role="alert">
            <div class="flex items-center">
              <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <span class="sr-only">warning</span>
              <h3 class="text-lg px-2 font-medium text-gray-800">Reminder!</h3>
            </div>
            <div class="mb-3 px-8 text-sm text-gray-800">
              Kindly make sure you have stable internet and power before starting the test.<br>
              Kindly note that once you start a test, the timer starts running for the allocated period of time.<br>
              Kindly note that once the allocated duration of time elapses, your assessment will be automatically
              submitted
            </div>
          </div>
          <hr class="h-px my-4 bg-orange-200 border-0 dark:bg-orange-200">
        </div>
        <div class="flex justify-between space-x-2">
          <button type="button" @click="closeTakeCatModal"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded">Cancel</button>
          <button @click="handleStartTest(assessment.assessment_id)"
            class="px-4 py-2 bg-custom-yellow text-white rounded">Start Now</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from '@/components/AlertLayout.vue';
import api from '@/services/api';

export default {
  name: "DocumentDetailsPage",
  components: {
    AlertLayout
  },
  data() {
    return {
      assessment: [],
      assessment_id: null,
      showCatModal: false,
      assessmentDone: false,
    }
  },
  created() {
    this.assessment_id = this.$route.params.assessment_id;
    this.fetchAssessment();
    this.confirmAssessmentDone();
  },
  computed: {
  },
  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    async fetchAssessment() {
      try {
        const response = await api.getAssessment(this.assessment_id);
        this.assessment = response.data || response;
      } catch (error) {
        this.error = error.message || "Failed to fetch assessments";
      }
    },

    async confirmAssessmentDone() {
      try {
        const response = await api.getUserAssessmentResult(this.assessment_id);
        const assessmentData = response.data || response;

        // Update `assessmentDone` based on the length of the array
        this.assessmentDone = Array.isArray(assessmentData) && assessmentData.length > 0;

      } catch (error) {
        this.error = error.message || "Failed to fetch assessments";
        this.assessmentDone = false;
      }
    },

    redirectToDocument(documentId) {
      this.$router.push({ name: 'DocumentDetailsPage', params: { document_id: documentId } });
    },

    handleStartTest(assessmentId) {
      this.$router.push({ name: 'StaffAssessmentTestPage', params: { assessment_id: assessmentId } });
    },

    handleTakeCatModal() {
      this.showCatModal = true
    },

    handleReviewAssesment() {
      this.$router.push({ name: 'ExamTestReviewPage', params: { assessment_id: this.assessment_id } });
    },

    closeTakeCatModal() {
      this.showCatModal = false;
    },
  },
};
</script>

<style scoped></style>
