<template>
  <div class="ml-12">
    <h2 class="text-lg font-semibold mb-4 mt-10">Personal Information</h2>
    <!-- Form Fields for Recording Donation -->
    <form @submit.prevent="moveToNext">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label class="block mb-1" for="lastName"
            >Last Name/Other Name <span class="text-red-500">*</span></label
          >
          <input
            id="lastName"
            type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter Last Name/Other Name"
            v-model="donorForm.user.last_name"
            @input="updateDonor"
            required
          />
        </div>

        <div>
          <label class="block mb-1" for="firstName"
            >First Name <span class="text-red-500">*</span></label
          >
          <input
            id="firstName"
            type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter First Name"
            v-model="donorForm.user.first_name"
            required
          />
        </div>

        <div>
          <label class="block mb-1" for="birthDate"
            >Date of Birth <span class="text-red-500">*</span></label
          >
          <input
            type="date"
            id="birthDate"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="select Date of Birth"
            v-model="donorForm.user.dob"
            @change="calculateAge"
            :max="maxDate"
            required
          />
        </div>

        <div>
          <label class="block mb-1" for="gender"
            >Gender <span class="text-red-500">*</span></label
          >
          <div class="relative">
            <select
              id="gender"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="donorForm.user.gender"
              required
            >
              <option value="" disabled selected>Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="idNum"
            >ID. Number / Passport </label
          >
          <input
            id="idNum"
            type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter ID. Number / Passport"
            v-model="donorForm.user.id_number"
          />
        </div>

        <div>
          <label class="block mb-1" for="age"
            >Age <span class="text-red-500">*</span></label
          >
          <input
            id="age"
            type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            v-model="donorForm.age"
            readonly
          />
        </div>
      </div>

      <h2 class="text-lg font-semibold mb-4 mt-10">Medical History</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label class="block mb-1" for="donorStatus"
            >Donor Status <span class="text-red-500">*</span></label
          >
          <div class="relative">
            <select
              id="donorStatus"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="donorForm.donor_status" required
            >
              <option value="" disabled selected>Select status</option>
              <option value="firstTime">First Time</option>
              <option value="repeat">Repeat</option>
              <option value="regular">Regular</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="donationType"
            >Donation Type <span class="text-red-500">*</span></label
          >
          <div class="relative">
            <select
              id="donationType"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="donorForm.donation_status" required
            >
              <option value="" disabled selected>Select status</option>
              <option value="replacement">
                Replacement (Replaces blood unit for specific patient)
              </option>
              <option value="random">
                Random (First-time donor, not for specific patient)
              </option>
              <option value="frequent">
                Regular-donor (Frequent donor, not for specific patient)
              </option>
              <option value="designated">
                Designated/Directed (Donates for specific patient with doctor's
                letter)
              </option>
              <option value="autologous">
                Autologous (Donates for future self use with doctor's letter)
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div v-if="['replacement', 'designated', 'autologous'].includes(donorForm.patient)">
          <label class="block mb-1" for="patientName"
            >Patient Name </label
          >
          <input
            id="patientName"
            type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter Patient Name"
            v-model="donorForm.patient"
          />
        </div>

        <div>
          <label class="block mb-1" for="regularDonor"
            >Would you like to become a regular blood donor?
            <span class="text-red-500">*</span></label
          >
          <div class="relative">
            <select
              id="regularDonor"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="donorForm.enroll_as_donor" required
            >
              <option value="" disabled selected>Select</option>
              <option value="true">yes</option>
              <option value="false">no</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div v-if="['replacement', 'designated', 'autologous'].includes(donorForm.donation_status)">
          <label class="block mb-1" for="wardNum"
            >Ward Number </label
          >
          <input
            id="wardNum"
            type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter Ward Number"
            v-model="donorForm.ward"
          />
        </div>
      </div>

      <h2 class="text-lg font-semibold mb-4 mt-10">Contact Information</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label class="block mb-1" for="phone"
            >Phone Number<span class="text-red-500">*</span></label
          >
          <input
            id="phone"
            type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter phone number"
            v-model="donorForm.user.phone_number"
            required
          />
        </div>

        <div>
          <label class="block mb-1" for="appointmentDate"
            >Preferred appointment date (if donor is not in the donation center)
           </label
          >
          <input
            type="text"
            id="appointmentDate"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Select preferred date"
            onfocus="(this.type='date')"
            onblur="if(this.value===''){this.type='text'}"
            v-model="preferredDate"
          />
        </div>

        <div>
          <label class="block mb-1" for="appointmentTime"
            >Preferred appointment time (if donor is not in the donation center)
        </label
          >
          <input
            type="time"
            id="appointmentTime"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Select preferred time"
            v-model="preferredTime"
          />
        </div>

        <div>
          <label class="block mb-1" for="email"
            >Email <span class="text-red-500">*</span></label
          >
          <input
            id="address"
            type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter Email"
            v-model="donorForm.user.email"
            required
          />
        </div>

        <div>
          <label class="block mb-1" for="address"
            >Postal Address </label
          >
          <input
            id="address"
            type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter Postal Address"
            v-model="donorForm.physical_address"
          />
        </div>

        <div>
          <label class="block mb-1 text-gray-700 font-medium" for="county">
            County <span class="text-red-500">*</span>
          </label>
          <div class="relative">
            <select
              id="county_id"
              v-model="donorForm.county_id"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required
            >
              <option value="" disabled>Select county</option>
              <option
                v-for="county in counties"
                :key="county.county_id"
                :value="county.county_id"
              >
                {{ county.name }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 flex justify-between">
        <button
          type="button"
          class="px-4 py-2 border rounded text-orange-500 border-orange-500"
          @click="moveToPrevious"
        >
          Back
        </button>
        <CustomButton
          type="submit"
          label="next"
          class="px-4 py-2"
          bg-class="bg-custom-yellow"
        />
      </div>
    </form>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from "@/services/api";

export default {
  name: "EditNewDonorInformationForm",
  components: {
    CustomButton,
  },
  props: {
    donor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      maxDate: this.calculateMaxDate(),
      counties: [],
      localDonor: [],
      donorForm: {
        user: {
          first_name: '',
          last_name: '',
          dob: '',
          gender: '',
          id_number: '',
          phone_number: '',
          email: '',
        },
        county_id: '',
        donor_status: '',
        donation_status: '',
        patient: '',
        enroll_as_donor: '',
        ward: '',
        work_phone: '',
        physical_address: '',
        age: '',
      },
      // preferredDate: '',
      // preferredTime: '',
      // maxDate: new Date().toISOString().split("T")[0],
    }
  },

  computed: {
    preferredDate: {
      get() {
        return this.localDonor.donation_bookings &&
          this.localDonor.donation_bookings.length > 0
          ? this.localDonor.donation_bookings[0].preferred_date
          : "N/A";
      },
      set(value) {
        if (
          this.localDonor.donation_bookings &&
          this.localDonor.donation_bookings.length > 0
        ) {
          this.localDonor.donation_bookings[0].preferred_date = value;
        }
      },
    },
    preferredTime: {
      get() {
        return this.localDonor.donation_bookings &&
          this.localDonor.donation_bookings.length > 0
          ? this.donor.donation_bookings[0].preferred_time
          : "N/A";
      },
      set(value) {
        if (
          this.localDonor.donation_bookings &&
          this.localDonor.donation_bookings.length > 0
        ) {
          this.localDonor.donation_bookings[0].preferred_time = value;
        }
      },
    },
  },

  created() {
    this.maxDate = this.calculateMaxDate();
    this.getDonors();
    this.fetchRegions();
  },

  watch: {
    "donor.user.dob": "calculateAge",
    // donor: {
    //   handler(newVal) {
    //     this.localDonor = { ...newVal }; // Update local data when prop changes
    //   },
    //   deep: true,
    // },
  },

  methods: {

    calculateMaxDate() {
      const today = new Date();
      const year = today.getFullYear() - 16;
      const month = today.getMonth() + 1;
      const day = today.getDate();
      return `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;
    },

    calculateAge() {
      const dob = this.donorForm.user.dob;
      if (!dob) {
        this.donorForm.age = "";
        return;
      }

      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      this.donorForm.age = age;
    },

    async getDonors() {
      try {
        const donorId = this.$route.params.donor_id;
        console.log("Fetching details for donor ID:", donorId);
        const response = await api.getDonor(donorId);
        this.localDonor = response.data || response;
        this.populateDonor(this.localDonor)
        console.log("data:", this.donor);
      } catch (error) {
        this.error = error.message || "Failed to fetch donors";
      }
    },
 
    populateDonor(donorData) {
      this.donorForm.user.first_name = donorData.user?.first_name || "",
      this.donorForm.user.last_name = donorData.user?.last_name || "",
      this.donorForm.user.email = donorData.user?.email || "",
      this.donorForm.user.phone_number = donorData.user?.phone_number || "",
      this.donorForm.user.id_number = donorData.user?.id_number || "",
      this.donorForm.user.blood_group_id = donorData.user?.blood_group_id || null,
      this.donorForm.user.gender = donorData.user?.gender || "",
      this.donorForm.user.dob = donorData.user?.dob || "",
      this.donorForm.user.contact_mode = donorData.user?.contact_mode || "",
      this.donorForm.county_id = donorData.county_id.county_id;
      console.log("Set county_id:", this.donorForm.county_id);

      this.donorForm.donor_status = donorData.donor_status || "",
      this.donorForm.donation_status = donorData.donation_status || "",
      this.donorForm.patient = donorData.patient || "",
      this.donorForm.enroll_as_donor = donorData.enroll_as_donor || "",
      this.donorForm.ward = donorData.ward || "",
      this.donorForm.work_phone = donorData.work_phone || "",
      this.donorForm.physical_address = donorData.physical_address || "",
      this.donorForm.age = donorData.age || ""
    },

    async fetchRegions() {
      try {
        const response = await api.getRegions();
        this.counties = response.counties || [];
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    },

    moveToNext() {
      localStorage.setItem('donorFormData', JSON.stringify(this.donorForm));
      this.$emit("move-to-next-step");
    },

    moveToPrevious() {
      this.$emit("move-to-previous-step");
    },
  },
};
</script>