<template>
  <div class="container mx-auto p-10 border border-t-0">
    <h2 class="text-2xl font-bold mb-6">Process new donation</h2>

    <form class="grid grid-cols-1 md:grid-cols-2 gap-5" @submit.prevent="handleSubmit">
      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="donation_type">
          Select donation type <span class="text-red-500">*</span>
        </label>
        <div class="relative">
          <select id="donation_type" v-model="formData.donation_type"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            required>
            <option value="" disabled>Select Donation Type</option>
            <option value="" disabled selected>Select status</option>
              <option value="Replacement">
                Replacement (Replaces blood unit for specific patient)
              </option>
              <option value="Random">
                Random (First-time donor, not for specific patient)
              </option>
              <option value="Frequent">
                Regular-donor (Frequent donor, not for specific patient)
              </option>
              <option value="Designated">
                Designated/Directed (Donates for specific patient with doctor's
                letter)
              </option>
              <option value="Autologous">
                Autologous (Donates for future self use with doctor's letter)
              </option>
          </select>
        </div>
      </div>



      <!-- Conditionally show this field when "Replacement" is selected -->
      <div v-if="formData.donation_type === 'Replacement'" class="mt-4">
        <label class="block mb-2 text-gray-700 font-medium" for="ipop">
          Enter IP/OP Number/Name of the patient donor is replacing for
          <span class="text-red-500">*</span>
        </label>
        <input id="ipop" v-model="formData.ipop" type="text"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400"
          placeholder="Enter IP/OP Number or Patient Name" required />
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="pint_serial">Scan or type blood bag/pilot tube serial
          number<span class="text-red-500">*</span></label>
        <input id="pint_serial" v-model="formData.pint_serial" type="text"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          placeholder="Pint Serial Number" required>
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="technology">Select donation process <span
            class="text-red-500">*</span></label>
        <div class="relative">
          <select id="technology" v-model="formData.technology"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            required>
            <option value="" disabled>Select Donation Process</option>
            <option value="Normal">Normal/Regular</option>
            <option value="Apheresis">Apheresis</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="donation_successful">Was the donation successful? <span
            class="text-red-500">*</span></label>
        <div class="relative">
          <select id="donation_successful" v-model="formData.donation_successful"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            required>
            <option value="" disabled>Select Success Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="date_added">Enter date and time of donation (yyyy-mm-dd
          H:m:s)<span class="text-red-500">*</span></label>
        <input id="date_added" v-model="formData.date_added" type="datetime-local"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          required>
      </div>
    </form>

    <div class="mt-8 flex justify-between">
      <button class="px-4 py-2 border rounded text-orange-500 border-orange-500" @click="handleBack">Back</button>
      <div class="flex items-center space-x-4 mt-4 sm:mt-0">
        <CustomButton v-if="formData.donation_successful === 'No'" label="Defer"
          bg-class="bg-red-500 text-white px-8 py-3" @click="handleDeferral" />
        <CustomButton v-if="formData.donation_successful === 'No'" label="New Reaction"
          bg-class="bg-red-500 text-white px-8 py-3" @click="redirectToDonationReactionForm" />
        <CustomButton v-if="formData.donation_successful === 'Yes'" label="Register Donation"
          bg-class="bg-custom-yellow text-white px-8 py-3" @click="handleRecordDonation" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue';
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';


export default {
  name: 'RecordDonationForm',
  components: {
    CustomButton,
  },
  props: {
    donorId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      facilityId: null,
      formData: {
        donation_type: '',
        pint_serial: '',
        technology: '',
        ipop: '',
        date_added: this.getCurrentDateTime(),
        donation_successful: '',
        added_by: this.user().user_id,
        facility_id: this.user().facility_id,
      }
    };
  },

  computed: {
    userClaims() {
      return this.$store.getters['auth/claims'];
    },
    userData() {
      return this.$store.getters['auth/user'];
    },
  },

  mounted() {
    this.facilityId = this.userData.facility_id
  },


  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = ('0' + (now.getMonth() + 1)).slice(-2);
      const day = ('0' + now.getDate()).slice(-2);
      const hours = ('0' + now.getHours()).slice(-2);
      const minutes = ('0' + now.getMinutes()).slice(-2);
      const seconds = ('0' + now.getSeconds()).slice(-2);
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    },

    // Helper method to check for empty fields
    validateFields() {
      const emptyFields = [];
      if (!this.formData.donation_type) emptyFields.push('Donation Type');
      if (!this.formData.pint_serial) emptyFields.push('Pint Serial');
      if (!this.formData.technology) emptyFields.push('Donation Process');
      if (!this.formData.donation_successful) emptyFields.push('Success Status');
      if (!this.formData.date_added) emptyFields.push('Date Added');

      return emptyFields;
    },

    // Modified handleRecordDonation method
    async handleRecordDonation() {
      const emptyFields = this.validateFields();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        const response = await api.createBloodDonation(this.donorId, this.formData);
        console.log('Donation data saved successfully:', response);
        await SweetAlertService.showSuccess('Success', 'Donation saved successfully!.');
        this.$router.push({ name: 'DonorManagementPage' });
      } catch (error) {
        // console.error('Error submitting the form:', error.message); 
        SweetAlertService.showError('Error', error.message || 'An unknown error occurred.');
      }
    },

    // Modified handleDeferral method
    async handleDeferral() {
      try {
        SweetAlertService.showConfirmation('Continue to deferrals', 'Do you want to continue to the deferral form?')
          .then(async (result) => {
            if (result.isConfirmed) {
              const newDonorId = this.donorId;
              if (!newDonorId) {
                throw new Error('Missing donor_id in response');
              }

              // Proceed to deferral page
              this.$router.push({
                name: 'NewDonorDeferralForm',
                params: { donor_id: newDonorId }
              });
            }
          });
      } catch (error) {
        console.error('Error during deferral:', error);
        SweetAlertService.showError('Error', 'Something went wrong during the deferral process.');
      }
    },



    async redirectToDonationReactionForm() {
      try {
        SweetAlertService.showConfirmation('Continue to reactions', 'Do you want to continue to the reactions form?')
          .then(async (result) => {
            if (result.isConfirmed) {
              const newDonorId = this.donorId;
              if (!newDonorId) {
                throw new Error('Missing donor_id in response');
              }

              // Proceed to deferral page
              this.$router.push({
                name: 'NewReactionForm',
                params: { donor_id: newDonorId }
              });
            }
          });
      } catch (error) {
        console.error('Error during reactions:', error);
        SweetAlertService.showError('Error', 'Something went wrong during the reactions process.');
      }
    },

    handleBack() {
      this.$emit('move-to-previous-step');
    }
  },


}
</script>

<style scoped></style>