<template>
  <div>
    <StepperLayout :steps="steps" :current-step="currentTab" />

    <div>
      <component
        :is="currentTabComponent"
        :donor="donor"
        :donorId="donorId"
        @move-to-previous-step="goToPreviousStep"
        @move-to-next-step="goToNextStep"
        @final-submit="handleFinalSubmit"
        @update:donor="updateDonor"
      >
      </component>
    </div>
  </div>
</template>

<script>
import EducationalAlerts from "@/components/EducationalAlerts.vue";
import StepperLayout from "@/components/StepperLayout.vue";
import EditNewDonorInformationForm from "../forms/EditDonorInformationForm.vue";
import EditNewDonorQuestionnaireForm from "../forms/EditDonorQuestionnaireForm.vue";
import EditNewDonorDeclarationForm from "../forms/EditDonorDeclarationForm.vue";
import api from "@/services/api";
import SweetAlertService from "@/services/SweetAlertService";


export default {
  name: "EditDonorQuestionnairePage",
  components: {
    EducationalAlerts,
    StepperLayout,
    EditNewDonorInformationForm,
    EditNewDonorQuestionnaireForm,
    EditNewDonorDeclarationForm,
  },
  data() {
    return {
      currentTab: "EditNewDonorInformationForm",
      donor: [],
      formData: {
        user: {},
        questionnaire: {},
        declaration: {},
      },
      facility_id: this.user().facility_id,
      steps: [
        {
          id: "EditNewDonorInformationForm",
          title: "1. Donor Information",
          subtitle: "Provide donor information",
          active: true,
        },
        {
          id: "EditNewDonorQuestionnaireForm",
          title: "2. Donor Questionnaire",
          subtitle: "Answer the donor questionnaire",
          active: false,
        },
        {
          id: "EditNewDonorDeclarationForm",
          title: "3. Declaration",
          subtitle: "Fill in declaration & consent form",
          active: false,
        },
      ],
    };
  },

  created() {
    this.getDonors();
  },

  computed: {
    currentTabComponent() {
      switch (this.currentTab) {
        case "EditNewDonorInformationForm":
          return EditNewDonorInformationForm;
        case "EditNewDonorQuestionnaireForm":
          return EditNewDonorQuestionnaireForm;
        case "EditNewDonorDeclarationForm":
          return EditNewDonorDeclarationForm;
        default:
          return EditNewDonorInformationForm;
      }
    },
  },
  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    updateDonor() {
      this.$emit("update:donor", this.localDonor);
    },

    async getDonors() {
      try {
        const donorId = this.$route.params.donor_id;
        console.log("Fetching details for donor ID:", donorId);
        const response = await api.getDonor(donorId);
        this.donor = response.data || response;
        console.log("data:", this.donor);
      } catch (error) {
        this.error = error.message || "Failed to fetch donors";
      }
    },

    handleDonorCreated(newDonorId) {
      this.donorId = newDonorId;
    },

    setActiveStep(stepId) {
      this.steps.forEach((step) => {
        step.active = step.id === stepId;
      });
      this.currentTab = stepId;
    },

    goToPreviousStep() {
      const currentIndex = this.steps.findIndex(
        (step) => step.id === this.currentTab
      );
      if (currentIndex > 0) {
        this.setActiveStep(this.steps[currentIndex - 1].id);
      }
    },

    goToNextStep() {
      const currentIndex = this.steps.findIndex(
        (step) => step.id === this.currentTab
      );
      if (currentIndex < this.steps.length - 1) {
        this.setActiveStep(this.steps[currentIndex + 1].id);
      }
    },
    handleFinalSubmit(combinedData) {
      const donorQuestionnaire = {
        feeling_healthy: combinedData.questionnaireFormData?.feeling_healthy,
        take_flight: combinedData.questionnaireFormData?.take_flight,
        minimum_weight: combinedData.questionnaireFormData?.minimum_weight,
        active_allergy: combinedData.questionnaireFormData?.active_allergy,
        severe_disease: combinedData.questionnaireFormData?.severe_disease,
        had_alcohol: combinedData.questionnaireFormData?.had_alcohol,
        had_drugs: combinedData.questionnaireFormData?.had_drugs,
        taking_medication: combinedData.questionnaireFormData?.taking_medication,
        dental_work: combinedData.questionnaireFormData?.dental_work,
        unexplained_sickness:
          combinedData.questionnaireFormData?.unexplained_sickness,
        relative_has_hepatitis:
          combinedData.questionnaireFormData?.relative_has_hepatitis,
        had_body_piercing: combinedData.questionnaireFormData?.had_body_piercing,
        immunization_vaccinations:
          combinedData.questionnaireFormData?.immunization_vaccinations,
        pos_for_Hepatitis_C:
          combinedData.questionnaireFormData?.pos_for_Hepatitis_C,
        had_surgery: combinedData.questionnaireFormData?.had_surgery,
        infection_exposure:
          combinedData.questionnaireFormData?.infection_exposure,
          medical_condition: combinedData.questionnaireFormData?.medical_condition,
          positive_for_HIV: combinedData.questionnaireFormData?.positive_for_HIV,
          bleeding_condition:
          combinedData.questionnaireFormData?.bleeding_condition,
          rejected_as_donor: combinedData.questionnaireFormData?.rejected_as_donor,
        };

      const user = {
        first_name: combinedData.donorFormData?.user?.first_name || "",
        last_name: combinedData.donorFormData.user?.last_name || "",
        email: combinedData.donorFormData.user?.email || "",
        phone_number: combinedData.donorFormData.user?.phone_number || "",
        id_number: combinedData.donorFormData.user?.id_number || "",
        blood_group_id: combinedData.donorFormData.user?.blood_group_id || null,
        gender: combinedData.donorFormData.user?.gender || "",
        dob: combinedData.donorFormData.user?.dob || "",
        contact_mode: combinedData.donorFormData.user?.contact_mode || "",
        county_id: combinedData.donorFormData.county_id || "",
        donor_status: combinedData.donorFormData.donor_status || "",
        donation_status: combinedData.donorFormData.donation_status || "",
        patient: combinedData.donorFormData.patient || "",
        enroll_as_donor: combinedData.donorFormData.enroll_as_donor || "",
        ward: combinedData.donorFormData.ward || "",
        work_phone: combinedData.donorFormData.work_phone || "",
        physical_address: combinedData.donorFormData.physical_address || "",
        age: combinedData.donorFormData.age || "",
      };

      const declaration = {
        read_educational_material: combinedData.declarationFormData?.read_educational_material,
        consent_checkbox: combinedData.declarationFormData?.consent_checkbox,
        blood_testing_consent: combinedData.declarationFormData?.blood_testing_consent,
        terms_agreed: combinedData.declarationFormData?.terms_agreed,
        consent_form: combinedData.declarationFormData?.consent_form,
      };

      // Construct the final payload
      const payload = {
        donor_questionnaire: donorQuestionnaire,
        declaration: declaration,
        user: user,
        facility_id: this.user().facility_id,
      };

      // console.log("Payload:", payload);

      const donorId = this.$route.params.donor_id;

      // Submit to API
      this.submitToApi(donorId, payload);
    },

    async submitToApi(donorId, payload) {
      try {
        await api.updateDonor(donorId, payload);
        console.log("Submission successful:", payload);
        await SweetAlertService.showSuccess(
          "Success!",
          "Donor info updated saved successfully!."
        );
        this.$router.push({ name: "DonorQuestionnaireInformation" });
      } catch (error) {
        console.error("Error submitting the form:", error);
        await SweetAlertService.showError(
          "Submission Error",
          "An error occurred while saving the donor information."
        );
      }
    },
  },
};
</script>
