<template>
  <div>
    <AlertLayout title="Reminders!"
      message="Kindly observe the facility's turn around time policy on received blood requisitions.<br>Click on Details button for more actions and information on the requisition of choice"
      type="warning" />
    <div class="flex flex-wrap items-center justify-between pb-4 py-1">
      <div class="flex items-center space-x-4">
        <div class="relative">
          <button id="dropdownRadioButton"
            class="inline-flex items-center text-gray-400 bg-white border border-gray-300 rounded-md px-6 py-2"
            type="button" @click="toggleDropdown">
            <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                d="M18.796 4H5.204a1 1 0 0 0-.753 1.659l5.302 6.058a1 1 0 0 1 .247.659v4.874a.5.5 0 0 0 .2.4l3 2.25a.5.5 0 0 0 .8-.4v-7.124a1 1 0 0 1 .247-.659l5.302-6.059c.566-.646.106-1.658-.753-1.658Z" />
            </svg>
            Filters
            <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 4 4 4-4" />
            </svg>
          </button>
          <!-- start dropdown filter -->
          <template v-if="currentTab === 'requisition'">
            <div v-show="isDropdownOpen" id="filterDropdown"
              class="absolute z-10 mt-2 w-80 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
              <div class="flex">
                <button type="button"
                  class="ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex text-gray-500 items-center justify-end h-8 w-8 hover:text-custom-yellow"
                  @click="cancelFilters" aria-label="Close">
                  <span class="sr-only">Dismiss</span>
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
              </div>
              <form class="max-w-sm mx-auto" @submit.prevent="applyFilters">
                <div class="mb-4">
                  <label for="donationProcess" class="block mb-2 text-sm font-medium text-gray-900">Blood Group
                  </label>
                  <select v-model="filters.bloodGroup"
                    class="bg-gray-50 border border-gray-200 text-gray-900 rounded-md block w-full py-2 px-1 dark:placeholder-gray-400">
                    <option disabled selected>Select blood group</option>
                    <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.description">
                      {{ group.description }}
                    </option>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="dataAdded" class="block mb-2 text-sm font-medium text-gray-900">Date Added</label>
                  <input type="text" id="dataAdded" v-model="filters.dataAdded"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="YYYY-MM-DD">
                </div>
                <div class="mb-4">
                  <label for="statusFilter" class="block mb-2 text-sm font-medium text-gray-900">Status</label>
                  <select id="statusFilter" v-model="filters.status"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                    <option value="" disabled selected>Select</option>
                    <option value="Pending">Pending</option>
                    <option value="Partially assigned">Partially assigned</option>
                    <option value="Awaiting collection">Awaiting collection</option>
                    <option value="Partially collected">Partially collected</option>
                    <option value="Partially assigned & Partially collected">Partially assigned & Partially collected
                    </option>
                    <option value="Collected">Collected</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Expired">Expired</option>
                  </select>
                </div>
                <div class="flex justify-end space-x-2">
                  <button type="button" @click="cancelFilters"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                    Cancel
                  </button>
                  <button type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-custom-yellow rounded-lg hover:bg-custom-yellow-dark">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </template>

        </div>
        <div class="relative flex">
          <input type="text" v-model="searchQuery" id="table-search"
            class="px-4 py-2 text-gray-800 border border-gray-300 rounded-sm dark:border-gray-300 dark:placeholder-gray-400"
            placeholder="Search" />
          <button type="submit" class="bg-custom-yellow text-white rounded-r px-2 md:px-3 py-0 md:py-1">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
            </svg>
          </button>
        </div>
      </div>

    </div>
    <div class="tabs flex border-b mb-4">
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'requisition' }" @click="currentTab = 'requisition'">
        Requisitions
      </button>
      <!-- <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'complete-requisition' }"
        @click="currentTab = 'complete-requisition'">
        Complete
      </button>
      <button class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'canceled-requisition' }"
        @click="currentTab = 'canceled-requisition'">
        Canceled/Expired
      </button> -->
    </div>
    <component :is="currentTabComponent" ref="currentTabComponent" :search-query="searchQuery">
    </component>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import RequisitionsTab from "../tabs/RequisitionsTab.vue";
// import CompleteRequisitionsTab from "../tabs/CompleteRequisitionsTab.vue";
// import CanceledRequisitionsTab from "../tabs/CanceledRequisitionsTab.vue";
import api from '@/services/api';

export default {
  name: "RequisitionManagementPage",
  components: {
    AlertLayout,
    CustomButton,
    RequisitionsTab,
    // CompleteRequisitionsTab,
    // CanceledRequisitionsTab,
  },
  data() {
    return {
      searchQuery: "",
      isDropdownOpen: false,
      currentTab: "requisition",
      bloodGroups: [],
      filters: {
        bloodGroup: "",
        dataAdded: '',
        status: "",
        sample_status: ""
      },
    };
  },
  computed: {
    currentTabComponent() {
      switch (this.currentTab) {
        case "requisition":
          return "RequisitionsTab";
        case "complete-requisition":
          return "CompleteRequisitionsTab";
        case "canceled-requisition":
          return "CanceledRequisitionsTab";
        default:
          return "RequisitionsTab";
      }
    },
  },
  props: {},
  created() {
    this.fetchBloodGroups();
  },
  methods: {

    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response;
      } catch (error) {
        console.error('Error fetching blood groups:', error);
      }
    },

    applyFilters() {
      console.log('Applying filters:', this.filters);
      this.applyFiltersToCurrentTab();
      this.isDropdownOpen = false;
    },

    applyFiltersToCurrentTab() {
      if (this.$refs.currentTabComponent && typeof this.$refs.currentTabComponent.applyFilters === 'function') {
        this.$refs.currentTabComponent.applyFilters(this.filters);
      } else {
        console.error('applyFilters method not found on current tab component');
      }
    },

    cancelFilters() {
      this.filters = {
        bloodGroup: "",
        dataAdded: '',
        status: "",
        sample_status: ""
      };
      this.applyFiltersToCurrentTab();
      this.isDropdownOpen = false;
    },

    handleNewUnitRequisition() {
      this.$router.push({ name: 'NewRequisitionForm' });
    },
    handleNewQuestionnaire() {
      // Handle the action for the "New Questionnaire" button
      // e.g., navigating to a form or opening a modal
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
  },
};
</script>

<style lang="scss" scoped></style>