<template>
  <div>
    <AlertLayout title="Reminders!" message="Kindly fill in the details as required below." type="warning" />
    <div class="mx-auto p-10 border border-t-0">
      <h2 class="text-lg font-bold mb-6">{{ departments_id ? 'Update Department' : 'Create a New Department' }}</h2>
      <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div>
          <label class="block mb-1">Department Name <span class="text-red-500">*</span></label>
          <input type="text" v-model="formData.name"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter department name" required>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium">Department Type <span
              class="text-red-500">*</span></label>
          <div class="relative">
            <select id="gender" v-model="formData.department_type"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled class="text-gray-400">Select departement type</option>
              <option value="Clinical Area">Clinical Area</option>
              <option value="Lab">Lab</option>
              <option value="Theatre">Theatre</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium">Select Specialization <span
              class="text-red-500">*</span></label>
          <div class="relative">
            <select id="specialization" v-model="formData.specialization"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled class="text-gray-400">Select specialization</option>
              <option value="General">General</option>
              <option value="Paediatrics">Paediatrics</option>
              <option value="Maternity / Lab">Maternity / Lab</option>
              <option value="ICU">ICU</option>
              <option value="Ear Nose and Throat(ENT)">Ear Nose and Throat(ENT)</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium">Select Gender <span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="gender" v-model="formData.gender"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled class="text-gray-400">Select gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="All">All</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium">Select Age Group <span
              class="text-red-500">*</span></label>
          <div class="relative">
            <select id="age_group" v-model="formData.age_group"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled class="text-gray-400">Select age group</option>
              <option value="All">All</option>
              <option value="Adult">Adult</option>
              <option value="Children">Children</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium">Select Wing <span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="wing" v-model="formData.wing"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled class="text-gray-400">Select wing</option>
              <option value="General">General</option>
              <option value="Private">Private</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      </form>
      <div class="flex items-center justify-between mt-8">
        
      <button @click="handleBackToDepartment" 
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Back</button>
        <div class="flex items-center ml-auto">
           <button @click="resetForm" 
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Cancel</button>
          <CustomButton v-if="!departments_id" label="Save" bg-class="bg-custom-yellow px-8 py-3 ml-3" @click="handleSubmitNewDepartment" />
          <CustomButton v-if="departments_id" label="Update" bg-class="bg-custom-yellow px-8 py-3 ml-3" @click="handleUpdatedDepartment" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: "NewTheatreDayForm",
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      user: '',
      departments_id: '',
      formData: {
        name: '',        
        department_type: '',
        specialization: '',
        gender: '',
        age_group: '',
        wing: '',
      },
      status: 'active',
      error: null,
    };
  },
  mounted() {
    this.user = this.$store.getters["auth/user"];
    this.departments_id = this.$route.params.departments_id ?? '';
  },
  created() {
    const department_id = this.$route.params.departments_id ?? '';
    console.log('department_id:', department_id);
    if (department_id) {
      this.fetchDepartmentData(department_id);
    }
  },  
  methods: {
    validateForm() {
      const emptyFields = [];
      for (const [key, value] of Object.entries(this.formData)) {
        if (value === '') {
          emptyFields.push(key.replace(/_/g, ' '));
        }
      }
      return emptyFields;
    },
    handleBackToDepartment() {
      this.$router.push({ name: 'DepartmentsPage' });
    },
    async fetchDepartmentData(department) {
      try {
        const response = await api.getDepartment(department);
        const departmentData = response.data || response;
        this.populateFormData(departmentData);
      } catch (error) {
        console.error('Failed to fetch department data:', error);
        this.error = error.message || 'Failed to fetch department data';
        SweetAlertService.showError('Error', 'Failed to fetch department data.');
      }
    },
    populateFormData(departmentData) {
      this.formData.name = departmentData.name || '';
      this.formData.department_type = departmentData.department_type || '';
      this.formData.specialization = departmentData.specialization || '';
      this.formData.gender = departmentData.gender || '';
      this.formData.age_group = departmentData.age_group || '';
      this.formData.wing = departmentData.wing || '';
    },

    async handleSubmitNewDepartment() {
      const data = {
        facility_id: this.user.facility_id,
        name: this.formData.name,
        department_type: this.formData.department_type,
        specialization: this.formData.specialization,
        gender: this.formData.gender,
        age_group: this.formData.age_group,
        wing: this.formData.wing,
      };

      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        // console.log('data:', JSON.stringify(data, null, 2));
        await api.createDepartment(data);
        await SweetAlertService.showSuccess('Success!', 'Department saved successfully!.');
        this.$router.push({ name: 'DepartmentsPage' });
      } catch (error) {
        this.error = error.message || 'Failed to create Department';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', 'Error saving department.');
      }
    },

    resetForm() {
      this.formData = {
        name: '',        
        department_type: '',
        specialization: '',
        gender: '',
        age_group: '',
        wing: '',
      };
    },

    async handleUpdatedDepartment() {
    const data = {
        facility_id: this.user.facility_id,
        name: this.formData.name,
        department_type: this.formData.department_type,
        specialization: this.formData.specialization,
        gender: this.formData.gender,
        age_group: this.formData.age_group,
        wing: this.formData.wing,
      };

      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        await api.updateDepartment(this.departments_id, data);
        await SweetAlertService.showSuccess('Success!', 'Department updated successfully!.');
        this.$router.push({ name: 'DepartmentsPage' });
      } catch (error) {
        this.error = error.message || 'Failed to update Department';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', 'Error updating department.');
      }
  },
  },
};
</script>

<style lang="css" scoped>
/* Your existing styles */
</style>
