// src/services/SweetAlertService.js

import Swal from 'sweetalert2';

export const SweetAlertService = {
    showAlert(options) {
        return Swal.fire(options);
    },

    showSuccess(title, text) {
        return this.showAlert({
            title,
            text,
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#012169',
        });
    },

    showError(title, text) {
        return this.showAlert({
            title,
            text,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#012169',
        });
    },

    showWarning(title, text) {
        return this.showAlert({
            title,
            text,
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#012169',
        });
    },


    showEmptyFieldsAlert(emptyFields) {
        const fieldsSentence = emptyFields.join(', ');

        return this.showAlert({
            title: 'Required Fields',
            html: `
        <p>Please fill in the following fields:</p>
        <p style="color: red;">
          ${fieldsSentence}
        </p>
      `,
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#012169',
        });
    },


    showConfirmation(title, text) {
        return Swal.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#012169',
            cancelButtonColor: '#DA1E28',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        });
    }
};

export default SweetAlertService;