<template>
  <div class="container mx-auto p-8 border border-t-0">
    <h2 class="text-2xl font-bold mb-6">New Donor Information</h2>
    <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="first_name">Enter First Name <span
            class="text-red-500">*</span></label>
        <input id="first_name" v-model="formData.first_name" type="text"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          placeholder="Enter First Name" required />
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="gender">Select gender <span
            class="text-red-500">*</span></label>
        <div class="relative">
          <select id="gender" v-model="formData.gender"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            required>
            <option value="" disabled class="text-gray-400">
              Select gender
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="last_name">Enter Last Name/Other Name <span
            class="text-red-500">*</span></label>
        <input id="last_name" v-model="formData.last_name" type="text"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          placeholder="Enter Last Name/Other Name" required />
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="county">Select county <span
            class="text-red-500">*</span></label>
        <div class="relative">
          <select id="county_id" v-model="formData.county_id"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            required>
            <option value="" disabled>Select county</option>
            <option v-for="county in counties" :key="county.county_id" :value="county.county_id">
              {{ county.name }}
            </option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
            </svg>
          </div>
        </div>
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="dob">Enter year of birth
          <span class="text-red-500">*</span></label>
        <input id="dob" v-model="formData.dob" @change="calculateAge" :max="maxDate" type="date"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          placeholder="Enter year of birth (Your age is:)" required />
      </div>

      <div>
        <label class="block mb-1" for="age">Age(Your age is:<span v-if="formData.age">{{ formData.age }}</span>) <span
            class="text-red-500">*</span></label>
        <input id="age" type="number"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          v-model="formData.age" />
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="blood_group">Select blood group <span
            class="text-red-500">*</span></label>
        <div class="relative">
          <select id="blood_group_id" v-model="formData.blood_group_id"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            required>
            <option value="" disabled>Select blood group</option>
            <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.blood_group_id">
              {{ group.description }}
            </option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="id_number">Enter Donor Unique Identifier/ National ID
          Number
          <span class="text-red-500">*</span></label>
        <input id="id_number" v-model="formData.id_number" type="text"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          placeholder="Enter Donor Unique Identifier/ National ID Number" required />
      </div>

      <div>
        <label class="block mb-2" for="regularDonor">Would you like to become a regular blood donor?
          <span class="text-red-500">*</span></label>
        <div class="relative">
          <select id="regularDonor"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            v-model="formData.enroll_as_donor">
            <option value="" disabled selected>Select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="phone_number">Contact phone number (Needed for official
          communication)
          <span class="text-red-500">*</span></label>
        <input id="phone_number" v-model="formData.phone_number" type="tel"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          placeholder="Contact phone number (Needed for official communication)" required />
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="terms_agreed">Select whether user has agreed with T&Cs
          <span class="text-red-500">*</span></label>
        <div class="relative">
          <select id="terms_agreed" v-model="formData.terms_agreed"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            required>
            <option value="" disabled>
              Select whether user has agreed with T&Cs
            </option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="email">Enter email <span
            class="text-red-500">*</span></label>
        <input id="email" v-model="formData.email" type="email"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          placeholder="Enter email" />
      </div>

      <div v-if="formData.terms_agreed === 'Yes'">
        <label class="block mb-2 text-gray-700 font-medium" for="consent_form">Upload Consent Form (Not
          mandatory)</label>
        <input type="file" id="consent_form" @change="handleFileUpload"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400" />
      </div>
    </form>

    <div class="mt-8 flex justify-between">
      <button @click="handleCancel" class="px-4 py-2 border rounded text-orange-500 border-orange-500">
        Cancel
      </button>
      <div v-if="formData.terms_agreed !== 'No'" class="flex items-center space-x-4 mt-4 sm:mt-0">
        <CustomButton label="Save and record vitals" bg-class="bg-custom-yellow px-8 py-3"
          @click="handleSaveAndRecordVitals" />
        <CustomButton label="Save and Defer" bg-class="bg-custom-red px-8 py-3" @click="handleSaveAndDefer" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from "@/services/api";
import SweetAlertService from "@/services/SweetAlertService";

export default {
  name: "NewDonorRegistrationForm",
  components: {
    CustomButton,
  },
  data() {
    return {
      formData: {
        first_name: "",
        last_name: "",
        dob: "",
        gender: "",
        email: "",
        phone_number: "",
        blood_group_id: "",
        enroll_as_donor: "",
        county_id: "",
        id_number: "",
        terms_agreed: "",
        consent_form: "",
        age: "",
      },
      maxDate: this.calculateMaxDate(),
      bloodGroups: [],
      counties: [],
      facility_id: this.user().facility_id,
      createdBy: this.user().user_id,
    };
  },
  created() {
    this.maxDate = this.calculateMaxDate();
    this.fetchBloodGroups();
    this.fetchRegions();
  },

  watch: {
    "localFormData.donorInformation.dob": "calculateAge",
    localFormData: {
      handler(newValue) {
        this.$emit("update:formData", newValue);
      },
      deep: true,
    },
  },

  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    checkEmptyFields() {
      const emptyFields = [];
      for (const key in this.formData) {
        if (this.formData[key] === "" && key !== "consent_form") {
          emptyFields.push(this.getFieldLabel(key));
        }
      }
      return emptyFields;
    },

    getFieldLabel(key) {
      const labels = {
        first_name: "First Name",
        last_name: "Last Name/Other Name",
        dob: "Date of Birth",
        gender: "Gender",
        email: "Email",
        phone_number: "Contact Phone Number",
        blood_group_id: "Blood Group",
        enroll_as_donor: "Regular Donor",
        county_id: "County",
        id_number: "Donor Unique Identifier/National ID Number",
        terms_agreed: "Terms and Conditions Agreement",
      };
      return labels[key] || key;
    },

    calculateMaxDate() {
      const today = new Date();
      const year = today.getFullYear() - 16;
      const month = today.getMonth() + 1;
      const day = today.getDate();
      return `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""
        }${day}`;
    },

    calculateAge() {
      const dob = this.formData.dob;
      if (!dob) {
        this.formData.age = "";
        return;
      }
      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      this.formData.age = age;
    },

    async handleSaveAndRecordVitals() {
      try {
        const emptyFields = this.checkEmptyFields();
        if (emptyFields.length > 0) {
          await SweetAlertService.showEmptyFieldsAlert(emptyFields);
          return;
        }

        const donorData = {
          user: {
            first_name: this.formData.first_name,
            last_name: this.formData.last_name,
            dob: this.formData.dob,
            gender: this.formData.gender,
            email: this.formData.email,
            phone_number: this.formData.phone_number,
            blood_group_id: this.formData.blood_group_id,
            id_number: this.formData.id_number,
            password: "123456",
            password_confirmation: "123456",
            enroll_as_donor: this.formData.enroll_as_donor,
            county_id: this.formData.county_id,
            terms_agreed: this.formData.terms_agreed,
            consent_form: this.formData.consent_form,
          },
          facility_id: this.facility_id,
        };

        const response = await api.createDonor(donorData);
        console.log(response);
        await SweetAlertService.showSuccess(
          "Success!",
          "Donor information saved successfully!."
        );
        const newDonorId = response.donor_id;
        this.$emit("donor-created", newDonorId);
        this.$emit("move-to-next-step");
      } catch (error) {
        console.error("Error submitting the form:", error);
        await SweetAlertService.showError(
          "Submission Error",
          "An error occurred while saving the donor information."
        );
      }
    },

    async handleSaveAndDefer() {
      try {
        const emptyFields = this.checkEmptyFields();
        if (emptyFields.length > 0) {
          await SweetAlertService.showEmptyFieldsAlert(emptyFields);
          return;
        }

        const donorData = {
          ...this.formData,
          facility_id: this.facility_id,
        };

        const response = await api.createDonor(donorData);
        console.log("Donor creation response:", response);

        const newDonorId = response.donor_id;
        if (!newDonorId) {
          throw new Error("Missing donor_id in response");
        }

        await SweetAlertService.showSuccess(
          "Success!",
          "Donor vitals saved. Continue to defferal form."
        );

        this.$router.push({
          name: "NewDonorDeferralForm",
          params: { donor_id: newDonorId },
        });
      } catch (error) {
        console.error("Error submitting the form for deferral:", error);
        await SweetAlertService.showError(
          "Submission Error",
          "An error occurred while saving and deferring donor vitals."
        );
      }
    },

    handleCancel() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
    },

    handleFileUpload(event) {
      this.formData.consent_form = event.target.files[0];
    },

    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response;
      } catch (error) {
        console.error("Error fetching blood groups:", error);
      }
    },

    async fetchRegions() {
      try {
        const response = await api.getRegions();
        const kenyaRegion = response.regions.find((region) => region.name === 'Kenya');
        this.counties = kenyaRegion ? kenyaRegion.counties : [];
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    },
  },
};
</script>

<style scoped>
/* Add any specific styles here if needed */
</style>
