<template>
  <div class="m-4">
    <h3 class="text-lg font-semibold mb-3">Personal Details</h3>
    <div>
      <div v-if="donor" class="grid grid-cols-2 gap-4">
        <!-- Left Column -->
        <div class="space-y-6">
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">Full Name:</label>
            <p class="text-sm font-normal mt-3">{{ donor.user?.first_name }} {{ donor.user?.last_name }}</p>
          </div>
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">Gender:</label>
            <p class="text-sm font-normal mt-3">{{ donor.user?.gender }}</p>
          </div>
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">D.O.B:</label>
            <p class="text-sm font-normal mt-3">{{ donor.user?.dob }}</p>
          </div>
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">Donor Status:</label>
            <p class="text-sm font-normal mt-3">{{ donor.donor_status }}</p>
          </div>

        </div>
        <!-- Right Column -->
        <div class="space-y-6">
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">Patient Name:</label>
            <p class="text-sm font-normal mt-3">{{ donor.patient }}</p>
          </div>
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">Phone</label>
            <p class="text-sm font-normal mt-3">{{ donor.user?.phone_number }}</p>
          </div>
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">Email:</label>
            <p class="text-sm font-normal mt-3">{{ donor.user?.email }}</p>
          </div>
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">Donation Type:</label>
            <p class="text-sm font-normal mt-3">{{ donor.donation_status }}</p>
          </div>
        </div>
      </div>
      <div v-else>Loading donor details...</div>

      <h3 class="text-lg font-semibold mt-5 mb-3">Donor Contact Information</h3>

      <div v-if="donor" class="grid grid-cols-2 gap-4">
        <!-- Left Column -->
        <div class="space-y-6">
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">County:</label>
            <p class="text-sm font-normal mt-3">{{ donor.county_id?.name }}</p>
          </div>
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">address:</label>
            <p class="text-sm font-normal mt-3"> {{ donor.physical_address }}</p>
          </div>
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">work phone:</label>
            <p class="text-sm font-normal mt-3">{{ donor.work_phone }}</p>
          </div>


        </div>

        <!-- Right Column -->
        <div class="space-y-6">
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">Enroll as donor:</label>
            <p class="text-sm font-normal mt-3">{{ donor.enroll_as_donor }}</p>
          </div>
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">Appointment date:</label>
            <p class="text-sm font-normal mt-3">{{ (donor.donation_bookings && donor.donation_bookings.length > 0) ?
              donor.donation_bookings[0].preferred_date : 'N/A' }}</p>
          </div>
          <div class="grid grid-cols-2 items-center border-t m-2">
            <label class="text-sm font-normal mt-3">Appointment Time:</label>
            <p class="text-sm font-normal mt-3">{{ (donor.donation_bookings && donor.donation_bookings.length > 0) ?
              donor.donation_bookings[0].preferred_time : 'N/A' }}</p>
          </div>

        </div>
      </div>
      <div v-else>Loading donor details...</div>

      <h3 class="text-lg font-semibold mt-7 mb-3">Donor Medical Information</h3>

      <table v-if="donor" class="min-w-full mt-5 bg-white border border-gray-200">
        <thead>
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b border-gray-200 w-1/7"> Healthy</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Weigh over 50kgs</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Taking flight</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Active allergy</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Severe disease</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">On medication</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Donated blood / Components</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Had covid-19 vaccination</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td :class="{ 'text-red-500': !donor.donor_questionnaire?.feeling_healthy }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.feeling_healthy ? 'Yes' : 'No' }}
            </td>
            <td :class="{ 'text-red-500': !donor.donor_questionnaire?.minimum_weight }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.minimum_weight ? 'Yes' : 'No' }}
            </td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.take_flight }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.take_flight ? 'Yes' : 'No' }}
            </td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.active_allergy }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.active_allergy ? 'Yes' : 'No' }}
            </td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.severe_disease }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.severe_disease ? 'Yes' : 'No' }}
            </td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.taking_medication }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.taking_medication ? 'Yes' : 'No' }}
            </td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.donated_blood_component }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.donated_blood_component ? 'Yes' : 'No' }}
            </td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.covid_vaccine }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.covid_vaccine ? 'Yes' : 'No' }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>Loading donor details...</div>
      <table v-if="donor" class="min-w-full mt-5 bg-white border border-gray-200">
        <thead>
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b border-gray-200 w-1/7"> Taken alcohol</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Taken hard Drugs</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Had any surgery</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Had any dental work</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Had infection exposure</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Had blood transfusion /body piercings</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Unexplained sickness</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Had Yellow Fever vaccination</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.had_alcohol }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.had_alcohol ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.had_drugs }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.had_drugs ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.had_surgery }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.had_surgery ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.dental_work }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.dental_work ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.infection_exposure }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.infection_exposure ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.had_body_piercing }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.had_body_piercing ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.unexplained_sickness }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.unexplained_sickness ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.yellow_fever_vaccine }"
            class="px-4 py-2 border-b border-gray-200 w-1/7">
            {{ donor.donor_questionnaire?.yellow_fever_vaccine ? 'Yes' : 'No' }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else>Loading donor details...</div>
      <table v-if="donor" class="min-w-full mt-5 bg-white border border-gray-200">
        <thead>
          <tr class="bg-gray-100 text-left">
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Had hepatitis /Jaundice</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Relative/friends hepatitis</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Had immunization /vaccination</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Had Hepatitis B or Rabies vaccination</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Had a medical condition</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">HIV/AIDS pos</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Have blood disease/bleeding tendencies</th>
            <th class="px-4 py-2 border-b border-gray-200 w-1/7">Been rejected as a donor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.pos_for_Hepatitis_C }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.pos_for_Hepatitis_C ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.relative_has_hepatitis }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.relative_has_hepatitis ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.immunization_vaccinations }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.hepatitis_rabies_vaccine ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.hepatitis_rabies_vaccine }"
            class="px-4 py-2 border-b border-gray-200 w-1/7">
            {{ donor.donor_questionnaire?.immunization_vaccinations ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.medical_condition }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.medical_condition ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.positive_for_HIV }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.positive_for_HIV ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.bleeding_condition }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.bleeding_condition ? 'Yes' : 'No' }}</td>
            <td :class="{ 'text-red-500': donor.donor_questionnaire?.rejected_as_donor }"
              class="px-4 py-2 border-b border-gray-200 w-1/7">
              {{ donor.donor_questionnaire?.rejected_as_donor ? 'Yes' : 'No' }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else>Loading donor details...</div>
      <div class="m-20">
        <p>Donor Declaration</p>
        <ul v-if="donor">
          <li class="text-gray-500">a. I have read and understood the pamphlet on "BLOOD
            DONATION":<b>{{ donor.declaration?.read_educational_material ? 'Yes' : 'No' }}</b> </li>
          <li class="text-gray-500"> b. I have accurately answered each of the above questions to the best of my
            knowledge and voluntarily donate blood: <b>{{ donor.declaration?.consent_checkbox ? 'Yes' : 'No' }}</b>
          </li>
          <li class="text-gray-500">c. I understand that my blood will be used for treatment of patients and consent to
            my blood being tested for HIV, Hepatitis B, Hepatitis C, VDRL and Malaria:
            <b>{{ donor.declaration?.blood_testing_consent ? 'Yes' : 'No' }}</b>
          </li>
          <li class="text-gray-500">d. I understand that I will be informed of my test results if I choose to:
            <b>{{ donor.declaration?.terms_agreed ? 'Yes' : 'No' }}</b>
          </li>
          <li class="text-gray-500">e. I consent to further communication by Hospital Donor Center regarding future
            donations: <b>{{ donor.declaration?.consent_form ? 'Yes' : 'No' }}</b> </li>
        </ul>
        <div v-else>Loading donor details...</div>

        <form>

          <div class="col-span-full">
            <label for="about" class="block text-sm mt-6 font-medium leading-6 text-gray-900">Doctor's Remarks</label>
            <div class="mt-2">
              <textarea id="about" name="about" rows="3"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 bg-gray-100 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
            </div>
          </div>

          <div class="mt-8 flex justify-between">
            <button @click="goToPreviousPage"
              class="px-4 py-2 border rounded text-orange-500 border-orange-500">Back</button>
            <div v-if="donor && donor.donor_questionnaire?.status === 'New'"
              class="flex items-center space-x-4 mt-4 sm:mt-0">
              <CustomButton label="Save and record vitals" bg-class="bg-custom-yellow"
                @click="handleSaveAndRecordVitals" />
              <CustomButton label="Save and defer" bg-class="bg-custom-yellow" @click="handleSaveAndDefer" />
              <CustomButton label="Edit" bg-class="bg-custom-yellow" @click="editQuestionnaire(donor.donor_id)" />
            </div>
            <div v-else>
              <CustomButton label="Reinstate" bg-class=" px-4 py-3 bg-custom-yellow" @click="handleReinstate" />
            </div>

          </div>
        </form>
      </div>

    </div>

  </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue';
import api from '@/services/api';
import { SweetAlertService } from '@/services/SweetAlertService';


export default {
  name: 'DonorQuestionnaireInformation',
  components: {
    CustomButton
  },

  data() {
    return {
      donor: null,
      error: null,
    }
  },

  created() {
    this.getDonors();
  },

  methods: {
    editQuestionnaire(donorId) {
      console.log('Passing donor:', donorId);
      this.$router.push({
        name: 'EditDonorQuestionnairePage',
        params: { donor_id: donorId }
      });
    },

    handleSaveAndRecordVitals() {
      this.$router.push({
        name: 'NewDonorVitalsForm',
        params: { donor_id: this.$route.params.donor_id }
      });
    },

    handleSaveAndDefer() {
      this.$router.push({
        name: 'NewDonorDeferralForm',
        params: { donor_id: this.$route.params.donor_id }
      });
    },

    async handleReinstate() {
      try {
        const donorId = this.$route.params.donor_id;
        console.log('Donor ID:', donorId);

        const data = { status: "New" };
        console.log('Payload being sent:', data);

        // Call the API to update the donor questionnaire status
        const response = await api.updateDonorQuestionnaireStatus(donorId, data);

        this.donor = response?.data || response;
        // console.log('Updated donor questionnaire status:', this.donor);
        await SweetAlertService.showSuccess('Reinstated', 'Questionnaire Reinstated successfully.');
        this.$router.push({ name: 'DonorManagementPage' });
      } catch (error) {
        console.error('Error updating donor questionnaire status:', error);
        this.error = error.response?.data?.message || error.message ||
          'Failed to update donor questionnaire status';
      }
    },


    goToPreviousPage() {
      this.$router.push({
        name: 'DonorManagementPage',
      });
    },

    async getDonors() {
      try {
        const donorId = this.$route.params.donor_id;
        console.log('Fetching details for donor ID:', donorId);
        const response = await api.getDonor(donorId);
        this.donor = response.data || response;
        console.log('data:', this.donor);
      } catch (error) {
        this.error = error.message || 'Failed to fetch donors';
      }
    },
  },
}

</script>
<style scoped></style>