<template>
  <header class="flex items-center justify-between px-6 py-2 bg-white shadow-md mb-3">
    <div class="flex items-center">
      <!-- Back Arrow Icon, shown only on NewDonorRegistrationForm page -->
      <button v-if="showBackArrow" @click="goBack" class="mr-4 focus:outline-none">
        <svg class="w-6 h-6 text-custom-blue" fill="none" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
        </svg>
      </button>
      <div class="relative">
        <h3 class="font-custom-font-wight leading-custom-title-line-height text-custom-title-size text-custom-blue">
          {{ pageTitle }}
        </h3>
      </div>
    </div>

    <div class="flex items-center">
      <button class="relative flex mx-4 text-gray-600 focus:outline-none">
        <svg class="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15 17H20L18.5951 15.5951C18.2141 15.2141 18 14.6973 18 14.1585V11C18 8.38757 16.3304 6.16509 14 5.34142V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V5.34142C7.66962 6.16509 6 8.38757 6 11V14.1585C6 14.6973 5.78595 15.2141 5.40493 15.5951L4 17H9M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17M15 17H9"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <!-- Notification Badge -->
        <span
          class="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-xs font-medium text-white bg-red-500 border border-white rounded-full translate-x-1/4 -translate-y-1/4">
          2
        </span>
      </button>

      <div class="relative ml-1">
        <button @click="dropdownOpen = !dropdownOpen"
          class="relative flex items-center w-18 h-12 overflow-hidden focus:outline-none">
          <img class="object-cover w-12 h-12 rounded-full" src="@/assets/images/avatar.png" alt="Your avatar" />
          <svg class="w-8 h-8 text-gray-800 dark:text-black ml-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
            width="20" height="20" fill="currentColor" viewBox="0 0 28 28">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m8 10 4 4 4-4" />
          </svg>
        </button>

        <div v-show="dropdownOpen" @click="dropdownOpen = false" class="fixed inset-0 z-10 w-full h-full"></div>

        <transition enter-active-class="transition duration-150 ease-out transform"
          enter-from-class="scale-95 opacity-0" enter-to-class="scale-100 opacity-100"
          leave-active-class="transition duration-150 ease-in transform" leave-from-class="scale-100 opacity-100"
          leave-to-class="scale-95 opacity-0">
          <div v-show="dropdownOpen" class="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl">
            <button href="#"
              class="block px-4 py-2 text-sm w-full text-gray-700 hover:bg-custom-blue hover:text-white" @click="redirectProfilePage">Profile</button>
            <button class="block px-4 py-2 w-full text-sm text-gray-700 hover:bg-custom-blue hover:text-white"
              @click="handleLogout">
              Log out
            </button>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "HeaderLayout",
  setup() {
    const dropdownOpen = ref(false);
    const route = useRoute();
    const router = useRouter();

    const pageTitle = computed(() => {
      return route.meta.title || "Blood Management System";
    });

    const showBackArrow = computed(() => {
      return route.name === 'NewDonorRegistrationPage' || route.name === 'DonorDetailsPage' || route.name === 'EditDonorInformationPage'
        || route.name === 'NewRecordDonationForm' || route.name === 'NewDonorVitalsForm' || route.name === 'NewDonorDeferralForm' || route.name === 'NewReactionForm'
        || route.name === 'SupervisorDocumentsDetailsPage' || route.name === 'SupervisorAssessementDetailsPage';
    });

    const goBack = () => {
      router.go(-1); // Navigate back to the previous page
    };

    const handleLogout = () => {
      localStorage.removeItem('token');
      router.push("/");
    };

    return {
      pageTitle,
      showBackArrow,
      goBack,
      handleLogout,
      dropdownOpen
    };
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (!token || this.isTokenExpired(token)) {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    isTokenExpired(token) {
      try {
        // Decode the token (assuming it's a JWT)
        const payload = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Math.floor(Date.now() / 1000);

        // If token's expiration time is less than current time, it's expired
        return payload.exp && payload.exp < currentTime;
      } catch (error) {
        console.error('Failed to decode token:', error);
        return true; // Treat any decoding errors as an invalid token
      }
    },
    redirectProfilePage() {
      this.$router.push({ name: 'ProfilePage' });
    },
  }
};
</script>

<style lang="css" scoped>
/* Your existing styles */
</style>
