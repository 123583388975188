<template>
    <div>
        <div class="w-11/12 mx-auto mt-4 h-full">
            <div class="bg-white rounded-lg shadow-md px-10 py-4 mx-auto mt-4 overflow-y-scroll h-[80vh]">
                <!-- Loop over all questions and display them with selected options -->
                <div v-for="(question, index) in questions" :key="question.question_id" class="mb-3 py-4 border-b">
                    <div class="mb-6">
                        <p class="font-bold text-xl text-gray-800">Question {{ index + 1 }}/{{ questions.length }}</p>
                    </div>
                    <div class="mb-6">
                        <p>{{ question.question }}</p>
                    </div>

                    <!-- Display the options with the selected answer checked -->
                    <div class="space-y-4">
                        <div v-for="option in [question.option_1, question.option_2, question.option_3, question.option_4]"
                            :key="option">
                            <label class="flex items-center cursor-pointer" :class="{
                                'bg-green-200': option === question.answer,
                                'bg-red-200': option === question.selected_answer && question.selected_answer !== question.answer
                            }" style="padding: 0.5rem; border-radius: 0.25rem;">
                                <input type="radio" :name="'question-' + question.question_id" :value="option"
                                    :checked="question.selected_answer === option" class="mr-2" disabled />
                                {{ option }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-end mt-6">
                <div>
                    <button class="bg-custom-yellow hover:bg-yellow-600 text-white py-2 px-4 rounded"
                        @click="handleBackToAssessmentPage">
                        Back
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/services/api';

export default {
    name: "ExamTestReviewPage",
    data() {
        return {
            questions: [],
        };
    },
    created() {
        this.assessment_id = this.$route.params.assessment_id;
        this.fetchQuestions();
    },
    methods: {
        async fetchQuestions() {
            try {
                const response = await api.getUserDoneAssessmentTest(this.assessment_id);
                this.questions = response.data || response;
            } catch (error) {
                console.error('Failed to fetch questions:', error.message || error);
            }
        },
        handleBackToAssessmentPage() {
            this.$router.push({
                name: "StaffLearningManagementPage"
            });
        },
    },
};
</script>

<style scoped>
.overflow-y-scroll {
    overflow-y: scroll;
}
</style>
