<template>
  <div>
    <AlertLayout title="Reminders!" message="-Kindly record the correct details." type="warning" />
    <div class="mx-auto p-10 border border-t-0">
      <h2 class="text-lg font-bold mb-6">Add New Reaction</h2>

      <!-- New Reaction Form -->
      <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div class="space-y-5">
          <div>
            <label class="block mb-1" for="hb">HB <span class="text-red-500">*</span></label>
            <input v-model="reactionForm.hb" type="text" id="hb"
              class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300">
          </div>
          <div>
            <label class="block mb-1" for="reactionType">Reaction Type <span class="text-red-500">*</span></label>
            <MultiSelectDropdown v-model="reactionForm.reaction_type" :options="reactionTypeOptions"
              placeholder="Select Reaction Types" />
          </div>
        </div>
 
        <div class="space-y-5">
          <div>
            <label class="block mb-1" for="reactionBroadType">Reaction Broad Type <span
                class="text-red-500">*</span></label>
            <select v-model="reactionForm.reaction_broad_type" id="reactionBroadType"
              class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300">
              <option value="" disabled>Select Broad Reaction Type</option>
              <option value="Acute">Acute</option>
              <option value="Adverse">Adverse</option>
              <option value="Unknown">Unknown</option>
            </select>
          </div>
          <div>
            <label class="block mb-1" for="timeOfReaction">Time of Reaction <span class="text-red-500">*</span></label>
            <input v-model="reactionForm.time_of_reaction" type="datetime-local" id="timeOfReaction"
              class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300">
          </div>
        </div>

        <div class="md:col-span-2 flex justify-end space-x-2 mt-5">
          <CustomButton label="Cancel" bg-class="dark:text-custom-yellow border-2 border-custom-yellow px-8 py-2"
            @click="clearReactionForm" />
          <CustomButton label="Submit" bg-class="bg-custom-yellow px-8 py-3" @click="submitReactionForm" />
        </div>
      </form>
      <!-- End New Reaction Form -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import MultiSelectDropdown from "@/components/MultiSelectDropdown.vue";
import api from '@/services/api';
import SweetAlertService from "@/services/SweetAlertService";


export default {
  name: "NewDonationReactionForm",
  components: {
    AlertLayout,
    CustomButton,
    MultiSelectDropdown,
  },
  data() {
    return {
      reactionForm: {
        hb: '',
        reaction_broad_type: '',
        time_of_reaction: this.getCurrentDateTime(),
        reaction_type: [],
        // recorded_by: this.user.user_id,
      },
      reactionTypeOptions: [
        'IV line not working',
        'Anxiety',
        'Chills',
        'Nausea and vomiting',
        'Urticaria',
        'Fever',
        'Hyperbilirubinaemia / Ulcers',
        'Pain',
        'Dyspnea without hypoxia',
        'Bacterial Contamination',
        'Allergic',
        'Laboratory confirmed ABO incompatibility',
        'Hemolytic',
        'Hypotension / Shock',
        'Acute Pulmonary Edema',
        'Transfusion-related Acute Lung Injury (TRALI)',
        'Oliguria / Anuria',
        'Death',
        'Increased Bp',
        'Increased Pulse & Resps',
        'Shivering',
        'Wheezing',
        'Itching',
        'Hives',
        'Joint Pain',
        'Back Pain',
        'Increased Temperature',
        'Lower Back Pain',
        'Headache',
        'Hematemesis'
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },
    async submitReactionForm() {

      const emptyFields = [];

      // Check if the required fields are empty
      if (!this.reactionForm.hb) emptyFields.push('HB');
      if (!this.reactionForm.reaction_broad_type) emptyFields.push('Reaction Broad Type');
      if (!this.reactionForm.reaction_type.length) emptyFields.push('Reaction Type');
      if (!this.reactionForm.time_of_reaction) emptyFields.push('Time of Reaction');

      // Show an alert if any fields are empty
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        const donorId = this.$route.params.donor_id;
        const payload = {
          donation_reaction: {
            hb: this.reactionForm.hb,
            reaction_broad_type: this.reactionForm.reaction_broad_type,
            time_of_reaction: this.reactionForm.time_of_reaction,
            recorded_by: this.user.user_id,
            reaction_type: this.reactionForm.reaction_type,
          }
        };

        await api.createDonationReaction(donorId, payload);
        await SweetAlertService.showSuccess('Success', 'Reaction recorded successfully!');
        this.clearReactionForm();
        this.$router.push({ name: 'DonorDetailsPage' });
      } catch (error) {
        console.error('Error creating reaction:', error);
        SweetAlertService.showError('Error', 'Failed to create reaction. Please try again.');
      }
    },
    clearReactionForm() {
      this.reactionForm = {
        hb: '',
        reaction_broad_type: '',
        time_of_reaction: this.getCurrentDateTime(),
        reaction_type: [],
        recorded_by: this.user.user_id,
      };
    },
    getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = ('0' + (now.getMonth() + 1)).slice(-2);
      const day = ('0' + now.getDate()).slice(-2);
      const hours = ('0' + now.getHours()).slice(-2);
      const minutes = ('0' + now.getMinutes()).slice(-2);
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },
  },
};
</script>

<style scoped>
/* Add any scoped styles here if needed */
</style>
