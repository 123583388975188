import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/);

const modules = requireContext.keys().reduce((acc, file) => {
  const moduleName = file.replace(/(^.\/)|(\.js$)/g, '');
  const module = requireContext(file).default;

  if (module.namespaced === undefined) {
    module.namespaced = true;
  }

  return { ...acc, [moduleName]: module };
}, {});

const store = createStore({
  modules,
  plugins: [createPersistedState()],
});

export default store;
