<template>
  <div>
    <AlertLayout
      title="Reminders!"
      message="Incorrect information will lead to nurses being unable to request for blood except for theatre."
      type="warning"
    />
    <div class="mx-auto p-10 border border-t-0">
      <h2 class="text-lg font-bold mb-6">Add new theatre only day</h2>
      <form class="grid grid-cols-1 md:grid-cols-1 gap-5">
        <div>
          <label class="block mb-1" for="theatreDay">Select theatre only day <span class="text-red-500">*</span></label>
          <input type="date"
            v-model="formData.theatreDay"
            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Select theatre only day" required>
        </div>
        <div>
          <label class="block mb-1" for="reason">Reason for theatre only here <span class="text-red-500">*</span></label>
          <input type="text"
            v-model="formData.reason"
            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Reason for theatre only here" required>
        </div>
      </form>
      <div class="flex items-center justify-between mt-8">
        <CustomButton
          label="Back"
          bg-class="dark:text-custom-yellow border-2 border-custom-yellow px-8 py-2"
          @click="handleBackToTheatreDay"
        />
        <CustomButton
          label="Save"
          bg-class="bg-custom-yellow px-8 py-3"
          @click="handleSubmitNewTheatreDay"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: "NewTheatreDayForm",
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      user: '',
      formData: {
        theatreDay: '',
        reason: '',
      },
      status: 'active',
      error: null,
    };
  },
  mounted() {
    this.user = this.$store.getters["auth/user"];
  },
  methods: {
    validateForm() {
      const emptyFields = [];
      for (const [key, value] of Object.entries(this.formData)) {
        if (value === '') {
          emptyFields.push(key.replace(/_/g, ' '));
        }
      }
      return emptyFields;
    },
    handleBackToTheatreDay() {
      this.$router.push({ name: 'TheatreDaysManagementPage' });
    },
    async handleSubmitNewTheatreDay() {
      const data = {
        facility_id: this.user.facility_id,
        date_applicable: this.formData.theatreDay,
        reason: this.formData.reason,
        created_by: this.createdBy,
        updated_by: this.updatedBy,
        status: this.status,
      };

      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        // console.log('data:', data);
        await api.createTheatreDay(data);
        await SweetAlertService.showSuccess('Success!', 'Schedule saved successfully!.');
        this.$router.push({ name: 'TheatreDaysManagementPage' });
      } catch (error) {
        this.error = error.message || 'Failed to create theatre only days';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', 'Error saving theatre days.');
      }
    },
  },
};
</script>

<style lang="css" scoped>
/* Your existing styles */
</style>
