const state = {
  user: null,
  token: null,
  roles: [],
  claims: [],
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  setClaims(state, claims) {
    state.claims = claims;
  },
};

const actions = {
  login({ commit }, { data, token, roles }) {
    commit('setUser', data);
    commit('setToken', token);
    commit('setRoles', roles);
    
    // Extract and set claims based on roles
    const claims = roles.flatMap(role => role.claims);
    commit('setClaims', claims);
  },

  logout({ commit }) {
    commit('setUser', null);
    commit('setToken', null);
    commit('setRoles', []);
    commit('setClaims', []);
  },
};

const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
  roles: (state) => state.roles,
  claims: (state) => state.claims,
  hasRole: (state) => (roleName) => {
    return state.roles.some((role) => role.name === roleName);
  },
  hasClaim: (state) => (claim) => {
    return state.claims.includes(claim);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
