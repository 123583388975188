<template>
    <div>
        <div class="w-11/12 mx-auto mt-4 h-full">
            <div class="bg-white rounded-lg shadow-md px-10 py-4 mx-auto mt-4 overflow-y-scroll h-[80vh]">
                <div v-for="(question, index) in questions" :key="question.question_id" class="mb-3 py-4 border-b">
                    <div class="mb-6 flex justify-between items-center">
                        <p class="font-bold text-xl text-gray-800">Question {{ index + 1 }}/{{ questions.length }}</p>
                        <!-- <button 
                            v-if="!question.isEditing" 
                            @click="startEditing(question)" 
                            class="text-blue-500 hover:text-blue-700"
                        >
                            Edit
                        </button> -->
                    </div>
                    
                    <div v-if="!question.isEditing" class="mb-6">
                        <p>{{ question.question }}</p>
                    </div>
                    
                    <!-- Editing Mode -->
                    <div v-else class="mb-6">
                        <input 
                            v-model="question.editedQuestion" 
                            class="w-full border rounded p-2 mb-4"
                            placeholder="Edit question text"
                        />
                    </div>
                    
                    <!-- Options Display/Edit -->
                    <div class="space-y-4">
                        <div v-for="optionKey in ['option_1', 'option_2', 'option_3', 'option_4']" :key="optionKey">
                            <label v-if="!question.isEditing" class="flex items-center cursor-pointer" :class="{
                                'bg-green-200': question[optionKey] === question.answer,
                                'bg-red-200': question[optionKey] === question.selected_answer && question.selected_answer !== question.answer
                            }" style="padding: 0.5rem; border-radius: 0.25rem;">
                                <input 
                                    type="radio" 
                                    :name="'question-' + question.question_id" 
                                    :value="question[optionKey]"
                                    :checked="question.selected_answer === question[optionKey]" 
                                    class="mr-2" 
                                    disabled 
                                />
                                {{ question[optionKey] }}
                            </label>
                            
                            <!-- Editing Options -->
                            <div v-else class="flex items-center mb-2">
                                <input 
                                    type="radio" 
                                    :name="'edit-' + question.question_id" 
                                    v-model="question.editedAnswer"
                                    :value="question['edited' + optionKey.charAt(0).toUpperCase() + optionKey.slice(1)]"
                                    class="mr-2"
                                />
                                <input 
                                    v-model="question['edited' + optionKey.charAt(0).toUpperCase() + optionKey.slice(1)]" 
                                    class="flex-grow border rounded p-2"
                                    :placeholder="`Edit ${optionKey}`"
                                />
                            </div>
                        </div>
                    </div>
                    
                    <!-- Edit Mode Action Buttons -->
                    <div v-if="question.isEditing" class="flex justify-end mt-6 mt-4 flex space-x-2">
                        <button 
                            @click="cancelEdit(question)" 
                            class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                        >
                            Cancel
                        </button>
                        <button 
                            @click="saveEdit(question)" 
                            class="bg-custom-yellow text-white px-4 py-2 rounded hover:bg-orange-500"
                        >
                            Saved
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex justify-end mt-6">
                <button 
                    class="bg-custom-yellow hover:bg-orange-600 text-white py-2 px-4 rounded"
                    @click="handleBackToAssessmentPage"
                >
                    Back
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
    name: "QuestionsReviewPage",
    data() {
        return {
            questions: [],
            editedQuestions: [],  // New property to track edited questions
            assessment_id: null
        };
    },
    created() {
        this.assessment_id = this.$route.params.assessment_id;
        this.fetchQuestions();
    },
    methods: {
        async fetchQuestions() {
            try {
                const response = await api.getQuestions(this.assessment_id);
                this.questions = (response.data || response).map(q => ({
                    ...q, 
                    isEditing: false,
                    editedQuestion: q.question,
                    editedOption_1: q.option_1,
                    editedOption_2: q.option_2,
                    editedOption_3: q.option_3,
                    editedOption_4: q.option_4,
                    editedAnswer: q.answer
                }));
            } catch (error) {
                console.error('Failed to fetch questions:', error.message || error);
                SweetAlertService.showError('Error', 'Failed to load questions');
            }
        },
        startEditing(question) {
            // Reset any previous edits
            question.editedQuestion = question.question;
            question.editedOption_1 = question.option_1;
            question.editedOption_2 = question.option_2;
            question.editedOption_3 = question.option_3;
            question.editedOption_4 = question.option_4;
            question.editedAnswer = question.answer;

            // Enter editing mode
            question.isEditing = true;
        },
        cancelEdit(question) {
            question.isEditing = false;
        },
        async saveEdit(question) {
            try {
                // Push the edited question to the array
                const editedQuestion = {
                    question_id: question.question_id,
                    question: question.editedQuestion,
                    option_1: question.editedOption_1,
                    option_2: question.editedOption_2,
                    option_3: question.editedOption_3,
                    option_4: question.editedOption_4,
                    answer: question.editedAnswer
                };
                this.editedQuestions.push(editedQuestion);

                // Check if all questions are saved
                if (this.editedQuestions.length === this.questions.length) {
                    // Prepare the payload for API
                    const payload = {
                        questions: this.editedQuestions
                    };

                    // Send update request
                    await api.updateQuestion(payload);

                    // Update local state for all edited questions
                    this.editedQuestions.forEach((editedQuestion) => {
                        const questionToUpdate = this.questions.find(q => q.question_id === editedQuestion.question_id);
                        if (questionToUpdate) {
                            questionToUpdate.question = editedQuestion.question;
                            questionToUpdate.option_1 = editedQuestion.option_1;
                            questionToUpdate.option_2 = editedQuestion.option_2;
                            questionToUpdate.option_3 = editedQuestion.option_3;
                            questionToUpdate.option_4 = editedQuestion.option_4;
                            questionToUpdate.answer = editedQuestion.answer;
                            questionToUpdate.isEditing = false; // Exit editing mode
                        }
                    });

                    // Clear the edited questions array
                    this.editedQuestions = [];

                    // Show success message
                    SweetAlertService.showSuccess('Success', 'Questions updated successfully');
                }
            } catch (error) {
                console.error('Failed to update questions:', error.message || error);
                SweetAlertService.showError('Error', error.message || 'Failed to update questions');
            }
        },
        handleBackToAssessmentPage() {
            this.$router.push({ name: 'SupervisorAssessementDetailsPage', params: { assessment_id: this.assessment_id } });
        }
    }
};
</script>

<style scoped>
.overflow-y-scroll {
    overflow-y: scroll;
}
</style>