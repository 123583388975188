<template>
  <div>
    <AlertLayout
      title="Reminders!"
      message="Kindly note that Jiji-Health reserves the right to make any additional changes to existing information. <br>
       Kindly note that the changes will only be done via a written email detailing approved changes sent to Jiji Health <br>
       Changes to package currently activated may however be made" 
      type="warning"
    />
    <div class="tabs flex border-b mb-4">
      <button
        class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'departments' }"
        @click="currentTab = 'departments'"
      >
        My Profile
      </button>
      <button v-if="hasManageClaim('Manage Users')"
        class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'bed-types' }"
        @click="currentTab = 'bed-types'"
      >
        Facility Profile
      </button>
    </div>
    <component
      :is="currentTabComponent"
      :search-query="searchQuery"
    ></component>
  </div>
</template>
  
  <script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import MyProfileTab from "../tabs/MyProfileTab.vue";
import FaciltyProfileTab from "../tabs/FaciltyProfileTab.vue";

export default {
  name: "MyProfileTab.",
  components: {
    AlertLayout,
    CustomButton,
    MyProfileTab,
    FaciltyProfileTab,
  },
  created() {},
  data() {
    return {
      searchQuery: "",
      isDropdownOpen: false,
      currentTab: "departments",
      user: [],
    };
  },
  computed: {
    currentTabComponent() {
      switch (this.currentTab) {
        case "blood-units":
          return "MyProfileTab";
        case "bed-types":
          return "FaciltyProfileTab";
        default:
          return "MyProfileTab";
      }
    },
  },
  props: {},
  methods: {
    handleNewDepartment() {
      this.$router.push({ name: 'NewDepartmentForm' });
    },
    handleNewBedType() {
      this.$router.push({ name: 'NewBedTypeForm' });
    },
    handleNewBed() {
      this.$router.push({ name: 'NewBedForm' });
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    // token() {
    //   return this.$store.getters["auth/token"];
    // },
    hasManageClaim(value) {
      const claims = this.user?.user_roles?.[0]?.role?.claims || [];
      return claims.some((claim) => claim.name === value);
    },
  },
  mounted() {
    this.user = this.$store.getters["auth/user"]; 
  }
};
</script>
  
  <style lang="css" scoped>
</style>