<template>
    <div v-if="visible" :class="alertClasses" role="alert">
      <div class="flex items-center">
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
        </svg>
        <span class="sr-only">{{ type }}</span>
        <h3 class="text-md px-2 font-semibold text-gray-800">{{ title }}</h3>
        <button type="button" class="ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex text-gray-800 items-center justify-center h-8 w-8" @click="dismiss" aria-label="Close">
          <span class="sr-only">Dismiss</span>
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
        </button>
      </div>
      <div class="mb-3 px-8 text-gray-800" :class="{ 'mt-4': hasMargin }" v-html="message">
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'EducarionalAlerts',
    props: {
      title: {
        type: String,
        required: true
      },
      message: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: 'info'
      },
      hasMargin: {
      type: Boolean,
      default: false,
    },
    },
    data() {
      return {
        visible: true
      };
    },
    computed: {
      alertClasses() {
        return {
          'py-2 px-4 mb-4 border-l-4': true,
          'text-custom-yellow border-custom-yellow': this.type === 'warning',
          'dark:bg-orange-50': this.type === 'warning'
        };
      }
    },
    methods: {
      dismiss() {
        this.visible = false;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add any specific styles here if needed */
  </style>
  