// const BASE_URL = "https://138.68.143.110/"; //Dev environment
const BASE_URL = "https://142.93.106.158/"; //Live environment
// const BASE_URL = 'http://127.0.0.1:3000/';

const getToken = () => localStorage.getItem("token");

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json();
    // Throwing an error with a detailed message
    throw new Error(errorData.message || errorData.errors?.[0]);
  }
  return response.json();
};

const fetchApi = async (endpoint, method = "GET", data) => {
  const token = getToken();
  const options = {
    method,

    headers: {
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    ...(data && { body: JSON.stringify(data) }),
  };
  const response = await fetch(`${BASE_URL}/${endpoint}`, options);
  return handleResponse(response);
};

// CRUD operations
const create = (resource, data) => fetchApi(resource, "POST", data);
const update = (resource, id, data) =>
  fetchApi(`${resource}/${id}`, "PATCH", data);
const get = (resource, id) => fetchApi(id ? `${resource}/${id}` : resource);
const remove = (resource, id) => fetchApi(`${resource}/${id}`, "DELETE");

// API client object
const apiClient = {
  login: async (credentials) => {
    const response = await fetchApi("/login", "POST", credentials);
    localStorage.setItem("token", response.token);
    localStorage.setItem("user", JSON.stringify(response.user));
    return response;
  },

  // User management
  getUsers: () => get("users"),
  getUser: (id) => get("users", id),
  createUser: (data) => create("users", data),
  updateUser: (id, data) => update("users", id, data),
  updatePassword: (id, data) => update("users/update_password", id, data),

  // Facilities management
  getFacilities: () => get("facilities"),
  getFacility: (id) => get("facilities", id),
  createFacility: (data) => create("facilities", data),
  createFacilityWithAdmin: (data) => create("facilities/admin", data),
  updateFacility: (id, data) => update("facilities", id, data),

  // Facilities management
  getReferralFacilities: () => get("referral_facilities"),
  getReferralFacilityById: (id) => get(`referral_facilities/${id}`),
  createReferralFacility: (data) => create("referral_facilities", data),
  updateReferralFacility: (id, data) =>
    update(`referral_facilities/${id}`, data),

  // Departments management
  getDepartments: () => get("departments"),
  getDepartment: (id) => get("departments", id),
  createDepartment: (data) => create("departments", data),
  updateDepartment: (id, data) => update("departments", id, data),

  // Roles management
  getRoles: () => get("roles"),
  getRole: (id) => get("roles", id),
  createRole: (data) => create("roles", data),
  updateRole: (id, data) => update("roles", id, data),

  // Theatre only days
  getTheatreDays: () => get("theatre_only_days"),
  getTheatreDay: (id) => get("theatre_only_days", id),
  createTheatreDay: (data) => create("theatre_only_days", data),
  updateTheatreDay: (id, data) => update("theatre_only_days", id, data),

  // Facility Departments management
  getFacilityDepartments: () => get("facility_departments"),
  getFacilityDepartment: (id) => get("facility_departments", id),
  createFacilityDepartment: (data) => create("facility_departments", data),
  updateFacilityDepartment: (id, data) =>
    update("facility_departments", id, data),

  // Role Claims management
  getRoleClaims: () => get("role_claims"),
  getRoleClaim: (id) => get("role_claims", id),
  fetchRolesClaims: (role_id) => get(`fetch_roles_claims/${role_id}`),
  createRoleClaim: (data) => create("role_claims", data),
  updateRoleClaim: (id, data) => update("role_claims", id, data),
  addClaimsToRole: (id, data) => create("add_claim_to_role", id, data),

  // Role Claims management
  getClaims: () => get("claims"),
  getClaim: (id) => get("claims", id),
  createClaim: (data) => create("claims", data),
  updateClaim: (id, data) => update("claims", id, data),

  // User Roles management
  getUserRoles: () => get("user_roles"),
  getUserRole: (id) => get("user_roles", id),
  createUserRole: (data) => create("user_roles", data),
  updateUserRole: (id, data) => update("user_roles", id, data),

  // Requisitions management
  fetchRequisitions: (queryParams) => {
    const params = new URLSearchParams();

    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== "" && value !== undefined && value !== null) {
        params.append(key, value);
      }
    });
    return get(`/requisitions/fetch_requisitions?${params.toString()}`);
  },
  fetchCompleteRequisitions: () => get("requisitions/complete_requisitions"),
  getRequisition: (id) => get("requisitions", id),
  createRequisition: (data) => create("requisitions", data),
  updateRequisition: (id, data) => update("requisitions", id, data),
  cancelRequisition: (id, data) => update("cancel_requisition", id, data),
  movePatientDepartment: (id, data) => update("move_patient_ward", id, data),
  assignUnit: (data) => create("requisitions/assign_units", data),
  fetchAssignedUnits: (queryParams) => {
    const params = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== "" && value !== undefined && value !== null) {
        params.append(key, value);
      }
    });
    return get(`requisitions/all_assigned_units?${params.toString()}`);
  },
  fetchAssignedUnit: (id) => get("requisitions/fetch_assigned_unit", id),
  issueOut: (data) => create("requisitions/issue_out", data),
  issuedProducts: (data) => create("requisitions/issued_products", data),
  fetchRequisitionProduct: (id) =>
    get("requisitions/by_requisition_product", id),

  // Transfusion
  startTransfusion: (data) => create("transfusions/start_transfusion", data),
  getTransfusions: () => get("transfusions"),
  getTransfusion: (id) => get("transfusions", id),
  updateTransfusion: (id, data) => update("transfusions", id, data),
  createTransfusionReading: (transfusionId, interval_min, data) =>
    create(`transfusions/${transfusionId}/readings`, { interval_min, ...data }),
  updateTransfusionReading: (transfusionId, interval_min, data) =>
    update(`transfusions/${transfusionId}/readings/${interval_min}`, data),
  getTransfusionReading: (transfusionId) =>
    get(`transfusions/readings/${transfusionId}`),
  createUpdateTransfusionVitals: (data) =>
    create("transfusions/transfusion_vitals", data),
  getAssignedUnitTransfusion: (id) => get("assigned_unit/transfusion", id),
  createUpdateReaction: (data) =>
    create("transfusions/transfusion_reaction", data),
  endTransfusion: (id, data) =>
    update("transfusions/end_transfusion", id, data),

  // Beds management
  getBeds: () => get("beds"),
  getBed: (id) => get("beds", id),
  createBed: (data) => create("beds", data),
  updateBed: (id, data) => update("beds", id, data),

  // Beds management
  getBedTypes: () => get("bed_types"),
  getBedType: (id) => get("bed_types", id),
  createBedType: (data) => create("bed_types", data),
  updateBedType: (id, data) => update("bed_types", id, data),

  // Blood Groups
  getBloodGroups: () => get("blood_groups"),
  getBloodGroup: (id) => get("blood_groups", id),
  createBloodGroup: (data) => create("blood_groups", data),
  updateBloodGroup: (id, data) => update("blood_groups", id, data),

  // Region
  createCountry: (data) => create("countries", data),
  createCounty: (data) => create("counties", data),
  getCounty: (id) => get("counties", id),
  getRegions: () => get("regions"),
  updateCounty: (id, data) => update("counties", id, data),

  // Unit Products
  getUnitProducts: () => get("unit_products"),
  getUnitProduct: (id) => get("unit_products", id),
  createUnitProduct: (data) => create("unit_products", data),
  updateUnitProduct: (id, data) => update("unit_products", id, data),

  // Patients management
  getPatients: () => get("patients"),
  getPatient: (id) => get("patients", id),
  createPatient: (data) => create("patients", data),
  updatePatient: (id, data) => update("patients", id, data),

  // Samples management
  getSamples: () => get("samples"),
  getSample: (id) => get("samples", id),
  createSample: (data) => create("samples", data),
  updateSample: (id, data) => update("samples", id, data),

  // Screenings management
  getScreenings: () => get("screenings"),
  getScreening: (id) => get("screenings", id),
  createScreening: (data) => create("screenings", data),
  confirmScreening: (id) =>
    fetchApi(`screenings/${id}/confirm_screening`, "POST"),
  updateScreeningTest: (id, data) =>
    create(`screenings/${id}/screening_test`, data),
  confirmScreeningTest: (id, data) =>
    fetchApi(`screenings/${id}/confirm_screening_test`, "POST", data),
  disposeScreening: (id) =>
    fetchApi(`screenings/${id}/dispose_screening_unit`, "POST"),

  // Inventory management
  createProduct: (data) => create("pint/create_product", data),
  componentPreparation: (data) => create("pint/component_preparation", data),
  unitSegregation: (id) => create(`/pint/unit_segregation/${id}`),
  returnSegregatedUnit: (id) => create(`/pint/return_degregated_unit/${id}`),
  fetchAllProducts: () => get("fetch_all_products"),
  fetchFilteredProducts: (queryParams) => {
    const params = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== "" && value !== undefined && value !== null) {
        params.append(key, value);
      }
    });
    return get(`/pint/fetch_products?${params.toString()}`);
  },
  fetchSingleProduct: (id) => get("pint/fetch_single_product", id),
  fetchProductOnDonation: (id) => get(`pint/fetch_donation_products/${id}`),
  scanEcosystem: (data) => create("pint_share_requests/scan_ecosystem", data),
  createPintShareRequest: (data) => create("pint_share_requests", data),
  getSupplyDemandDeficit: () => get("supply_demand_deficit"),

  // Donor management
  // getDonors: () => get("donors"),
  getDonors: (queryParams) => {
    // Convert the queryParams object into URL parameters
    const params = new URLSearchParams();

    // Add non-empty parameters to the query string
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== "" && value !== undefined && value !== null) {
        params.append(key, value);
      }
    });

    return get(`/donors?${params.toString()}`);
  },
  getDonor: (id) => get(`donors/${id}`),
  createDonor: (data) => create("donors", data),
  // updateDonor: (id, data) => update(`donors/${id}`, data),
  updateDonor: (id, data) => update("donors", id, data),
  deleteDonor: (id) => remove(`donors/${id}`),
  updateDonorQuestionnaireStatus: (donorId, data) =>
    fetchApi(`donors/${donorId}/questionnaire/update_status`, "PATCH", data),

  // Nested resources
  createDonorQuestionnaire: (donorId, data) =>
    create(`donors/${donorId}/donor_questionnaire`, data),
  updateDonorQuestionnaire: (donorId, data) =>
    update(`donors/${donorId}/donor_questionnaire`, data),
  createDeclaration: (donorId, data) =>
    create(`donors/${donorId}/declaration`, data),
  updateDeclaration: (donorId, data) =>
    update(`donors/${donorId}/declaration`, data),

  createDonationBooking: (donorId, data) =>
    create(`donors/${donorId}/donation_bookings`, data),
  updateDonationBooking: (donorId, bookingId, data) =>
    update(`donors/${donorId}/donation_bookings/${bookingId}`, data),
  deleteDonationBooking: (donorId, bookingId) =>
    remove(`donors/${donorId}/donation_bookings/${bookingId}`),

  // Blood donations
  getBloodDonations: () => get("blood_donations"),
  getDeferrals: () => get("blood_donations/deferrals"),
  getEligibleDonors: () => get("blood_donations/eligible_donors"),
  createBloodDonation: (donorId, data) =>
    create(`donors/${donorId}/blood_donations`, data),
  getBloodDonation: (donationId) => get(`blood_donations/${donationId}`),
  updateBloodDonation: (donorId, donationId, data) =>
    update(`donors/${donorId}/blood_donations/${donationId}`, data),
  deleteBloodDonation: (donorId, donationId) =>
    remove(`donors/${donorId}/blood_donations/${donationId}`),

  // Blood donation with unknown blood group
  getUnknownBloodGroupDonations: () =>
    get("blood_donations/unknown_blood_group"),
  getDueNormalDonations: () => get("blood_donations/due_normal"),
  getSumDonors: () => get("blood_donations/sum_donors"),

  // Donor deferrals
  createDonorDeferral: (donorId, data) =>
    create(`donors/${donorId}/deferral`, data),
  getDonorDeferrals: () => get("deferrals"),
  getDonorDeferral: (donorId) => get(`deferrals/${donorId}`),

  // Donor referrals
  createDonorReferral: (donorId, data) =>
    create(`donors/${donorId}/referrals`, data),
  getDonorReferrals: () => get("referrals"),
  getDonorReferral: (donorId) => get(`donors/${donorId}/referrals`),

  // Donor vitals
  createDonorVital: (donorId, data) =>
    create(`donors/${donorId}/donor_vitals`, data),
  getAllDonorVitals: () => get("donor_vitals"),
  getDonorVital: (donorId) => get(`donors/${donorId}/donor_vitals`),

  // donor recalls (blood appeals)
  createDonorRecall: (data) => create("donor_recalls", data),
  getDonorRecalls: () => get("donor_recalls"),

  //Reactions
  getAllDonationReactions: () => get("donation_reactions"),
  getDonationReactionForDonor: (donorId, reactionId) =>
    get(`donors/${donorId}/donation_reactions/${reactionId}`),
  createDonationReaction: (donorId, data) =>
    create(`donors/${donorId}/donation_reactions`, data),
  getAllDonationReactionsForDonor: (donorId) =>
    get(`donors/${donorId}/donation_reactions`),

  // Donation bookings
  createDonationBooking2: (data) => create("donation_bookings", data),
  validateBookingSlot: (data) => create("check-booking-slot", data),

  // Blood appeals management
  getBloodAppeals: () => get("blood_appeals"),
  getBloodAppeal: (id) => get(`blood_appeals/${id}`),
  createBloodAppeal: (data) => create("blood_appeals", data),
  updateBloodAppeal: (id, data) => update("blood_appeals", id, data),
  deleteBloodAppeal: (id) => remove("blood_appeals", id),
  approveBloodAppeal: (id) => create(`blood_appeals/${id}/approve`),
  rejectBloodAppeal: (id) => create(`blood_appeals/${id}/reject`),
  setBloodAppealAvailable: (id) => create(`blood_appeals/${id}/set_available`),
  setBloodAppealPendingCollection: (id) =>
    create(`blood_appeals/${id}/set_pending_collection`),
  setBloodAppealCollected: (id) => create(`blood_appeals/${id}/set_collected`),
  getOngoingBloodAppeals: () => get("blood_appeals/ongoing"),
  getCollectedBloodAppeals: () => get("blood_appeals/collected"),

  // Unit targets
  getUnitTargets: () => get("unit_targets"),
  getUnitTarget: (id) => get("unit_targets", id),
  createUnitTarget: (data) => create("unit_targets", data),
  updateUnitTarget: (id, data) => update("unit_targets", id, data),

  // Learning Management system
  getExamEndTime: () => get("exam_end_time"),

  // Documents Claims management
  getDocuments: (queryParams) => {
    const params = new URLSearchParams();

    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== "" && value !== undefined && value !== null) {
        params.append(key, value);
      }
    });
    return get(`documents?${params.toString()}`);
  },
  getDocument: (id) => get("documents", id),
  createDocument: (data) => create("documents", data),
  updateDocument: (id, data) => update("documents", id, data),
  toggleDocumentStatus: (id, data) =>
    fetchApi(`documents/${id}/archive_or_active`,  "POST", data),

  // Documents Claims management
  getAssessments: (queryParams) => {
    const params = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== "" && value !== undefined && value !== null) {
        params.append(key, value);
      }
    });
    return get(`assessments?${params.toString()}`);
  },
  getStaffAssessments: (queryParams) => {
    const params = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== "" && value !== undefined && value !== null) {
        params.append(key, value);
      }
    });
    return get(`staff_assessment?${params.toString()}`);
  },
  getAssessmentFilter: () => get("assessments_filter"),
  getAssessment: (id) => get("assessments", id),
  createAssessment: (data) => create("assessments", data),
  updateAssessment: (id, data) => update("assessments", id, data),
  toggleAssessmentStatus: (id, data) =>
    fetchApi(`assessments/${id}/archive_or_ongoing`,  "POST", data),


  // Questioin management
  getQuestions: (assessment_id) => get("questions", assessment_id),
  checkIfAssessmentExist: (assessment_id) =>
    get("assessment_exists", assessment_id),
  getAssessmentQuestion: (assesment_id) =>
    get("staff_test_questions", assesment_id),
  createQuestion: (assesment_id, data) =>
    create(`questions/${assesment_id}`, data),
  updateQuestion: (id, data) => update("questions", id, data),

  getUserDoneAssessmentTest: (assesment_id) =>
    get(`assessments/${assesment_id}/exams`),

  //exam results
  getAssessmentResults: (queryParams) => {
    const params = new URLSearchParams();

    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== "" && value !== undefined && value !== null) {
        params.append(key, value);
      }
    });
    return get(`exam_results?${params.toString()}`);
  },
  getUserAssessmentResults: (queryParams) => {
    const params = new URLSearchParams();

    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== "" && value !== undefined && value !== null) {
        params.append(key, value);
      }
    });
    return get(`users_exam_results?${params.toString()}`);
  },
  getAssessmentTestResults: (assessment_id, queryParams) => {
    const params = new URLSearchParams();

    // Add query params if they exist
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== "" && value !== undefined && value !== null) {
        params.append(key, value);
      }
    });

    // Use the assessmentId in the URL path
    return get(`assessment_test_results/${assessment_id}?${params.toString()}`);
  },
  getUserAssessmentResult: (assesment_id) => get("exam_results", assesment_id),
  createExam: (data) => create("exams", data),
};

export default apiClient;
