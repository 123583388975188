<template>
  <div class="container mx-auto p-4">
    <AlertLayout title="Reminders!"
      message="Kindly note that only blood units registered on Damu-Sasa will be found by the search.<br>Non-registered units should 1st be registered."
      type="warning" class="mb-4" />
    <div class="bg-white border border-t-0 rounded-lg shadow-md p-5">
      <h2 class="text-xl font-bold mb-2">Blood unit Information</h2>

      <div class="bg-gray-10 p-6 rounded-lg mb-6">
        <div class="grid grid-cols-2 gap-4">
          <!-- Left Column -->
          <div class="space-y-6">
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Serial Number:</label>
              <p class="text-sm font-normal mt-3">
                {{ product.pint_serial }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Donor Name:</label>
              <p class="text-sm font-normal mt-3">{{ product.blood_donation?.donor?.user?.first_name ?? '' }}
                {{ product.blood_donation?.donor?.user?.last_name ?? '' }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Blood Group:</label>
              <p class="text-sm font-normal mt-3">{{ product.blood_group?.description ?? 'Unknown' }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Blood Volume:</label>
              <p class="text-sm font-normal mt-3">{{ product.volume }} ml</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">product Date:</label>
              <p class="text-sm font-normal mt-3">{{ product.blood_donation?.date_added }}</p>
            </div>
          </div>
          <!-- Right Column -->
          <div class="space-y-6">
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Blood Product:</label>
              <p class="text-sm font-normal mt-3">{{ product.unit_product?.description }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Source Unit: </label>
              <p class="text-sm font-normal mt-3">{{ product.blood_donation?.pint_serial ? product.blood_donation?.pint_serial : product.source }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Expiry Date:</label>
              <p class="text-sm font-normal mt-3">{{ (new Date(product.expiry_date).toLocaleDateString()) ?? '' }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Blood Status:</label>
              <p class="text-sm font-normal mt-3">{{ product.status }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-between">
        <div>
          <button class="bg-custom-yellow text-white font-bold py-2 px-4 rounded mr-2"
            @click="handleComponentPrep(flag = 'Split')">
            Component Preparation
          </button>
          <button class="bg-custom-yellow text-white font-bold py-2 px-4 rounded mr-2"
            @click="handleComponentPrep(flag = 'Requote')">
            Aliquot
          </button>
          <!-- <button
            v-if="product.unit_product?.name !== 'LDPRC'"
            class="bg-custom-yellow text-white font-bold py-2 px-4 rounded mr-2"
            @click="handleComponentPrep(flag = 'Leuco-reduction')">
            Leuco Reduction
          </button> -->
        </div>
        <div>
          <button class="px-4 py-2 border rounded text-orange-500 border-orange-500 mr-2"
            @click="handleBackToUnitDetails">
            Back
          </button>
          <button v-if="product.status === 'Okay'" class="bg-custom-yellow text-white font-bold py-2 px-4 rounded mr-2"
            @click="handleSegregation">
            Segregate
          </button>
          <button v-if="product.status === 'Segregated'"
            class="bg-custom-yellow text-white font-bold py-2 px-4 rounded mr-2" @click="handleReturnUnit">
            Return to Inventory
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import AlertLayout from '@/components/AlertLayout.vue';
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: 'ProductDetailsPage',
  components: {
    AlertLayout,
  },
  data() {
    return {
      donation: [],
      product: [],
      product_id: '',
      totalVolume: 0,
      error: null,
      showDeferralPopup: false,
    };
  },
  created() {
    this.product_id = this.$route.params.product_id;
    this.getBloodProduct();
  },

  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },
    handleBackToUnitDetails() {
      this.$router.push({ name: 'InventoryManagementPage' });
    },    
    handleComponentPrep(flag) {
      if (this.product_id) {
        this.$router.push({
          name: 'ComponentPreparationPage',
          params: {
            product_id: this.product_id,
            flag: flag
          }
        });
      } else {
        console.error('Invalid donationId');
      }
    },
    async handleReturnUnit() {
      try {
        await api.returnSegregatedUnit(this.product_id);
        await SweetAlertService.showSuccess('Success!', 'Unit returned to inventory successfully!.');
        this.$router.go(0);
      } catch (error) {
        console.error('Error:', error.response.data.error);
      }
    },
    async handleSegregation() {
      try {
        await api.unitSegregation(this.product_id);
        await SweetAlertService.showSuccess('Success!', 'Blood unit segregated successfully!.');
        this.$router.go(0);
      } catch (error) {
        console.error('Error:', error.response.data.error);
      }
    },
    async getBloodProduct() {
      try {
        const response = await api.fetchSingleProduct(this.product_id);
        this.product = response.data || response;
      } catch (error) {
        this.error = error.message || 'Failed to fetch donation';
      }
    },
  },
};
</script>