<template>
  <div class="container mx-auto p-4">
    <AlertLayout title="Reminders!"
      message="Kindly search for donor using either national ID number, phone number or email address of the donor. Use only one criterion at a time. Only registered donors who have ever donated at your facility will be found by the search. Non-registered donors should be registered first. Register new donor here Non-registered donations should be registered first. Register new donation here"
      type="warning" class="mb-4" />

    <div class="flex justify-end space-x-2 mb-4">

      <button class="bg-custom-yellow hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
        @click="redirectToVitalsForm">
        + New Donation
      </button>

      <!-- <button v-if="donor && donor.blood_donations && donor.blood_donations.length > 0"
        class="bg-custom-yellow hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
        @click="redirectToDonationReactionForm">
        + New Reaction
      </button> -->

      <button class="bg-custom-yellow hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
        @click="redirectToDeferralForm">
        + New Deferral
      </button>

      <button class="bg-custom-yellow hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
        @click="openReferralPopup">
        + New Referral
      </button>
    </div>

    <div class="bg-white border border-t-0 rounded-lg shadow-md p-10">
      <h2 class="text-xl font-bold mb-2">Donor Information</h2>

      <div v-if="donor" class="bg-gray-10 p-6 rounded-lg mb-6">
        <div class="grid grid-cols-2 gap-4">
          <!-- Left Column -->
          <div class="space-y-6">
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Name:</label>
              <p v-if="donor && donor.user" class="text-sm font-semibold mt-3">
                {{ donor?.user?.first_name }} {{ donor?.user?.last_name }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">National ID number:</label>
              <p class="text-sm font-semibold mt-3">{{ donor.user?.id_number }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Phone Number:</label>
              <p class="text-sm font-semibold mt-3">
                {{ donor.user?.phone_number }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Email:</label>
              <p class="text-sm font-semibold mt-3">{{ donor.user?.email }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Donor date of birth:</label>
              <p class="text-sm font-semibold mt-3">{{ donor.user?.dob }}</p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Donor registration date:</label>
              <p class="text-sm font-semibold mt-3">
                {{ new Date(donor.created_at).toLocaleDateString("en-CA") }}
              </p>
            </div>
          </div>
          <!-- Right Column -->
          <div class="space-y-6">
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Blood Group:</label>
              <p class="text-sm font-semibold mt-3">
                {{ donor.user?.blood_group_id?.description ?? "N/A" }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Last donation date:</label>
              <p class="text-sm font-semibold mt-3">
                {{
                  donor.blood_donations && donor.blood_donations.length > 0
                    ? donor.blood_donations[0].date_added
                    : "Unknown"
                }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Last donation center:</label>
              <p class="text-sm font-semibold mt-3">
                {{
                  donor.blood_donations && donor.blood_donations.length > 0
                    ? donor.blood_donations[0].facility_id.name
                    : "Unknown"
                }}
              </p>
            </div>
            <div class="grid grid-cols-2 items-center border-t m-2">
              <label class="text-sm font-normal mt-3">Next donation date:</label>
              <p class="text-sm font-semibold mt-3">
                {{
                  donor.blood_donations && donor.blood_donations.length > 0
                    ? donor.blood_donations[0].next_donation_date
                    : "Unknown"
                }}
              </p>
            </div>
          </div>
        </div>
        <!-- edit button -->
        <div class="flex justify-end mt-4">
          <button class="bg-custom-yellow hover:bg-red-600 text-white font-bold py-2 px-4 rounded flex justify-end"
            @click="handleEditDonorInfo">
            Edit
          </button>
        </div>
      </div>
      <div v-else>Loading donor details...</div>



      <div class="mb-6">
        <h2 class="text-xl font-semibold mb-4">Donation History</h2>
        <div v-if="
          donor && donor.blood_donations && donor.blood_donations.length > 0
        " class="overflow-x-auto">
          <table class="min-w-full divide-y border border-gray-200 rounded-lg shadow-md">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs tracking-wider">
                  Date Donated
                </th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">
                  Type of Donation
                </th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">
                  Technology
                </th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">
                  Donor Attendant
                </th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">
                  Donated To
                </th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">
                  Next Donation Date
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(donation, index) in sortedDonations" :key="index">
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation.date_added }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation.donation_type }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation.technology }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation?.added_by?.first_name }} {{
                  donation?.added_by?.last_name }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation.donors?.patient ?? 'Unknown' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ donation.next_donation_date ?? 'N/A' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="
          donor &&
          (!donor.blood_donations || donor.blood_donations.length === 0)
        ">
          No donation history available.
        </div>
        <div v-else>Loading Donation History details...</div>
      </div>


      <div class="mb-6">
        <h2 class="text-xl font-semibold mb-4">Vitals History</h2>

        <!-- Render table if donor vitals are present -->
        <div v-if="donor_vitals && donor_vitals.length > 0" class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg shadow-md">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Date Recorded</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Weight</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Temperature</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Blood Pressure</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">HB Levels</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Pulse</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Donor Attendant</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(vital, index) in donor_vitals" :key="index">
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  {{ vital && vital.created_at ? new Date(vital.created_at).toLocaleDateString("en-CA") : 'N/A' }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ vital?.weight || 'N/A' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ vital?.temperature || 'N/A' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ vital?.blood_pressure || 'N/A' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ vital?.hb_levels || 'N/A' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ vital?.pulse || 'N/A' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  {{ vital?.added_by?.first_name || 'N/A' }} {{ vital?.added_by?.last_name || 'N/A' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- No donor vitals available -->
        <div v-else-if="donor_vitals && donor_vitals.length === 0">
          No donor vitals history available.
        </div>

        <!-- Loading state -->
        <div v-else>Loading donor vitals history...</div>
      </div>

      <div class="mb-6">
        <h2 class="text-xl font-semibold mb-4">Deferral History</h2>

        <div v-if="deferrals && deferrals.length > 0" class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg shadow-md">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Deferral date</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Deferral days</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Deferral hours</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Deferral reason</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Next donation date</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Donor Attendant</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(deferral, index) in deferrals" :key="index">
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ new
                  Date(deferral.created_at).toLocaleDateString("en-CA") }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ deferral.deferral_days }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ deferral.deferral_hours }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ deferral.deferral_reason }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ deferral.next_donation_date }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  {{ deferral?.added_by?.first_name || 'N/A' }} {{ deferral?.added_by?.last_name || 'N/A' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- No reactions available -->
        <div v-else-if="deferrals && deferrals.length === 0">
          No Deferral history available.
        </div>

        <!-- Loading state -->
        <div v-else>Loading Deferral History details...</div>
      </div>


      <div class="mb-6">
        <h2 class="text-xl font-semibold mb-4">Referral History</h2>

        <!-- Render table if form_url are present -->
        <div v-if="form_url && form_url.length > 0" class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg shadow-md">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Date Referred</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Donor Attendant</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Referred From</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Referred To</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Reason for Referral</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Uploaded File</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(referral, index) in form_url" :key="index">
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  {{ new Date(referral.created_at).toLocaleDateString("en-CA") }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  {{ referral.created_by?.first_name || 'N/A' }} {{ referral.created_by?.last_name || 'N/A' }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  {{ referral.facility_id?.name || 'N/A' }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  {{ referral.referral_facility?.facility_name || 'N/A' }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  {{ referral.reason || 'N/A' }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  <div class="flex items-center space-x-2">
                    <span class="truncate max-w-xs">
                      {{ referral.form_url || 'No file' }}
                    </span>
                    <button v-if="referral.form_url" @click="openPreview(referral)"
                      class="px-3 py-1 text-sm text-blue-600 hover:text-blue-800 border border-blue-600 rounded-md hover:bg-blue-50 transition-colors">
                      Preview
                    </button>

                    <!-- Modal for preview with larger size -->
                    <div v-if="showPreviewModal"
                      class="fixed inset-0 bg-gray-800 bg-opacity-40 flex justify-center items-center">
                      <div class="bg-gray-500 p-6 rounded-lg shadow-lg w-full max-w-6xl h-[90vh] relative">
                        <button @click="closePreview" class="absolute top-2 right-2 text-gray-50 text-xl">✕</button>
                        <iframe :src="`/uploads/${selectedFile}`" class="w-full h-full"></iframe>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- No form_url available -->
        <div v-else-if="form_url && form_url.length === 0">
          No referral history available.
        </div>

        <!-- Loading state -->
        <div v-else>Loading referral history details...</div>
      </div>


      <div class="mb-6">
        <h2 class="text-xl font-semibold mb-4">Reactions History</h2>

        <div v-if="donation_reactions && donation_reactions.length > 0" class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg shadow-md">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Time of Reaction</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Donor HB</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Broad Reaction Type</th>
                <th class="px-6 py-3 text-left text-xs tracking-wider">Reaction Type</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(reaction, index) in donation_reactions" :key="index">
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ new
                  Date(reaction.time_of_reaction).toLocaleString("en-CA") }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ reaction.hb }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ reaction.reaction_broad_type }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">{{ reaction.reaction_type.join(', ') }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- No reactions available -->
        <div v-else-if="donation_reactions && donation_reactions.length === 0">
          No reactions history available.
        </div>

        <!-- Loading state -->
        <div v-else>Loading Reactions History details...</div>
      </div>





      <div class="flex justify-between">
        <button class="px-4 py-2 border rounded text-orange-500 border-orange-500">
          Back
        </button>
        <div>
          <button class="bg-custom-yellow text-white font-bold py-2 px-4 rounded mr-2" @click="printDonorCertificate">
            Print Donor Certificate
          </button>
        </div>
      </div>
    </div>

    <!-- <component v-if="certificateComponent" :is="certificateComponent" ref="certificateComponent" :donor="donor"
      style="display: none"></component> -->

    <div v-if="showReferralPopup"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div class="bg-white p-8 rounded-lg shadow-xl w-[50rem]">
        <AlertLayout title="Reminders!" message="
        <ul>Only refer donors that need further medical assistance. View guiding document here.</ul>
      <ul>Kindly remember to enter brief reason for referral (optional)</ul>
      <ul>Kindly remember to upload supporting documents for referral (optional)</ul>
      <ul>Kindly note that donor will be immediately informed of their referral</ul>" type="warning" />
        <h2 class="text-2xl font-bold mb-4">New Referral</h2>

        <!-- Align the button to the right and make it smaller -->
        <div class="flex justify-end mb-4">
          <button class="bg-custom-yellow hover:bg-orange-600 text-white font-bold py-1 px-2 text-sm rounded"
            @click="handleNewFacility">
            + Add Referral Facility
          </button>
        </div>

        <form @submit.prevent="submitReferralForm" enctype="multipart/form-data">
          <div class="mb-4">
            <label class="block mb-2 text-md" for="facility">Referral Facility <span
                class="text-red-500">*</span></label>
            <select id="facility"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400"
              v-model="referralForm.referral_facility_id" required>
              <option disabled value="">Select Facility</option>
              <option v-for="facility in facilities" :key="facility.id" :value="facility.id">
                {{ facility.facility_name }}
              </option>
            </select>
            <p v-if="error" class="text-red-500">{{ error }}</p>
          </div>

          <div class="mb-4">
            <label class="block mb-2 text-md" for="reason">Reason for Referral</label>
            <textarea id="reason" v-model="referralForm.reason"
              class="w-full p-3 border rounded bg-gray-100 shadow-sm shadow-gray-400" required></textarea>
          </div>

          <!-- File Upload Fields -->
          <div class="mb-4">
            <label class="block mb-2 text-md">Upload Referral Documents</label>
            <input type="file" accept=".pdf" @change="handleFileUpload"
              class="w-full p-3 border rounded bg-gray-100 shadow-sm shadow-gray-400">
          </div>

          <div class="flex justify-end space-x-2">
            <button type="button" @click="closeReferralPopup" class="px-4 py-2 bg-gray-200 text-gray-800 rounded">
              Cancel
            </button>
            <button type="submit" class="px-4 py-2 bg-custom-yellow text-white rounded">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import api from "@/services/api";
import SweetAlertService from '@/services/SweetAlertService';
import axios from 'axios';

export default {
  name: "DonorDetailsPage",
  components: {
    AlertLayout,
  },

  data() {
    return {
      donor: null,
      error: null,
      donor_vitals: [],
      deferrals: [],
      referral: [],
      donation_reactions: [],
      showDeferralPopup: false,
      showReferralPopup: false,
      facilities: [],
      referralForm: {
        reason: "",
        facility_id: this.user().facility_id,
        referral_facility_id: "",
        form_url: "",
        created_by: this.user().user_id,
      },
      uploadedFile: null,
      showPreviewModal: false,
      selectedFile: null
    };
  },
  created() {
    this.fetchDonorDetails();
    this.fetchDonorVitals();
    this.fetchDonationReactions();
    this.fetchDonorDeferrals();
    this.fetchDonorform_url();
    this.fetchFacilities();
  },
  computed: {
    sortedDonations() {
      return this.donor.blood_donations.slice().sort((a, b) => {
        return new Date(b.date_added) - new Date(a.date_added); // Sort by descending order of date_added
      });
    },
  },

  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    async fetchDonorform_url() {
      try {
        const donorId = this.$route.params.donor_id;
        const response = await api.getDonorReferral(donorId);
        const data = response.data || response;

        // Assigning the fetched form_url directly to the form_url property
        this.form_url = data;

        // console.log("Fetching donor form_url", this.form_url);
      } catch (error) {
        this.error = error.message || "Failed to fetch donor form_url";
        console.error("Error fetching donor form_url:", this.error);
      }
    },

    openPreview(referral) {
      if (referral.form_url) {
        this.selectedFile = referral.form_url;
        this.showPreviewModal = true;
      } else {
        SweetAlertService.showError("Error", "No file available for preview");
      }
    },
    closePreview() {
      this.showPreviewModal = false;
      this.selectedFile = null;
    },

    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append("file", file);

      try {
        // Send file to the Express server
        const response = await axios.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // Extract filename from response and store it in referralForm
        this.referralForm.form_url = response.data.filename;

        // Update component state to show the uploaded file
        this.uploadedFile = file;

      } catch (error) {
        console.error("Error handling file upload:", error);
        await SweetAlertService.showError("Error", "Failed to upload file");
      }
    },



    async submitReferralForm() {
      try {
        const donorId = this.$route.params.donor_id;

        // Prepare payload with file name included if available
        const payload = {
          referral: {
            reason: this.referralForm.reason,
            referral_facility: this.referralForm.referral_facility_id,
            facility_id: this.referralForm.facility_id,
            created_by: this.referralForm.created_by,
            ...(this.referralForm.form_url && { form_url: this.referralForm.form_url }),
          },
        };

        // Send payload to API
        await api.createDonorReferral(donorId, payload);
        await SweetAlertService.showSuccess("Success!", "Referral saved successfully!");
        this.$router.push({ name: "DonorManagementPage" });

      } catch (error) {
        console.error("Error referring donor:", error);
        await SweetAlertService.showError("Error", "Error creating referral.");
      }
    },


    async fetchDonorDetails() {
      try {
        const donorId = this.$route.params.donor_id;
        const response = await api.getDonor(donorId);
        this.donor = response.data || response;
        // console.log("Fetched donor details:", this.donor);
      } catch (error) {
        this.error = error.message || "Failed to fetch donor details";
        console.error("Error fetching donor details:", this.error);
      }
    },


    async fetchDonorVitals() {
      try {
        const donorId = this.$route.params.donor_id;
        const response = await api.getDonorVital(donorId);
        console.log("API Response:", response);

        this.donor_vitals = response.data || response;

        console.log("Fetched donor vitals:", this.donor_vitals);
      } catch (error) {
        this.error = error.message || "Failed to fetch donor vitals";
        console.error("Error fetching donor vitals:", this.error);
      }
    },


    async fetchDonorDeferrals() {
      try {
        const donorId = this.$route.params.donor_id;
        const response = await api.getDonorDeferral(donorId);
        this.deferrals = response.data || response;


        console.log("Fetched donor vitals:", this.deferrals);
      } catch (error) {
        this.error = error.message || "Failed to fetch donor details";
        console.error("Error fetching donor details:", this.error);
      }
    },

    async fetchDonationReactions() {
      try {
        const donorId = this.$route.params.donor_id;
        const response = await api.getAllDonationReactionsForDonor(donorId);
        const data = response.data || response;
        this.donation_reactions = data.donation_reactions
        console.log("Fetcnhing donor reactions", this.donation_reactions);
      } catch (error) {
        this.error = error.message || "Failed to fetch donor details";
        console.error("Error fetching donor details:", this.error);
      }
    },

    handleEditDonorInfo() {
      this.$router.push({
        name: "EditDonorInformationPage",
        params: { donor_id: this.$route.params.donor_id },
      });
    },

    redirectToVitalsForm() {
      this.$router.push({
        name: 'NewDonorVitalsForm',
        params: { donor_id: this.$route.params.donor_id }
      });
    },

    redirectToDeferralForm() {
      this.$router.push({
        name: 'NewDonorDeferralForm',
        params: { donor_id: this.$route.params.donor_id }
      });
    },

    redirectToDonationReactionForm() {
      this.$router.push({
        name: 'NewReactionForm',
        params: { donor_id: this.$route.params.donor_id }
      });
    },

    openReferralPopup() {
      this.referralForm.donor_id = parseInt(this.$route.params.donor_id, 10);
      this.showReferralPopup = true;
    },

    closeReferralPopup() {
      this.showReferralPopup = false;
      this.referralForm = {
        reason: "",
        referral_facility: "",

      };
    },

    // handleNewFacility() {
    //   this.$router.push({ name: 'NewReferralFacilityForm' });
    // },


    handleNewFacility() {
      this.$router.push({
        name: 'NewReferralFacilityForm',
        params: { donor_id: this.$route.params.donor_id }
      });
    },

    async fetchFacilities() {
      try {
        const response = await api.getReferralFacilities();
        this.facilities = response.data || response;
      } catch (error) {
        this.error = error.message || "Failed to fetch facilities";
      }
    },

    calculateDeferralDays(dateAdded, nextDonationDate) {
      if (dateAdded && nextDonationDate) {
        const dateAddedObj = new Date(dateAdded);
        const nextDonationDateObj = new Date(nextDonationDate);
        const diffTime = Math.abs(nextDonationDateObj - dateAddedObj);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
        return diffDays;
      }
      return 'N/A';
    },



    printDonorCertificate() {
      const printWindow = document.createElement('iframe');
      printWindow.style.position = 'absolute';
      printWindow.style.width = '0';
      printWindow.style.height = '0';
      document.body.appendChild(printWindow);

      // Fetch donor data
      const donorName = `${this.donor.user.first_name} ${this.donor.user.last_name}`;
      const donorBloodGroup = this.donor.user.blood_group_id.name;
      const registrationDate =  new Date(this.donor.created_at).toLocaleDateString("en-CA")

      // Check facility ID and set appropriate background image
      const facilityId = this.user().facility_id;
      const backgroundImageClass = facilityId === '56dfcf46-e9ac-4484-a1ea-f5eb01b8f91f' ? 'tnh_background-image' : 'general_background-image';

      // Get the full absolute URL for the images
      const baseUrl = window.location.origin;
      const printContent = `
    <div class="print-container">
      <div class="${backgroundImageClass}">
        <!-- Name -->
        <div class="cert-text donor_names">
          <b>${donorName}</b>
        </div>

        <!-- Blood Group -->
        <div class="credits-text blood_group">
          ${donorBloodGroup}
        </div>

        <!-- Salutation -->
        <div class="credits-text reg_date">
          ${registrationDate}
        </div>

       
      </div>
    </div>
  `;

      const printDocument = `
    <!DOCTYPE html>
    <html>
    <head>
      <title>Print Preview</title>
      <style>
        @page {
          margin: 0;
        }
        
        html {
          margin: 0;
          padding: 0;
          height: 100%;
        }

        body {
          margin: 0;
          padding: 0;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #555555;
        }

        .print-container {
          width: 100%;
          height: 100vh;
          position: relative;
          overflow: hidden;
        }

        .tnh_background-image,
        .general_background-image {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
        }

        .tnh_background-image {
          background-image: url('${baseUrl}/TNH-cert-front.png');
        }

        .general_background-image {
          background-image: url('${baseUrl}/JijiHealthCert.jpg');
        }

        .cert-text, .credits-text {
          font-family: serif;
          color: #555555;
          font-weight: bold;
          position: absolute;
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
        }

        .cert-text.donor_names {
          top: 49%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 24pt;
          font-family: "Manjari", Helvetica, serif;
          width: 80%;
          text-align: center;
        }

        .credits-text.blood_group {
          top: 57%;
          left: 29%;
          font-size: 18pt;
          font-family: "Manjari", Helvetica, serif;
        }

        .credits-text.reg_date {
          top: 57%;
          right: 35%;
          font-size: 18pt;
          font-family: "Manjari", Helvetica, serif;
        }

        .credits-text.phone {
          top: 69%;
          left: 25%;
          font-size: 18pt;
          font-family: "Manjari", Helvetica, serif;
        }

        @media print {
          html {
            height: 100%;
          }
          
          body {
            height: 100vh;
            margin: 0;
            padding: 0;
          }

          .print-container {
            height: 100vh;
            page-break-after: avoid;
            page-break-before: avoid;
          }

          .cert-text, .credits-text {
            print-color-adjust: exact;
            -webkit-print-color-adjust: exact;
          }
        }
      </style>
    </head>
    <body>
      ${printContent}
    </body>
    </html>
  `;

      // Write to iframe and print
      printWindow.contentDocument.open();
      printWindow.contentDocument.write(printDocument);
      printWindow.contentDocument.close();

      // Wait for images to load before printing
      printWindow.onload = function () {
        setTimeout(() => {
          printWindow.contentWindow.print();
          // Remove the iframe after printing
          setTimeout(() => {
            document.body.removeChild(printWindow);
          }, 1000);
        }, 1000); // Add a delay to ensure styles are applied 
      };
    }

  }
}
</script>

<style scoped>
.container {
  padding: 20px;
}

/* .details-card {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  align-items: center;
} */

.detail {
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  margin-right: 10px;
  color: black;
}

.value {
  color: #333;

}
</style>