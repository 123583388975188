<template>
  <div>
    <AlertLayout title="Reminders!" message="<ul>Kindly remember to inform the donor why they have been deferred.</ul>
      <ul>Kindly remember to inform the donor that they are free to donate once the deferral period is over</ul>
      <ul>Kindly thank the donor for turning up to donate</ul>" type="warning" />
    <div class="mx-auto p-10 border border-t-0">
      <h2 class="text-lg font-bold mb-6">Add New Deferral</h2>

      <!-- New Deferral Form -->
      <form class="grid grid-cols-1 gap-5">
        <div>
          <label class="block mb-1" for="deferralDays">Deferral Days <span class="text-red-500">*</span></label>
          <input v-model.number="deferralForm.deferral_days" type="number" id="deferralDays"
            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
        </div>
        <div>
          <label class="block mb-1" for="deferralHours">Deferral Hours <span class="text-red-500">*</span></label>
          <input v-model.number="deferralForm.deferral_hours" type="number" id="deferralHours"
            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
        </div>
        <div>
          <label class="block mb-1" for="deferralReason">Reason for Deferral <span class="text-red-500">*</span></label>
          <textarea v-model="deferralForm.deferral_reason" id="deferralReason"
            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            required></textarea>
        </div>
        <div class="flex justify-end space-x-2">
           <button @click="resetForm" 
          class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Cancel</button>
          <CustomButton label="Submit" bg-class="bg-custom-yellow px-8 py-3" @click="submitDeferralForm" />
        </div>
      </form>
      <!-- End New Deferral Form -->
    </div>
  </div>
</template>


<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from "@/services/SweetAlertService";

export default {
  name: "NewDonorDeferralForm",
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      donor: null,
      error: null,
      showDeferralPopup: false,
      deferralForm: {
        deferral_days: '',
        deferral_hours: '',
        deferral_reason: '',
      },
    };
  },

  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    async submitDeferralForm() {
      // Check for empty fields
      const emptyFields = [];
      if (!this.deferralForm.deferral_days) emptyFields.push('Deferral Days');
      if (!this.deferralForm.deferral_hours) emptyFields.push('Deferral Hours');
      if (!this.deferralForm.deferral_reason) emptyFields.push('Reason for Deferral');

      // If any required fields are empty, show an alert and stop the submission
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        const donorId = this.$route.params.donor_id;

        const payload = {
          donor_deferral: {
            deferral_days: this.deferralForm.deferral_days,
            deferral_hours: this.deferralForm.deferral_hours,
            deferral_reason: this.deferralForm.deferral_reason,
            added_by: this.user().user_id, 
          }
        };

        // Make API call to create deferral
        await api.createDonorDeferral(donorId, payload);

        // Show success alert
        await SweetAlertService.showSuccess('Success', 'Deferral added successfully!.');

        this.clearDeferralForm();
        this.$router.push({ name: 'DonorDetailsPage' });

      } catch (error) {
        console.error('Error creating deferral:', error);

        // Show error alert
        SweetAlertService.showError('Error', 'Failed to create deferral. Please try again.');
      }
    },

    resetForm() {
      this.deferralForm = {
        deferral_days: '',
        deferral_hours: '',
        deferral_reason: '',
        added_by: this.user().user_id,
        facility_id: this.user().facility_id,
      };
    }
  },
};

</script>

<style lang="css" scoped>
/* Your existing styles */
</style>