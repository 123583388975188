<template>
  <div>
    <AlertLayout
      title="Reminders!"
      message="Kindly fill in all the details as required below"
      type="warning"
    />
    <div class="flex flex-wrap items-center justify-between pb-4 py-1">
      <div class="flex items-center space-x-4">
        <div class="relative">
          <button
            id="dropdownRadioButton"
            class="inline-flex items-center text-gray-400 bg-white border border-gray-300 rounded-md px-6 py-2"
            type="button"
            @click="toggleDropdown"
          >
            <svg
              class="w-6 h-6"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="2"
                d="M18.796 4H5.204a1 1 0 0 0-.753 1.659l5.302 6.058a1 1 0 0 1 .247.659v4.874a.5.5 0 0 0 .2.4l3 2.25a.5.5 0 0 0 .8-.4v-7.124a1 1 0 0 1 .247-.659l5.302-6.059c.566-.646.106-1.658-.753-1.658Z"
              />
            </svg>
            Filters
            <svg
              class="w-2.5 h-2.5 ms-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
          <div
            v-show="isDropdownOpen"
            id="filterDropdown"
            class="absolute z-10 mt-2 w-96 p-3 bg-white border border-gray-300 rounded-lg shadow-lg"
          >
            <form class="max-w-sm mx-auto">
              <h6 class="mb-3 text-sm font-medium text-gray-900">
                Blood Group
              </h6>
              <ul
                class="space-y-2 text-sm"
                aria-labelledby="filterDropdownButton"
              >
                <li class="flex items-center">
                  <input
                    id="all"
                    type="checkbox"
                    value="all"
                    class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500"
                  />
                  <label
                    for="all"
                    class="ml-2 text-sm font-medium text-gray-900"
                    >All</label
                  >
                </li>
                <li class="flex items-center">
                  <input
                    id="donorNames"
                    type="checkbox"
                    value="donorNames"
                    class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500"
                  />
                  <label
                    for="donorNames"
                    class="ml-2 text-sm font-medium text-gray-900"
                    >Uknown</label
                  >
                </li>
                <li class="flex items-center">
                  <input
                    id="nationalID"
                    type="checkbox"
                    value="nationalID"
                    class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500"
                  />
                  <label
                    for="nationalID"
                    class="ml-2 text-sm font-medium text-gray-900"
                    >Known</label
                  >
                </li>
              </ul>
              <div class="mt-5">
                <label for="email" class="block mb-2 text-gray-900"
                  >Date Registered</label
                >
                <input
                  type="text"
                  class="bg-gray-100 border border-gray-200 text-gray-900 rounded-md block w-full py-2 px-1 dark:placeholder-gray-400"
                  placeholder="MM-DD-YYYY"
                />
              </div>
              <div class="mt-5">
                <label for="email" class="block mb-2 text-gray-900"
                  >Date Of Birth</label
                >
                <input
                  type="text" 
                  class="bg-gray-100 border border-gray-200 text-gray-900 rounded-md block w-full py-2 px-1 dark:placeholder-gray-400"
                  placeholder="MM-DD-YYYY"
                />
              </div>
              <div class="mt-5">
                <label for="email" class="block mb-2 text-gray-900"
                  >Donation Process</label
                >
                <select
                  class="g-gray-100 border border-gray-200 text-gray-900 rounded-md block w-full py-2 px-1 dark:placeholder-gray-400"
                >
                  <option disabled selected>Select</option>
                  <option value="Male">Normal</option>
                  <option value="Female">Apheresis</option>
                </select>
              </div>
              <div class="flex justify-end space-x-4 mt-4">
                <CustomButton
                  label="Cancel"
                  bg-class="bg-gray-50 text-gray-900 py-2"
                />
                <CustomButton label="Filter" bg-class="bg-custom-yellow py-2" />
              </div>
            </form>
          </div>
        </div>
        <div class="relative flex">
          <input
            type="text"
            v-model="searchQuery"
            id="table-search"
            class="px-4 py-2 text-gray-800 border border-gray-300 rounded-sm dark:border-gray-300 dark:placeholder-gray-400"
            placeholder="Search"
          />
          <button
            type="submit"
            class="bg-custom-yellow text-white rounded-r px-2 md:px-3 py-0 md:py-1"
          >
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="2"
                d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="flex items-center space-x-4 mt-4 sm:mt-0">
        <template v-if="currentTab === 'departments'">
          <CustomButton
            label="New Department"
            :icon-path="'M5 12h14m-7 7V5'"
            bg-class="bg-custom-yellow"
            @click="handleNewDepartment"
          />
        </template>
        <template v-if="currentTab === 'bed-types'">
          <CustomButton
            label="New Bed Types"
            :icon-path="'M5 12h14m-7 7V5'"
            bg-class="bg-custom-yellow"
            @click="handleNewBedType"
          />
        </template>
        <template v-if="currentTab === 'beds'">
          <CustomButton
            label="New Bed"
            :icon-path="'M5 12h14m-7 7V5'"
            bg-class="bg-custom-yellow"
            @click="handleNewBed"
          />
        </template>
      </div>
    </div>
    <div class="tabs flex border-b mb-4">
      <button
        class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'departments' }"
        @click="currentTab = 'departments'"
      >
        List of Departments
      </button>
      <button
        class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'bed-types' }"
        @click="currentTab = 'bed-types'"
      >
        Bed Types
      </button>
      <button
        class="px-4 py-2 -mb-px font-semibold text-gray-500 border-b-2"
        :class="{ 'border-custom-yellow': currentTab === 'beds' }"
        @click="currentTab = 'beds'"
      >
        Beds
      </button>
    </div>
    <component
      :is="currentTabComponent"
      :search-query="searchQuery"
    ></component>
  </div>
</template>
  
  <script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import DepartmentsTab from "../tabs/DepartmentsTab.vue";
import BedTypesTab from "../tabs/BedTypesTab.vue";
import BedsTab from "../tabs/BedsTab.vue";

export default {
  name: "DepartmentsPage.",
  components: {
    AlertLayout,
    CustomButton,
    DepartmentsTab,
    BedTypesTab,
    BedsTab
  },
  created() {},
  data() {
    return {
      searchQuery: "",
      isDropdownOpen: false,
      currentTab: "departments",
    };
  },
  computed: {
    currentTabComponent() {
      switch (this.currentTab) {
        case "blood-units":
          return "DepartmentsTab";
        case "bed-types":
          return "BedTypesTab";
        case "beds":
          return "BedsTab";
        default:
          return "DepartmentsTab";
      }
    },
  },
  props: {},
  methods: {
    handleNewDepartment() {
      this.$router.push({ name: 'NewDepartmentForm' });
    },
    handleNewBedType() {
      this.$router.push({ name: 'NewBedTypeForm' });
    },
    handleNewBed() {
      this.$router.push({ name: 'NewBedForm' });
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    // token() {
    //   return this.$store.getters["auth/token"];
    // },
  },
  mounted() {
    const facilityId = this.user().facility_id;
    console.log('facility_id:', facilityId);
  }
};
</script>
  
  <style lang="css" scoped>
</style>