<template>
  <div>
    <AlertLayout title="Reminders!"
      message="Kindly make sure you have stable internet and power before starting the test" type="warning" />

    <div class="w-11/12 mx-auto mt-4 h-full">
      <div class="flex items-center justify-end w-full">
        <div class="text-xl font-bold text-center bg-blue-200 py-3 w-80 rounded-md">
          <p v-if="timeRemaining !== null">Time Left: {{ formattedTime }}</p>
          <p v-else>Countdown not started</p>
        </div>
      </div>

      <div v-if="!reviewQuestions" class="bg-white rounded-lg shadow-md px-10 py-4 mx-auto mt-4">
        <div class="mb-6">
          <p class="font-bold text-xl text-gray-800">Question {{ currentQuestionIndex + 1 }}/{{ questions.length }}</p>
        </div>
        <div class="mb-6">
          <p>{{ currentQuestion.question }}</p>
        </div>
        <div class="space-y-4">
          <div v-for="(option, index) in options" :key="index">
            <label>
              <input type="radio" v-model="selectedOption" :value="option" class="mr-2" />
              {{ option }}
            </label>
          </div>
        </div>
      </div>

      <!-- Review Section (When in review mode) -->
      <div v-if="reviewQuestions" class="bg-white rounded-lg shadow-md px-10 py-4 mx-auto mt-4 overflow-y-scroll h-[60vh]">
        <!-- Loop over all questions and display them with selected options -->
        <div v-for="(question, index) in questions" :key="index" class="mb-3 py-4 border-b">
          <div class="mb-6">
            <p class="font-bold text-xl text-gray-800">Question {{ index + 1 }}/{{ questions.length }}</p>
          </div>
          <div class="mb-6">
            <p>{{ question.question }}</p>
          </div>

          <!-- Display the options with the selected answer checked -->
          <div class="space-y-4">
            <div v-for="option in [question.option_1, question.option_2, question.option_3, question.option_4]"
              :key="option">
              <label class="flex items-center cursor-pointer">
                <input type="radio" :name="'question-' + question.question_id" :value="option"
                  :checked="answers[question.question_id]?.selectedAnswer === option"
                  @change="updateAnswer(question.question_id, option)" class="mr-2" />
                {{ option }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-between mt-6">
        <div>
          <button class="bg-custom-blue hover:bg-gray-800 text-white py-2 px-4 rounded"
            :disabled="currentQuestionIndex === 0" @click="handlePrevious">
            Previous
          </button>
        </div>
        <div>
          <button v-if="currentQuestionIndex === questions.length - 1 && !reviewQuestions"
            class="bg-custom-blue hover:bg-gray-800 text-white mr-4 py-2 px-4 rounded" @click="handleReviewQuestions">
            Review
          </button>
          <button class="bg-custom-yellow hover:bg-yellow-600 text-white py-2 px-4 rounded"
            @click="handleNextOrFinish">
            {{ currentQuestionIndex === questions.length - 1 ? 'Finish' : 'Next' }}
          </button>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from '@/components/AlertLayout.vue';
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: "StaffLearningManagementPage",
  components: {
    AlertLayout,
  },
  data() {
    return {
      startTime: null,
      duration: 0,
      timeRemaining: null,
      timer: null,
      questions: [],
      currentQuestionIndex: 0,
      selectedOption: null,
      assessment_id: null,
      reviewQuestions: false,
      answers: {},
    };
  },
  created() {
    this.assessment_id = this.$route.params.assessment_id;
    this.fetchQuestions();
    this.loadAnswersFromLocalStorage();
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex] || {};
    },
    options() {
      return [
        this.currentQuestion.option_1,
        this.currentQuestion.option_2,
        this.currentQuestion.option_3,
        this.currentQuestion.option_4,
      ];
    },
    formattedTime() {
      if (this.timeRemaining === null) {
        return '00:00:00';
      }

      const hours = String(Math.floor(this.timeRemaining / 3600)).padStart(2, '0');
      const minutes = String(Math.floor((this.timeRemaining % 3600) / 60)).padStart(2, '0');
      const seconds = String(this.timeRemaining % 60).padStart(2, '0');

      return `${hours}:${minutes}:${seconds}`;
    },
  },
  watch: {
    selectedOption(newOption) {
      const currentQuestion = this.questions[this.currentQuestionIndex];

      if (currentQuestion) {
        this.updateAnswer(currentQuestion.question_id, newOption);
      } else {
        console.warn('No current question available for selectedOption watcher');
      }
    },
  },
  methods: {
    async fetchQuestions() {
      try {
        const response = await api.getAssessmentQuestion(this.assessment_id);
        this.questions = response.questions || [];

        // Extract duration from the API response
        const [hours, minutes, seconds] = response.duration.split(':').map(Number);
        const durationInMilliseconds = ((hours * 60 + minutes) * 60 + seconds) * 1000;

        // Save duration to be used later
        if (!localStorage.getItem(`assessment_duration_${this.assessment_id}`)) {
          this.duration = durationInMilliseconds;
          localStorage.setItem(`assessment_duration_${this.assessment_id}`, this.duration);
        }

        this.startCountdown();
        this.loadAnswersFromLocalStorage();
      } catch (error) {
        console.error('Failed to fetch questions:', error.message || error);
      }
    },

    updateAnswer(questionId, selectedAnswer) {
      const question = this.questions.find(q => q.question_id === questionId);
      if (question) {
        this.answers[questionId] = {
          question: question.question,
          options: [
            question.option_1,
            question.option_2,
            question.option_3,
            question.option_4,
          ],
          correctAnswer: question.answer,
          selectedAnswer: selectedAnswer,
        };
        this.saveAnswersToLocalStorage();
      }
    },
    handleNextOrFinish() {
      if (this.currentQuestionIndex === this.questions.length - 1) {
        this.finishAssessment();
      } else {
        this.handleNext();
      }
    },
    handleReviewQuestions() {
      this.reviewQuestions = true;
    },
    handleNext() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.selectedOption = this.answers[this.questions[this.currentQuestionIndex].question_id]?.selectedAnswer || null;
      }
    },
    handlePrevious() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
        this.selectedOption = this.answers[this.questions[this.currentQuestionIndex].question_id]?.selectedAnswer || null;
      }
      this.reviewQuestions = false;
    },

    async finishAssessment() {
      // Map all questions, ensuring every question is included in the payload
      const result = this.questions.map((question) => ({
        question_id: question.question_id,
        selected_answer: this.answers[question.question_id]?.selectedAnswer || null, // Use null if no answer is provided
      }));

      // Generate the payload
      const payload = {
        assessment_id: this.assessment_id,
        exams: result,
      };

      console.log('Payload to send to backend:', payload); // Log for debugging

      try {
        // Send the payload to the backend
        await api.createExam(payload);
        await SweetAlertService.showSuccess('Success', 'Assessment submitted successfully!');
        this.clearAssessmentDataFromLocalStorage();
        this.$router.push({
          name: "StaffAssessmentDetailsPage",
          params: { assessment_id: this.assessment_id },
        });
      } catch (error) {
        console.error('Error:', error.message || 'Failed to submit Assessment');
        SweetAlertService.showError('Error', error.message || 'Error submitting Assessment.');
      }
    },
    updateTime() {
      const elapsed = Date.now() - this.startTime;
      const remaining = Math.floor((this.duration - elapsed) / 1000);

      if (remaining <= 0) {
        clearInterval(this.timer);
        this.timeRemaining = 0;
        this.finishAssessment();
      } else {
        this.timeRemaining = remaining;
      }
    },
    startCountdown() {
      const storedStartTime = localStorage.getItem(`assessment_start_time_${this.assessment_id}`);
      const storedDuration = localStorage.getItem(`assessment_duration_${this.assessment_id}`);

      if (storedStartTime && storedDuration) {
        // Use stored values
        this.startTime = Number(storedStartTime);
        this.duration = Number(storedDuration);
      } else {
        // Initialize and store values on first launch
        this.startTime = Date.now();
        this.duration = this.duration || 0; // Duration should be set from the database earlier
        localStorage.setItem(`assessment_start_time_${this.assessment_id}`, this.startTime);
        localStorage.setItem(`assessment_duration_${this.assessment_id}`, this.duration);
      }

      this.updateTime();
      this.timer = setInterval(this.updateTime, 1000); // Check time every second
    },
    saveAnswersToLocalStorage() {
      const answersToSave = JSON.stringify(this.answers);
      localStorage.setItem(`assessment_answers_${this.assessment_id}`, answersToSave);
    },
    loadAnswersFromLocalStorage() {
      const savedAnswers = localStorage.getItem(`assessment_answers_${this.assessment_id}`);
      try {
        this.answers = savedAnswers ? JSON.parse(savedAnswers) : {};
      } catch (error) {
        console.error('Error parsing saved answers:', error);
        this.answers = {};
      }
    },
    clearAssessmentDataFromLocalStorage() {
      const keysToClear = [
        `assessment_answers_${this.assessment_id}`,
        `assessment_start_time_${this.assessment_id}`,
        `assessment_duration_${this.assessment_id}`,
      ];

      keysToClear.forEach(key => {
        if (localStorage.getItem(key)) {
          localStorage.removeItem(key);
          console.log(`Cleared local storage for key: ${key}`);
        } else {
          console.warn(`No data found in local storage for key: ${key}`);
        }
      });
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
    this.saveAnswersToLocalStorage();
  },
};
</script>

<style lang="css" scoped>
.overflow-y-scroll {
  overflow-y: scroll;
}
</style>