<template>
    <div>
        <AlertLayout title="Reminders!"
            message="Kindly remember to set 5 extra questions on each C.A.T to enable jumbling up the questions for the examiners."
            type="warning" />

        <div class="mt-6 overflow-y-auto max-h-[80vh]">
            <div v-for="(question, index) in questions" :key="index"
                class="bg-white py-6 px-16 rounded-lg shadow-md mb-6">
                <div class="flex justify-end">
                    <button @click="removeQuestion(index)" v-if="questions.length > 1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                </div>
                <h2 class="text-2xl font-bold mb-4">Question {{ index + 1 }}</h2>
                <div class="mb-4">
                    <label for="question" class="block font-medium mb-2">Question:</label>
                    <textarea :id="'question-' + index" v-model="question.text"
                        class="w-full border-gray-300 rounded-md p-2 shadow-sm" rows="3"
                        placeholder="Enter the question"></textarea>
                </div>
                <div class="mb-4">
                    <label class="block font-medium mb-2">Options:</label>
                    <div v-for="(option, optionIndex) in ['option1', 'option2', 'option3', 'option4']" :key="option"
                        class="flex items-center mb-4">
                        <input type="radio" :name="'answer-' + index" :value="question[option]"
                            v-model="question.answer" class="form-radio h-5 w-5 text-indigo-600" />
                        <input type="text" v-model="question[option]"
                            class="w-full border-gray-300 rounded-md p-2 shadow-sm ml-2"
                            :placeholder="'Option ' + (optionIndex + 1)" />
                    </div>
                </div>
                <div class="flex justify-end" v-if="questions.length < maxQuestions">
                    <button class="bg-custom-yellow text-white py-2 px-4 rounded-md hover:bg-yellow-600"
                        @click="addQuestion" v-if="index === questions.length - 1">
                        Add Question
                    </button>
                </div>
            </div>
        </div>
        <div class="mt-6 flex justify-end">
            <button class="bg-custom-yellow text-white py-2 px-4 rounded-md hover:bg-yellow-600" @click="saveQuestions">
                Submit
            </button>
        </div>
    </div>
</template>

<script>
import AlertLayout from '@/components/AlertLayout.vue';
import SweetAlertService from '@/services/SweetAlertService';
import api from '@/services/api';

export default {
    name: 'AddAssessementQuestionsPage',
    components: {
        AlertLayout,
    },
    data() {
        return {
            maxQuestions: null,
            questions: this.loadQuestionsFromLocalStorage() || [this.createEmptyQuestion()],
            loading: false,
            error: null,
        };
    },
    created() {
        this.assessment_id = this.$route.params.assessment_id;
        this.fetchAssessment();
    },
    methods: {
        createEmptyQuestion() {
            return {
                text: '',
                option1: '',
                option2: '',
                option3: '',
                option4: '',
                answer: null,
            };
        },
        loadQuestionsFromLocalStorage() {
            const questions = localStorage.getItem('questions');
            return questions ? JSON.parse(questions) : null;
        },
        saveQuestionsToLocalStorage() {
            localStorage.setItem('questions', JSON.stringify(this.questions));
        },
        async fetchAssessment() {
            this.loading = true;
            this.error = null;
            try {
                const response = await api.getAssessment(this.assessment_id);
                this.assessment = response.data || response;
                this.maxQuestions = this.assessment.number_of_questions + 5;
            } catch (error) {
                this.error = error.message || "Failed to fetch assessment details.";
                console.error("Error fetching assessment:", error);
            } finally {
                this.loading = false;
            }
        },
        addQuestion() {
            if (this.questions.length < this.maxQuestions) {
                this.questions.push(this.createEmptyQuestion());
                this.saveQuestionsToLocalStorage();
            }
        },
        removeQuestion(index) {
            this.questions.splice(index, 1);
            this.saveQuestionsToLocalStorage();
        },
        async saveQuestions() {
            // Validate questions
            const invalidQuestions = this.questions.filter(question =>
                !question.text ||
                !question.option1 && !question.option2 && !question.option3 && !question.option4 ||
                !question.answer
            );

            if (invalidQuestions.length > 0) {
                await SweetAlertService.showError('Error', 'Please ensure all questions and answers are filled out correctly.');
                return;
            }
            const payload = {
                questions: this.questions.map((question) => ({
                    question: question.text,
                    option_1: question.option1,
                    option_2: question.option2,
                    option_3: question.option3,
                    option_4: question.option4,
                    answer: question.answer,
                })),
            };

            // console.log('Saving questions:', payload);

            try {
                await api.createQuestion(this.assessment_id, payload);
                await SweetAlertService.showSuccess('Success', 'Assessment submitted successfully!');
                localStorage.removeItem('questions'); // Clear local storage after successful submission
                this.$router.push({ name: 'SupervisorLearningManagementPage' });
            } catch (error) {
                console.error('Error:', error.message || 'Failed to submit Assessment');
                await SweetAlertService.showError('Error', error.message || 'Error submitting Assessment.');
            }
        },
    },
    watch: {
        questions: {
            handler() {
                this.saveQuestionsToLocalStorage();
            },
            deep: true,
        },
    },
};
</script>