<template>
  <div class="ml-7">
    <h2 class="text-lg font-semibold mb-4 mt-10">Personal Information</h2>
    <!-- Form Fields for Recording Donation -->
    <form @submit.prevent="moveToNext">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label class="block mb-1" for="lastName">Last Name/Other Name <span class="text-red-500">*</span></label>
          <input id="lastName" type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter Last Name/Other Name" v-model="localFormData.donorInformation.last_name" />
        </div>

        <div>
          <label class="block mb-1" for="firstName">First Name <span class="text-red-500">*</span></label>
          <input id="firstName" type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter First Name" v-model="localFormData.donorInformation.first_name" />
        </div>

        <div>
          <label class="block mb-1" for="gender">Gender <span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="gender"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="localFormData.donorInformation.gender">
              <option value="" disabled selected>Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div>
            <label class="block mb-1" for="birthDate">Date of Birth <span class="text-red-500">*</span></label>
            <input type="date" id="birthDate"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="select Date of Birth" v-model="localFormData.donorInformation.dob" @change="calculateAge"
              :max="maxDate" required />
          </div>

          <div>
            <label class="block mb-1" for="age">Age <span class="text-red-500">*</span></label>
            <input id="age" type="text"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="localFormData.donorInformation.age" readonly />
          </div>
        </div>

        <div>
          <label class="block mb-1" for="idNum">ID. Number / Passport <span class="text-red-500">*</span></label>
          <input id="idNum" type="number"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter ID. Number / Passport" v-model="localFormData.donorInformation.id_number" />
        </div>
      </div>

      <h2 class="text-lg font-semibold mb-4 mt-10">Medical History</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label class="block mb-1" for="donorStatus">Donor Status <span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="donorStatus"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="localFormData.donorInformation.donor_status">
              <option value="" disabled selected>Select status</option>
              <option value="FirstTime">First Time</option>
              <option value="Repeat">Repeat</option>
              <option value="Regular">Regular</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="patientName">Patient Name <span class="text-red-500">*</span></label>
          <input id="patientName" type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter Patient Name" v-model="localFormData.donorInformation.patient" />
        </div>

        <div>
          <label class="block mb-1" for="wardNum">Ward Number <span class="text-red-500">*</span></label>
          <input id="wardNum" type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter Ward Number" v-model="localFormData.donorInformation.ward" />
        </div>

        <div>
          <label class="block mb-1" for="donationType">Donation Type <span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="donationType"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="localFormData.donorInformation.donation_status">
              <option value="" disabled selected>Select status</option>
              <option value="Replacement">
                Replacement (Replaces blood unit for specific patient)
              </option>
              <option value="Random">
                Random (First-time donor, not for specific patient)
              </option>
              <option value="Frequent">
                Regular-donor (Frequent donor, not for specific patient)
              </option>
              <option value="Designated">
                Designated/Directed (Donates for specific patient with doctor's
                letter)
              </option>
              <option value="Autologous">
                Autologous (Donates for future self use with doctor's letter)
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="regularDonor">Would you like to become a regular blood donor?
            <span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="regularDonor"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="localFormData.donorInformation.enroll_as_donor">
              <option value="" disabled selected>Select</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <h2 class="text-lg font-semibold mb-4 mt-10">Contact Information</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label class="block mb-1" for="phone">Phone Number<span class="text-red-500">*</span></label>
          <input id="phone" type="number"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter phone number" v-model="localFormData.donorInformation.phone_number" />
        </div>

        <div>
          <label class="block mb-1" for="workPhone">Work Telephone<span class="text-red-500">*</span></label>
          <input id="workPhone" type="number"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter phone number" v-model="localFormData.donorInformation.work_phone" />
        </div>

        <div>
          <label class="block mb-1" for="email">Email <span class="text-red-500">*</span></label>
          <input id="address" type="email"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter Email" v-model="localFormData.donorInformation.email" />
        </div>

        <div>
          <label class="block mb-1" for="address">Postal Address <span class="text-red-500">*</span></label>
          <input id="address" type="text"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Enter Postal Address" v-model="localFormData.donorInformation.physical_address" />
        </div>

        <div>
          <label class="block mb-1 text-gray-700 font-medium" for="county"> county <span
              class="text-red-500">*</span></label>
          <div class="relative">
            <select id="county_id" v-model="localFormData.donorInformation.county_id"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled>Select county</option>
              <option v-for="county in counties" :key="county.county_id" :value="county.county_id">
                {{ county.name }}
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1" for="appointmentDate">Preferred appointment date (if donor is not in the donation
            center)
            <span class="text-red-500">*</span></label>
          <input type="text" id="appointmentDate"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Select preferred date" onfocus="(this.type='date')"
            onblur="if(this.value===''){this.type='text'}" v-model="localFormData.donorInformation.preferred_date" />
        </div>

        <div>
          <label class="block mb-1" for="appointmentTime">Preferred appointment time (if donor is not in the donation
            center)
            <span class="text-red-500">*</span></label>
          <input type="time" id="appointmentTime"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Select preferred time" v-model="localFormData.donorInformation.preferred_time" />
        </div>
      </div>

      <div class="mt-8 flex justify-between">
        <button type="button" class="px-4 py-2 border rounded text-orange-500 border-orange-500"
          @click="moveToPrevious">
          Back
        </button>
        <CustomButton type="submit" label="next" class="px-4 py-2" bg-class="bg-custom-yellow" />
      </div>
    </form>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';


export default {
  name: "NewDonorInformationFormMobile",
  components: {
    CustomButton,
  },
  data() {
    return {
      localFormData: { ...this.formData },
      maxDate: this.calculateMaxDate(),
      counties: [],
    };
  },

  created() {
    this.maxDate = this.calculateMaxDate();
    this.fetchRegions();
  },

  watch: {
    'localFormData.donorInformation.dob': 'calculateAge',
    localFormData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true,
    },
  },

  props: {
    formData: Object
  },

  methods: {
    calculateMaxDate() {
      const today = new Date();
      const year = today.getFullYear() - 18;
      const month = today.getMonth() + 1;
      const day = today.getDate();
      return `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""
        }${day}`;
    },
    calculateAge() {
      const dob = this.localFormData.donorInformation.dob;
      if (!dob) {
        this.localFormData.donorInformation.age = "";
        return;
      }

      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      this.localFormData.donorInformation.age = age;
    },

    async validateBookingSlot() {
      try {
        const response = await api.validateBookingSlot({
          preferred_date: this.localFormData.donorInformation.preferred_date,
          preferred_time: this.localFormData.donorInformation.preferred_time,
        });
        console.log('API response:', response); // Log response for debugging

        return response.available;
      } catch (error) {
        console.error('Error validating booking slot:', error);
        return false;
      }
    },

    async fetchRegions() {
      try {
        const response = await api.getRegions();
        const kenyaRegion = response.regions.find((region) => region.name === 'Kenya');
        this.counties = kenyaRegion ? kenyaRegion.counties : [];
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    },

    async moveToNext() {
      const isSlotAvailable = await this.validateBookingSlot();

      if (!isSlotAvailable) {
        alert('The selected date and time are not available. Please choose a different slot.');
        return;
      }
      this.$emit("move-to-next-step");
    },

    moveToPrevious() {
      this.$emit("move-to-previous-step");
    },
  },
};
</script>
