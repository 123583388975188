<template>
  <div>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
      <table class="w-full text-left rtl:text-right text-gray-700">
        <thead>
          <tr class="bg-gray-100">
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input id="checkbox-all-search" type="checkbox"
                  class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label for="checkbox-all-search" class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Serial Number
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Blood Group
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Patient Name
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                IP/OP Number
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Patient Blood Group
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Ward
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Requisition Date
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Issued Out Date
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Status
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(unit, index) in filteredUnits" :key="index" class="border-b">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-search-' + index" type="checkbox" class="w-4 h-4" />
                <label :for="'checkbox-table-search-' + index" class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="px-6 py-2">{{ unit.product?.pint_serial }}</td>
            <td class="px-6 py-2">{{ unit.product?.blood_group?.description }}</td>
            <td class="px-6 py-2">{{ unit.requisition_product?.patient_requisition?.patient?.first_name }}
              {{ unit.requisition_product?.patient_requisition?.patient?.last_name }}
            </td>
            <td class="px-6 py-2">{{ unit.requisition_product?.patient_requisition?.patient?.ipop }}</td>
            <td class="px-6 py-2">{{ unit.requisition_product?.patient_requisition?.patient?.blood_group?.description }}
            </td>
            <td class="px-6 py-2">{{ unit.requisition_product?.patient_requisition?.requisition?.department?.name }}
            </td>
            <td class="px-6 py-2">{{ unit.requisition_product?.patient_requisition?.requisition?.date_added }}</td>
            <td class="px-6 py-2">{{ unit.issued_on }}</td>
            <td class="px-6 py-2">
              <span class="p-2 rounded-lg" :class="{
                'dark:bg-green-100 dark:text-green-800': ['Transfused', 'Issued'].includes(unit.product?.status),
                'dark:bg-red-100 dark:text-red-800': ['Rejected'].includes(unit.product?.status)
              }">
                {{ unit.product?.status }}
              </span>
            </td>
            <td class="flex items-center p-4">
              <CustomButton v-if="unit.product?.status === 'Issued'" label="Start Transfusion"
                @click="handleLoadWitness(unit.assigned_units_id, unit.product_id)"
                bg-class="bg-custom-yellow text-sm py-1" />
              <CustomButton v-if="unit.product?.status === 'Transfused' || unit.product?.status === 'Rejected'"
                label="View Chart" @click="handleTransfusionChart(unit.assigned_units_id)"
                bg-class="bg-custom-yellow text-sm py-1" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="flex justify-center gap-4 mt-4">
      <button @click="previousPage" :disabled="currentPage === 1"
        class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button">
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m15 19-7-7 7-7" />
        </svg>
        Previous
      </button>

      <div class="flex items-center gap-2">
        <button v-for="page in visiblePages" :key="page" @click="goToPage(page)" :class="{
          'bg-custom-blue text-white': page === currentPage,
          'text-custom-blue': page !== currentPage
        }"
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            {{ page }}
          </span>
        </button>
      </div>

      <button @click="nextPage" :disabled="currentPage === totalPages"
        class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button">
        Next
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m9 5 7 7-7 7" />
        </svg>
      </button>
    </div>

    <div v-if="showClaimsModal"
      class="fixed inset-0 bg-gray-800 bg-opacity-60 overflow-y-auto h-full w-full flex items-center justify-center">
      <div class="bg-white p-8 rounded-lg shadow-xl w-full sm:w-4/6 md:w-3/6 lg:w-2/6 max-w-full">
        <h2 class="text-2xl text-gray-800 font-bold mb-4">Transfusion Witness</h2>
        <hr class="h-px my-4 bg-orange-200 border-0 dark:bg-orange-200">
        <div class="mb-6">
          <div class="grid grid-cols-1 md:grid-cols-1 gap-5">
            <div>
              <label class="block mb-1">Select witness <span class="text-red-500">*</span></label>
              <select v-model="formData.witness" class="w-full p-2.5 border rounded bg-gray-100 shadow-sm">
                <option v-for="user in witness" :key="user.user_id" :value="user.user_id">
                  {{ user.first_name }} {{ user.last_name }}
                </option>
              </select>
            </div>
            <div>
              <label class="block mb-1">Started on</label>
              <input v-model="formData.started_on" type="datetime-local" id="timeOfReaction"
                class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300">
            </div>
          </div>
          <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-200">
        </div>
        <div class="flex justify-end space-x-2">
          <button type="button" @click="closeClaimModal"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded">Cancel</button>
          <button @click="handleStartTransfusion()" class="px-4 py-2 bg-custom-yellow text-white rounded">Start</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import CustomButton from '@/components/CustomButton.vue';

export default {
  name: "IssuedOutUnitsTab",
  components: {
    CustomButton,
  },
  props: {
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 0,
      perPage: 10,
      loading: false,
      assigned_units: [],
      assignedUnitId: '',
      productId: '',
      transfusion: null,
      witness: [],
      showClaimsModal: false,
      error: null,
      formData: {
        witness: '',
        started_on: '',
      },
      filters: {
        bloodGroup: "",
        status: '',
      },
    };
  },
  computed: {
    visiblePages() {
      return this.calculatePageRange();
    },
    filteredUnits() {
      const search = this.searchQuery.toLowerCase();
      const safeToLower = (value) =>
        value?.toString().toLowerCase() || "";

      return this.assigned_units.filter((unit) => {
        const {
          product,
          requisition_product: {
            patient_requisition: {
              patient,
              requisition,
            } = {},
          } = {},
        } = unit;

        const fieldsToSearch = [
          product?.blood_group?.description,
          patient?.first_name,
          patient?.last_name,
          patient?.ipop,
          patient?.blood_group?.description,
          requisition?.department?.name,
          requisition?.date_added,
          product?.issued_on,
          product?.status,
        ];

        return fieldsToSearch.some((field) => safeToLower(field).includes(search));
      });
    },
  },
  watch: {
    filters: { deep: true, handler: 'getAssignedUnits' },
    searchQuery: 'getAssignedUnits'
  },
  mounted() {
    this.getAssignedUnits()
    this.fetchUsers()
  },
  methods: {
    handleLoadWitness(assigned_unit_id, product_id) {
      this.assignedUnitId = assigned_unit_id
      this.productId = product_id
      this.showClaimsModal = true;
    },
    closeClaimModal() {
      this.showClaimsModal = false;
    },

    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };
      this.currentPage = 1;
    },

    resetFilters() {
      this.filters = {
        bloodGroup: "",
        status: "",
      };
      this.currentPage = 1;
    },

    async handleTransfusionChart(assigned_unit_id) {
      await this.fetchTransfusion(assigned_unit_id);
      const transfusionId = this.transfusion ? this.transfusion.transfusion_id : null;

      if (transfusionId) {
        this.$router.push({ name: 'TransfusionChart', params: { transfusion_id: transfusionId } });
      } else {
        console.error('Transfusion not found or transfusion_id is missing');
      }
    },

    async fetchTransfusion(assigned_unit_id) {
      try {
        const response = await api.getAssignedUnitTransfusion(assigned_unit_id);
        this.transfusion = response.data || response;
      } catch (error) {
        this.error = error.message || "Failed to fetch assigned units";
      }
    },

    async handleStartTransfusion() {
      const data = {
        witness: this.formData.witness,
        assigned_units_id: this.assignedUnitId,
        product_id: this.productId,
        started_on: this.formData.started_on,
      };
      const response = await api.startTransfusion(data);
      const transfusionId = response.transfusion_id
      this.$router.push({ name: 'TransfusionChart', params: { transfusion_id: transfusionId } });
    },

    async getAssignedUnits() {
      this.loading = true;
      try {
        const queryParams = { ...this.filters, search_query: this.searchQuery, page: this.currentPage, per_page: this.perPage };
        const response = await api.fetchAssignedUnits(queryParams);
        const fetchedData = response.data || response;
        // Filter out items with status "Issued"
        this.assigned_units = fetchedData.filter(item => item.status === "Issued");
        this.currentPage = response.current_page;
        this.totalPages = response.total_pages;
      } catch (error) {
        this.error = error.message || "Failed to fetch assigned units";
      } finally {
        this.loading = false;
      }
    },

    calculatePageRange() {
      const maxPagesToShow = 5;
      let startPage = Math.max(1, this.currentPage - Math.floor(maxPagesToShow / 2));
      let endPage = Math.min(this.totalPages, startPage + maxPagesToShow - 1);

      // Adjust start page if we're near the end
      if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },

    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.getAssignedUnits();
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.getAssignedUnits();
      }
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.getAssignedUnits();
      }
    },

    async fetchUsers() {
      try {
        const response = await api.getUsers();
        const allUsers = response.data || response;

        // Filter users who have the user_roles property and it's not empty
        this.witness = allUsers.filter(user => Array.isArray(user.user_roles) && user.user_roles.length > 0 && (user.user_roles[0]?.role?.name == 'Nurse' || user.user_roles[0]?.role?.name == 'Consultant'));
      } catch (error) {
        this.error = error.message || "Failed to fetch witness";
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
