<template>
    <div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
            <table class="w-full text-left rtl:text-right text-gray-700">
                <thead>
                    <tr class="bg-gray-100">
                        <th scope="col" class="p-4">
                            <div class="flex items-center">
                                <input id="checkbox-all-search" type="checkbox"
                                    class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="checkbox-all-search" class="sr-only">checkbox</label>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Donor Names
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                National ID
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Blood Group
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Gender
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <!-- <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Donation Process
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th> -->
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Recall Consent
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Appeal Date
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(donor_recall, index) in filteredDonors" :key="index" class="border-b">
                        <td class="w-4 p-4">
                            <div class="flex items-center">
                                <input :id="'checkbox-table-search-' + index" type="checkbox" class="w-4 h-4">
                                <label :for="'checkbox-table-search-' + index" class="sr-only">checkbox</label>
                            </div>
                        </td>
                        <td class="px-6 py-2">{{ donor_recall.donor?.user?.first_name }} {{
                            donor_recall.donor?.user?.last_name }}</td>
                        <td class="px-6 py-2">{{ donor_recall.donor?.user?.id_number }}</td>
                        <td class="px-6 py-2">{{ donor_recall.donor?.user?.blood_group?.description ?? 'N/A' }}</td>
                        <td class="px-6 py-2">{{ donor_recall.donor?.user?.gender }}</td>
                        <!-- <td class="px-6 py-2">
                            {{ donor_recall.blood_donations && donor_recall.blood_donations.length > 0
                                ? donor_recall.blood_donations[0].technology
                            : 'Unknown' }}
                        </td> -->
                        <td class="px-6 py-2">{{ donor_recall.donor?.enroll_as_donor ? 'Yes' : 'No' }}</td>
                        <td class="px-6 py-2">{{ new Date(donor_recall.created_at).toLocaleDateString('en-CA') }}</td>
                        <td class="flex items-center px-6 py-2">
                            <CustomButton label="View Details" bg-class="bg-custom-yellow text-sm py-1"
                                @click="handleDonorDetails(donor_recall.donor?.donor_id)" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="flex justify-center gap-4 mt-4">
            <button disabled
                class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button">
                <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m15 19-7-7 7-7" />
                </svg>
                Previous
            </button>
            <div class="flex items-center gap-2">
                <button
                    class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg bg-custom-blue text-center align-middle font-medium text-white shadow-md shadow-custom-blue/10 transition-all hover:shadow-lg hover:shadow-custom-blue/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button">
                    <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        1
                    </span>
                </button>
                <button
                    class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button">
                    <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        2
                    </span>
                </button>
                <button
                    class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button">
                    <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        3
                    </span>
                </button>
                <button
                    class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button">
                    <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        4
                    </span>
                </button>
                <button
                    class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button">
                    <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        5
                    </span>
                </button>
            </div>
            <button
                class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button">
                Next
                <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m9 5 7 7-7 7" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue';
import api from '@/services/api';

export default {
    name: 'DonationAppealsTab',
    components: {
        CustomButton,
    },
    created() {
        this.fetchDonorRecalls();
    },
    props: {
        searchQuery: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            donor_recalls: [],  // Changed to donor_recalls to match consistent naming
            error: null,
            filters: {
                bloodGroup: '',
                dateRegistered: '',
                gender: '',
            }
        }
    },
    computed: {
        filteredDonors() {
            return this.donor_recalls.filter(donor_recall => {
                const search = this.searchQuery.toLowerCase();
                const safeToLower = value => typeof value === 'string' ? value.toLowerCase() : '';

                // Apply search filter
                const matchesSearch =
                    safeToLower(donor_recall.donor?.user?.first_name).includes(search) ||
                    safeToLower(donor_recall.donor?.user?.last_name).includes(search) ||
                    safeToLower(donor_recall.donor?.user?.id_number).includes(search) ||
                    safeToLower(donor_recall.donor?.user?.blood_group?.description).includes(search) ||
                    safeToLower(donor_recall.donor?.user?.gender).includes(search) ||
                    safeToLower(donor_recall.donor?.county_id?.name).includes(search) ||
                    safeToLower(donor_recall.donor?.terms_agreed).includes(search) ||
                    safeToLower(new Date(donor_recall.created_at).toLocaleDateString('en-CA')).includes(search);

                // Apply additional filters
                const matchesBloodGroup =
                    this.filters.bloodGroup === '' ||
                    safeToLower(donor_recall.donor?.user?.blood_group?.description) === safeToLower(this.filters.bloodGroup);

                const matchesDateRegistered = this.filters.dateRegistered === '' ||
                    new Date(donor_recall.created_at).toLocaleDateString('en-CA').includes(this.filters.dateRegistered);

                const matchesGender = this.filters.gender === '' ||
                    safeToLower(donor_recall.donor?.user?.gender) === safeToLower(this.filters.gender);


                return matchesSearch && matchesBloodGroup && matchesDateRegistered &&
                    matchesGender;
            });
        }
    },
    methods: {
        handleDonorDetails(donorId) {
            console.log('Passing donor:', donorId);
            this.$router.push({ name: 'DonorDetailsPage', params: { donor_id: donorId } });
        },

        async fetchDonorRecalls() {
            try {
                const response = await api.getDonorRecalls();
                this.donor_recalls = response.data || response;
                console.log('Recall data:', this.donor_recalls);
            } catch (error) {
                this.error = error.message || 'Failed to fetch donors';
            }
        },

        applyFilters(filters) {
            this.filters = { ...this.filters, ...filters };
        },

        resetFilters() {
            this.filters = {
                bloodGroup: '',
                dateRegistered: '',
                gender: '',
                donationProcess: ''
            };
        }
    },
}
</script>



<style lang="css" scoped></style>