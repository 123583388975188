<template>
  <div>
    <AlertLayout
      title="Reminders!"
      message="Incorrect information will lead to nurses being unable to request for blood except for theatre."
      type="warning"
    />
    <div class="mx-auto p-10 border border-t-0">
      <h2 class="text-lg font-bold mb-6">{{ bed_id ? 'Update Bed' : 'Add new bed' }}</h2>
      <form class="grid grid-cols-1 md:grid-cols-1 gap-5">
        <div>
          <label class="block mb-2 text-gray-700 font-medium">Select Bed Type<span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="blood_group" v-model="formData.bed_type"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            required>
            <option value="" disabled>Select bed type</option>
            <option v-for="bed_type in bed_types" :key="bed_type.bed_type_id" :value="bed_type.bed_type_id">
              {{ bed_type.description }}
            </option>
          </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div>
          <label class="block mb-1" for="reason">Name <span class="text-red-500">*</span></label>
          <input type="text"
            v-model="formData.name"
            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Bed description" required>
        </div>
        <div>
          <label class="block mb-1" for="reason">Description <span class="text-red-500">*</span></label>
          <input type="text"
            v-model="formData.description"
            class="w-full p-2 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Bed description" required>
        </div>
      </form>
      <div class="flex items-center justify-between mt-8">
       
         <button @click="handleBackToDepartments" 
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Back</button>
        <CustomButton
          label="Save"
          v-if="!bed_id"
          bg-class="bg-custom-yellow px-8 py-3"
          @click="handleSubmitNewBed"
        />
        <CustomButton
          label="Update"
          v-if="bed_id"
          bg-class="bg-custom-yellow px-8 py-3"
          @click="handleUpdateBed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: "NewBedForm",
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      user: '',
      bed_id: '',
      formData: {
        bed_type: '',
        name: '',
        description: '',
      },
      status: 'active',
      error: null,
      bed_types: [],
    };
  },
  mounted() {
    this.user = this.$store.getters["auth/user"];
    this.bed_id = this.$route.params.bed_id;
  },
  created() {
    this.fetchBedTypes();
    const bedId = this.$route.params.bed_id;
    if (bedId) {
      this.fetchBedData(bedId);
    }
  },
  methods: {
    validateForm() {
      const emptyFields = [];
      for (const [key, value] of Object.entries(this.formData)) {
        if (value === '') {
          emptyFields.push(key.replace(/_/g, ' '));
        }
      }
      return emptyFields;
    },
    handleBackToDepartments() {
      this.$router.push({ name: 'DepartmentsPage' });
    },
    async fetchBedTypes() {
      try {
        const response = await api.getBedTypes();
        this.bed_types = response;
      } catch (error) {
        console.error('Error fetching blood groups:', error);
      }
    },
    async fetchBedData(bedId) {
      try {
        const response = await api.getBed(bedId);
        const bedData = response.data || response;
        this.populateFormData(bedData);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        this.error = error.message || 'Failed to fetch user data';
        SweetAlertService.showError('Error', 'Failed to fetch bed data.');
      }
    },
    populateFormData(bedData) {
      this.formData.bed_type = bedData.bed_type_id || '';
      this.formData.name = bedData.name || '';
      this.formData.description = bedData.description || '';
    },
    async handleSubmitNewBed() {
      const data = {
        bed_type_id: this.formData.bed_type,
        name: this.formData.name,
        description: this.formData.description,
      };

      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        // console.log('data:', data);
        await api.createBed(data);
        await SweetAlertService.showSuccess('Success!', 'Bed saved successfully!.');
        this.$router.push({ name: 'DepartmentsPage' });
      } catch (error) {
        this.error = error.message || 'Failed to create bed';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', 'Error saving bed.');
      }
    },
    async handleUpdateBed() {
      const data = {
        bed_type_id: this.formData.bed_type,
        name: this.formData.name,
        description: this.formData.description,
      };

      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        await api.updateBed(this.bed_id, data);
        await SweetAlertService.showSuccess('Success!', 'Bed updated successfully!.');
        this.$router.push({ name: 'DepartmentsPage' });
      } catch (error) {
        this.error = error.message || 'Failed to update bed';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', 'Error updating bed.');
      }
    },
  },
};
</script>

<style lang="css" scoped>
/* Your existing styles */
</style>
