<template>
  <div class="container mx-auto p-10 border border-t-0">
    <h2 class="text-2xl font-bold mb-6">Record pre-donation vitals</h2>

    <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="weight">Weight <span
            class="text-red-500">*</span></label>
        <input id="weight" type="number" v-model="formData.weight"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          placeholder="Weight" required>
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="hb_levels">HB levels <span
            class="text-red-500">*</span></label>
        <input id="hb_levels" type="number" v-model="formData.hb_levels"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          placeholder="HB levels" required>
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="temperature">Temperature <span
            class="text-red-500">*</span></label>
        <input id="temperature" type="number" v-model="formData.temperature"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          placeholder="Temperature" required>
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="pulse">Pulse <span
            class="text-red-500">*</span></label>
        <input id="pulse" type="number" v-model="formData.pulse"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          placeholder="Pulse" required>
      </div>

      <div>
        <label class="block mb-2 text-gray-700 font-medium" for="blood_pressure">Blood Pressure <span
            class="text-red-500">*</span></label>
        <input id="blood_pressure" type="text" v-model="formData.blood_pressure"
          class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
          placeholder="Blood Pressure (e.g. 90/110)" required>
      </div>

      <div>
            <label class="block mb-1" for="regularDonor"
              >Arm Inspection
              <span class="text-red-500">*</span></label
            >
            <div class="relative">
              <select
                id="regularDonor"
                class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.arm_inspection " required
              >
                <option value="" disabled selected>Select</option>
                <option value="pass">pass</option>
                <option value="fail">fail</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
    </form>

    <div class="mt-8 flex justify-between">
      <button class="px-4 py-2 border rounded text-orange-500 border-orange-500" @click="handleBack">Back</button>
      <div class="flex items-center space-x-4 mt-4 sm:mt-0">
        <CustomButton label="Save and continue" bg-class="bg-custom-yellow px-8 py-3" @click="handleSaveAndContinue" />
        <CustomButton label="Save and defer" bg-class="bg-custom-red px-8 py-3" @click="handleSaveAndDefer" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue';
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: 'DonorVitalsForm',
  components: {
    CustomButton,
  },
  props: {
    donorId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formData: {
        weight: '',
        hb_levels: '',
        temperature: '',
        pulse: '',
        blood_pressure: '',
        arm_inspection:''
      }
    };
  },
  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    validateForm() {
      const emptyFields = [];
      for (const [key, value] of Object.entries(this.formData)) {
        if (value === '') {
          emptyFields.push(key.replace(/_/g, ' '));
        }
      }
      return emptyFields;
    },

    async handleSaveAndContinue() {
      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {

        const bloodPressure = this.formData.blood_pressure;

        // Validate the format using a regular expression
        const validFormat = /^\d{2,3}\/\d{2,3}$/.test(bloodPressure);
        if (!validFormat) {
          SweetAlertService.showError('Invalid Input', 'Please enter blood pressure in the correct format (e.g., 120/80).');
          return;
        }

        // Extract systolic and diastolic values
        const [systolic, diastolic] = bloodPressure.split('/').map(Number);

        // Combine formData with additional fields like added_by
        const payload = {
          ...this.formData,
          systolic: systolic,  // Send systolic value separately
          diastolic: diastolic, // Send diastolic value separately
          added_by: this.user().user_id,
        };

        const response = await api.createDonorVital(this.donorId, payload);
        console.log('Donor vitals saved successfully:', response);
        await SweetAlertService.showSuccess('Success!', 'Donor vitals saved successfully!.');
        this.$emit('move-to-next-step', this.donorId);
      } catch (error) {
        console.error('Error submitting the form:', error);
        SweetAlertService.showError('Error', 'Something went wrong while saving donor vitals.');
      }
    },

    async handleSaveAndDefer() {
      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        const bloodPressure = this.formData.blood_pressure;

        const validFormat = /^\d{2,3}\/\d{2,3}$/.test(bloodPressure);
        if (!validFormat) {
          SweetAlertService.showError('Invalid Input', 'Please enter blood pressure in the correct format (e.g., 120/80).');
          return;
        }

        const [systolic, diastolic] = bloodPressure.split('/').map(Number);

        const payload = {
          ...this.formData,
          systolic: systolic, 
          diastolic: diastolic, 
          added_by: this.user().user_id,
        };

        const response = await api.createDonorVital(this.donorId, payload);
        console.log('Donor vitals saved successfully:', response);

        // Extract donor_id from response
        const newDonorId = this.donorId;
        if (!newDonorId) {
          throw new Error('Missing donor_id in response');
        }

        await SweetAlertService.showSuccess('Success!', 'Donor vitals saved. Continue to defferal form.');

        // Use the extracted donor_id for redirection
        this.$router.push({
          name: 'NewDonorDeferralForm',
          params: { donor_id: newDonorId }
        });

      } catch (error) {
        console.error('Error submitting the form:', error);
        SweetAlertService.showError('Error', 'Something went wrong while saving donor vitals and initiating deferral.');
      }
    },

    handleBack() {
      this.$emit('move-to-previous-step');
    }
  }
};
</script>

<style scoped>
/* Add any specific styles here if needed */
</style>
