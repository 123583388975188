<template>
  <div class="flex h-screen bg-white font-roboto">
    <!-- Sidebar -->
    <div :class="['transition-all duration-300', isSidebarShrunk ? 'w-16' : 'w-72']"
      class="relative bg-custom-blue text-white">
      <div class="flex items-center justify-between px-6 py-3">
        <div v-if="!isSidebarShrunk" class="flex items-center">
          <img :src="facilityLogo" alt="Facility Logo" class="object-cover w-18 h-10"   @error="onImageError"  />

        </div>
        <p>
        {{ facilityData.name }}
        </p>
        <div>
          <button @click="toggleSidebar" class="text-white focus:outline-none">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h14" />
            </svg>
          </button>
        </div>
      </div>

      <nav>
        <!-- Loop through sidebarTabs -->
        <div v-for="tab in sidebarTabs" :key="tab.name">
          <router-link v-if="!tab.children" :to="tab.route"
            :class="[isActive(tab) ? activeClass : '', 'flex items-center px-4 py-3 m-2 duration-200 text-white rounded-md']">
            <div v-html="tab.icon" class="w-6 h-6"></div>
            <!-- Conditionally show the name based on sidebar state -->
            <span v-if="!isSidebarShrunk" class="ml-4">{{ tab.name }}</span>
          </router-link>

          <!-- Dropdown tabs -->
          <div v-else>
            <div class="flex items-center px-4 py-3 m-2 duration-200 text-white rounded-md cursor-pointer"
              @click="toggleDropdown(tab)">
              <div v-html="tab.icon" class="w-6 h-6"></div>
              <span v-if="!isSidebarShrunk" class="ml-4">{{ tab.name }}</span>
            </div>
            <div v-if="isDropdownOpen(tab)" :class="[isSidebarShrunk ? 'ml-0' : 'ml-8']">
              <router-link v-for="child in tab.children" :key="child.name" :to="child.route"
                :class="[isActive(child) ? activeClass : '', 'flex items-center px-4 py-2 m-2 duration-200 text-white rounded-md']">
                <div v-html="child.icon" class="w-5 h-5"></div>
                <span v-if="!isSidebarShrunk" class="ml-4">{{ child.name }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <!-- Main content -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <Header />
      <main class="flex-1 overflow-x-hidden overflow-y-auto bg-white">
        <div class="mx-auto px-6 py-2">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import sidebarTabs from '@/config/sidebar';
import Header from "./HeaderLayout.vue";
import { mapGetters } from 'vuex';
// import api from '@/services/api';


export default {
  name: 'SidebarLayout',
  components: {
    Header
  },
  data() {
    return {
      sidebarTabs,
      activeClass: 'bg-custom-yellow',
      openDropdown: null,
      isSidebarShrunk: false,
      user: [],
      facilityLogo: null, 
      facilityData: {
        name: '',
        email: '',
        facility_code: '',
        facility_type: '',
        contact_person_name: '',
        contact_person_phone: '',
        country_id: '',
        county_id: '',
        second_level_devision: '',
        third_level_devision: '',
        longitude: '',
        latitude: '',
        account_status: '',
        logo: '',
      },
    };
  },

  mounted() {
    this.user = this.$store.getters["auth/user"];  
  },

  created() {

  // Retrieve the facility logo from local storage or user data
  this.user = this.$store.getters["auth/user"];
  const userFacilityLogo = this.user?.user_roles?.[0]?.facility_department?.facility?.logo;

  // Set the resolved logo
  this.facilityLogo = `/uploads/${userFacilityLogo}` ;

  console.log('Resolved facilityLogo:', this.facilityLogo);
},




  computed: {
    ...mapGetters({
      token: 'auth/token', // Assuming token is stored in Vuex
    }),
  },
  // Navigation guard to check token before the component renders
  beforeRouteEnter(to, from, next) {
    const token = localStorage.getItem('token'); // Or use Vuex to get the token from the store
    
    // Check if token exists and is valid
    if (!token || isTokenExpired(token)) {
      next({ name: 'Login' }); // Redirect to login page
    } else {
      next(); // Proceed to the requested route
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarShrunk = !this.isSidebarShrunk;
    },
    isActive(tab) {
      return this.$route.path === tab.route;
    },
    toggleDropdown(tab) {
      this.openDropdown = this.openDropdown === tab ? null : tab;
    },
    isDropdownOpen(tab) {
      return this.openDropdown === tab;
    },

    onImageError() {
    // If the image fails to load, fall back to the default logo
    this.facilityLogo = require('@/assets/images/damu-sasa-logo.svg');
  },
   
  },
};

// Helper function to check if the token is expired
function isTokenExpired(token) {
  try {
    // Decode the token (assuming it's a JWT)
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    
    // If token's expiration time is less than current time, it's expired
    return payload.exp && payload.exp < currentTime;
  } catch (error) {
    console.error('Failed to decode token:', error);
    return true; // Treat any decoding errors as an invalid token
  }
}
</script>
