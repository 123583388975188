<template>
    <div >
      <h3 class="m-7  text-lg font-semibold mb-4 " > Blood Donor Educational Material</h3>
      <form class="ml-7" @submit.prevent="moveToNext">
        <EducationalAlerts 
        title="MAKING YOUR DONATION SAFE" 
        message="We know that you would not donate unless you think your blood is safe. However, in order for us to assess all risks that may affect you or a patient receiving a transfusion, it is essential that you answer each question <u>completely and accurately.</u> If you don’t understand a question, ask the blood center staff. All information provided is confidential." 
        type="warning"
        :hasMargin="true"
      />
      <EducationalAlerts 
        title="TO DETERMINE IF YOU ARW ELIGIBLE TO DONATE WE WILL:" 
        message="
              <li>Ask about your health and travel</li>
              <li> Ask about medicines you are or have taken</li>
              <li>Ask about your risk for infections that can be transmitted by blood-especially HIV/AIDS and viral hepatitis.</li>
              <li>Take your blood pressure, temperature and pulse</li>
              <li>Take a blood sample to be sure your blood count is acceptable</li>
             "
        type="warning"
      /> 
      <EducationalAlerts 
        title="IF YOU ARE ELIGIBLE TO DONATE WE WILL:"  
        message="
              <li> Clean your arm with an antiseptic. Please inform us if you have any skin allergies.</li>
              <li>Use a new, sterile and disposable needle to collect your blood.</li>
              
             "
        type="warning"
      />
      <EducationalAlerts 
        title="DONOR ELIGIBILITY – SPECIFIC INFORMATION" 
        message="Certain diseases such as HIV/AIDS and hepatitis, can spread through sexual contact and enter your blood stream. We will ask specific questions about sexual contact" 
        type="warning"
      />
      <EducationalAlerts 
        title="WHAT DO WE MEAN BY 'SEXUAL CONTACT'?" 
        message="The words “have sexual contact with” and “sex” are used in some of the questions we will ask you and apply to any of the activities below, whether or not a condom or other protection was used:
        <li> Vaginal sex (contact between penis and vagina).</li>
        <li>Oral sex (mouth or tongue on someone’s vagina, penis or anus)</li>
        <li>Anal sex (contact between penis and anus).</li>
        " 
        type="warning"
      />
      <EducationalAlerts 
        title="HIV/AIDS RISK BEHAVIOURS" 
        message="HIV is the virus that causes AIDS. It is spread mainly by sexual contact with an infected person or by sharing needles and syringes used by an infected person for injecting drugs." 
        type="warning"
      />
      <EducationalAlerts 
        title="HIV/AIDS RISK BEHAVIOURS" 
        message="<li> Have AIDS or have ever had a positive test for HIV</li>
        <li>Have EVER used needles to take any drugs not prescribed by the doctor</li>
        <li>Have EVER taken money, drugs or any other payment for sex</li>
        <li>Have had sexual contact IN THE PAST 12 MONTHS with anyone described above.</li>
        <li>Have had syphilis or gonorrhea IN THE PAST 12 MONTHS</li>
        <li>Have been in prison for more than 72 consecutive hours IN THE PAST 2 MONTHS. Your blood can transmit infections, including HIV/AIDS even if you feel well and all your tests are normal. That is because even the best tests cannot detect the virus for a period of time after you are infected.</li>
        <li> <u>DO NOT donate to get a test!</u>If you think you may be at risk for HIV/AIDS or any other infection, do not donate simply to get a test. Ask us where you can be tested outside the blood centre.</li>
        <li>The following symptoms can present before a HIV test turns positive:
          <li>Fever</li>
          <li>Enlarged lymph glands</li>
          <li>Sore throat</li>
          <li>Rash</li>
        </li>
        DO NOT donate if you have any of the above symptoms!" 
        type="warning"
      />

      <EducationalAlerts 
        title="TRAVEL TO OR BIRTH IN OTHER COUNTRIES" 
        message="Blood donor test may not be available for some infections that are found in some countries. If you were born in, have lived or visited certain countries you may not be eligible to donate." 
        type="warning"
      />

      <EducationalAlerts 
        title="WHAT HAPPENS AFTER YOUR DONATION?" 
        message="To protect patients, your blood is tested for several types of Hepatitis, HIV, Syphilis and Malaria. In the event that your blood tests are found positive for any of the above diseases, the blood center shall contact you for repeat testing to confirm the outcome(s)." 
        type="warning"
      />
      <EducationalAlerts 
        title="THANK YOU FOR CHOOSING TO COME DONATE BLOOD TODAY!" 
        type="warning"
      />


        <div class="flex justify-between mt-4">
          <button type="button" class="px-4 py-2 border rounded text-orange-500 border-orange-500" @click="moveToPrevious" >Back</button>
          <CustomButton type="submit" label="next" class="px-4 py-2" bg-class="bg-custom-yellow"  />
         
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import EducationalAlerts from '@/components/EducationalAlerts.vue';
  import CustomButton from '@/components/CustomButton.vue';
  
  
  
  export default {
    name: "NewDonorEducationalPamphlet",
    components: {
      EducationalAlerts,
      CustomButton,
    },
    methods: {
      moveToNext() {
        this.$emit('move-to-next-step');
      },
      moveToPrevious() {
        this.$emit('move-to-previous-step');
      }
    },
  };
  </script>
  