<template>
  <div>
    <AlertLayout title="Reminders!" message="Kindly note that the default stats below are based on today's date. <br> 
        Kindly note that the stats below are ordered in the following manner SUPPLY/ DEMAND/ TARGET DEFICIT <br> 
        Kindly note that the stats below can be downloaded to an excel file format for further analysis" type="warning" />
    <div class="flex flex-wrap items-center justify-between pb-4 py-1">
      <div v-for="(group, index) in filteredBloodGroups" :key="index" class="mb-6">
        <h2 class="text-center font-semibold text-lg">{{ group.name }}</h2>
        <table class="w-full text-left rtl:text-right text-gray-700 border">
          <thead>
            <tr class="bg-gray-100">
              <th scope="col" class="px-4 py-2">
                <div class="flex items-center text-sm">Product</div>
              </th>
              <th scope="col" class="px-4 py-2">
                <div class="flex items-center text-sm">Supply</div>
              </th>
              <th scope="col" class="px-4 py-2">
                <div class="flex items-center text-sm">Demand</div>
              </th>
              <th scope="col" class="px-4 py-2">
                <div class="flex items-center text-sm">Target Deficit</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, productIndex) in bloodProducts" :key="productIndex" class="border-b">
              <td class="px-4 py-2">{{ product.name }}</td>
              <td class="px-4 py-2">{{ getSupply(product.unit_product_id, group.blood_group_id) }}</td>
              <td class="px-4 py-2">{{ getDemand(product.unit_product_id, group.blood_group_id) }}</td>
              <td class="px-4 py-2">{{ getTargetDeficit(product.unit_product_id, group.blood_group_id) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import api from '@/services/api'; // Import your API service

export default {
  name: 'LiveCounterTallyPage',
  components: {
    AlertLayout,
  },
  data() {
    return {
      bloodGroups: [],
      bloodProducts: [],
      bloodData: {}, // Data structure to store supply, demand, and deficit
    };
  },
  mounted() {
    this.user = this.$store.getters["auth/user"];
    this.fetchBloodGroups();
    this.fetchBloodProducts();
    this.fetchSupplyDemandData(); // New method to fetch the blood data (supply/demand/deficit)
  },
  computed: {
    filteredBloodGroups() {
      // Filter out blood groups named "Unknown"
      return this.bloodGroups.filter(group => group.name !== 'Unknown');
    }
  },
  methods: {
    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response || [];
      } catch (error) {
        console.error('Error fetching blood groups:', error);
      }
    },
    async fetchBloodProducts() {
      try {
        const response = await api.getUnitProducts();
        this.bloodProducts = response || [];
      } catch (error) {
        console.error('Error fetching blood products:', error);
      }
    },
    async fetchSupplyDemandData() {
      try {
        const response = await api.getSupplyDemandDeficit();
        // console.log('Backend response:', response);
        this.bloodData = response || {};
      } catch (error) {
        console.error('Error fetching supply and demand data:', error);
      }
    },
    getSupply(productId, groupId) {
      return this.bloodData[groupId]?.[productId]?.supply ?? 0;
    },
    getDemand(productId, groupId) {
      return this.bloodData[groupId]?.[productId]?.demand ?? 0;
    },
    getTargetDeficit(productId, groupId) {
      return this.bloodData[groupId]?.[productId]?.deficit ?? 0;
    }
  }
}
</script>

<style scoped>
/* Add your scoped styles here */
</style>
